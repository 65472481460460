export const highlightChoose = (selectedItemId: number) => {
    const guardTable = document.querySelector('table.guard-for-schedule');
    guardTable?.querySelectorAll(`tbody tr`)?.forEach(tr => {
        if (!tr.classList.contains(`row-id-${selectedItemId}`))
        tr.classList.remove('guard-for-schedule--selected');
    })
    guardTable?.querySelector(`tbody tr.row-id-${selectedItemId}`)?.classList.toggle('guard-for-schedule--selected')
}

export const clearHighlight = () => {
    const guardTable = document.querySelector('table.guard-for-schedule');
    guardTable?.querySelectorAll(`tbody tr`)?.forEach(tr => {
        tr.classList.remove('guard-for-schedule--selected');
    })
}