import {FC} from 'react'

const navItems = ['Set Job', 'Add Schedules', 'Assign Guards', 'Confirmation']
const navDescriptions = ['Add job details and related schools', 'Provide schedule details', 'Assign guards to job schedules', 'Confirm job <br /> details']

type Props = {
    activeStep: number
}

const JobFormNavigation: FC<Props> = ({activeStep}) => {

    return (
        <section className="job__from-nav">
            <div className="row">
                <div className="col-12">
                    <nav className="job__from-nav__card d-flex  overflow-auto">
                        <ul className="list-unstyled d-none d-md-flex flex-row mb-0 ml-auto mr-auto ">
                            {
                                navItems.map((item, indx) =>
                                    <li
                                        key={indx}
                                        className={`job__from-nav__card__step text-center pl-3 d-flex align-items-center flex-column
                                        ${activeStep === indx + 1 ? "job__from-nav__card__step--active" : ""}`}
                                    >
                                        <h6>{item}</h6>
                                        <span
                                            className="d-flex align-items-center justify-content-center"
                                            // onClick={() => onClick(indx + 1)}
                                        >{indx + 1}</span>
                                        {

                                            activeStep === indx +1 &&
                                            <small className="d-block mt-2" dangerouslySetInnerHTML={{ __html: navDescriptions[indx]  }} />
                                        }
                                    </li>
                                )
                            }
                            <li></li>
                        </ul>
                        <ul className="list-unstyled d-flex d-md-none flex-row mb-0 ml-auto mr-auto ">
                            {
                                <li
                                    className={`job__from-nav__card__step text-center pl-0 pl-md-3 d-flex align-items-center flex-column
                                        job__from-nav__card__step--active`}
                                >
                                    <h6>{navItems[activeStep - 1]}</h6>
                                    <span
                                        className="d-flex align-items-center justify-content-center"
                                    >{activeStep}</span>
                                    {

                                        <small className="d-block mt-2">{navDescriptions[activeStep - 1]}</small>
                                    }
                                </li>
                            }
                        </ul>
                    </nav>
                </div>
            </div>
        </section>
    )
}

export default JobFormNavigation
