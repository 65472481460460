import React from 'react'
import { NotificationTypes } from '../common/interfaces/INotification';
import { ILoggedInUser } from '../common/interfaces/IUser';
import { ICurrentClient } from 'common/interfaces/user/IClientUser';

interface IAppContext {
    authenticated: boolean,
    loading: boolean,
    currentUser: ILoggedInUser
    showLoader: (show: boolean) => void,
    setLoggedIn: (isLoggednIn: boolean) => void
    setCurrentUser: (user: ILoggedInUser) => void,
    showNotification: (type: NotificationTypes, msgs: Array<string> | string) => void;
    currentClient: ICurrentClient;
    setCurrentClient: (client: ICurrentClient) => void;
}

const AppContext = React.createContext<IAppContext>({} as IAppContext);

export default AppContext;