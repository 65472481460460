import React, {useEffect, useState} from 'react'
import {useContext} from 'react'
import {IBaseNotification, IManageNotification} from '../../common/interfaces/administration/INotificaion'
import {AdminCustomMessageTypes} from '../../common/enums/Administration'
import {NotificationTypes} from '../../common/interfaces/INotification'

import Layout from '../../components/layout/Layout'
import EditNotificationModal from '../../components/modal/administration/notifications/EditNotificationModal'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import NotificationsTableBody from '../../components/administration/NotificationsTableBody'

import {manageNotificationsContent} from '../../content/administration/AdministrationContent'
import AppContext from '../../context/AppContext'
import CustomMessageToClients from '../../components/modal/administration/notifications/CustomMessageToClients'
import CustomMessageToGuards from '../../components/modal/administration/notifications/CustomMessageToGuards'
import useActivateDeactivate from "../../customHooks/useActivateDeactivate";
import {DataService} from '../../common/services/DataService'
import BaseModal from '../../components/modal/BaseModal'
import {IClient} from '../../common/interfaces/clients/IClient'
import {useTableList} from '../../customHooks/useTableList'
import {CustomClientNotification} from '../../common/models/CustomNotification'
import RemoveBaseModal from '../../components/modal/administration/RemoveBaseModal'
import {ActivationDeactivation} from '../../common/enums/Actions'
import useGetModel from '../../customHooks/useGetModel'

type ModalState = { editEmail: boolean, customClientMsg: boolean, customGuardMsg: boolean, editTitle: string, customType: AdminCustomMessageTypes, showSuccess: boolean, successText: string, editOperations: boolean }

const ManageNotifications = () => {
    const [notifications, setNotifications] = useState<IManageNotification>({} as IManageNotification)
    const [selectedNotification, setSelectedNotification] = useState<IBaseNotification>({} as IBaseNotification)
    const [modal, setModal] = useState<ModalState>({} as ModalState)
    const {showNotification, showLoader, loading: isLoading} = useContext(AppContext)
    const {onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus} = useActivateDeactivate('notification');

    const {getModelData} = useGetModel<IManageNotification>('notification')
    const dataSvc = new DataService<IBaseNotification>({url: 'notification'})
    const sendclientsSvc = new DataService<CustomClientNotification>({url: 'notification/sendclients'})
    const sendguardSvc = new DataService<CustomClientNotification>({url: 'notification/sendguard'})

    const {tableData: clients, fetchData} = useTableList<IClient>('client', 0, false)

    useEffect(() => {
        getModelData(data => setNotifications(data))
    }, [])

    const onBtnClickHandler = (rowItem: IBaseNotification, notificationType?: string) => {
        setSelectedNotification(rowItem)
        setModal({
            ...modal,
            editEmail: true,
            showSuccess: false,
            editOperations: notificationType === 'operations' ? true : false,
            editTitle: `
            <h4 className="text-left mb-4">
            <span class="font-weight-bold">${rowItem.name}</span><br />
            Email Settings and Content</h4>`,
        })
    }

    const onCustomMsgBtnClick = async (notificationType: AdminCustomMessageTypes) => {
        if (AdminCustomMessageTypes.client) {
            await fetchClients()
        }
        setModal({
            ...modal,
            customClientMsg: notificationType === AdminCustomMessageTypes.client ? true : false,
            customGuardMsg: notificationType === AdminCustomMessageTypes.guard ? true : false,
            editTitle: notificationType === AdminCustomMessageTypes.client ? 'Send a Message to Client Contacts' : 'Send a Message to Guards',
            customType: notificationType
        })
    }

    const fetchClients = () => clients.length === 0 && fetchData(0, 0);

    const onEditNotificationSubmit = async (notification: IBaseNotification) => {
        showLoader(true)
        try {
            await dataSvc.update(notification, notification.id)
            setModal({
                ...modal,
                editEmail: false,
                showSuccess: true,
                successText: `
                <h4 className="text-left mb-4">
                <span class="font-weight-bold">${notification.name}</span><br />
                Email Settings and Content</h4>
                <h5>Your updates are saved</h5>`,
            })
            setNotifications(
                {
                    ...Object.fromEntries(Object.entries(notifications).map(([key, value]) => [
                            key, value.map((item: IBaseNotification) => {
                                item.id === notification.id && (item = notification);
                                return item
                            })
                        ])) as IManageNotification
                }
            )
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    const onStatusClickHandler = (rowItem: IBaseNotification) => {
        onRequestHandler({
            title: `Are you sure you want to ${rowItem.isActive ? 'deactivate' : 'activate'} notification <span class="font-weight-bold">${rowItem.name}</span>?`,
            itemInFocus: rowItem
        })
    }

    const onStatusSubmitHandler = (id: number) => {
        setNotifications(
            {
                ...Object.fromEntries(Object.entries(notifications).map(([key, value]) => {
                    return [
                        key, value.map((notification: IBaseNotification) => {
                            if (notification.id === id) {
                                notification.isActive = !notification.isActive
                            }
                            return notification
                        })
                    ]
                })) as IManageNotification
            }
        )
    }

    const sendCustomMsgToClients = async (data: CustomClientNotification) => {
        showLoader(true)
        try {
            await sendclientsSvc.updateWithoutId(data)
            setModal({
                ...modal,
                customClientMsg: false,
                successText: '<h4>Your Message Has Been Sent to Client Contacts</h4>',
                showSuccess: true
            })
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    const sendCustomMsgToGuard = async (data: CustomClientNotification) => {
        showLoader(true)
        try {
            await sendguardSvc.updateWithoutId(data)
            setModal({
                ...modal,
                customGuardMsg: false,
                successText: '<h4>Your Message Has Been Sent to Guards</h4>',
                showSuccess: true
            })
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Administration',
                        link: 'administration'
                    },
                ],
                currentPageTitle: 'Manage Notifications'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4">
                    <SectionTitle title="Manage Notifications"/>
                </div>
            </div>


            <div className="row">

                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <h5 className="mb-0">Job Notifications</h5>
                    <button type="button" className="btn btn-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0"
                            onClick={() => onCustomMsgBtnClick(AdminCustomMessageTypes.client)}>Send Custom Message to
                        Clients
                    </button>
                </div>

                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageNotificationsContent.thead}
                                tbody={notifications.clientNotificationViewModel ? notifications.clientNotificationViewModel.map(item => ({
                                    id: item.id,
                                    name: item.name,
                                    message: item.message,
                                    subject: item.subject,
                                    isActive: item.isActive
                                })) : []}
                                ignoreCols={[0, 2, 3, 4]}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed table--notifications'}
                            >
                                {
                                    (id, rowItem: IBaseNotification) => (
                                        <NotificationsTableBody
                                            onEmailClick={() => onBtnClickHandler(rowItem)}
                                            onStatusClick={() => onStatusClickHandler(rowItem)}
                                            itemId={id}
                                            rowItem={rowItem}
                                        />
                                    )
                                }
                            </DataTable>
                        ), [notifications.clientNotificationViewModel, isLoading])
                    }
                </div>
            </div>

            <div className="row my-5">

                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <h5 className="mb-0">Guard Notifications</h5>
                    <button type="button" className="btn btn-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0"
                            onClick={() => onCustomMsgBtnClick(AdminCustomMessageTypes.guard)}>Send A Custom Message to
                        Guards
                    </button>
                </div>

                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageNotificationsContent.thead}
                                tbody={notifications.guardNotificationViewModel ? notifications.guardNotificationViewModel.map(item => ({
                                    id: item.id,
                                    name: item.name,
                                    message: item.message,
                                    subject: item.subject,
                                    isActive: item.isActive,

                                })) : []}
                                ignoreCols={[0, 2, 3, 4]}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed table--notifications'}
                            >
                                {
                                    (id, rowItem: IBaseNotification) => (
                                        <NotificationsTableBody
                                            onEmailClick={() => onBtnClickHandler(rowItem)}
                                            onStatusClick={() => onStatusClickHandler(rowItem)}
                                            itemId={id}
                                            rowItem={rowItem}
                                        />
                                    )
                                }
                            </DataTable>
                        ), [notifications.clientNotificationViewModel, isLoading])
                    }
                </div>
            </div>

            <div className="row my-5">

                <div className="col-12 mb-4 d-flex flex-row align-items-center justify-content-between">
                    <h5 className="mb-0">Operations Notifications</h5>
                </div>

                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageNotificationsContent.thead}
                                tbody={notifications.operationsNotificationViewModel ?
                                    notifications.operationsNotificationViewModel.map(item => ({
                                        id: item.id,
                                        name: item.name,
                                        message: item.message,
                                        subject: item.subject,
                                        isActive: item.isActive,
                                        recipient: item.recipient
                                    }))
                                    : []
                                }
                                ignoreCols={[0, 2, 3, 4, 5]}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed table--notifications'}
                            >
                                {
                                    (id, rowItem: IBaseNotification) => (
                                        <NotificationsTableBody
                                            onEmailClick={() => onBtnClickHandler(rowItem, 'operations')}
                                            onStatusClick={() => onStatusClickHandler(rowItem)}
                                            itemId={id}
                                            rowItem={rowItem}
                                        />
                                    )
                                }
                            </DataTable>
                        ), [notifications.operationsNotificationViewModel, isLoading])
                    }
                </div>
            </div>
            {
                (modal.editEmail) &&
                <EditNotificationModal
                    onCancel={() => setModal({...modal, editEmail: false})}
                    onSubmit={notification => onEditNotificationSubmit(notification)}
                    title={modal.editTitle}
                    notification={selectedNotification}
                    showRecipient={selectedNotification.recipient ? true : false}
                />
            }

            {
                (modal.customClientMsg) &&
                <CustomMessageToClients
                    onCancel={() => setModal({...modal, customClientMsg: false})}
                    onSubmit={notification => sendCustomMsgToClients(notification)}
                    title={modal.editTitle}
                    type={modal.customType}
                    clients={clients}
                />
            }

            {
                (modal.customGuardMsg) &&
                <CustomMessageToGuards
                    onCancel={() => setModal({...modal, customGuardMsg: false})}
                    onSubmit={notification => sendCustomMsgToGuard(notification)}
                    title={modal.editTitle}
                    type={modal.customType}
                    clients={clients}
                />
            }

            {
                (modal.showSuccess || requestModals.showSuccess) && <BaseModal
                    show={modal.showSuccess || requestModals.showSuccess}
                    onCancel={() => {
                        setModal({...modal, showSuccess: false});
                        onCloseRequestModals()
                    }}
                    cancelBtnText={"Close"}
                >
                    <div dangerouslySetInnerHTML={{__html: modal.showSuccess ? modal.successText : modalText.title}}/>
                </BaseModal>
            }

            {
                requestModals.showRequest &&
                <RemoveBaseModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(
                        itemInFocus.isActive ? ActivationDeactivation.deactivate : ActivationDeactivation.activate,
                        {itemData: null, successCallback: id => onStatusSubmitHandler(id)},
                        `<h4><span class="font-weight-bold">${itemInFocus.name}</span> has been ${itemInFocus.isActive ? 'deactivated' : 'activated'}</h4>`
                    )}
                    title={modalText.title}
                >
                </RemoveBaseModal>
            }

        </Layout>
    )
}

export default ManageNotifications
