interface ModalSignature {
    [key: string]: any
}

export class ScheduleModals {
    showEdit: boolean = false;
    showEditSuccess: boolean = false;
    showAdd: boolean = false;
    showChangeGuard: boolean = false;
    showChangeGuardSuccess: boolean = false;
    showAddGuard: boolean = false;
    showAddGuardSuccess: boolean = false;
    showUnassignGuard: boolean = false;
    showUnassignGuardSuccess: boolean = false;
    showAddShift: boolean = false;
    showListModal: boolean = false;

    constructor({ showEdit = false, showEditSuccess = false, showAdd = false, showChangeGuard = false,
        showChangeGuardSuccess = false, showUnassignGuard = false, showUnassignGuardSuccess = false,
        showAddGuard = false, showAddGuardSuccess = false, showAddShift = false, showListModal = false }) {
        this.showEdit = showEdit;
        this.showEditSuccess = showEditSuccess;
        this.showAdd = showAdd;
        this.showChangeGuard = showChangeGuard;
        this.showChangeGuardSuccess = showChangeGuardSuccess;
        this.showUnassignGuard = showUnassignGuard;
        this.showUnassignGuardSuccess = showUnassignGuardSuccess;
        this.showAddGuard = showAddGuard;
        this.showAddGuardSuccess = showAddGuardSuccess;
        this.showAddShift = showAddShift;
        this.showListModal = showListModal;
    }
}

export class AddShiftToScheduleModals {
    showAddModal: boolean = false;
    showEditShiftModal: boolean = false
    showAddModalSuccess: boolean = false;

    constructor({ showAddModal = false, showAddModalSuccess = false, showEditShiftModal = false }) {
        this.showAddModal = showAddModal;
        this.showAddModalSuccess = showAddModalSuccess;
        this.showEditShiftModal = showEditShiftModal;
    };
}

export class EditPastJobShiftsModals {
    showEditModal: boolean = false
    showTempCheckInModal: boolean = false
    showTempCheckInSuccessModal: boolean = false
    showEditModalSuccess: boolean = false
    showAbsentGuardSuccess: boolean = false

    constructor({ showEditModal = false, showEditModalSuccess = false, showTempCheckInModal = false,
        showTempCheckInSuccessModal = false, showAbsentGuardSuccess = false,
    }) {
        this.showEditModal = showEditModal;
        this.showEditModalSuccess = showEditModalSuccess;
        this.showTempCheckInModal = showTempCheckInModal;
        this.showTempCheckInSuccessModal = showTempCheckInSuccessModal;
        this.showAbsentGuardSuccess = showAbsentGuardSuccess;
    };
}

export class EditJobShiftsModals {
    showGuardsModal: boolean = false
    showEditModal: boolean = false
    showEditConfirmation: boolean = false

    constructor({ showGuardsModal = false, showEditModal = false, showEditConfirmation = false }) {
        this.showGuardsModal = showGuardsModal;
        this.showEditModal = showEditModal;
        this.showEditConfirmation = showEditConfirmation;
    };
}


export class ViewShiftsOfScheduleModals {
    showListModal = false;
    showEditShiftModal = false;
    showEditShiftSuccessModal = false;
    showRemoveShiftModal = false;
    showRemoveShiftSuccessModal = false;
    showRemoveGuardModal = false;
    showRemoveGuardSuccessModal = false;
    showReplaceGuardModal = false;
    showReplaceGuardConfirmModal = false;
    showReplaceGuardSuccessModal = false;

    constructor({ showListModal = false, showEditShiftModal = false, showRemoveShiftModal = false,
        showRemoveGuardModal = false, showReplaceGuardModal = false, showEditShiftSuccessModal = false,
        showRemoveShiftSuccessModal = false, showRemoveGuardSuccessModal= false,
        showReplaceGuardSuccessModal = false, showReplaceGuardConfirmModal = false,
    }) {
        this.showListModal = showListModal;
        this.showEditShiftModal = showEditShiftModal;
        this.showRemoveShiftModal = showRemoveShiftModal;
        this.showRemoveGuardModal = showRemoveGuardModal;
        this.showReplaceGuardModal = showReplaceGuardModal;
        this.showEditShiftSuccessModal = showEditShiftSuccessModal;
        this.showRemoveShiftSuccessModal = showRemoveShiftSuccessModal;
        this.showRemoveGuardSuccessModal = showRemoveGuardSuccessModal;
        this.showReplaceGuardSuccessModal = showReplaceGuardSuccessModal;
        this.showReplaceGuardConfirmModal = showReplaceGuardConfirmModal;
    };
}

export class UpdateShiftModals {
    showUpdateModal: boolean = false
    showAddGuardModal: boolean = false
    showReplaceModal: boolean = false
    showAbsentModal: boolean = false
    showAbsentModalSuccess: boolean = false

    constructor({ showUpdateModal = false, showAddGuardModal = false, showReplaceModal = false, showAbsentModal = false, showAbsentModalSuccess = false }) {
        this.showUpdateModal = showUpdateModal;
        this.showAddGuardModal = showAddGuardModal;
        this.showReplaceModal = showReplaceModal;
        this.showAbsentModal = showAbsentModal;
        this.showAbsentModalSuccess = showAbsentModalSuccess;
    };
}

export class BulkActionsModal {
    showBulkGuardsModal = false;
    showBulkUnassignModal = false
    showBulkUnassignSuccessModal = false
    showBulkConfirmGuardsModal = false;
    showBulkSuccessGuardsModal = false;

    constructor({ showBulkGuardsModal = false, showBulkConfirmGuardsModal = false,
        showBulkSuccessGuardsModal = false, showBulkUnassignModal = false, showBulkUnassignSuccessModal = false }) {
        this.showBulkGuardsModal = showBulkGuardsModal;
        this.showBulkConfirmGuardsModal = showBulkConfirmGuardsModal;
        this.showBulkSuccessGuardsModal = showBulkSuccessGuardsModal;
        this.showBulkUnassignModal = showBulkUnassignModal;
        this.showBulkUnassignSuccessModal = showBulkUnassignSuccessModal;
    };
}

export class JobsActiveRightNowModals implements ModalSignature {
    [k: string]: any;
    absent = false;
    checkIn = false;
    checkOut = false;
    replaceGuard = false;
    successModal = false;
    confirmModal = false;
    assignGuard = false;
    updateStatusModal = false;
    successUpdate = false;
    addComment = false;
    bulkTempCheckIn = false;
    bulkConfirmCheckIn = false;
    bulkMarkAbsent = false;
    removeComment = false;
    removeCommentSuccess = false;

    constructor({ absent = false, checkIn = false, checkOut = false, replaceGuard = false, successModal = false,
        confirmModal = false, assignGuard = false, updateStatusModal = false, successUpdate = false,
        addComment = false, bulkTempCheckIn = false, bulkConfirmCheckIn = false, bulkMarkAbsent = false, removeComment = false,
        removeCommentSuccess = false}) {
        this.absent = absent;
        this.checkIn = checkIn;
        this.checkOut = checkOut;
        this.replaceGuard = replaceGuard;
        this.successModal = successModal;
        this.confirmModal = confirmModal;
        this.assignGuard = assignGuard;
        this.updateStatusModal = updateStatusModal;
        this.successUpdate = successUpdate;
        this.addComment = addComment;
        this.bulkTempCheckIn = bulkTempCheckIn;
        this.bulkConfirmCheckIn = bulkConfirmCheckIn;
        this.bulkMarkAbsent = bulkMarkAbsent;
        this.removeComment = removeComment;
        this.removeCommentSuccess = removeCommentSuccess;
    };
}
