import React from 'react'
import { FC } from 'react'
import { SVGPencil } from '../../assets/icons/SvgIcons'
import { IBaseNotification } from '../../common/interfaces/administration/INotificaion'

type Props = {
    onEmailClick: () => void
    onStatusClick: () => void
    itemId: number
    rowItem: IBaseNotification
}

const NotificationsTableBody: FC<Props> = ({ onEmailClick, onStatusClick, rowItem }) =>  {

    const renderStatus = () => {
        return (
            <div className={`notification-status notification-status--${rowItem.isActive ? 'active' : 'inactive'}`}>
                <button className={`btn ${rowItem.isActive ? 'btn-outline-aqua-blue' : 'btn-outline-dark-lighter--2'}`}
                    onClick={() => onStatusClick()}
                >
                    <span className="mr-2 d-inline-block notification-status__circle"></span>
                    { rowItem.isActive ? 'Active' : 'Inactive' }
                </button>
            </div>
        )
    }

    return (
        <>
            <td className="aling-middle">
                <div>
                    <button
                        className={`btn btn-aqua-blue`}
                        onClick={() => onEmailClick()}
                    >
                        <SVGPencil />
                    </button>
                </div>
            </td>

            <td className="aling-middle">  
                { renderStatus() }
            </td>
        </>
    )
}

export default NotificationsTableBody
