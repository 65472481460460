interface IBaseQueryBuilder {
  [index: string]: string
}

export const queryUrlXGuardReports = <T extends IBaseQueryBuilder>(queryParams: T, typeIsMulti?: boolean) => {
  let query = '';

  if (queryParams.startDate) {
    query+= `&StartDate=${encodeURIComponent(queryParams.startDate)}`
  }

  if (queryParams.endDate) {
    query+= `&EndDate=${encodeURIComponent(queryParams.endDate)}`
  }

  if (queryParams.guardNameOrEmployeeNumber) {
    query+= `&GuardName=${encodeURIComponent(queryParams.guardNameOrEmployeeNumber)}`
  }

  if (queryParams.jobNameOrId) {
    query+= `&Job=${encodeURIComponent(queryParams.jobNameOrId)}`
  }

  if (typeIsMulti && queryParams.type) {
    query+= `${queryParams.type}`.split(';').reduce((acc, r) => acc +`&Type=${r}`, '')
  }

  if (!typeIsMulti && queryParams.type) {
    query+= `&Type=${queryParams.type}`
  }

  // Guard Messages
  if (queryParams.topic) {
    query+= `&Topic=${queryParams.topic}`
  }

  if (queryParams.keyWord) {
    query+= `&Keyword=${queryParams.keyWord}`
  }

  if (queryParams.resolvedOnly) {
    query+= `&ResolvedOnly=${queryParams.resolvedOnly}`
  }

  if (queryParams.unresolvedOnly) {
    query+= `&UnresolvedOnly=${queryParams.unresolvedOnly}`
  }

  if (queryParams.response) {
    query+= String(queryParams.response).split(';').reduce((acc, r) => acc +`&Response=${r}`, '')
  }

  if (queryParams.question) {
    query+= `&Questions=${queryParams.question}`
  }
  
  if (queryParams.shiftPeriod) {
    query+= `&ShiftPeriod=${queryParams.shiftPeriod}`
  }
  if (queryParams.schoolYearId) {
    query+= `&SchoolYearId=${queryParams.schoolYearId}`
  }
  if (queryParams.shiftDate) {
    query+= `&ShiftDate=${queryParams.shiftDate}`
  }
  if (queryParams.status) {
    query+= `&Status=${queryParams.status}`
  }
  return query;
}
