import { IBaseModalProps } from 'common/interfaces/IModal'
import React, { FC } from 'react'
import BaseModal from '../BaseModal'
import { IJobGuardLeaves } from "common/interfaces/jobs/IJobGuardLeaves"
import { momentDateFormat } from 'utils/DateFormatting'

interface IProps extends IBaseModalProps {
    leaves: IJobGuardLeaves[]
}

const GuardsLeaveForJobModal: FC<IProps> = ({ onCancel, leaves }) => {
    return (
        <BaseModal
            show
            onCancel={onCancel}
            cancelBtnText={"Close"}
        >
            <h4 className="mb-5">There is some leave booked during schedule(s) for selected guard(s): </h4>
            {
                leaves && leaves.map(item => 
                    <>
                        <div className="row mb-3">
                            <div className="col-4 col-md-4 details-information__title mb-0">
                                <h6 className="mb-0">Guard:</h6>
                            </div>
                            <div className="col-8 col-md-8 details-information__value">
                                <p className="mb-0">{item.fullName}</p>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-4 col-md-4 details-information__title mb-0">
                                <h6 className="mb-0">Booked leave(s):</h6>
                            </div>
                            <div className="col-8 col-md-8 details-information__value">
                                <p className="mb-0">
                                    {
                                        `${momentDateFormat(item.guardBookLeaves.startDate)}
                                        ${item.guardBookLeaves.endDate && " - " + momentDateFormat(item.guardBookLeaves.endDate)}
                                        ${item.guardBookLeaves.shiftPeriod ? (", " + item.guardBookLeaves.shiftPeriod) : ''}
                                        `
                                    }
                                </p>
                            </div>
                        </div>
                    </>
                )
            }
        </BaseModal>
    )
}

export default GuardsLeaveForJobModal
