import React, { FC } from 'react'
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary'
import { IJobShiftsInNeedOfGuards } from 'common/interfaces/jobs/IJob'
import BaseModal from '../../BaseModal'
import ConfirmBulkShiftBody from './ConfirmBulkShiftBody'

type Props = {
    onClose: () => void
    onConfirm: () => void
    title: string
    shifts: IJobShiftsInNeedOfGuards[]
    vocabulary: IVocabulary[]
}

const ConfirmBulkShiftModal: FC<Props> = ({ onClose, title, shifts, vocabulary, onConfirm }) => {
    return (
        <BaseModal
            show
            cancelBtnText={'Close'}
            submitBtnText={'Confirm'}
            onCancel={onClose}
            onSubmit={onConfirm}
            className="available-guards-modal"
        >
            <ConfirmBulkShiftBody
                shifts={shifts}
                title={title}
                vocabulary={vocabulary}
            />
        </BaseModal>
    )
}

export default ConfirmBulkShiftModal
