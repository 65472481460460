import React, { FC } from 'react'
import { useState } from 'react'
import { AdminCustomMessageTypes } from '../../../../common/enums/Administration'
import { IBaseModalProps } from '../../../../common/interfaces/IModal'
import CustomSelectControl from '../../../custom-select/CustomSelectControl'
import BaseModal from '../../BaseModal'
import ConfirmNotificationModal from './ConfirmGuardNotificationModal'
import { IClient } from '../../../../common/interfaces/clients/IClient'
import CustomBaseNotificationEmail from './CustomBaseNotificationEmail'
import { CustomGuardNotification } from '../../../../common/models/CustomNotification'
import { convertToHtml } from '../../../../utils/Converting'
import useSendCustomNotification from '../../../../customHooks/useSendCustomNotification'
import { IValidationBody, ValidationRules } from '../../../../common/interfaces/IValidation'
import { validateFiled, highlightError, removeHighlightError } from '../../../../utils/Validation'

interface Props extends IBaseModalProps {
    type: AdminCustomMessageTypes
    clients?: IClient[]
}

const CustomMessageToGuards: FC<Props> = ({ onCancel, onSubmit, title, clients }) => {
    const [modals, setModals] = useState<{ main: boolean, confirm: boolean, success: boolean }>({ main: true, confirm: false, success: false });

    const { stepOneOpts, stepTwoOpts, handleRadioBtns, state, onChangeHandler, setState, decodeRadioBtns } = useSendCustomNotification<CustomGuardNotification>(new CustomGuardNotification(), 'guard')

    const onSaveDataClick = () => {
        const body  = {
            ...state,
            howSendMessage: decodeRadioBtns().howSendMessage,
            toWhomeSendMessage: decodeRadioBtns().toWhomeSendMessage,
            message: convertToHtml(state.editor)
        }
        if (!formIsValid(body)) return;

        setState(body)
        setModals({ ...modals, main: false, confirm: true })
    }

    const formIsValid = (formData: CustomGuardNotification) => {
        let errors: IValidationBody[] = [];

        errors = [...errors, ...validateFiled(formData.subject, "subject", ValidationRules.required)]
        formData.message.trim() === '<p></p>' && (errors = [...errors, ...[{ fieldName: 'content-editor__wrapper', errorMessage: '' }]])
        stepTwoOpts.allAssigned && (errors = [...errors, ...validateFiled(formData.clientsId, "clientsId", ValidationRules.required)])

        if (errors.length !== 0) {
            highlightError(errors)
            return false
        }
        else {
            removeHighlightError()
            return true
        }
    }

    const onConfirmBtnClick = () =>  onSubmit && onSubmit(state)

    return (
        <>
        <BaseModal
            show={modals.main}
            onCancel={() => onCancel()}
            onSubmit={() => onSaveDataClick()}
            submitBtnText={'Send'}
            title={title}
            className="custom-message"
        >
            <form>
                <h6 className="mt-0 mt-md-4 mb-3 font-weight-bold text-dark">
                    <span className="list-counter mr-2 d-inline-flex align-items-center justify-content-center">1</span>
                    How do you want to send your message?
                </h6>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="sendEmail" name="sendEmail" className="custom-control-input"
                            checked={!!stepOneOpts.sendEmail}
                            onChange={e => handleRadioBtns(1, "sendEmail")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="sendEmail">Send email to all</label>
                    </div>
                </div>

                <h6 className="mt-4 mb-3 font-weight-bold text-dark">
                    <span className="list-counter mr-2 d-inline-flex align-items-center justify-content-center">2</span>
                    To which guards do you want your message to go?
                </h6>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="all" name="all" className="custom-control-input"
                            checked={!!stepTwoOpts.all}
                            onChange={e => handleRadioBtns(2, "all")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="all">All Guards</label>
                    </div>
                </div>

                <div className="form-group">
                    <div className="custom-control custom-control-radio custom-control-inline">
                        <input type="radio" id="allAssigned" name="allAssigned" className="custom-control-input"
                            checked={!!stepTwoOpts.allAssigned}
                            onChange={e => handleRadioBtns(2, "allAssigned")}
                            />
                        <label className="custom-control-label custom-control-radio-label" htmlFor="allAssigned">All Guards assigned to jobs of a client</label>
                    </div>
                </div>

                { stepTwoOpts.allAssigned && clients?.length !== 0 &&
                    <div className="form-group">
                        <label>Select Clients</label>
                        <CustomSelectControl
                            options={clients ? clients.map(client => ({
                                id: client.id,
                                name: client.organizationName
                            })) : []}
                            onChange={val => onChangeHandler(val, 'clientsId')}
                            value={state.clientsId}
                            isMulti={true}
                            className={`custom-react-select--clientsId`}
                        />
                    </div>
                }

                <CustomBaseNotificationEmail
                    onChangeHandler={(field, key) => onChangeHandler(field, key)}
                    fields={{ subject: state.subject, message: state.editor }}
                />

            </form>
        </BaseModal>

        {
            modals.confirm &&
            <ConfirmNotificationModal
                onCancel={() => setModals({ ...modals, confirm: false, main: true})}
                onSubmit={() => onConfirmBtnClick()}
                data={state}
                clients={clients}
            />
        }
        </>
    )
}

export default CustomMessageToGuards
