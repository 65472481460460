import { NotificationTypes } from 'common/interfaces/INotification';
import { DataService } from 'common/services/DataService';
import ResourceDataService, { IResourceData } from 'common/services/ResourceDataService';
import AppContext from 'context/AppContext';
import { useContext } from 'react';

export interface BookLeaveBody {
	startDate: string | Date;
	endDate: string | Date;
	reason: string;
	shifts: string;
    guardId?: string;
}
type UseLeaveReportActions = {
	updateGuardBookedLeave: (id: string, body: BookLeaveBody, successCallback: (id: string) => void) => Promise<void>;
	deleteGuardBookedLeave: (id: string, successCallback: (id: string) => void) => Promise<void>;
	checkGuardShifts: (id: string, body?: BookLeaveBody) => Promise<boolean>;
	approveOrDisapproveGuardBookedLeave: (
		id: string,
		request: 'approve' | 'disapprove',
		successCallback: (id: string) => void
	) => Promise<void>;
};
const useLeaveReportActions = (): UseLeaveReportActions => {
    const { showLoader, showNotification } = useContext(AppContext)
	const dataService = new DataService({ url: 'guardbookedleave' });
	const resourceDataService = new ResourceDataService({ url: 'guardbookedleave' });

    const generateQueryParams = (params?: BookLeaveBody) => {
        if (params) {
            let query = '?'
            if (params.guardId) {
                query += `Id=${params.guardId} `
            }
            if (params.startDate) {
                query += `StartDate=${params.startDate} `
            }
            if (params.endDate) {
                query += `EndDate=${params.endDate} `
            }
            if (params.shifts) {
                query += `Shifts=${params.shifts} `
            }
            return query.trim().split(' ').join('&')
        }
       
       return ''
    }

	const updateGuardBookedLeave = async (
		id: string,
		body: BookLeaveBody,
		successCallback: (id: string) => void
	): Promise<void> => {
		showLoader(true);
		try {
			const response = await dataService.update(body, id);
			console.log(response);
			successCallback(id);
		} catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
		} finally {
			showLoader(false);
		}
	};

	const deleteGuardBookedLeave = async (id: string, successCallback: (id: string) => void): Promise<void> => {
		showLoader(true);
		try {
			await dataService.delete(id);
			successCallback(id);
		} catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
		} finally {
			showLoader(false);
		}
	};

	const checkGuardShifts = async (id: string, body?: BookLeaveBody): Promise<boolean> => {
		try {
			const response: IResourceData<{ hasShifts: boolean }> = (await resourceDataService.getAll(
				`/${id}${generateQueryParams(body)}`
			)) as IResourceData<{ hasShifts: boolean }>;
			return response.data.hasShifts;
		} catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
			return false;
		}
	};

	const approveOrDisapproveGuardBookedLeave = async (
		id: string,
		request: 'approve' | 'disapprove',
		successCallback: (id: string) => void
	): Promise<void> => {
		showLoader(true);
		try {
			const response = await dataService.update({ request }, id, request);
			console.log(response);
			successCallback(id);
		} catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
		} finally {
			showLoader(false);
		}
	};

	return {
		updateGuardBookedLeave,
		deleteGuardBookedLeave,
		checkGuardShifts,
		approveOrDisapproveGuardBookedLeave,
	};
};

export default useLeaveReportActions;
