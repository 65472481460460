import { IGuardReport } from '../common/interfaces/guards/IGuard';
import { IJobReports } from '../common/interfaces/jobs/IJob'

export const useLateOrAbsent = () => {
    const setLateOrAbsentValue = (itemInFocusId: number, originalData: IJobReports[] | IGuardReport[], ignoreUnassignedStatus = false) => {
        let result: string = '';
        const classNames = {
            lateOnAspTime: 'late-on-asp',
            lateOnClientTime: 'late-on-client'
        }
        const itemInFocus = originalData.find(item => item.id === itemInFocusId)
        if (itemInFocus === undefined) return;
        const buffer = originalData[0].bufferTime
        const late = itemInFocus.status
        const hasGuard = (itemInFocus as IJobReports).guardName !== null ? true : false

        if (late === null || late <= 0) {
            result = ''
        } else if (late <= buffer) {
            result = classNames.lateOnAspTime
        } else if (late > buffer) {
            result = classNames.lateOnClientTime
        } else result = '';
        
        return (
            <>
                { 
                    !hasGuard && !ignoreUnassignedStatus ?
                    <td className="align-middle">
                        <span>Unassigned</span>
                    </td>
                    :
                    <td className={`align-middle ${result}`}>
                        <span>{ late === null ? 'Absent' : (late <= 0 ? '-' : `Late ${late} minute(s)`) }</span>
                    </td>
                }
            </>
        )
    }

    return {
        setLateOrAbsentValue
    }
}