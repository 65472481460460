export enum NotificationTypes {
    success = 'success',
    warning = 'warning',
    danger = 'danger',
}

export interface INotification {
    show: boolean,
    type: NotificationTypes,
    msgs: Array<string> | string
} 