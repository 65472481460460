export type DefaultCenter = {
    lat: number,
    lng: number
}

export const circleOptions = {
    strokeColor: '#4EC3E0',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: '#4EC3E0',
    fillOpacity: 0.4,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
}