import React from 'react'
import UserActivityTable from '../../components/administration/UserActivityTable'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'

const ActivityReport = () => {
    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: '/administration'
                }],
                currentPageTitle: 'Recent Activity'
            }}
        >
            <div className="row mb-4">
                <div className="col-12 mb-4 d-flex flex-row align-items-center justify-content-between">
                    <SectionTitle title="Recent Activity"/>
                </div>
            </div>
            <UserActivityTable baseUrl="log?ShowAll=true" allActivity={true}/>

        </Layout>
    )
}

export default ActivityReport
