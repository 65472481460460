import { useContext } from "react"
import { useState } from "react"
import { AddNewItemEnum } from '../common/enums/AddNewItemEnum'
import { NotificationTypes } from "../common/interfaces/INotification"
import { DataService } from "../common/services/DataService"
import AppContext from "../context/AppContext"
import { useTenant } from "./useTenant"

type BodyResponse = {
    id: number,
    cityId?: number
}

type SubmitNewItemExtraProps = {itemType: AddNewItemEnum | null, url?: string, hideLoader: boolean}

export const useAddNewItem = () => {
    const [ showAddNewItemModal, setShowAddNewItemModal ] = useState<boolean>(false)
    const [ newItemModalTypo, setNewItemModalTypo ] = useState<{ title: string, placeholder: string }>({ title: "", placeholder: "" })
    const [ currentItemType, setCurrentItemType ] = useState<AddNewItemEnum | null>(null);
    const [svcUrl, setSvcUrl] = useState<string>('')
    const appContext = useContext(AppContext)

    const mapModalTypo = (itemType: AddNewItemEnum) => {
        switch (itemType) {
            case AddNewItemEnum.city:
                setSvcUrl('city')
                return {
                    title: "Add a city", placeholder: 'Enter city name'
                }
            case AddNewItemEnum.zone:
                setSvcUrl('zone')
                return {
                    title: "Add a zone", placeholder: 'Enter zone name'
                }
            case AddNewItemEnum.board:
                setSvcUrl('school/schoolboard')
                return {
                    title: "Add a School Board", placeholder: 'Enter school board name (in full)'
                }
            case AddNewItemEnum.holidayName:
                setSvcUrl('tes')
                return {
                    title: "Add a New Holiday to this Holiday Type", placeholder: 'Enter a holiday name'
                }
            case AddNewItemEnum.holidayType:
                setSvcUrl('')
                return {
                    title: "Add a New Holiday Type", placeholder: 'Enter a holiday type name'
                }
            case AddNewItemEnum.ASPAdminDeactivationReason:
            case AddNewItemEnum.ASPUserDeactivationReason:
            case AddNewItemEnum.ClientDeactivationReason:
            case AddNewItemEnum.GuardAbsenceReason:
            case AddNewItemEnum.GuardActivationReason:
            case AddNewItemEnum.GuardBookAbsenceReason:
            case AddNewItemEnum.GuardBookedLeaveReason:
            case AddNewItemEnum.GuardDeactivationReason:
            case AddNewItemEnum.GuardRemoveJobScheduleReason:
            case AddNewItemEnum.GuardRemoveJobShiftReason:
            case AddNewItemEnum.GuardReplacementReason:
            case AddNewItemEnum.JobActivationReason:
            case AddNewItemEnum.JobDeactivationReason:
            case AddNewItemEnum.ScheduleDeactivationReason:
            case AddNewItemEnum.DeviceBrand:
            case AddNewItemEnum.ClientContactDeactivationReason:
            case AddNewItemEnum.IncidentType:
            case AddNewItemEnum.ContactFormTopicsOnGuardMobileApp:
            case AddNewItemEnum.ArrivalTypesOnGuardMobileApp:
            case AddNewItemEnum.SecurityQuestionsForGuards:
                setSvcUrl(`vocabulary/${itemType}`)
                return {
                    title: '', placeholder: ''
                }
            default:
                setSvcUrl('')
                return {
                    title: "Add a item", placeholder: 'Enter item name'
                }
        }
    }

    const requestBody = (newItem: any) => {
        switch (currentItemType) {
            case AddNewItemEnum.city:
                return { cityName: newItem.itemName }
            case AddNewItemEnum.zone:
                return { zoneName: newItem.itemName }
            case AddNewItemEnum.board:
                return { boardName: newItem.itemName }
            case AddNewItemEnum.holidayName:
                return { name: newItem.itemName }
            case AddNewItemEnum.holidayType:
                return { name: newItem.itemName }
            case AddNewItemEnum.ASPAdminDeactivationReason:
            case AddNewItemEnum.ASPUserDeactivationReason:
            case AddNewItemEnum.ClientDeactivationReason:
            case AddNewItemEnum.GuardAbsenceReason:
            case AddNewItemEnum.GuardActivationReason:
            case AddNewItemEnum.GuardBookAbsenceReason:
            case AddNewItemEnum.GuardBookedLeaveReason:
            case AddNewItemEnum.GuardDeactivationReason:
            case AddNewItemEnum.GuardRemoveJobScheduleReason:
            case AddNewItemEnum.GuardRemoveJobShiftReason:
            case AddNewItemEnum.GuardReplacementReason:
            case AddNewItemEnum.JobActivationReason:
            case AddNewItemEnum.JobDeactivationReason:
            case AddNewItemEnum.ScheduleDeactivationReason:
            case AddNewItemEnum.DeviceBrand:
            case AddNewItemEnum.ClientContactDeactivationReason:
            case AddNewItemEnum.IncidentType:
            case AddNewItemEnum.ContactFormTopicsOnGuardMobileApp:
            case AddNewItemEnum.ArrivalTypesOnGuardMobileApp:
            case AddNewItemEnum.SecurityQuestionsForGuards:
                return { name: newItem.itemName }
            default:
                break;
        }
    }

    const onAddNewItemClick = (itemType: AddNewItemEnum) => {
        console.log(itemType, '-----');
        const typo = mapModalTypo(itemType)
        setCurrentItemType(itemType)
        setNewItemModalTypo({ title: typo.title, placeholder: typo.placeholder })
        setShowAddNewItemModal(true)
    }

    const onAddNewItemSubmit = async (newItem: any, callback: (option: any, type: AddNewItemEnum) => void, useFakeBackend = false, extraOpts: SubmitNewItemExtraProps = {itemType: null, url: undefined, hideLoader: false}) => {
        !extraOpts.hideLoader && appContext.showLoader(true);
        const svc = new DataService({ url: svcUrl || extraOpts.url || '' })
        try {
            console.log(newItem)
            const body = extraOpts.url ? newItem : requestBody(newItem);
            if (!useFakeBackend) {
                const response = await svc.create(body);
                const newItemId = (response.data as BodyResponse).id ? (response.data as BodyResponse).id : response.data;
                (currentItemType !== null || extraOpts.itemType !== null) && callback({...{ id: newItemId }, ...body }, extraOpts.itemType || currentItemType || AddNewItemEnum.city);
            } else {
                currentItemType !== null && callback({...{ id: new Date().getTime() }, ...body }, currentItemType);
            }

            setShowAddNewItemModal(false)
            setCurrentItemType(null)
            !extraOpts.hideLoader && appContext.showLoader(false);
        } catch (error: any) {
            !extraOpts.hideLoader && appContext.showLoader(false);
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onAddNewItemModalClose = () => {
        setCurrentItemType(null)
        setShowAddNewItemModal(false)
    }

    return {
        showAddNewItemModal, newItemModalTypo, onAddNewItemClick, onAddNewItemSubmit, onAddNewItemModalClose, currentItemType, setShowAddNewItemModal
    }
}