import React, { FC } from 'react'
import { IGuardDevice } from '../../../common/interfaces/guards/IGuard'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import InputMask from 'react-input-mask';
import { validatePhoneNumber } from '../../../utils/Validation';
import { isObjectEmpty } from 'utils/IsObjectEmpty';

type Props = {
    device: IGuardDevice
    onChange: (value: React.ChangeEvent<HTMLInputElement> | string | Date | boolean, fieldName?: string) => void
    vocabulary: IVocabulary[]
}

const ASPDeviceModal: FC<Props> = ({ device, onChange, vocabulary }) => {
    
    return (
        <form>
            <div className="row mb-3">
                <div className="col-md-4 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0 required">Brand: </label>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <CustomSelectControl 
                        options={vocabulary} 
                        onChange={e => onChange(e, "brand")}
                        placeholder="Select device brand"
                        value={device.brand}
                        className="custom-react-select--brand"
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0 required">Model: </label>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <input value={device.model} onChange={e => onChange(e)} name="model" type="text" id="model" className="form-control"/>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Serial Number: </label>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <input value={device.serialNumber} onChange={e => onChange(e)} name="serialNumber" id="serialNumber" type="text" className="form-control"/>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-4 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Number: </label>
                </div>
                <div className="col-md-8 form-group mb-0">
                    <InputMask
                        mask={"999-999-9999"}
                        value={device.aspMobile}
                        onChange={e => onChange(e)}
                        name="aspMobile"
                        id="aspMobile"
                        type="text"
                        className="form-control"
                        onBlur={e => validatePhoneNumber(e)}
                    />
                </div>
            </div>
        </form>
    )
}

export default ASPDeviceModal
