import { GuardAvailability } from "common/models/Guard";
import AppContext from "context/AppContext";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { IGuardAvailability } from "../common/interfaces/guards/IGuard";
import { IHoliday } from "../common/interfaces/IHoliday";
import { IJobSchedule } from "../common/interfaces/jobs/IJob";
import { inputDataChecker } from "../utils/InputDataChecker";
import { useHolidays } from "./useHolidays";
import { useOnIputChangeV2 } from "./useOnInputChangeV2";

type Model = {
    [index: string]: any
}

export const useCalendarFormV2 = <T extends Model> (keyName: string, defaultState: T,
    allHolidays: IHoliday[]) => {    
    const [calendarFormData, setCalendarFormData] = useState(defaultState)
    const holidays = useRef({ original: [] as IHoliday[], filtered: [] as IHoliday[] })
    const { getWeekDayNum, checkIfDayHasHoliday } = useHolidays()
    const { onChange } = useOnIputChangeV2<T>(calendarFormData, setCalendarFormData)
    const { showLoader } = useContext(AppContext);

    useEffect(() => {
        setHolidaysData(
            allHolidays.map(holiday => {
                holiday.remove = false;
                return holiday
            })
        )}
    , [allHolidays])

    const setHolidaysData = (incomeHolidays: IHoliday[]) => {
        holidays.current = {
            original: [...incomeHolidays],
            filtered: [...incomeHolidays]
        }
    }

    useEffect(() => { showLoader(false) }, [calendarFormData])

    const onCalendarChange = (event: React.ChangeEvent<HTMLInputElement> | string | Date | boolean | null,
        fieldName?: string | null, id?: number) => {
        const res = inputDataChecker(event, fieldName);

        setCalendarFormData((prevState: any) => ({
                ...prevState,
                [keyName]: prevState[keyName].map((item: any) => {
                    if (id && (item.uid === id || item.id === id)) {
                        if (res.field === "repeatOn" && res.value !== '') {
                            const days = item.repeatOn ? getWeekDayNum(item.repeatOn) : [];
                            
                            item[res.field] = (days.includes(String(res.value))
                            ? [...days].filter(day => day !== String(res.value))
                            : [...days, String(res.value)]).join(';');
                        } else {
                            if (res.field === "schoolBoardId") {
                                item.schoolId = 0
                            }
                            item[res.field] = res.value
                        }
                        const allowedFieldsForTrackingHolidays = ['startDate', 'endsOn', 'repeatOn']
                        if (allowedFieldsForTrackingHolidays.includes(res.field )) {
                            item.holidays = trackHolidays(item, id)
                        }
                    }
                return item
            })
        }));
    }

    const trackHolidays = (schedule: IGuardAvailability | IJobSchedule, id: number) => {
        const holidaysCopy = [...holidays.current.original];
        return checkIfDayHasHoliday<IGuardAvailability | IJobSchedule>(holidaysCopy, schedule)
        .filter(holiday => getWeekDayNum(holiday.dayOfWeek).some(day => getWeekDayNum(schedule.repeatOn).includes(day)))
        .map(holiday => {
            holiday.parentId = id;
            const mathchedHoliday = schedule.holidays.find((item: any) => moment(item.startDate).isSame(holiday.startDate) && moment(item.endDate).isSame(holiday.endDate))
            holiday.remove = mathchedHoliday ? mathchedHoliday.remove : false
            return holiday
        })

    }

    const onHolidayChange = (holidayId: number, yesOrNo: boolean, parentId: number, keyName: string, originalData: T) => {
        const matchCondition = (scheduleId: number | undefined) => scheduleId === parentId

        setCalendarFormData(prevState => ({
            ...prevState,
            [keyName]: prevState[keyName].map((item: any) => {
                if (matchCondition(item.id) || matchCondition(item.uid)) {
                    item.holidays = [...item.holidays].map((holiday: IHoliday) => {
                        if (holiday.id === holidayId) {
                            holiday.remove = yesOrNo ? true : false;
                        }
                        return holiday
                    });
                }
                return item;
            })
        }))
    }

    const onRemoveItemFromCalendar = (scheduleInFocusId: number, keyName: string, originalData: T) => {
        setCalendarFormData({
            ...originalData,
            [keyName]: originalData[keyName]
            .filter((schedule: IGuardAvailability | IJobSchedule) => (
                schedule.uid !== scheduleInFocusId
            ))
        })
    }

    const onCalendarSubmit = (originalData: T, callBack?: (res: T) => void) => {
        const formDataCopy = [...calendarFormData[keyName]];
        let lastObject = {...formDataCopy[formDataCopy.length - 1]};
        delete lastObject.id;
        
        if (Object.keys(lastObject).length === 0) {
            lastObject = new GuardAvailability()
        }
        
        lastObject.guardId && delete lastObject.guardId;
        lastObject.guardName && delete lastObject.guardName;
        lastObject.assigned = false
        const maxId = Math.max(...formDataCopy.map((item: any) => item.id? item.id : item.uid), 0)
        lastObject.uid = maxId + 1;
        lastObject.edited = true;
        formDataCopy.push(lastObject)

        const result = { ...originalData,
            [keyName]: formDataCopy
        }
        callBack && callBack(result);
        setCalendarFormData(result)
    }

    return {
        onCalendarSubmit, calendarFormData, setCalendarFormData, onCalendarChange, holidays,
        onHolidayChange, onRemoveItemFromCalendar, trackHolidays, onChange
    }
}
