import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {SVGApprove, SVGEraser} from '../../../assets/icons/SvgIcons'
import {IJobSchedule, IJobScheduleRequested,} from '../../../common/interfaces/jobs/IJob'
import Layout from '../../../components/layout/Layout'
import BaseModal from '../../../components/modal/BaseModal'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import {useTableList} from '../../../customHooks/useTableList'
import {momentDateFormat} from '../../../utils/DateFormatting'
import {VocabularyEnum} from '../../../common/enums/VocabularyEnum'
import {useVocabulary} from '../../../customHooks/vocabulary/useVocabulary'
import CustomSelectControl from '../../../components/custom-select/CustomSelectControl'
import DeactivateModal from "../../../components/modal/users/DeactivateModal";
import {manageDeactivatedSchedulesContent} from "../../../content/administration/AdministrationContent";
import {ActivationDeactivation} from "../../../common/enums/Actions";
import {useListFilter} from "../../../customHooks/useListFilter";
import useActivateDeactivate from "../../../customHooks/useActivateDeactivate";
import {useMatchArrayOfReasons} from '../../../customHooks/useMatchArrayOfReasons'
import {AuthService} from '../../../common/auth/AuthService'
import {Roles} from '../../../common/enums/Roles'
import AppContext from '../../../context/AppContext'
import AutocompleteField from '../../../components/autosuggestField/AutocompleteField'
import {useSchoolYear} from '../../../customHooks/useSchoolYear'
import ReactivateScheduleModalBody from 'components/modal/administration/ReactivateScheduleModalBody'

const DeactivedSchedules = () => {
    const {tableData, isLoading, setTableData, fetchData, onClearFilterQuery} = useTableList<IJobScheduleRequested>('schedule/inactive', 0)
    const {onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse} = useActivateDeactivate('schedule');
    const {
        generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, schoolBoards,
        cities, onSearchChange, onFilterSearchClearClick, getCities, getZones, getSchoolBoard, onFilterDefaultSchoolYear, zones
    } = useListFilter("schedule/inactive")
    const {vocabulary: shifts} = useVocabulary(VocabularyEnum.shift, true)
    const {vocabulary: reasonsForDeactivation} = useVocabulary(VocabularyEnum.scheduleDeactivation, true)
    const {vocabulary: jobReasonsForDeactivation} = useVocabulary(VocabularyEnum.jobDeactivation, true)
    const {matchNameWithMultipleReasons} = useMatchArrayOfReasons()

    const {currentUser} = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], currentUser.role)
    const {schoolYears, getSchoolYears: setDefaultSchoolYears, defaultYear} = useSchoolYear();

    useEffect(() => {
        getCities()
        getSchoolBoard()
        getZones();
    }, [])

    useEffect(() => {
        if (!filter.hasValue) {
            setDefaultSchoolYears(onFilterDefaultSchoolYear, yearId => 
                fetchData(1, 24, generateFilterQuery(false, false, undefined, yearId ? `&SchoolYearId=${yearId}` : '')), true
            );
        }
    }, [filter.hasValue])

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') filter.keyWord !== "" && fetchData(1, 0, generateFilterQuery(false), false, true)
    }

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Jobs',
                        link: 'jobs'
                    },
                    {
                        title: 'Job Schedules',
                        link: 'jobs/schedules'
                    },
                ],
                currentPageTitle: 'Deactivated Schedules'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Deactivated Schedules"/>
                    <Link to="/jobs/job-schedules/schedules/deactivate"
                          className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View deactivation requests</Link>
                </div>
            </div>

            {/* Start Filters */}
            <div className="row mb-4">
                <div className="col-12">
                    {
                        React.useMemo(() => (
                            <form className="filters-form-group mb-0" onKeyDown={(event) => {
                                if (event.key === 'Enter') fetchData(1, 0, generateFilterQuery(true, false))
                            }}>
                                <div className="">
                                    <h5>Search and Filter Job Schedule List</h5>
                                </div>
                                <div className=" d-flex flex-row flex-wrap">
                                    <div className="d-flex d-md-none flex-row ml-0 w-100 ml-md-auto mb-2 mb-md-0">
                                        <AutocompleteField
                                            includes={'Job,SchoolBoard'}
                                            isAsync={true}
                                            placeholder={'Enter keyword'}
                                            onClear={() => {
                                                onFilterSearchClearClick(() => fetchData(1, 24, generateFilterQuery(true)));
                                            }}
                                            onSearch={async (value) => {
                                                onFilterDropdownChange(value, 'keyWord')
                                                await fetchData(1, 0, generateFilterQuery(false, true, value), false, true)
                                            }}
                                        />
                                    </div>
                                    <CustomSelectControl
                                        isClearable={true}
                                        options={zones || []}
                                        value={filter.zone}
                                        sort
                                        placeholder={"Select Zone"}
                                        onChange={e => onFilterDropdownChange(e, "zone")}
                                        className="flex-grow-0 mb-2 mb-lg-0 custom-react-select-sm"
                                    />
                                    <CustomSelectControl
                                        isClearable={true}
                                        options={schoolBoards || []}
                                        value={filter.schoolBoardId}
                                        placeholder={"Select School Board"}
                                        onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                                        className="flex-grow-0 ml-0 ml-lg-2 mb-2 mb-lg-0 custom-react-select-sm"
                                    />

                                    <CustomSelectControl
                                        options={schoolYears}
                                        onChange={(value) => onFilterDropdownChange(value, 'schoolYearId')}
                                        value={filter.schoolYearId}
                                        placeholder={'Select a School Year'}
                                        className={`flex-grow-0 ml-0 ml-lg-2 mb-2 custom-react-select-lg`}
                                    />

                                    <button
                                        type="button"
                                        className="btn btn-aqua-blue ml-auto ml-md-0 ml-lg-2 mb-2 mb-md-0 mt-2 mt-lg-0"
                                        onClick={() => fetchData(1, 0, generateFilterQuery())}
                                    >
                                        Filter Schedules
                                    </button>
                                    {
                                        filter.hasValue &&
                                        <button
                                            type="button"
                                            className="btn btn-aqua-blue ml-2 mb-2 mb-md-0 mt-2 mt-lg-0"
                                            onClick={() => {
                                                onFilterClearClick()
                                            }}

                                        >
                                            <SVGEraser/>
                                        </button>
                                    }

                                    <div className="d-none d-md-flex flex-row ml-auto mt-2 mt-xl-0">
                                        <div className="form-group mb-0">
                                            <AutocompleteField
                                                includes={'Job,SchoolBoard'}
                                                isAsync={true}
                                                placeholder={'Enter keyword'}
                                                onClear={() => {
                                                    onFilterSearchClearClick(() => fetchData(1, 24, generateFilterQuery(true)));
                                                }}
                                                onSearch={async (value) => {
                                                    onFilterDropdownChange(value, 'keyWord')
                                                    await fetchData(1, 0, generateFilterQuery(false, true, value), false, true)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        ), [filter, cities, schoolBoards])
                    }
                </div>
            </div>
            {/* End Filters */}

            <div className="row">
                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageDeactivatedSchedulesContent.thead}
                                tbody={tableData ?
                                    tableData.map(schedule => ({
                                        id: schedule.id,
                                        jobId: schedule.jobId,
                                        jobNameLink: `<a href="#/jobs/${schedule.jobId}/details">${schedule.jobName}</a>`,
                                        statusChangeDate: schedule.statusChangeDate ? momentDateFormat(schedule.statusChangeDate) : "-",
                                        statusChangeBy: schedule.statusChangeBy || "-",
                                        statusRequestDate: schedule.statusRequestDate ? momentDateFormat(schedule.statusRequestDate) : "-",
                                        statusRequestedBy: schedule.statusRequestedBy || "-",
                                        statusChangeReason: schedule.statusChangeReason ? matchNameWithMultipleReasons(schedule.statusChangeReason, reasonsForDeactivation, jobReasonsForDeactivation) : '-',
                                        isDeactivatedByJob: schedule.isDeactivatedByJob
                                    }))
                                    : []}
                                ignoreCols={[0, 1, 8]}
                                // onColClick={(key, _trIndx, rowItem) => handleColClick(key, rowItem.jobId, 'jobNameLink', 'jobs')}
                                tableClass={'table-info--notFixed'}
                            >
                                {
                                    (id, rowItem: IJobScheduleRequested) => (
                                        <td className="aling-middle">
                                            <div>
                                                <button
                                                    className={`btn ${(rowItem.isDeactivatedByJob || !itemsIsVisible) ? 'btn-disabled' : 'btn-aqua-blue'} `}
                                                    onClick={() => {
                                                        (!rowItem.isDeactivatedByJob && itemsIsVisible) && onRequestHandler(
                                                            {
                                                                title: `Are you sure you want to reactivate this Job Schedule?`,
                                                                itemInFocus: tableData.find(schedule => schedule.id === id),
                                                                type: ActivationDeactivation.activate
                                                            })
                                                    }}
                                                >
                                                    <SVGApprove/>
                                                </button>
                                            </div>
                                        </td>
                                    )
                                }
                            </DataTable>
                        ), [tableData, isLoading, reasonsForDeactivation, shifts, jobReasonsForDeactivation])
                    }
                </div>
            </div>

            {(requestModals.showRequest) &&
            <DeactivateModal
                onCancel={() => onCloseRequestModals()}
                onSubmit={() => onRequestSubmitHandler(ActivationDeactivation.activate,
                    {
                        itemData: null,
                        successCallback: (id: any) => setTableData(onSuccessResponse<IJobScheduleRequested>(id, tableData))
                    },
                    `The schedule for <span class="font-weight-bold">${itemInFocus.jobName}</span> has been Reactivated.`,
                )}
                title={modalText.title}
                htmlBody={<ReactivateScheduleModalBody selectedItem={itemInFocus as IJobSchedule} />}
            />
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{__html: modalText.title}}/>
                </BaseModal>
            }

        </Layout>
    )
}

export default DeactivedSchedules
