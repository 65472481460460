import React, {useContext, useEffect, useState} from 'react'
import {FC} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {IClientDropdown} from "../../../common/interfaces/dropdowns/IClientDropDown";
import SectionTitle from "../../titles/SectionTitle";
import CustomSelectControl from "../../custom-select/CustomSelectControl";
import {ISchoolYearDropdown} from "../../../common/interfaces/dropdowns/ISchoolYearDropdown";
import BaseModal from "../../modal/BaseModal";
import ConfirmationModal from "../../modal/ConfirmationModal";
import moment from "moment";
import {removeHighlightError} from "../../../utils/Validation";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import AppContext from "../../../context/AppContext";
import {DataService} from "../../../common/services/DataService";
import {dateSendWithAddDay} from "../../../utils/DateFormatting";
import { useSchoolYear } from 'customHooks/useSchoolYear';


type Props = {
    clients: IClientDropdown[]
}

const DuplicateJobsChoose: FC<Props> = ({ clients}) => {
    const [selectedOption, setSelectedOption] = useState<{ cliendId: number, schoolYearId: number, newSchoolYearId: number }>({
        cliendId: 1, schoolYearId: 1, newSchoolYearId: 0
    })
    const appContext = useContext(AppContext)
    // const [schoolYears, setSchoolYears] = useState<ISchoolYearDropdown[]>([] as ISchoolYearDropdown[])
    const history = useHistory()
    const [modalsConfirm, setModalsConfirm] = useState(false)
    const [modalsRedirect, setModalsRedirect] = useState(false)
    const {getSchoolYears: setDefaultSchoolYears, schoolYears, schoolYearsData, getCurrentYear} = useSchoolYear()
    const svc = new DataService<any>({ url: 'job/duplicate' })



    useEffect(() => {
        changeSchoolYears()
    }, [selectedOption.cliendId])

    useEffect(() => {
        if (appContext.currentClient.id) {
            setDefaultSchoolYears((value) => setSelectedOption({
                schoolYearId: +value,
                cliendId: appContext.currentClient.id,
                newSchoolYearId: +value,
            }), undefined, false, 5)
        }
    }, [appContext.currentClient.id])


    const changeSchoolYears = () => {
        schoolYears.length && setSelectedOption({...selectedOption, schoolYearId: +schoolYears[0].id})
    }

    const onDuplicateJobClick = () => {
        setModalsConfirm(true)
    }

    const changedModals = () => {
        onFormSubmit()
    }

    const formatYears = () => {
        const currentYear = new Date(getCurrentYear(schoolYearsData)?.startDate || '')?.getFullYear()
        const selectedSchoolYear = schoolYearsData.filter(year => year.id === selectedOption.schoolYearId)[0];
        const selectedStartYear = new Date(selectedSchoolYear?.startDate || '')?.getFullYear();
        const selectedEndYear = new Date(selectedSchoolYear?.endDate || '')?.getFullYear();
        const selectedYearsDiff = selectedEndYear - selectedStartYear;
        if (selectedSchoolYear) {
            const schoolYears = new Array(10).fill(selectedSchoolYear)
                .map((element, index) => ({ id: element.id, startDate: dateSendWithAddDay(element.startDate, index + 1, currentYear), endDate: dateSendWithAddDay(element.endDate, index + 1, currentYear + selectedYearsDiff)}))
            return schoolYears
        }
        return []
    }


    const onFormSubmit = async () => {
        appContext.showLoader(true)
        try {

            const body = {
                clientId: selectedOption.cliendId,
                schoolYearId: selectedOption.schoolYearId,
                startDate: schoolYearsData.find(v => v.id === selectedOption.newSchoolYearId)!.startDate,
                endDate: schoolYearsData.find(v => v.id === selectedOption.newSchoolYearId)!.endDate
            }
            await svc.create(body)
            setModalsConfirm(false)
            setModalsRedirect(true)
            appContext.showNotification(NotificationTypes.success,
                'Your job schedules and resulting shifts have successfully been duplicated.'
            )
            appContext.showLoader(false)
        } catch (e: any) {
            removeHighlightError()
            appContext.showNotification(NotificationTypes.danger, e.message)
            appContext.showLoader(false)
        }
    }


    const onEditJobClickRoute = () => {
        history.push({
            pathname: '/jobs/duplicate/pending',
        })
    }

    return (

        <section>
            <div className="row pb-4">
                <div className="col-12 d-flex flex-row justify-content-between">
                    <SectionTitle title="Duplicate Job"/>
                </div>
                <div className="col-12 pt-3">
                    <p>
                      The following school year has been automatically generated and is available in the dropdown below
                      for "To Create New School Year. Use the first dropdown to select the school year from which you
                      want all jobs to be copied.
                    </p>
                </div>
                <form className="col-lg-5 mx-auto">
                    <div className="form-group">
                        <label>Duplicate School Year:</label>
                        <CustomSelectControl
                            options={schoolYearsData.map((year, i) => ({
                                id: year.id,
                                name: `${moment.utc(year.startDate).format('MMMM DD Y')} - ${moment.utc(year.endDate).format('MMMM DD Y')}`
                            }))}
                            onChange={value => setSelectedOption({...selectedOption, schoolYearId: +value})}
                            value={selectedOption.schoolYearId}
                            placeholder={"Select a school year"}
                            className={`custom-react-select--clientId `}
                        />
                    </div>
                    <div className="form-group ">
                        <label>To Create New School Year:</label>
                        <CustomSelectControl
                            options={schoolYearsData.map(year => ({
                                id: year.id,
                                name: `${moment.utc(year.startDate).format('MMMM DD Y')} - ${moment.utc(year.endDate).format('MMMM DD Y')}`
                            }))}
                           
                            onChange={value => setSelectedOption({ ...selectedOption, newSchoolYearId: +value })}
                            value={selectedOption.newSchoolYearId}
                            placeholder={"Select a school year"}
                            className={`custom-react-select--clientId`}
                        />
                    </div>

                    <p className='mb-3'>If you don't see the new school year in the list, go to <Link className=" font-weight-bold text-primary" to={'/administration/schools-years'}>School Years</Link> to add it.</p>
                    {
                        selectedOption.cliendId !== 0 && selectedOption.schoolYearId !== 0 &&
                        <button
                            type="button"
                            className="btn btn-aqua-blue d-block mx-auto text-center"
                            onClick={() => onDuplicateJobClick()}
                        >Duplicate job schedules </button>
                    }
                </form>

                {
                    modalsConfirm &&
                    <BaseModal
                        show={modalsConfirm}
                        onCancel={() => setModalsConfirm(false)}
                        cancelBtnText={'No'}
                        onSubmit={changedModals}
                        submitBtnText={'Yes'}
                    >
                        <ConfirmationModal text={`Are you sure you want to duplicate selected job schedules`}/>
                    </BaseModal>
                }
                {
                    modalsRedirect &&
                    <BaseModal
                        classNameButton="justify-content-center"
                        show={modalsRedirect}
                        onCancel={() => setModalsRedirect(false)}
                        showCancelBtn={false}
                        onSubmit={onEditJobClickRoute}
                        submitBtnText={'Go to pending Job Schedules to activate'}
                    >
                        <ConfirmationModal
                            text={`Your job schedules and resulting shifts have successfully been duplicated.`}/>
                    </BaseModal>

                }
            </div>

        </section>
    )
}

export default DuplicateJobsChoose
