import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import DataTable from "../../../components/tables/DataTable";
import { jobShiftsReport } from "../../../content/dashboard/TablesContent";
import { useTableList } from "../../../customHooks/useTableList";
import { IShiftJobReportModel } from "../../../common/interfaces/IDataFilter";
import { matchOptionWithName } from "../../../utils/MatchOptionWithName";
import { useVocabulary } from "../../../customHooks/vocabulary/useVocabulary";
import { VocabularyEnum } from "../../../common/enums/VocabularyEnum";
import ReportActions from "../../../components/reports/ReportActions";
import { dateIsInPast, isTodayUpToMinus72hours, momentHoursFormat } from "../../../utils/DateFormatting";
import { convertToM } from "../../../utils/ConvertToKm";
import AppContext from 'context/AppContext';
import { AuthService } from 'common/auth/AuthService';
import { Roles } from 'common/enums/Roles';
import {
    GuardCheckInStatus as guardCheckInStatus, GuardCheckOutStatus as guardCheckOutStatus,
    GuardCheckInMethods as guardCheckInMethod, GuardStatusonShift as guardStatusonShift, GuardCheckOutMethods
} from 'content/xguard-reports/tableVocabulary';
import { useModals } from 'customHooks/modals/useModals';
import { SVGPencil } from 'assets/icons/SvgIcons';
import { EditPastJobShiftsModals } from 'common/models/Modals';
import UpdateShiftStatusModal from 'components/modal/ dashboard/UpdateShiftStatusModal';
import BaseModal from 'components/modal/BaseModal';
import ConfirmationModal from 'components/modal/ConfirmationModal';
import UpdateShiftStatusContext from 'context/dashboard/UpdateShiftStatusContext';
import CheckInOutModal from 'components/modal/CheckInOutModal';
import { useCheckGuard } from 'customHooks/modals/useCheckGuard';
import useClientComment from 'customHooks/useClientComment';

type Props = {
    queryFilter: string
}

const ListJobShiftReport: FC<Props> = ({ queryFilter }) => {
    const { tableData, isLoading, perPage, allRecords, onPagination, onPerPage, currentPage, onSortCall, fetchData } = useTableList<IShiftJobReportModel>(`${queryFilter}`, '24', false)
    const { vocabulary: shiftVocabulary } = useVocabulary(VocabularyEnum.shift, true)
    const tableRef = useRef<HTMLDivElement>(null)
    const { currentUser, showLoader } = useContext(AppContext)
    const isClient = AuthService.checkIfUserHasPermission([Roles.Client], currentUser.role);
    const hiddenTheadCols = [...[], ...(isClient ? [4, 14, 16, 18, 21, 22, 23, 30] : [])]
    const hiddenTbodyCols = [...[], ...(isClient ? [4, 14, 16, 18, 21, 22, 23, 30] : [])]
    const { modals, setModals, itemInFocus: selectedShift,
        setItemInFocus: setSelectedShift } = useModals<EditPastJobShiftsModals, IShiftJobReportModel>({
            defaultState: new EditPastJobShiftsModals({})
        });
    const { vocabulary: replaceGuardVoc } = useVocabulary(VocabularyEnum.replacement, true)
    const { handleCheckActions } = useCheckGuard()
    const [tempCheckInReason, setTempCheckInReason] = useState("");
    const { handleAddEditClientComment, showErrorMessage, onCommentChange, comment, setComment } = useClientComment({required: false})

    useEffect(() => {
        fetchData(1, '24', queryFilter)
            .then(() => tableRef.current?.scrollIntoView())
    }, [queryFilter])


    const onEditShiftClick = (shift: IShiftJobReportModel) => {
        setSelectedShift(shift)
        setModals(new EditPastJobShiftsModals({
            showEditModal: shift.shiftStatus?.tentantCheckIn ? false : true,
            showTempCheckInModal: shift.shiftStatus?.tentantCheckIn ? true : false
        }))
    }

    const onEditShiftSubmit = async () => {
        setModals(new EditPastJobShiftsModals({ showEditModalSuccess: true }))
        await fetchData(1, 24, queryFilter)
        setComment("");
    }

    const onAbsentSuccess = async () => {
        await fetchData(1, 24, queryFilter)
        setModals(new EditPastJobShiftsModals({ showAbsentGuardSuccess: true }))
        showLoader(false)
    }

    const onConfirmCheckInSubmit = async () => {
        handleCheckActions({
            checkType: 'checkIn',
            checkTime: selectedShift.aspStartDate,
            checkReason: tempCheckInReason,
            shiftId: selectedShift.id ? selectedShift.id! : selectedShift.shiftId!
        }, async () => {
            showLoader(true)
            await fetchData(1, 24, queryFilter)
            setModals(new EditPastJobShiftsModals({ showTempCheckInSuccessModal: true }))
            showLoader(false)
        })
        handleAddEditClientComment(selectedShift.id ? +selectedShift.id : +selectedShift.shiftId!);
        setComment("");
        setTempCheckInReason("");
    }

    const refreshTableData = async () => {
      await fetchData(1, +perPage, queryFilter);
    }

    const updateSelectedShift = (selectedShiftId?: number) => {
      const shift = tableData.find((shift) => shift.shiftId === selectedShiftId);
      if (shift) {
        setSelectedShift(shift);
      }
    }

    useEffect(() => {
      updateSelectedShift(selectedShift.shiftId);
    }, [tableData])

    return (
        <>
            <div className="row  mt-5 mb-3">
                <div className="col-12">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                        <div className="d-flex mt-2 mt-sm-0 mb-2 mb-sm-0">
                            {
                                React.useMemo(() => (
                                    <ReportActions title="" className="mr-0 mt-lg-0" showForShiftReport={false}
                                        page="report/shifts"
                                        query={queryFilter.replace('reports/shifts?', '')} />
                                ), [queryFilter, tableData, isLoading])
                            }
                        </div>

                    </div>
                </div>
            </div>
            <section>
                <div className="row">
                    <div className="col-12 shift-report" ref={tableRef}>
                        {
                            React.useMemo(
                                () => (
                                    <DataTable
                                        tableClass="mt-4"
                                        thead={jobShiftsReport.thead}
                                        tbody={
                                            tableData ? tableData
                                                .map(shift => (
                                                    {
                                                        cityJobId: shift.cityJobId ? shift.cityJobId : '-',
                                                        jobName: shift.jobName ? shift.jobName : '-',
                                                        date: shift.startDate ? shift.startDate : '-',
                                                        shiftPeriod: matchOptionWithName(shift.shiftPeriod, shiftVocabulary),
                                                        aspStartTime: `${momentHoursFormat(shift.aspStartDate, shift.timeFrom, true)}`,
                                                        timeFrom: `${momentHoursFormat(shift.timeFrom)}`,
                                                        timeTo: `${momentHoursFormat(shift.timeTo, shift.timeFrom)}`,
                                                        zone: shift.zone ? shift.zone : '-',
                                                        schoolBoard: shift.schoolBoard ? shift.schoolBoard : '-',
                                                        holiday: shift.holiday ? shift.holiday : '-',
                                                        guardEmployeeNumber: shift.guardEmployeeNumber ? shift.guardEmployeeNumber : '-',
                                                        guardName: shift.guardName ? shift.guardName : '-',
                                                        guardStatus: shift.guardStatus ? matchOptionWithName(shift.guardStatus!, guardStatusonShift) : '-',
                                                        checkInStatus: shift.checkInStatus ? matchOptionWithName(shift.checkInStatus!, guardCheckInStatus) : '-',
                                                        checkInType: shift.checkInType ? matchOptionWithName(shift.checkInType!, guardCheckInMethod) : '-',
                                                        checkInDateTime: shift.checkInDateTime ? `${momentHoursFormat(shift.checkInDateTime)}` : '-',
                                                        checkInBatteryLevel: shift.checkInBatteryLevel ? `${Math.round(shift.checkInBatteryLevel * 100)}%` : '-',
                                                        lateTime: shift.lateTime ? shift.lateTime : '-',
                                                        checkOutStatus: shift.checkOutStatus ? matchOptionWithName(shift.checkOutStatus!, guardCheckOutStatus) : '-',
                                                        checkOutType: shift.checkOutType ? matchOptionWithName(shift.checkOutType!, GuardCheckOutMethods) : '-',
                                                        checkOutDateTime: shift.checkOutDateTime ? `${momentHoursFormat(shift.checkOutDateTime)}` : '-',
                                                        checkOutBatteryLevel: shift.checkOutBatteryLevel ? `${Math.round(shift.checkOutBatteryLevel * 100)}%` : '-',
                                                        absentType: shift.absentType ? shift.absentType : '-',
                                                        latitude: shift.guardLocation ? (+shift.guardLocation?.latitude).toFixed(7) : '-',
                                                        longitude: shift.guardLocation ? (+shift.guardLocation?.longitude).toFixed(7) : '-',
                                                        checkOutDistance: shift.guardLocation ? convertToM(shift.guardLocation?.distanceFromJob) : '-',
                                                        geoFence: shift.geoFence ? shift.geoFence : '-',
                                                        aspMobile: shift.aspMobile ? shift.aspMobile : '-',
                                                        version: shift.version || '-',
                                                        build: shift.build || '-',
                                                        operationSystem: shift.operationSystem || '-',
                                                        updateComments: shift.updateComments || '-',
                                                        lastComment: shift.lastComment || '-'
                                                    }
                                                )) : []}
                                        isLoading={isLoading}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={page => onPagination(page)}
                                        onPerPage={value => onPerPage(value)}
                                        tableName={" Job Shifts Report"}
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
                                        showTableLengthData={true}
                                        sortOnBackend={true}
                                        defaultSortedColumn={3}
                                        ignoreTheadCols={hiddenTheadCols}
                                        ignoreCols={hiddenTbodyCols}
                                        customMarkupAtStart={(_id, rowItem, rowIndex: number) => (
                                            <td className='align-middle'>
                                                <div>
                                                    {isTodayUpToMinus72hours(rowItem.date) && dateIsInPast(tableData[rowIndex].timeTo) &&
                                                        <button type="button" className="btn btn-aqua-blue"
                                                            onClick={() => onEditShiftClick(tableData[rowIndex])}
                                                        >
                                                            <SVGPencil />
                                                        </button>
                                                    }
                                                </div>
                                            </td>
                                        )}
                                    />
                                ), [tableData, isLoading]
                            )
                        }
                    </div>
                </div>

                <UpdateShiftStatusContext.Provider
                    value={{
                        vocabulary: [...replaceGuardVoc, ...[{ id: 0, name: "Other" }]],
                    }}
                >
                    {modals.showEditModal &&
                        <UpdateShiftStatusModal
                            shift={selectedShift}
                            onCancel={() => [setModals(new EditPastJobShiftsModals({})), setComment("")]}
                            onSubmit={onEditShiftSubmit}
                            showReasonField
                            onMarkAbsent={onAbsentSuccess}
                            refreshTableData={refreshTableData}
                            comment={comment}
                            setComment={setComment}
                            onCommentChange={onCommentChange}
                            showErrorMessage={showErrorMessage}
                            handleAddEditClientComment={handleAddEditClientComment}
                        />
                    }
                </UpdateShiftStatusContext.Provider>

                {
                    modals.showTempCheckInModal &&
                    <BaseModal
                        show={modals.showTempCheckInModal}
                        title={'Confirm Temp Check In'}
                        onSubmit={() => onConfirmCheckInSubmit()}
                        onCancel={() => [setModals(new EditPastJobShiftsModals({})), setComment(""), setTempCheckInReason("")]}
                        submitBtnText={'Confirm'}
                    >
                        <CheckInOutModal
                            type={"checkin"}
                            absenceReasons={[]}
                            checkTime={selectedShift.aspStartDate}
                            onTimeChange={time => setSelectedShift({
                                ...selectedShift,
                                aspStartDate: time
                            })}
                            selectedItem={selectedShift}
                            showReasonField
                            tempCheckInReason={tempCheckInReason}
                            setTempCheckInReason={setTempCheckInReason}
                            comment={comment}
                            setComment={setComment}
                            onCommentChange={onCommentChange}
                            showErrorMessage={showErrorMessage}
                        />
                    </BaseModal>
                }

                {(modals.showEditModalSuccess || modals.showTempCheckInSuccessModal) &&
                    <BaseModal
                        show={(modals.showEditModalSuccess || modals.showTempCheckInSuccessModal)}
                        onCancel={() => setModals(new EditPastJobShiftsModals({}))}
                        cancelBtnText={'Close'}
                    >
                        <ConfirmationModal text={
                            modals.showEditModalSuccess
                                ? `Shift details for <span class="font-weight-bold">${selectedShift?.jobName}</span> has been updated.`
                                : `<span class="font-weight-bold">${selectedShift?.guardName} </span>
                        has been checked in for <span class="font-weight-bold">${selectedShift?.jobName}</span>`
                        }
                        />
                    </BaseModal>
                }
                {modals.showAbsentGuardSuccess &&
                <BaseModal
                    show
                    onCancel={() => setModals(new EditPastJobShiftsModals({}))}
                    cancelBtnText='Close'
                >
                    <h4><span className="font-weight-bold">{selectedShift.guardName}</span> has been marked as absent for
                    <span className="font-weight-bold"> {selectedShift.jobName}</span></h4>
                </BaseModal>
            }

            </section>
        </>
    )
}

export default ListJobShiftReport;
