import React, { FC } from 'react'
import { Link } from 'react-router-dom'

export type BreadcrumbsData = {
    links?: Array<{
        title: string,
        link: string
    }>
    isHome?: boolean
    currentPageTitle: string
}

const Breadcrumbs: FC<BreadcrumbsData> = ({ links, currentPageTitle, isHome }) => {
    return (
        <div className="breadcrumbs py-3 mb-3 mb-md-5">
            <div className="container">
                <div className="row flex-wrap">
                    <div className="col-12">
                        <ul className="list-unstyled d-flex flex-row align-items-center mb-0">
                            {
                                !isHome &&
                                <li className="d-flex align-items-center">
                                    <Link to="/dashboard">My Dashboard</Link>
                                    <span className="d-inline-block">/</span>
                                </li>
                            }

                            {
                                links && links.length !== 0 && links.map(item =>
                                    <li key={item.title} className="d-flex align-items-center">
                                        <Link to={`/${item.link}`}>{ item.title }</Link>
                                        <span className="d-inline-block">/</span>
                                    </li>
                                )
                            }
                            <li>{ currentPageTitle }</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Breadcrumbs
