import { useReducer, useState } from 'react';
type BaseModalType = string | number | symbol;

type ModalAction<T extends BaseModalType, DT> =
	| { type: 'open'; modalType: T; data?: DT; id: number | string }
	| { type: 'close'; modalType: T };

export type ModalState<T extends BaseModalType, DT> = {
	[key in T]: {
		isOpen: boolean;
		data?: DT;
		id: number | string;
	};
};

type ModalOptions<T extends BaseModalType, DT> = {
	initialState: ModalState<T, DT>;
	modalReducer: (state: ModalState<T, DT>, action: ModalAction<T, DT>) => ModalState<T, DT>;
};

type ModalDispatcher<DT> = {
	openModal: (id: number | string, data?: DT) => void;
	closeModal: () => void;
};

type ModalHook<T extends BaseModalType, DT> = ModalState<T, DT> & {
	dispatch: (action: ModalAction<T, DT>) => void;
	onModal: (modalType: T) => ModalDispatcher<DT>;
};

function useActionModals<T extends BaseModalType, DT>({
	initialState,
	modalReducer,
}: ModalOptions<T, DT>): ModalHook<T, DT> {
	const [state, dispatch] = useReducer(modalReducer, initialState);
	const [modalTitle, setModalTitle] = useState('');

	function onModal(modalType: T): ModalDispatcher<DT> {
		return {
			openModal: (id: number | string, data?: DT) => {
				dispatch({ type: 'open', modalType, id, data });
			},
			closeModal: () => {
				dispatch({ type: 'close', modalType });
				setModalTitle('');
			},
		};
	}

	return {
		...state,
		dispatch,
		onModal
	};
}

export default useActionModals;
