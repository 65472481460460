import { EditorState } from "draft-js"
import { useState } from "react"
import { HowSendMessageClientGuard, ToWhomeSendMessageClient, ToWhomeSendMessageGuard } from "../common/enums/Administration"

function useSendCustomNotification<T>(model: T, type: 'client' | 'guard') {
    const [stepOneOpts, setStepOneOpts] = useState<{ [index: string]: boolean, sendEmail: boolean, sendSms: boolean, sendAsPreferred: boolean }>(
        { sendEmail: true, sendSms: false, sendAsPreferred: false }
    )
    const stepTwoClient = { all: true, sub: false, custom: false }
    const stepTwoGuard = { all: true, allAssigned: false }

    const [stepTwoOpts, setStepTwoOpts] = useState<{ [index: string]: boolean}>(type === 'client' ? stepTwoClient : stepTwoGuard)
    const [state, setState] = useState<T>(model)

    const onChangeHandler = (value: EditorState | string, key: string) => {
        setState({
            ...state,
            [key]: value
        })
    }

    const toggleRadioBtnsState = (state: any, keyName: string) => {
        let stateCopy = {...state};
        
        for (let [key, ] of Object.entries(stateCopy)) {
            stateCopy[key] = false
        }
        stateCopy[keyName] = true
        return stateCopy
    }

    const handleRadioBtns = (stepNum: number, key: string) => {
        if (stepNum === 1) {
            setStepOneOpts(toggleRadioBtnsState(stepOneOpts, key))
        } else if (stepNum === 2) {
            setStepTwoOpts(toggleRadioBtnsState(stepTwoOpts, key))
        }
    }

    const decodeRadioBtns = () => {
        if (type === 'client') {
            return decodeClientRadioBtns()
        } else {
            return decodeGuardRadioBtns()
        }
    }

    const decodeClientRadioBtns = () => {
        let howSendMessage: HowSendMessageClientGuard = HowSendMessageClientGuard.Email;
        let toWhomeSendMessage: ToWhomeSendMessageClient = ToWhomeSendMessageClient.All;
        
        if (stepOneOpts.sendEmail) {
            howSendMessage = HowSendMessageClientGuard.Email
        }

        if (stepTwoOpts.all) {
            toWhomeSendMessage = ToWhomeSendMessageClient.All
        } else if (stepTwoOpts.sub) {
            toWhomeSendMessage = ToWhomeSendMessageClient.WithAccess
        } else if (stepTwoOpts.custom) {
            toWhomeSendMessage = ToWhomeSendMessageClient.Selected
        }

        return { howSendMessage, toWhomeSendMessage }
    }

    const decodeGuardRadioBtns = () => {
        let howSendMessage: HowSendMessageClientGuard = HowSendMessageClientGuard.Email;
        let toWhomeSendMessage: ToWhomeSendMessageGuard = ToWhomeSendMessageGuard.All;
        
        if (stepOneOpts.sendEmail) {
            howSendMessage = HowSendMessageClientGuard.Email
        }

        if (stepTwoOpts.all) {
            toWhomeSendMessage = ToWhomeSendMessageGuard.All
        } else if (stepTwoOpts.allAssigned) {
            toWhomeSendMessage = ToWhomeSendMessageGuard.AssignedTojobs
        }

        return { howSendMessage, toWhomeSendMessage }
    }

    return {
        stepOneOpts, stepTwoOpts, handleRadioBtns, decodeRadioBtns, state, onChangeHandler, setState
    }
}

export default useSendCustomNotification