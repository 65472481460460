import React, {useContext, useEffect} from 'react'
import {useState} from 'react'
import {IValidationBody, ValidationRules} from '../../common/interfaces/IValidation'
import {IAdministrationUser, IManagePassword} from '../../common/interfaces/manage-users/IManageUser'
import {AdministrationUser, ManagePassword} from '../../common/models/ManageUser'
import Layout from '../../components/layout/Layout'
import BaseModal from '../../components/modal/BaseModal'
import AddEditUserModal from '../../components/modal/users/AddEditUserModal'
import MyProfileInfo from '../../components/my-profile/MyProfileInfo'
import SectionTitle from '../../components/titles/SectionTitle'
import {useAddEditUser} from '../../customHooks/useAddEditUser'
import {validateFiled} from '../../utils/Validation'
import {NotificationTypes} from "../../common/interfaces/INotification";
import ResourceDataService from "../../common/services/ResourceDataService";
import AppContext from "../../context/AppContext";
import {DataService} from "../../common/services/DataService";   

const MyProfile = () => {
    const {formData, setFormData} = useAddEditUser<IAdministrationUser>(new AdministrationUser())
    const usePassword = useAddEditUser<IManagePassword>(new ManagePassword())
    const [modals, setModals,] = useState<{ edit: boolean, password: boolean, success: boolean }>(
        {edit: false, password: false, success: false})
    const [successText, setSuccessText] = useState<string>('')
    const {showNotification, currentUser, setCurrentUser, showLoader} = useContext(AppContext);
    const userSvc = new ResourceDataService<IAdministrationUser>({
        url: 'user'
    })
    const passwordSvc = new DataService<any>({
        url: 'account/change-password'
    })
    const dataService = new DataService<IAdministrationUser>({url: 'user'})
    const [passwordError, setPasswordError] = useState<boolean>(false)

    useEffect(() => {
        geetUserDetail();
    }, [])

    const geetUserDetail = async () => {
        showLoader(true)
        try {
            const res = await userSvc.select(currentUser.id);
            setFormData(res.data)
            showLoader(false)
        } catch (e: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, e.message)
        }
    }
    const updateUser = async (updatedData: IAdministrationUser) => {
        try {
            const res = await dataService.update(updatedData, currentUser.id);
            if (res) {
                setSuccessText('Your Profile Has Been Updated')
                setModals({success: true, edit: false, password: false})
                setCurrentUser({
                    ...currentUser,
                    firstName: updatedData.firstName,
                    lastName: updatedData.lastName,
                })
                geetUserDetail()
            }
        } catch (e: any) {
            showNotification(NotificationTypes.danger, e.message)
        }
    }

    const onEditProfileSaveClick = (updatedData: IAdministrationUser) => {
        if (updatedData) {
            updateUser(updatedData)
        }

    }

    const onPasswordChangeSaveClick = async (updatedData: IManagePassword) => {
        if (usePassword.formIsValid([]) && comparePasswords()) {
            try {
                const res = await passwordSvc.create({
                    currentPassword: updatedData.currentPassword,
                    password: updatedData.newPassword
                })
                if (res.succeeded === true) {
                    usePassword.setFormData(new ManagePassword())
                    setSuccessText('Your Password Has Been Updated')
                    setPasswordError(false)
                    setModals({success: true, edit: false, password: false})
                } else {
                }
            } catch (err: any) {
                showNotification(NotificationTypes.danger, err.message)
                if (err.message === 'Error: Password must be at least 6 characters') {
                    usePassword.formIsValid([], 'length')
                }
            }
        }
    }

    const comparePasswords = () => {
        let errors: IValidationBody[] = [];
        errors = [...errors, ...validateFiled('', 'password', ValidationRules.passwordMatch, [usePassword.formData.newPassword, usePassword.formData.confirmNewPassword])]
        if (errors.length !== 0) {
            setPasswordError(true)
            return false
        }
        setPasswordError(false)
        return true
    }

    const onPasswordModalCloseClick = () => {
        usePassword.setFormData(new ManagePassword())
        setPasswordError(false)
        setModals({...modals, password: false})
    }

    return (
        <Layout
            breadcrumbs={{currentPageTitle: 'My Profile'}}
        >
            <div className="row mb-3">
                <div className="col-12">
                    <SectionTitle title={`My Profile`}/>
                </div>
            </div>

            {formData && <MyProfileInfo
                user={formData}
                onEditProfile={() => setModals({...modals, edit: true})}
                onChangePassword={() => setModals({...modals, password: true})}
            />}

            {
                modals.success &&
                <BaseModal
                    show={modals.success}
                    onCancel={() => setModals({...modals, success: false})}
                    cancelBtnText={'close'}
                >
                    <h4 className="mb-4">{successText}</h4>
                </BaseModal>
            }

            {
                modals.edit && formData && <AddEditUserModal
                    user={formData}
                    onCancel={() => setModals({...modals, edit: false, password: false})}
                    onSubmit={onEditProfileSaveClick}
                    title={'Edit My Profile'}
                />
            }

            {
                modals.password &&
                <BaseModal
                    show={modals.password}
                    onCancel={() => onPasswordModalCloseClick()}
                    onSubmit={() => onPasswordChangeSaveClick(usePassword.formData)}
                    cancelBtnText={'Close'}
                    submitBtnText={'Save'}
                    className={'edit-user-info'}
                >
                    <h4 className="mb-4">Change Password</h4>
                    <form className="row">
                        <div className="form-group col-md-6 align-self-end">
                            <label className="required" htmlFor="currentPassword">Current Password</label>
                            <input type="password" name="currentPassword" className="form-control" id="currentPassword"
                                   placeholder="Enter Current Password"
                                   autoComplete="nope"
                                   value={usePassword.formData.currentPassword}
                                   onChange={e => usePassword.onInputChange(e)}
                            />
                        </div>
                        <div className="form-group col-md-6 align-self-end">
                            <label className="required" htmlFor="newPassword">New Password</label>
                            <input type="password" name="newPassword" className="form-control" id="newPassword"
                                   placeholder="Enter New Password"
                                   autoComplete="nope"
                                   value={usePassword.formData.newPassword}
                                   onChange={e => usePassword.onInputChange(e)}
                            />
                        </div>
                        <div className="form-group col-md-6 align-self-end">

                        </div>
                        <div className="form-group col-md-6 align-self-end">
                            <label className="required" htmlFor="confirmNewPassword">Confirm New Password</label>
                            <input type="password" name="confirmNewPassword" className="form-control"
                                   id="confirmNewPassword" placeholder="Confirm New Password"
                                   autoComplete="nope"
                                   value={usePassword.formData.confirmNewPassword}
                                   onChange={e => usePassword.onInputChange(e)}
                            />
                        </div>
                        {
                            passwordError &&
                            <div className="col-md-6 form-group ml-auto">
                                <small className="text-danger pt-4">New password and confirmation password should
                                    match</small>
                            </div>
                        }
                    </form>
                </BaseModal>
            }

        </Layout>
    )
}

export default MyProfile
