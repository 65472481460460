import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { momentNowCustomFormat } from 'utils/DateFormatting'
import Brand from '../../assets/images/asplogo.png'
import { SVGXGuardLogo } from 'assets/icons/SvgIcons'

type Props = {
    children: ReactNode;
    title: string;
    agreement: string;
    forgot?: boolean;
    formValid?: boolean;
    link: string;
    linkTitle: string;
    bottomText?: string;
}

const BaseStartScreen: FC<Props> = ({
    children, title, agreement, forgot, formValid, link, linkTitle, bottomText
}) => {
  const year = momentNowCustomFormat("Y");

    return (
        <section className="signin position-relative">
            <div className="container-fluid h-100 px-0 position-absolute position-md-relative">
                <div className="row h-100 mx-0">
                    <div className="col-lg-6 col-xl-7 h-100 d-none d-lg-block col-left px-0">
                        <div className="signin__start-text h-100 py-2 py-lg-3">
                            <div className="row h-100">
                                <div className="col-12 d-flex flex-column justify-content-between align-items-center signin__left">
                                    <div></div>
                                    <div className="text-center">
                                        <SVGXGuardLogo className='mr-4 mb-1 mt-1'/>
                                        <h2 className="text-dark mt-3" >ASP XGuard Admin Panel</h2>
                                    </div>
                                    <div className="copyright d-flex mb-5">
                                      <div>Copyright {year} A.S.P. Security Services</div>
                                      <div className="px-2">|</div>
                                      <a className="text-decoration-none cursor-pointer" href="https://www.security-asp.com" target="_blank">www.security-asp.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-xl-5 h-100 col-right px-0">
                        <div className={`form-container h-100 d-flex flex-column justify-content-start align-items-center`} >
                            <div className="form-container__row py-2 py-lg-3 w-100 h-100 d-flex  justify-content-between flex-column">
                            <div className="form-container__logo mb-2 mb-lg-5">
                                <img
                                className="d-lg-none d-block mx-auto"
                                src={Brand}
                                alt="Logo"
                                />
                                <img
                                className="d-none d-lg-block mx-auto"
                                src={Brand}
                                alt="Logo"
                                />
                            </div>

                            <form className={!formValid ? "formInvalid" : ""}>
                                <h3 className="text-center mb-4">{title}</h3>
                                {children}
                                {agreement !== "" && (
                                <div className="form-container__agreement mb-3 mb-lg-5">
                                    <small>{agreement}</small>
                                </div>
                                )}
                            </form>
                            
                            {forgot && (
                                <div className="form-container__forgot mb-3 mb-lg-5 text-center">
                                    <Link
                                    className="text-decoration-none"
                                    to="/password/forgot"
                                    >
                                    Forgot your password?
                                    </Link>
                                    <br></br>
                                    <br></br>
                                    Need help? Contact&nbsp;
                                    <a href="mailto:support@asp.com?subject=Help">
                                    support@asp.com
                                    </a>
                                </div>
                            )}

                            {bottomText && (
                                <div className="form-container__redirect text-center">
                                <span>{bottomText} </span>
                                    <strong>
                                        <Link className="text-decoration-none" to={link}>
                                        {linkTitle}
                                        </Link>
                                    </strong>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BaseStartScreen
