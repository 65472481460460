import { FC } from 'react'
import { SVGMoreDots } from 'assets/icons/SvgIcons'
import { Dropdown } from 'react-bootstrap'

type TProps = {
    onEditSchedule?: () => void
    onDeactivateSchedule?: () => void
    onAddAShift?: () => void
    onViewEditShifts?: () => void
    onAddChangeGuard?: () => void
    onRemoveGuard?: () => void
    scheduleWithGuard?: boolean
}

const SchedulesActions: FC<TProps> = ({ onEditSchedule, onDeactivateSchedule, onAddAShift,
    onAddChangeGuard, onRemoveGuard, scheduleWithGuard, onViewEditShifts }) => {

    return (
        <Dropdown
            className='job-schedules-action'
        >
            <Dropdown.Toggle
                id={`dropdown-action-${1}`}
                className={'btn-aqua-blue'}
            >
                <SVGMoreDots />
            </Dropdown.Toggle>
            <Dropdown.Menu rootCloseEvent="mousedown">
                {onEditSchedule &&
                    <Dropdown.Item
                        onClick={onEditSchedule}
                        className="justify-content-start"
                    >
                        Edit Schedule
                    </Dropdown.Item>
                }
                {onDeactivateSchedule &&
                    <Dropdown.Item
                        onClick={onDeactivateSchedule}
                        className="justify-content-start"
                    >
                        Deactivate Schedule
                    </Dropdown.Item>
                }

                {onViewEditShifts &&
                    <Dropdown.Item
                        onClick={onViewEditShifts}
                        className="justify-content-start"
                    >
                        View/Edit Shifts
                    </Dropdown.Item>
                }

                {onAddAShift &&
                    <Dropdown.Item
                        onClick={onAddAShift}
                        className="justify-content-start"
                    >
                        Add Shift
                    </Dropdown.Item>
                }

                {onAddChangeGuard &&
                    <Dropdown.Item
                        onClick={onAddChangeGuard}
                        className="justify-content-start"
                    >
                        {scheduleWithGuard ? 'Change ' : 'Add '} Guard
                    </Dropdown.Item>
                }

                {
                    scheduleWithGuard &&
                    <Dropdown.Item
                        onClick={onRemoveGuard}
                        className="justify-content-start"
                    >
                        Remove Guard
                    </Dropdown.Item>
                }

            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SchedulesActions
