import React, {useContext} from 'react'
import {Link} from 'react-router-dom';
import {SVGCancel} from '../../assets/icons/SvgIcons';
import {AuthService} from '../../common/auth/AuthService';
import {ActivationDeactivation} from '../../common/enums/Actions';
import {Roles} from '../../common/enums/Roles';
import {VocabularyEnum} from '../../common/enums/VocabularyEnum';
import {IClientRequested, IDeactivatedClient} from '../../common/interfaces/clients/IClient';
import Layout from '../../components/layout/Layout'
import BaseModal from '../../components/modal/BaseModal';
import DeactivateModal from '../../components/modal/users/DeactivateModal';
import DataTable from '../../components/tables/DataTable';
import SectionTitle from '../../components/titles/SectionTitle';
import {inactiveClientsContent} from '../../content/clients/ClientsContent';
import AppContext from '../../context/AppContext';
import useActivateDeactivate from '../../customHooks/useActivateDeactivate';
import {useTableList} from '../../customHooks/useTableList';
import {useVocabulary} from '../../customHooks/vocabulary/useVocabulary';
import {matchOptionWithName} from '../../utils/MatchOptionWithName';

const RequestsToDeactivateClients = () => {
    const {currentUser} = useContext(AppContext)
    const {onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse} = useActivateDeactivate<IDeactivatedClient>('client');
    const {vocabulary: deactivationReasons} = useVocabulary(VocabularyEnum.clientDeactivationReason, true)
    const {tableData, isLoading, setTableData, handleColClick} = useTableList<IClientRequested>('client/requested', 0)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], currentUser.role)

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Clients',
                        link: 'clients'
                    },
                ],
                currentPageTitle: 'Deactivation Requests for Clients'
            }}
        >
            <section>
                <div className="row">
                    <div className="col-12 pb-4 d-flex flex-row flex-wrap justify-content-between align-items-center">
                        <SectionTitle title="Deactivation Requests for Clients" className="mt-2 mt-md-0"/>
                        <div>
                            {
                                AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], currentUser.role) &&
                                <Link to="/clients" className="btn ml-0 ml-md-2 mt-2 mt-md-0 btn-outline-aqua-blue">View
                                    active clients</Link>
                            }
                        </div>
                    </div>
                </div>

                {
                    React.useMemo(() => (
                        <div className="row">
                            {
                                tableData &&
                                <div className="col-12">
                                    <DataTable
                                        thead={inactiveClientsContent.thead}
                                        tbody={tableData.map(item => (
                                            {
                                                id: item.id,
                                                fullNameLink: `<a href="#/clients/${item.id}/details">${item.organizationName}</a>`,
                                                statusChangeDate: item.statusRequestDate,
                                                statusChangeBy: item.statusRequestedBy,
                                                statusChangeReason: item.statusChangeReason ? matchOptionWithName(item.statusChangeReason, deactivationReasons) : ''
                                            }
                                        ))}
                                        isLoading={isLoading}
                                        ignoreCols={[0]}
                                        // onColClick={(key, trIndx) => handleColClick(key, trIndx, 'fullNameLink', 'clients')}
                                        tableName={"Active Clients"}
                                    >
                                        {
                                            (_id, rowItem: IClientRequested) => (
                                                <td className="aling-middle">
                                                    <div>
                                                        <button
                                                            className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                            onClick={() => {
                                                                itemsIsVisible && onRequestHandler(
                                                                    {
                                                                        title: `Are you sure you want to decline the deactivation request for <span class="font-weight-bold">${rowItem.fullNameLink}</span>?`,
                                                                        itemInFocus: rowItem,
                                                                        type: ActivationDeactivation.disapprove
                                                                    })
                                                            }}
                                                        >
                                                            <SVGCancel/>
                                                        </button>
                                                    </div>
                                                </td>
                                            )
                                        }
                                    </DataTable>
                                </div>

                            }
                        </div>
                    ), [tableData, isLoading])
                }
            </section>

            {(requestModals.showDissaprove) &&
            <DeactivateModal
                onCancel={() => onCloseRequestModals()}
                onSubmit={() => onRequestSubmitHandler(ActivationDeactivation.activate,
                    {
                        itemData: null,
                        successCallback: id => setTableData(onSuccessResponse<IDeactivatedClient>(id, tableData))
                    },
                    `<span class="font-weight-bold">${itemInFocus.fullNameLink}</span> has not been deactivated.`,
                )}
                title={modalText.title}
            />
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{__html: modalText.title}}/>
                </BaseModal>
            }

        </Layout>
    )
}

export default RequestsToDeactivateClients
