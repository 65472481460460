import {IShiftJobReportModel} from './../interfaces/IDataFilter';
import moment from "moment/moment";
import { setCustomTimeInUTC, setHoursAndMinutes } from '../../utils/DateFormatting';

export class ShiftJobReportModel implements IShiftJobReportModel {
    aspStartDate = ''
    hasValue = false;
    timeFrom = setCustomTimeInUTC(6).format();
    timeTo = setCustomTimeInUTC(18).format();
    holidayName = ''
    jobNameOrId = ''
    startDate = setHoursAndMinutes(moment().format());
    endDate = setHoursAndMinutes(moment().format());
    guardNameOrEmployeeNumber = ''
    shiftPeriod = ''
    lateAbsenceRange = ''
    zone = ''
    guardStatusOnShift = ''
    guardCheckOut = ''
    guardCheckIn = ''
    guardChecknStatusShift = ''
    guardCheckOutStatusShift = ''
    clientId = 0
    schoolYearId = 0
}
