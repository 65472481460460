import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/Overlay';

type Props = {
    bodyText: string
    keyValue?: any
    children: any
    placement?: Placement
}

const CustomTooltip: FC<Props> = ({ bodyText, keyValue, children, placement = 'top' }) => {
    return (
        <OverlayTrigger
            key={`custom-tooltip-${keyValue ? keyValue : '0'}`}
            placement={placement}
            delay={{ show: 250, hide: 200 }}
            overlay={
                <Tooltip className="table-info__tooltip" id={`custom-tooltip-${keyValue ? keyValue : '0'}`}>
                    <span dangerouslySetInnerHTML={{ __html: bodyText }} />
                </Tooltip>
            }
        >
            { children }
        </OverlayTrigger>
    )
}

export default CustomTooltip
