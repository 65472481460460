import React, { FC, useContext, useEffect } from 'react'
import { SVGWarning } from '../../../assets/icons/SvgIcons';
import { BookLeaveModalContext } from '../../../context/guards/BookLeaveContext';
import MultipleDays from '../../guards/details/book-leave/MultipleDays';
import SingleDayAndShift from '../../guards/details/book-leave/SingleDayAndShift';

export type LeaveTypeState = {
    [index: string]: any,
    multipleDays: boolean;
    singleDay: boolean;
    singleShift: boolean;
}

const BookLeaveModal: FC = () => {
    const { bookLeave, createMode, guardAssignedDuringLeave, onBookLeaveTypeChanged, bookLeaveType } = useContext(BookLeaveModalContext)

    const handleLeaveTypes = (e: React.ChangeEvent<HTMLInputElement> | string) => {
        let name = ""
        if (typeof e !== "string") {
            const trg = e.currentTarget;
            name = trg.id;
        } else {
            name = e;
        }

        let leaveTypeCopy = {...bookLeaveType};
        
        for (let [key, ] of Object.entries(leaveTypeCopy)) {
            leaveTypeCopy[key] = false
        }
        leaveTypeCopy[name] = true
        onBookLeaveTypeChanged && onBookLeaveTypeChanged(leaveTypeCopy)
    }

    // useEffect(() => {
    //     ((bookLeave?.shifts !== "" && bookLeave?.shifts !== null)&& !createMode) ?
    //         handleLeaveTypes("singleShift") : handleLeaveTypes("multipleDays");
    // }, [])

    return (
		<form>
			{createMode && (
				<div className="form-group">
					<div className="custom-control custom-control-radio custom-control-inline">
						<input
							type="radio"
							id="multipleDays"
							name="leaveType"
							className="custom-control-input"
							checked={!!bookLeaveType.multipleDays}
							onChange={(e) => handleLeaveTypes(e)}
						/>
						<label className="custom-control-label custom-control-radio-label" htmlFor="multipleDays">
							Multiple Days
						</label>
					</div>
					<div className="custom-control custom-control-radio custom-control-inline">
						<input
							type="radio"
							id="singleDay"
							name="leaveType"
							className="custom-control-input"
							checked={!!bookLeaveType.singleDay}
							onChange={(e) => handleLeaveTypes(e)}
						/>
						<label className="custom-control-label custom-control-radio-label" htmlFor="singleDay">
							Single Day
						</label>
					</div>
					<div className="custom-control custom-control-radio custom-control-inline">
						<input
							type="radio"
							id="singleShift"
							name="leaveType"
							className="custom-control-input"
							checked={!!bookLeaveType.singleShift}
							onChange={(e) => handleLeaveTypes(e)}
						/>
						<label className="custom-control-label custom-control-radio-label" htmlFor="singleShift">
							Single Shift
						</label>
					</div>
				</div>
			)}
			{bookLeaveType.multipleDays && <MultipleDays />}
			{bookLeaveType.singleDay && <SingleDayAndShift day />}
			{bookLeaveType.singleShift && <SingleDayAndShift />}
			{guardAssignedDuringLeave && (
				<div className="d-flex flex-row align-items-center pt-2">
					<SVGWarning className="mr-3" width={65} height={65}/>
					{!!bookLeaveType.multipleDays ? (
						<small className="text-danger">
							This update has additional assigned shift(s) for this guard.
                            If you confirm this update, the affected shifts will be unassigned.
                            A notification will be sent to the Guard and Resource Planning.
						</small>
					) : (
						<small className="text-danger">
							This Guard is currently assigned to shift(s) during this leave. If you confirm this update,
							the affected shifts will be unassigned. A notification will be sent to the Guard and
							Resource Planning
						</small>
					)}
				</div>
			)}
		</form>
	);
}

export default BookLeaveModal
