import React, {FC} from 'react'
import {IAdministrationUser} from "../../common/interfaces/manage-users/IManageUser";

type Props = {
    user: IAdministrationUser;
    onEditProfile: () => void;
    onChangePassword: () => void;
}

const MyProfileInfo: FC<Props> = ({user, onEditProfile, onChangePassword}) => {
    return (
        <>
            <section className="row">
                <div className="col-md-9 mx-auto">
                    <div className="row mb-4">
                        <div className="col-md-12 d-flex flex-wrap flex-row justify-content-between align-items-center">
                            <h4 className="mb-0">{user.firstName + ' ' + user.lastName}</h4>
                            <button className="btn btn-aqua-blue ml-auto" onClick={() => onEditProfile()}>Edit Profile
                            </button>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-3 details-information__title mb-0">
                            <h6 className="mb-0">Position:</h6>
                        </div>
                        <div className="col-9 details-information__value">
                            <p className="mb-0">{user.position}</p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-3 details-information__title mb-0">
                            <h6 className="mb-0">Email Address:</h6>
                        </div>
                        <div className="col-9 details-information__value">
                            <p className="mb-0">{user.email}</p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-3 details-information__title mb-0">
                            <h6 className="mb-0">Mobile Number:</h6>
                        </div>
                        <div className="col-9 details-information__value">
                            <p className="mb-0">{user.mobileNumber}</p>
                        </div>
                    </div>

                    {user.phoneNumber &&
                    <div className="row mb-3">
                        <div className="col-3 details-information__title mb-0">
                            <h6 className="mb-0">Phone Number:</h6>
                        </div>
                        <div className="col-9 details-information__value">
                            <p className="mb-0">{user.phoneNumber}</p>
                        </div>
                    </div>
                    }

                </div>
            </section>

            <section className="row mt-3">
                <div className="col-md-9 mx-auto">
                    <div className="row mb-4">
                        <div className="col-md-12 d-flex flex-row justify-content-between align-items-center">
                            <h4 className="mb-0">Password</h4>
                            <button className="btn btn-aqua-blue" onClick={() => onChangePassword()}>Change Password
                            </button>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-3 details-information__title mb-0">
                            <h6 className="mb-0">Username:</h6>
                        </div>
                        <div className="col-9 details-information__value">
                            <p className="mb-0">{user.email}</p>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-3 details-information__title mb-0">
                            <h6 className="mb-0">Password:</h6>
                        </div>
                        <div className="col-9 details-information__value">
                            <p className="mb-0">*********</p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default MyProfileInfo
