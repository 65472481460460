export const clientsContent = {
    thead: [
        {
            label: "Client Name",
        },
        {
            label: "Institution Code",
        },
        {
            label: "Primary Contact Name",
        },
        {
            label: "Address",
        },
        {
            label: "Client Email",
        },
        {
            label: "Client Phone",
        },
        {
            label: "First Active School Year",
        },
        {
            label: "Latest Active School Year",
        }
    ],
}

export const inactiveClientsContent = {
    thead: [
        {
            label: "Client Name",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        // {
        //     label: "Deactivation Reason",
        // },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Approve",
        },
    ],
}