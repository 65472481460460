import React, { useState } from 'react';
import { useGoogleAPIs } from './useGoogleAPIs';
import { useVocabulary } from './vocabulary/useVocabulary';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import { OptionTypeBase } from 'react-select';
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary';

export interface IAddressInfo {
	firstAddress: string;
	city: string;
	postalCode: string;
	province: string;
	timeZone?: number;
}

const useCountryField = () => {
	const { getPlaceDetails, getTimeZone } = useGoogleAPIs();
	const { vocabulary: countries } = useVocabulary(VocabularyEnum.country, true);
	const { vocabulary: states } = useVocabulary(VocabularyEnum.state, true);
	const { vocabulary: provinces } = useVocabulary(VocabularyEnum.province, true);
	const [countryName, setCountryName] = useState<string>('Canada');

	const findCountyIdByName = (name: string) => countries?.find((v) => v.name === name)?.id || '';
	const findCountyNameById = (id: string) => countries?.find((v) => +v.id === +id)?.name || '';

	const currentStates = countryName === 'Canada' ? provinces : states;
    const postalCodeMask = countryName === 'Canada' ? 'a9a 9a9' : '99999';
	const populateAddressFields = async (response: OptionTypeBase, onSuccess: (data: IAddressInfo) => void) => {
		if (Object.keys(response).length === 0) return;

		await getPlaceDetails(response.value.place_id, async (place) => {
			if (place === undefined || place === null) return;
			const timeZone = await getTimeZone(place.placeCoordinates.lat, place.placeCoordinates.lng);
			console.log(timeZone);
			onSuccess({
				firstAddress: place.street?.long_name
					? place.street?.long_name + ' ' + place.address?.long_name || ''
					: place.address?.long_name || '',
				city: place?.city?.long_name || '',
				postalCode: place?.postalCode?.short_name || '',
				province:
					currentStates.find((v) => v.name.toLowerCase() === place?.state?.long_name?.toLowerCase())?.id +
						'' || '',
				timeZone,
			});
		});
	};
	return {
		populateAddressFields,
		findCountyIdByName,
		findCountyNameById,
		setCountryName,
		currentStates,
		provinces,
		states,
		countries,
        countryName,
        postalCodeMask,
	};
};

export default useCountryField;
