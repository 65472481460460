import { CheckInTypeLabelEnum, CheckOutTypeLabelEnum } from "common/enums/CheckInOutTypeEnum"

export const showNextDropdownOptions = [
    {
        id: -1440,
        name: 'Show Last 24 hours'
    },
    {
        id: -720,
        name: 'Show Last 12 hours'
    },
    {
        id: -480,
        name: 'Show Last 8 hours'
    },
    {
        id: -240,
        name: 'Show Last 4 hours'
    },
    {
        id: -120,
        name: 'Show Last 2 hours'
    },
    {
        id: -90,
        name: 'Show Last 90 Minutes'
    },
    {
        id: -60,
        name: 'Show Last 60 Minutes'
    },
    {
        id: -30,
        name: 'Show Last 30 Minutes'
    },
    {
        id: 30,
        name: 'Show Next 30 Minutes'
    },
    {
        id: 60,
        name: 'Show Next 60 Minutes'
    },
    {
        id: 90,
        name: 'Show Next 90 Minutes'
    },
    {
        id: 120,
        name: 'Show Next 2 hours'
    },
    {
        id: 240,
        name: 'Show Next 4 hours'
    },
    {
        id: 480,
        name: 'Show Next 8 hours'
    },
    {
        id: 720,
        name: 'Show Next 12 hours'
    }
]

export const checkInTypeDropdownOptions = [
    {
        id: 0,
        name: 'All Check-In Statuses'
    },
    {
        id: 1,
        name: CheckInTypeLabelEnum.Manual
    },
    {
        id: 2,
        name: CheckInTypeLabelEnum.Auto
    },
    {
        id: 3,
        name: CheckInTypeLabelEnum.Admin
    },
    {
        id: 4,
        name: CheckInTypeLabelEnum.Temp
    },
    {
        id: 5,
        name: 'Check-In Is Not Received'
    }

]

export const checkOutTypeDropdownOptions = [
    {
        id: 0,
        name: 'All Check-Out Statuses'
    },
    {
        id: 1,
        name: CheckOutTypeLabelEnum.Manual
    },
    {
        id: 2,
        name: CheckOutTypeLabelEnum.Auto
    },
    {
        id: 3,
        name: CheckOutTypeLabelEnum.Admin
    },
    {
        id: 4,
        name: CheckOutTypeLabelEnum.Offline
    },
]

export const jobScheduleDropdownOptions = [
    {
        id: 1,
        name: 'Full Schedule'
    },
    {
        id: 2,
        name: 'Partial Schedule'
    },
]