export enum VocabularyEnum {
    deactReasons = "GuardDeactivationReason",
    actReasons = "GuardActivationReason",
    absenceReasons = "GuardActivationReason",
    shift = "Shift",
    deviceBrand = "DeviceBrand",
    shiftReason = "GuardRemoveJobShiftReason",
    bookLeave = "GuardBookedLeaveReason",
    replacement = "GuardReplacementReason",
    absence = "GuardAbsenceReason",
    province = "Province",
    state = "UsaStates",
    clientDeactivationReason = "ClientDeactivationReason",
    clientContactDeactivationReason = "ClientContactDeactivationReason",
    aspUserDeactivationReason = "ASPUserDeactivationReason",
    aspAdminDeactivationReason = "ASPAdminDeactivationReason",
    guardDeactivation = "GuardDeactivationReason",
    guardActivation = "GuardActivationReason",
    jobDeactivation = "JobDeactivationReason",
    scheduleDeactivation = "ScheduleDeactivationReason",
    jobActivation = "JobActivationReason",
    guardBookAbsenceReason = "GuardBookAbsenceReason",
    guardRemoveJobScheduleReason = "GuardRemoveJobScheduleReason",
    arrivalTypesOnGuardMobileApp = "ArrivalTypesOnGuardMobileApp",
    contactFormTopicsOnGuardMobileApp  = "ContactFormTopicsOnGuardMobileApp",
    incidentTypesOnGuardMobileApp = "IncidentTypesOnGuardMobileApp",
    country = "Country",
    securityQuestionsForGuards = "SecurityQuestions",
}