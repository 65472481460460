import React from 'react'

export interface IGuardDetailsModals {
    [index: string]: boolean,
    device: boolean, 
    deviceSuccess: boolean,
    bookLeaveEdit: boolean,
    bookLeaveEditSuccess: boolean,
    unassignGuard: boolean,
    unassignGuardSuccess: boolean,
    bookLeaveRemove: boolean, 
    bookLeaveRemoveSuccess: boolean
    assign: boolean
    assignSuccess: boolean
}

interface IGuardDetailsModalsContext {
    isLoading: boolean
    userId: number | string
    userName: string
    modals: IGuardDetailsModals,
    setModals: (state: boolean, keyName: string) => void
}

const GuardDetailsModalsContext = React.createContext<IGuardDetailsModalsContext>({} as IGuardDetailsModalsContext);

export default GuardDetailsModalsContext;