import React, { FC } from 'react'

type Props = {
    title: string;
    value: string;
    description: string;
}

const CardStatistic: FC<Props> = ({ title, value, description }) => {
    return (
        <div className="card card-statistic h-100 ">
            <div className="card-body text-center px-3 d-flex flex-column justify-content-between">
                <div className="mb-4">
                    <h6>{ title }</h6>
                    <p>{description}</p>
                </div>
                <strong>{ value }</strong>
            </div>
        </div>
    )
}

export default CardStatistic
