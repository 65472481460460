import React, { useEffect, useState } from 'react'
import AutocompleteField from 'components/autosuggestField/AutocompleteField'
import Layout from 'components/layout/Layout'
import SectionTitle from 'components/titles/SectionTitle'
import DatePicker from "react-datepicker";
import { useDatapickerSvg } from 'customHooks/useDatapickerSvg';
import { SVGCalendar, SVGEraser } from 'assets/icons/SvgIcons';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import { useListFilterJobShiftReport } from 'customHooks/useListFilterJobShiftReport';
import { datePickerFormat, setHoursAndMinutes } from 'utils/DateFormatting';
import { IShiftJobReportModel } from 'common/interfaces/IDataFilter';
import { guardCoverageOpts } from 'content/dropdownOptionsConsts'
import useReportActions from 'customHooks/reports/useReportActions';
import AttendaceReportList from './AttendaceReportList';
import { useSchoolYear } from 'customHooks/useSchoolYear';
import { useRole } from 'customHooks/useRole';
import '../report-fields.scss';
import useZones from 'customHooks/useZones';

const AttendaceReportDashboard = () => {
    const { generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick, onFilterDefaultSchoolYear }
        = useListFilterJobShiftReport('reports/attendance')
    const { holidays, schoolBoards, onChangeMultiSelectString, showTable,
        setShowTable, fetchAdditionalData, shiftVocabulary, zones,
        fetchHolidaysBySchoolBoard, fetchHolidays } = useReportActions(filter);
    const { showDatapicker, changeDatapicker } = useDatapickerSvg()
    const {isClientRole} = useRole();
    const [generatedQuery, setGeneratedQuery] = useState('')
    const zoneList = useZones();
    const { schoolYears, getSchoolYears: setDefaultSchoolYears } = useSchoolYear();
    const handleCheckBox = (type: keyof IShiftJobReportModel, index: number) => {
        onChangeMultiSelectString(type, index, (result, type) => onFilterDropdownChange(result, type))
    }

    const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setGeneratedQuery(generateFilterQuery())
        setShowTable(true)
    }

    useEffect(() => {
        fetchAdditionalData();
        setDefaultSchoolYears(onFilterDefaultSchoolYear);
    }, [])

    useEffect(() => {
        filter.schoolBoard && fetchHolidaysBySchoolBoard()
    }, [filter.schoolBoard])

    useEffect(() => {
        !filter.schoolBoard && fetchHolidays()
    }, [filter.schoolBoard])

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Reports',
                    link: 'reports'
                }],
                currentPageTitle: 'Attendance Report'
            }}
            className={showTable ? 'container-xl' : ''}
        >

            {!showTable &&
                <>
                    <div className="row mb-3">
                        <div className="col-12 d-flex justify-content-between">
                            <SectionTitle title={'Attendance Report'} />
                            <button
                                type="button"
                                className="btn btn-aqua-blue"
                                onClick={() => onFilterClearClick()}
                            >
                                <SVGEraser />
                            </button>
                        </div>
                    </div>


                    <form onSubmit={onFormSubmit} className="mb-5">

                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    {!isClientRole() && <div className="col-md-6 form-group">
                                        <label>School Year</label>
                                        <CustomSelectControl
                                            options={schoolYears}
                                            onChange={(value) => onFilterDropdownChange(value, "schoolYearId")}
                                            value={filter.schoolYearId}
                                            reverse
                                            placeholder={"Select a School Year"}
                                            className={`custom-react-select--schoolYearId`}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-6 form-group shfit-autosegest">
                                        <label htmlFor="firstStreet">Job Name or ID</label>
                                        <AutocompleteField
                                            showButton={false}
                                            includes={'Job'}
                                            isAsync={true}
                                            defaultValue={!filter.jobNameOrId ? true : false}
                                            inputValue={filter.jobNameOrId || undefined}
                                            placeholder='Enter Job Name or ID'
                                            onSearch={value => onFilterDropdownChange(value, 'jobNameOrId')}
                                            onChange={(value) => onFilterDropdownChange(value, 'jobNameOrId')}
                                            onClear={() => onFilterDropdownChange('', 'jobNameOrId')} />
                                    </div>
                                    <div
                                        className="col-md-6 form-group d-flex flex-row align-items-end flex-wrap">
                                        <div className="col-6 pl-0">
                                            <div className="form-group mb-0 d-flex align-items-center">
                                                <label>From: </label>
                                            </div>
                                            <div
                                                className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                <DatePicker
                                                    selected={datePickerFormat(filter.startDate!)}
                                                    onChange={date => {
                                                        date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'startDate')
                                                        changeDatapicker(1);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    onInputClick={() => changeDatapicker(1)}
                                                    open={showDatapicker[1]}
                                                    onClickOutside={() => changeDatapicker(1)}
                                                />
                                                <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                                            </div>
                                        </div>
                                        <div className="col-6 pr-0">
                                            <div className="form-group mb-0 d-flex align-items-center">
                                                <label>To: </label>
                                            </div>
                                            <div
                                                className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                <DatePicker
                                                    selected={datePickerFormat(filter.endDate!)}
                                                    onChange={date => {
                                                        date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'endDate')
                                                        changeDatapicker(2);
                                                    }}
                                                    dateFormat="dd/MM/yyyy"
                                                    onInputClick={() => changeDatapicker(2)}
                                                    open={showDatapicker[2]}
                                                    onClickOutside={() => changeDatapicker(2)}
                                                />
                                                <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 form-group">
                                        <label htmlFor="firstStreet">Shift Period</label>
                                        <div
                                            className="custom-control custom-checkbox d-flex flex-wrap align-items-center mt-2">
                                            <div className="mr-5">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.shiftPeriod!.indexOf('42') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('shiftPeriod', 42)}
                                                    className="custom-control-input" id="Morning"
                                                    name="Morning" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="Morning">Morning</label>
                                            </div>
                                            <div className="mr-5 ml-2">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.shiftPeriod!.indexOf('43') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('shiftPeriod', 43)}
                                                    className="custom-control-input"
                                                    id="Afternoon"
                                                    name="Afternoon" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="Afternoon">Lunch</label>
                                            </div>
                                            <div className="ml-2">
                                                <input
                                                    type="checkbox"
                                                    checked={(filter.shiftPeriod!.indexOf('44') >= 0) ? true : false}
                                                    onChange={() => handleCheckBox('shiftPeriod', 44)}
                                                    className="custom-control-input"
                                                    id="Dismissal"
                                                    name="Dismissal" />
                                                <label className="custom-control-label mb-0"
                                                    htmlFor="Dismissal">Dismissal</label>
                                            </div>
                                        </div>
                                    </div>

                                        <div className="col-md-6 form-group">
                                            {zoneList.length > 0 && (
                                                <>
                                                    <label htmlFor="firstStreet">Zone</label>
                                                    <div
                                                        className="custom-control custom-checkbox d-flex align-items-center flex-wrap mt-2 report-checkbox-container">
                                                        {zoneList.map(({ id, name }) => (
                                                            <div key={id} className=" mb-2 d-flex align-items-center checkbox-field">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={(filter.zone!.split(';').indexOf(id.toString()) >= 0) ? true : false}
                                                                    onChange={() => handleCheckBox('zone', id)}
                                                                    className="custom-control-input" id={name}
                                                                    name={name} />
                                                                <label className="custom-control-label mb-0"
                                                                    htmlFor={name}>{name}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    <div className="col-md-4 form-group">
                                        <label>School Board</label>
                                        <CustomSelectControl
                                            options={schoolBoards}
                                            onChange={e => onFilterDropdownChange(e, "schoolBoard")}
                                            value={filter.schoolBoard}
                                            placeholder={"Select a School Board"}
                                            className={`custom-react-select--clientId`}
                                            isMulti={true}
                                            sort
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Holidays</label>
                                        <CustomSelectControl
                                            options={holidays}
                                            onChange={e => onFilterDropdownChange(e, "holidayName")}
                                            value={filter.holidayName}
                                            placeholder={"Select Holiday(s)"}
                                            className={`custom-react-select--clientId`}
                                            isMulti={true}
                                            sort
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label>Guard Coverage for Shift</label>
                                        <CustomSelectControl
                                            options={guardCoverageOpts}
                                            onChange={e => onFilterDropdownChange(e, "coverageForShift")}
                                            value={filter.coverageForShift}
                                            placeholder={"Select a Guard Coverage"}
                                            className={`custom-react-select--clientId`}
                                            isClearable
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col text-right">
                                        <button type="submit" className="btn btn-aqua-blue ml-auto mt-4">Create Report</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </>
            }

            {showTable &&
                <AttendaceReportList
                    onBackClick={() => setShowTable(false)}
                    queryUrl={generatedQuery}
                    filter={filter}
                    options={[shiftVocabulary, zones, schoolBoards, holidays, guardCoverageOpts]}
                    hideTable={() => setShowTable(false)}
                />
            }

        </Layout>
    )
}

export default AttendaceReportDashboard
