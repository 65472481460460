import { IServiceConfig } from "../common/interfaces/IServiceConfig";
import UrlHelper from "./UrlHelper";


export class ConfigHelper {
  static getDefaultConfig(url: string, includes: string[] = []): IServiceConfig {
    return {
      url,
      includes,
      urlBuilder: new UrlHelper(),
    } as IServiceConfig;
  }

  static ensureConfig(config: IServiceConfig): IServiceConfig {
    if (!config.urlBuilder) {
      config.urlBuilder = new UrlHelper();
    }
    return config;
  }
}