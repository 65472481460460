import { EditorState } from 'draft-js'
import React, { FC } from 'react'
import { ToWhomeSendMessageGuard } from '../../../../common/enums/Administration'
import { ICustomNotificationGuard } from '../../../../common/interfaces/administration/INotificaion'
import { IClient } from '../../../../common/interfaces/clients/IClient'
import { IBaseModalProps } from '../../../../common/interfaces/IModal'
import { convertToHtml } from '../../../../utils/Converting'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import BaseModal from '../../BaseModal'

interface Props extends IBaseModalProps {
    data: ICustomNotificationGuard
    clients: IClient[] | undefined
}

const ConfirmGuardNotificationModal: FC<Props> = ({ onCancel, onSubmit, data, clients }) => {
    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSubmit && onSubmit()}
            cancelBtnText={'Back'}
            submitBtnText={'Confirm and Send'}
            title={"Confirm Your Message to Guards"}
            className="custom-message"
        >
        <form>
            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Sending message by:</label>
                </div>
                <div className="col-md-9 form-group mb-0">
                    <p className="mb-0">Email to All</p>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Guard recipients</label>
                </div>
                <div className="col-md-9 form-group mb-0">
                    <p className="mb-0">
                        { data.toWhomeSendMessage === ToWhomeSendMessageGuard.All ? 'All Guards' : 'All Guards assigned to jobs of a Client(s):'}
                        { clients && ( data.toWhomeSendMessage === ToWhomeSendMessageGuard.AssignedTojobs) && <p className="mb-0">{ matchOptionWithName(data.clientsId as string, clients.map(client => ({
                        id: client.id,
                        name: client.organizationName
                        }))) }</p> }
                    </p>
                </div>
            </div>

            <div className="row mb-3 d-flex align-item-start">
                <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                    <label className="mb-0">Message:</label>
                </div>
                <div className="col-md-9 form-group mb-0" dangerouslySetInnerHTML={{ __html: convertToHtml(data.editor as EditorState) }}/>
            </div>
        </form>    
        </BaseModal>
    )
}

export default ConfirmGuardNotificationModal
