import { IBaseShift } from 'common/interfaces/jobs/IShifts'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { IJobModalProps } from '../../../common/interfaces/jobs/IJobModal'
import BaseModal from '../BaseModal'

const RemoveShiftModalSuccess: FC<IJobModalProps<IBaseShift>> = ({ show, onClose, shift, jobName, userName, title }) => {
    return (
        <BaseModal
            show={show}
            onCancel={() => onClose()}
            cancelBtnText={"Close"}
            title={title || ''}
        >
            { userName && jobName && shift &&
                <div className="w-100 text-center">
                    {
                        userName
                        ?   <h4 className="text-left mb-4">{userName}  has been removed from <span className="font-weight-bold">{jobName}</span> shift</h4>
                        :   <h4 className="text-left mb-4">
                                { shift?.assignedGuard
                                    ? <span className="font-weight-bold"> {shift.assignedGuard} </span>
                                    : <span className="font-weight-bold">{shift?.firstName} {shift?.middleName} {shift?.lastName} </span>
                                } 
                                has been removed from  <span className="font-weight-bold">{jobName}</span> shift
                            </h4>
                    }
                    <Link className="btn btn-aqua-blue mt-4" to="/jobs/unassigned">Go To Unassigned Job Shifts</Link>
                </div>
            }
        </BaseModal>
    )
}

export default RemoveShiftModalSuccess
