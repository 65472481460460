import React, { FC } from 'react';
import BaseModal from '../BaseModal';
import useClientComment from 'customHooks/useClientComment'

type Props = {
	show: boolean;
	onCancel: () => void;
	submitText?: string;
    defaultValue?: string;
    title?: string;
	jobId: number;
	submitCallback?: () => void;
};
const AddEditCommentModal: FC<Props> = ({ show, onCancel, submitText = 'Add Comment', defaultValue = '', title, jobId, submitCallback }: Props) => {
	const { comment, onCommentChange, handleAddEditClientComment,
		showErrorMessage } = useClientComment({ defaultValue })

	const onSubmit = async () => {
		await handleAddEditClientComment(jobId, submitCallback)
		onCancel && onCancel()
	};

	return (
		<BaseModal
			show={show}
			onCancel={onCancel}
			submitBtnText={submitText}
			onSubmit={onSubmit}
			cancelBtnText={'Close'}>
			<form>
				<div className='form-group'>
					<label className='required' htmlFor='geoFence'>
						{title || 'Add a Comment for the City'}
					</label>
					<textarea
						name='comments'
						className='form-control'
						id='comments'
						placeholder='Enter comment'
						autoComplete='nope'
						rows={4}
						value={comment}
						onChange={onCommentChange}
					/>
					<small className='d-block text-right'><span className={comment.length === 200 ? 'text-danger font-weight-bold': ''}>{comment.length}</span> / 200</small>
				</div>
				<div className='form-group ml-auto'>
					<small className='text-danger pb-4'>
						{showErrorMessage}
					</small>
				</div>
			</form>
		</BaseModal>
	);
};

export default AddEditCommentModal;
