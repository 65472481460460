import { useContext, useState } from "react"
import { IAvailableGuard } from '../common/interfaces/guards/IGuard'
import AppContext from '../context/AppContext'
import { momentDateFormat, momentHoursFormat } from '../utils/DateFormatting'
import { DataService } from '../common/services/DataService'
import { NotificationTypes } from "../common/interfaces/INotification"
import { IVocabulary } from "../common/interfaces/vocabulary/IVocabulary"
import { checkIfIsOtherReason } from "../utils/CheckOtherReason"
import { IUpdateShiftBody } from "../common/interfaces/jobs/IJob"
import { matchOptionWithName } from "../utils/MatchOptionWithName"
import { VocabularyEnum } from "common/enums/VocabularyEnum"
import { useVocabulary } from "./vocabulary/useVocabulary"

export const useReplaceGuard = () => {
    const [selectedGuard, setSelectedGuard] = useState<IAvailableGuard>({} as IAvailableGuard)
    const [modalContent, setModalContent] = useState<string>('')
    const { showLoader, showNotification } = useContext(AppContext)
    const [replaceModal, setReplaceModal] = useState<{ confirm: boolean, success: boolean }>({ confirm: false, success: false })
    const shiftUpdateSvc = new DataService<IUpdateShiftBody>({ url: "shift" })
    const { vocabulary: shiftsVocabulary } = useVocabulary(VocabularyEnum.shift, true)

    const onReplaceGuardSaveClick = async (emptyReason: boolean, shift: any, reason: string | IVocabulary) => {
        try {
            const emptyGuard = Object.keys(selectedGuard).length === 0;
            if (emptyGuard || emptyReason) {
                let msg = '';
                emptyGuard && (msg += `Please Select A Guard To Continue. `);
                emptyReason && (msg += `Please Select A Reason To Continue. `);
                showNotification(NotificationTypes.warning, msg)
                // emptyReason && showNotification(NotificationTypes.warning, 'Please Select A Reason To Continue.')
                throw Error
            }
            setModalContent(modalMarkup(shift, 'Are you sure you want to replace the shift assignment for the following guard:', reason))
            setReplaceModal({ confirm: true, success: false  })
            return true
        } catch (error: any) {
            console.log(error)
            throw Error
        }
    }

    const onReplaceGuardConfirmClick = async (shift: any, selectedReason: IVocabulary | string, idKeyName: string = 'id') => {
        showLoader(true)
        const body = {
            guardId: selectedGuard.id,
            timeFrom: shift.timeFrom,
            timeTo: shift.timeTo,
            reason: checkIfIsOtherReason(selectedReason) ? selectedReason as string : (selectedReason as IVocabulary).id
        }
        try {
            await shiftUpdateSvc.update(body, shift[idKeyName])
            setModalContent(modalMarkup(shift, 'You have successfully replaced the shift assignment for the following guard:', selectedReason))
            setReplaceModal({ confirm: false, success: true  })
            setTimeout(() => showLoader(false), 1500)

        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onBulkReplaceGuardConfirmClick = async (shifts: any, selectedReason: IVocabulary | string) => {
        const svc = new DataService<IUpdateShiftBody>({ url: "shift/bulk-replace-guards" })
        showLoader(true)
        const body = {
            guardId: selectedGuard.id,
            shiftIds: shifts,
            reason: checkIfIsOtherReason(selectedReason) ? selectedReason as string : (selectedReason as IVocabulary).id
        }
        try {
            await svc.update(body)
            setModalContent(modalMarkup(undefined, 'You have successfully replaced the shift assignments', selectedReason))
            setReplaceModal({ confirm: false, success: true  })
            setTimeout(() => showLoader(false), 1500)
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const updateShiftStatus = async ({ guardId, reason, checkInDateTime, checkOutDateTime, updateComments }: IUpdateShiftBody,
        shiftId: number, callBack?: () => void) => {
        showLoader(true)
        try {
            let body: IUpdateShiftBody = { guardId: guardId };
            reason && (body.reason = reason);
            checkInDateTime && (body.checkInDateTime = checkInDateTime);
            checkOutDateTime && (body.checkOutDateTime = checkOutDateTime);
            updateComments && (body.updateComments = updateComments);
            await shiftUpdateSvc.update(body, shiftId)
            callBack && callBack();
            setTimeout(() => showLoader(false), 1500)
        } catch (error: any) {
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const modalMarkup = (shift: any, title: string, reason: string | IVocabulary) => (
        `<h4 class="mb-4">${title}</h4>
        ${shift ? ` <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">Job Name:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">${shift.jobName}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">Date:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">${momentDateFormat(shift.startDate)}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">ASP Start Time:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">${momentHoursFormat(shift.aspStartDate, shift.timeFrom, true)}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">Shift Times:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">${momentHoursFormat(shift.timeFrom)} - ${momentHoursFormat(shift.timeTo, shift.timeFrom)}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">Shift Period:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">${matchOptionWithName(shift.shiftPeriod, shiftsVocabulary)}</p>
            </div>
        </div>
        
        <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">Reason:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">${checkIfIsOtherReason(reason) ? reason : (reason as IVocabulary).name}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 details-information__title mb-3 ">
                <h6 class="mb-0">Guard Scheduled to Take Shift:</h6>
            </div>
            <div class="col-sm-6 details-information__value d-flex flex-row align-items-center mb-3 ">
                <p class="mb-0">
                    ${selectedGuard.firstName} ${selectedGuard.middleName} ${selectedGuard.lastName}
                </p>
            </div>
        </div>` : ''}`
    );

    return {
        selectedGuard, setSelectedGuard, modalContent, setReplaceModal, replaceModal,
        onReplaceGuardSaveClick, onReplaceGuardConfirmClick, updateShiftStatus, onBulkReplaceGuardConfirmClick
    }
}