import React, {useContext, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {SVGCancel, SVGPencil} from '../../../assets/icons/SvgIcons'
import {manageSchoolsContent} from '../../../content/administration/AdministrationContent'

import {IManageModalsState} from '../../../common/interfaces/administration/IAdministrationStates'
import {ISchool} from '../../../common/interfaces/administration/ISchool'
import {VocabularyEnum} from '../../../common/enums/VocabularyEnum'
import {ISchoolBoardsDropdown} from '../../../common/interfaces/dropdowns/ISchoolDropdown'
import {NotificationTypes} from '../../../common/interfaces/INotification'
import {DataService} from '../../../common/services/DataService'

import Layout from '../../../components/layout/Layout'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import AddSchoolModal from '../../../components/modal/administration/AddSchoolModal'
import UploadFileModal from '../../../components/modal/administration/UploadFileModal'
import ReportActions from '../../../components/reports/ReportActions'
import RemoveBaseModal from '../../../components/modal/administration/RemoveBaseModal'
import BaseModal from '../../../components/modal/BaseModal'

import {useTableList} from '../../../customHooks/useTableList'
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import {useVocabulary} from '../../../customHooks/vocabulary/useVocabulary'
import AppContext from '../../../context/AppContext'
import {ActivationDeactivation} from '../../../common/enums/Actions'
import {matchOptionWithName} from '../../../utils/MatchOptionWithName'
import useGetModel from '../../../customHooks/useGetModel'
import {Reports} from "../../../common/enums/Reports";
import {AuthService} from '../../../common/auth/AuthService'
import {Roles} from '../../../common/enums/Roles'
import ResourceDataService from '../../../common/services/ResourceDataService'

const ManageSchoolsAndBoards = () => {
    const {tableData, isLoading, setTableData, onSortCall, fetchData} = useTableList<ISchool>('school')
    const [modal, setModal] = useState<IManageModalsState>({
        add: false,
        confirm: false,
        upload: false,
        deactivate: false,
        deactivateSuccess: false,
        index: 0,
        modalData: {} as ISchool,
    })
    const [isEditMode, setIsEditMode] = useState(false);
    const {vocabulary: provinces} = useVocabulary(VocabularyEnum.province, true)
    const { vocabulary: states} = useVocabulary(VocabularyEnum.state, true);
    const { vocabulary: countries} = useVocabulary(VocabularyEnum.country, true);
    const [schoolBoards, setSchoolBoards] = useState<Array<{ id: number, name: string }>>([{id: 0, name: ''}])
    const {showLoader, showNotification} = useContext(AppContext)
    const {onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse} = useActivateDeactivate('school');
    const schoolSvc = new DataService<ISchool>({url: 'school'})
    const uploadSvc = new ResourceDataService({url: 'document/upload'})
    const {getModelData: fetchSchoolBoards} = useGetModel<ISchoolBoardsDropdown[]>('school/dropdown')

    useEffect(() => {
        fetchSchoolBoards(data => {
            setSchoolBoards(data.map(board => ({
                id: board.id, name: board.name
            })))
        })
    }, [])

    const baseAction = useMemo(() => (
        async (callBack: () => Promise<void>, successCallback: (data: ISchool[]) => void) => {
            showLoader(true)
            try {
                await callBack()
                const data = await fetchData(0, 0, '', true)
                data && successCallback(data)
                showLoader(false)
            } catch (error: any) {
                showLoader(false)
                showNotification(NotificationTypes.danger, error.message)
            }
        }

    ), [fetchData, showLoader, showNotification])

    const saveNewSchool = async (newSchool: ISchool) => {
        baseAction(async () => {
            await schoolSvc.create(newSchool)
        }, (schools) => {
            schools && setTableData(schools);
            showNotification(NotificationTypes.success, `${newSchool.schoolName} has been added`)
            setModal({...modal, add: false})
        })
    }

    const editExistedSchool = async (newSchool: ISchool) => {
        baseAction(async () => {
            await schoolSvc.update(newSchool, newSchool.id)
        }, (schools) => {
            schools && setTableData(schools);
            showNotification(NotificationTypes.success, `${newSchool.schoolName} has been updated`)
            setModal({...modal, add: false})
        })
    }

    const onEditClick = (schoolId: string | number) => {
        setIsEditMode(true)
        setModal({
            ...modal,
            add: true,
            modalData: tableData.find(school => school.id === schoolId)
        })
    }

    const handleUploadFile = async (file: FormData) => {
        showLoader(true)
        try {
            await uploadSvc.sendFile(file);
            await fetchData(0, 0)
            showNotification(NotificationTypes.success, `The file has been uploaded`)
            setModal({...modal, upload: false})
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    const newSchoolBoardAdded = (option: any) => {
        setSchoolBoards([
            ...schoolBoards,
            ...[{
                id: option.id,
                name: option.boardName
            }]
        ].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())))
    }

    const {currentUser} = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], currentUser.role)

    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: 'administration'
                }],
                currentPageTitle: 'Manage Schools and School Boards'
            }}
        >
            <div className="row mb-4">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-between">
                    <div>
                        <SectionTitle title={`Manage Schools and School Boards`}/>
                        <small className="mt-2 mt-md-0 font-weight-normal align-section-subtitle">
                            Note that you cannot deactivate a school if jobs are set up for it.</small>
                    </div>
                    <Link className="btn btn-outline-aqua-blue" to="/administration/schools-and-boards/deactivated">View
                        inactive schools</Link>

                </div>
                <div className="col-12 mt-3">
                    <div
                        className="d-flex flex-row flex-wrap align-items-center justify-content-start justify-content-md-end">
                        <ReportActions title="Full Details:" className="mr-3 mr-md-0 mt-2 mt-sm-0"
                                       page={Reports.schools} tabName="SchoolsAndSchoolBoards" showForShiftReport={false} showPrint/>
                        <div>
                            <button type="button" className="btn btn-aqua-blue ml-0 ml-sm-3 mt-2 mt-sm-0"
                                    onClick={() => {
                                        setIsEditMode(false)
                                        setModal({...modal, add: true, modalData: undefined })
                                    }}>
                                Add a School
                            </button>
                            <button type="button" className="btn btn-outline-aqua-blue ml-2 ml-sm-3 mt-2 mt-sm-0"
                                    onClick={() => setModal({...modal, upload: true})}>
                                Upload a File
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageSchoolsContent.thead}
                                tbody={tableData ?
                                    tableData.map(item => ({
                                        id: item.id,
                                        schoolName: item.schoolName,
                                        address: [item.firstStreet, item.secondStreet, item.city, matchOptionWithName(item.province, [...provinces, ...states]), item.postalCode ? item.postalCode.toUpperCase() : item.postalCode].filter(item => item).join(", "),
                                        boardname: item.boardName,
                                        schoolHasJobs: item.schoolHasJobs,
                                    }))
                                    : []}
                                ignoreCols={[0, 4]}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed'}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
                                sortOnBackend
                                defaultSortedColumn={0}
                            >
                                {
                                    (id, rowItem: ISchool) => (
                                        <>
                                            <td className="align-middle">
                                                <div>
                                                    <button
                                                        className={`btn ${!itemsIsVisible ? 'btn-disabled' : ' btn-aqua-blue'}`}
                                                        onClick={() => (itemsIsVisible) && onEditClick(id)}
                                                    >
                                                        <SVGPencil />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="aling-middle">
                                            
                                                <div>
                                                    <button
                                                        className={`btn ${(rowItem.schoolHasJobs || !itemsIsVisible) ? 'btn-disabled' : ' btn-aqua-blue'}`}
                                                        onClick={() => (!rowItem.schoolHasJobs && itemsIsVisible) && onRequestHandler({
                                                            title: `Are you sure you want to deactivate <span class="font-weight-bold">${rowItem.schoolName}</span>?`,
                                                            itemInFocus: rowItem
                                                        })}
                                                    >
                                                        <SVGCancel/>
                                                    </button>
                                                </div>
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        ), [tableData, isLoading, provinces])
                    }
                </div>
            </div>

            {modal.add &&
            <AddSchoolModal
                isEditMode={isEditMode}
                onCancel={() => setModal({...modal, add: false, modalData: undefined })}
                onSubmit={(school: ISchool) => 
                    isEditMode ? editExistedSchool(school)  : saveNewSchool(school)
                }
                vocabulary={provinces}
                states={states}
                countries={countries}
                schoolBoards={schoolBoards}
                boardsCallback={option => newSchoolBoardAdded(option)}
                schoolToEdit={modal.modalData}
            />
            }

            {
                modal.upload && <UploadFileModal
                    onCancel={() => setModal({...modal, upload: false})}
                    onSubmit={(file: FormData) => handleUploadFile(file)}
                    subTitle={"Upload a spreadsheet with schools and boards"}
                    exampleLink={'https://docs.google.com/spreadsheets/d/1tdgtnPBcEb3gNCL3VhV5iG_4lU5fJRXZ7xwd21xqgTs/edit#gid=1619430924'}
                />
            }

            {
                requestModals.showRequest &&
                <RemoveBaseModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(ActivationDeactivation.deactivate, {
                        itemData: null,
                        successCallback: id => setTableData(onSuccessResponse<ISchool>(id, tableData))
                    })}
                    title={modalText.title}
                >
                </RemoveBaseModal>
            }

            {
                requestModals.showSuccess &&
                <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                    title={`<span class="font-weight-bold">${(itemInFocus as ISchool).schoolName}</span> has been deactivated`}
                >
                </BaseModal>
            }

        </Layout>
    )
}

export default ManageSchoolsAndBoards
