import React, { FC, useEffect } from 'react'
import { useContext } from 'react';
import { SVGApprove, SVGCancel } from '../../assets/icons/SvgIcons';
import { AuthService } from '../../common/auth/AuthService';
import { ActivationDeactivation } from '../../common/enums/Actions';
import { Roles } from '../../common/enums/Roles';
import { VocabularyEnum } from '../../common/enums/VocabularyEnum';
import { IBaseRequestToDeactivationProps } from '../../common/interfaces/IBaseActivationDeactivation'
import { IDeactivatedUsers } from '../../common/interfaces/manage-users/IDeactivatedUser';
import { manageDeactivateUsersContent } from '../../content/administration/AdministrationContent';
import AppContext from '../../context/AppContext';
import useActivateDeactivate from '../../customHooks/useActivateDeactivate';
import { useMatchArrayOfReasons } from '../../customHooks/useMatchArrayOfReasons';
import { useTableList } from '../../customHooks/useTableList';
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary';
import { momentDateFormat } from '../../utils/DateFormatting';
import BaseModal from '../modal/BaseModal';
import DataTable from '../tables/DataTable';

interface IProps extends IBaseRequestToDeactivationProps { }

const RequestsToDeactivateUsers: FC<IProps> = ({ hideTbodyCols, hideTheadCols, pageSize, onSuccess, reload }) => {
    const { tableData, isLoading, setTableData, fetchData } = useTableList<IDeactivatedUsers>('user/requested', pageSize ? pageSize : 0)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse } = useActivateDeactivate<IDeactivatedUsers>('user');
    const { matchUsersReasonIdWithName } = useMatchArrayOfReasons();
    const { vocabulary: clientDeactVocabulary } = useVocabulary(VocabularyEnum.clientDeactivationReason, true)
    const { vocabulary: aspDeactVocabulary } = useVocabulary(VocabularyEnum.aspUserDeactivationReason, true)
    const { vocabulary: adminDeactVocabulary } = useVocabulary(VocabularyEnum.aspAdminDeactivationReason, true)
    const { vocabulary: clientContactVocabulary } = useVocabulary(VocabularyEnum.clientContactDeactivationReason, true)

    useEffect(() => {
        reload && fetchData(1, pageSize ? pageSize : 0, 'user/requested')
    }, [reload])

    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], currentUser.role)

    return (
        <>
            {
                React.useMemo(() => (
                    <DataTable
                        thead={manageDeactivateUsersContent.thead}
                        tbody={tableData ?
                        tableData.map(user => (
                            {
                                id: user.id,
                                fullName: user.fullName,
                                userType: user.userType,
                                statusRequestDate: user.statusRequestDate ? momentDateFormat(user.statusRequestDate) : '-',
                                statusRequestedBy: user.statusRequestedBy || '-',
                                statusChangeReason: user.statusChangeReason ? matchUsersReasonIdWithName(user.statusChangeReason, clientDeactVocabulary, aspDeactVocabulary, adminDeactVocabulary, clientContactVocabulary) : '-',
                            }
                        ))
                        .sort((a, b) => a.fullName.localeCompare(b.fullName))
                        : []}
                        ignoreCols={[...[0], ...hideTbodyCols || []]}
                        ignoreTheadCols={hideTheadCols}
                        isLoading={isLoading}
                        tableClass={'table-info--notFixed'}
                    >
                        {
                            (_id, rowItem: IDeactivatedUsers) => (
                                <>
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    {
                                                        title: `Are you sure you want to deactivate  <span class="font-weight-bold">${rowItem.fullName}</span>?`,
                                                        itemInFocus: rowItem 
                                                    }
                                                )}
                                            >
                                                <SVGApprove />
                                            </button>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'} ml-2`}
                                                onClick={() => itemsIsVisible && onRequestHandler(
                                                    { 
                                                        title: `Are you sure you want to decline the deactivation request for <span class="font-weight-bold">${rowItem.fullName}</span>?`,
                                                        itemInFocus: rowItem,
                                                        type: ActivationDeactivation.disapprove
                                                    }
                                                )}
                                            >
                                                <SVGCancel />
                                            </button>
                                        </div>
                                    </td>
                                </>
                            )
                        }
                    </DataTable>
                ), [tableData, isLoading, clientDeactVocabulary, aspDeactVocabulary, adminDeactVocabulary, matchUsersReasonIdWithName])
            }

            {
                (requestModals.showRequest || requestModals.showDissaprove) && <BaseModal
                    show={requestModals.showRequest || requestModals.showDissaprove}
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(requestModals.showRequest ? ActivationDeactivation.approve : ActivationDeactivation.disapprove, 
                        { itemData: null, successCallback: id => { onSuccess && onSuccess(); setTableData(onSuccessResponse<IDeactivatedUsers>(id, tableData)) }},
                        requestModals.showRequest ? 
                        `<span class="font-weight-bold">${itemInFocus.fullName}</span> has been deactivated.` :
                         `<span class="font-weight-bold">${itemInFocus.fullName}</span> has not been deactivated.`
                    )}
                    submitBtnText={"Confirm"}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }

            {
                requestModals.showSuccess && <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={'Close'}
                >
                    <h4 className="text-left mb-4" dangerouslySetInnerHTML={{ __html: modalText.title }} />
                </BaseModal>
            }
        </>
    )
}

export default RequestsToDeactivateUsers
