import React, { FC, useEffect, useState } from 'react';

import { filterDateFormat, momentDateFormat, momentHoursFormat } from '../../utils/DateFormatting';
import { useTableList } from '../../customHooks/useTableList';
import { useListFilter } from '../../customHooks/useListFilter';

import { recentActivityContent } from '../../content/administration/AdministrationContent';
import { IRecentActivity } from '../../common/interfaces/IRecentActivity';
import DatePicker from 'react-datepicker';
import DataTable from '../../components/tables/DataTable';
import { useDatapickerSvg } from '../../customHooks/useDatapickerSvg';
import { SVGCalendar, SVGEraser } from '../../assets/icons/SvgIcons';
import CustomSelectControl from '../custom-select/CustomSelectControl';
import { Roles } from '../../common/enums/Roles';
import ResourceDataService from '../../common/services/ResourceDataService';
import { IAspAdmin } from '../../common/interfaces/user/IAspAdmin';
import { IAspUser } from '../../common/interfaces/user/IAspUser';
import { IClientUser } from '../../common/interfaces/user/IClientUser';

type Props = {
    baseUrl: string;
    allActivity: boolean
    tableName?: string;
    hideFilterByUser?: boolean;

};
interface IDropdownOpt {
    id: string | number;
    name: string;
    isDisabled?: boolean
}
interface IDataUsers {
    aspAdmins: IAspAdmin[];
    aspUsers: IAspUser[];
    clientUsers: IClientUser[];
}

type UserType = 'aspAdmins' | 'aspUsers' | 'clientUsers';


const UserActivityTable: FC<Props> = ({ baseUrl, allActivity, tableName, hideFilterByUser  }) => {
    const {
        currentPage,
        perPage,
        allRecords,
        onPagination,
        onPerPage,
        tableData,
        isLoading,
        onSortCall,
        fetchData,
        onClearFilterQuery,
    } = useTableList<IRecentActivity>(baseUrl);
    const { filter, onFilterDropdownChange, onFilterClearClick, generateFilterQuery, setFilter } = useListFilter(baseUrl);
    const { showDatapicker, changeDatapicker } = useDatapickerSvg();
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
    const [users, setUsers] = useState<IDropdownOpt[]>([]);
    const [roles, setRoles] = useState<string>('');

    useEffect(() => {
        fetchUsers(data => setUsersDropdown('', data, ['aspUsers', 'aspAdmins', 'clientUsers']));
    }, []);

    const fetchUsers = async (callback: (data: IDataUsers) => void) => {
        setUsers([]);
        const userSvc = new ResourceDataService<any>({ url: 'user' });
        try {
            const { data } = await userSvc.getAll();
            callback(data)
        } catch (error: any) {
            console.log(error);
        }
    };
    const filterByRole = (role: string) => {
        fetchUsers(data => setUsersDropdown(role, data, ['aspUsers', 'aspAdmins', 'clientUsers']))
    };

    const setUsersDropdown = (roles: string, data: IDataUsers, keys: UserType[] ) => {
        let allUsers: IDropdownOpt[] = [];
        if (!roles) {
            allUsers = [...data.aspAdmins, ...data.aspUsers, ...data.clientUsers].map(us => ({
                id: us.id,
                name: `${us.firstName} ${us.lastName}`,
            }));
        } else {
            roles.split(';').forEach(role => {
                allUsers = [...allUsers, ...[...data[keys[+role - 1]]].map(us => ({ id: us.id, name: `${us.firstName} ${us.lastName}` }))];
            });
        }
        setUsers(allUsers);
    }

    const convertFromRoleIdToRoleName = (roles: string): string => {
        return roles.split(';').map(role => {
            switch (role) {
                case '1':
                    return 'ASP';
                case '2':
                    return 'Admin';
                case '3':
                    return 'Client';
                default:
                    break;
            }
        }).join(';')
    }

    return (
        <div className='row'>
            <div className="col-md-12">
            <form className='filters-form-group mb-4 ' onKeyDown={(event) => {
                if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
            }}>
                <div className='row'>
                    <div className='col-12'>
                        <h5>​Filter Activities: </h5>
                    </div>
                    <div className='d-flex  w-100 flex-wrap ml-1 mr-1 align-items-center'>
                        {
                            !hideFilterByUser &&
                            <>
                                 <CustomSelectControl
                                    isClearable={true}
                                    options={[
                                        { id: 1, name: Roles.ASP },
                                        { id: 2, name: Roles.Admin },
                                        { id: 3, name: Roles.Client },
                                    ]}
                                    value={roles}
                                    placeholder={'Select Role'}
                                    onChange={(e) => {
                                        onFilterDropdownChange(convertFromRoleIdToRoleName(e), 'role')
                                        setRoles(e);
                                        filterByRole(e);
                                        if(!e && !filter.completedBy && !filter.startDate && !filter.endDate) {
                                            setFilter({ ...filter, hasValue: false })
                                            onClearFilterQuery();
                                            onFilterClearClick();
                                        }
                                    }}
                                    className='flex-grow-0 ml-2 mb-2 custom-react-multi-select'
                                    isMulti={true}
                                />
                                <CustomSelectControl
                                    isClearable={true}
                                    options={users}
                                    value={filter.completedBy}
                                    placeholder={'Select User'}
                                    onChange={(e) => {
                                        onFilterDropdownChange(e, 'completedBy');
                                        if(!e && !filter.role && !filter.startDate && !filter.endDate) {
                                            setFilter({ ...filter, hasValue: false, completedBy: ''})
                                            onClearFilterQuery();
                                            onFilterClearClick();
                                        }
                                    }}
                                    className='"flex-grow-0 ml-2 mb-2'
                                />
                            </>
                        }
                        <div className='react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 mr-2'>
                            <DatePicker
                                selected={filter.startDate ? new Date(filter.startDate) : null}
                                onChange={(date) => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate');
                                    changeDatapicker(1);
                                }}
                                dateFormat='dd/MM/yyyy'
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(1)}
                                open={showDatapicker[1]}
                                onClickOutside={() => changeDatapicker(1)}
                            />
                            <div onClick={() => changeDatapicker(1)}>
                                <SVGCalendar />
                            </div>
                        </div>

                        <div className='react-datepicker-custom-wrapper datepicker-width d-inline-block mb-2 '>
                            <DatePicker
                                selected={filter.endDate ? new Date(filter.endDate) : null}
                                onChange={(date) => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate');
                                    changeDatapicker(2);
                                }}
                                dateFormat='dd/MM/yyyy'
                                className='flex-basis-0'
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(2)}
                                open={showDatapicker[2]}
                                onClickOutside={() => changeDatapicker(2)}
                            />
                            <div onClick={() => changeDatapicker(2)}>
                                <SVGCalendar />
                            </div>
                        </div>

                        <button
                            type='button'
                            className='btn btn-aqua-blue ml-auto mr-0 mb-2'
                            onClick={() => {
                                filter.hasValue ? fetchData(1, 24, generateFilterQuery(true).replace('?&', '&')) : fetchData(1, 24)
                            }}>
                            Filter
                        </button>
                        {filter.hasValue && (
                            <button
                                type='button'
                                className='btn btn-aqua-blue ml-3 mb-2'
                                onClick={() => {
                                    setSearchIsActive(false);
                                    onClearFilterQuery();
                                    onFilterClearClick();
                                    setRoles('');
                                    filterByRole('');
                                }}>
                                <SVGEraser />
                            </button>
                        )}
                    </div>
                </div>
            </form>
            </div>
            <div className='col-md-12'>
                {React.useMemo(
                    () => (
                        <div className='row'>
                            {tableData && (
                                <div className='col-12'>
                                    <DataTable
                                        thead={recentActivityContent.thead}
                                        tbody={tableData.map((item) => ({
                                            id: item.id,
                                            date: momentDateFormat(item.date),
                                            time: momentHoursFormat(item.date),
                                            event: item.event,
                                            completedBy: item.completedBy,
                                        }))}
                                        tableClass={`table-info--notFixed`}
                                        isLoading={isLoading}
                                        ignoreCols={[0]}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={(page) => onPagination(page)}
                                        onPerPage={(value) => onPerPage(value)}
                                        tableName={tableName ? tableName : 'Events'}
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                                        showTableLengthData={true}
                                        sortOnBackend={true}
                                        defaultSortedColumn={0}
                                        searchIsActive={searchIsActive}
                                    />
                                </div>
                            )}
                        </div>
                    ),
                    [tableData, isLoading]
                )}
            </div>
        </div>
    );
};

export default UserActivityTable;
