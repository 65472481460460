import { EditorState } from 'draft-js';
import { ICustomNotificationClient, ICustomNotificationGuard } from '../interfaces/administration/INotificaion'

export class CustomClientNotification implements ICustomNotificationClient {
    subject = '';
    editor = EditorState.createEmpty();
    message = ""
    howSendMessage = '';
    toWhomeSendMessage = "";
    clientContacts = ""
    clients = ''
}

export class CustomGuardNotification implements ICustomNotificationGuard {
    subject = '';
    editor = EditorState.createEmpty();
    message = ""
    howSendMessage = '';
    toWhomeSendMessage = "";
    clientsId = '';
}