import { FC, useEffect, useRef, useState } from 'react';
import BaseModal from 'components/modal/BaseModal';
import { IBaseModalProps } from 'common/interfaces/IModal';
import {
	Circle,
	GoogleMap,
	HeatmapLayer,
	InfoWindow,
	LoadScript,
	Marker,
} from '@react-google-maps/api';
import {
	IGuardLocationTrackingReport,
	IGuardLocationTrackingReportLocation,
} from 'common/interfaces/reports/xguard-reports/IGuardLocationTracking';
import { circleOptions } from 'common/interfaces/maps/IMaps';
import Pin from 'assets/icons/jobPin.svg';
import { Link } from 'react-router-dom';
import { SVGEraser, SVGSearch } from 'assets/icons/SvgIcons';
import CustomTimePicker from 'components/custom-time-picker/CustomTimePicker';
import { timeWithoutSecondsFormat, UTCtimeToLocal } from 'utils/DateFormatting';
import { useTimeFilter } from 'customHooks/useTimeFilter';
import { Button } from 'react-bootstrap';

interface IProps extends IBaseModalProps {
	reports: IGuardLocationTrackingReport[];
}

interface IReadyData {
	coordinates: google.maps.LatLng[];
	defaultMapCenter: {
		lat: number;
		lng: number;
	};
}

const HeatmapReportModal: FC<IProps> = ({ onCancel, reports }) => {
	const [readyData, setReadyData] = useState<IReadyData>();
	const [showHeatLayer, setShowHetaLayer] = useState<boolean>(false);
	const [timeFilters, setTimeFilters] = useState({ timeFrom: '', timeTo: '' });
	const { filteredData, onFilterMultiTable, onClear } = useTimeFilter(reports);
	const [infoModal, setInfoModal] = useState<{ showTooltip: boolean; infoModalIndx: number }>({
		showTooltip: false,
		infoModalIndx: 0,
	});
	const mapRef = useRef(null);

	useEffect(() => {
		showHeatLayer && filteredData && initData();
	}, [showHeatLayer, filteredData]);

	useEffect(() => {
		setTimeFilters({
			timeFrom: reports[0]?.aspStartDate || '',
			timeTo: reports[0]?.timeTo || '',
		});
		onFilterMultiTable(reports[0]?.aspStartDate || '', reports[0]?.timeTo || '', 'guardLocations');
	}, [onFilterMultiTable, reports]);

	const getLastCoordinates = (locations: IGuardLocationTrackingReportLocation[]) => {
		return (
			locations[locations.length - 1] || {
				latitude: filteredData[0].latitude,
				longitude: filteredData[0].longitude,
			}
		);
	};
	const initData = () => {
		const heatmapLayerCenter = getLastCoordinates(filteredData[0].guardLocations);
		setReadyData({
			coordinates: getAllGuardCoordinates(filteredData),
			defaultMapCenter: {
				lat: +heatmapLayerCenter.latitude || +filteredData[0].latitude,
				lng: +heatmapLayerCenter.longitude || +filteredData[0].longitude,
			},
		});
	};

	const getAllGuardCoordinates = (reports: IGuardLocationTrackingReport[]) => {
		const coordinates = [] as google.maps.LatLng[];
		reports.forEach((report) => {
			report.guardLocations.length !== 0 &&
				report.guardLocations.forEach((location) => {
					coordinates.push(
						new window.google.maps.LatLng(+location.latitude, +location.longitude)
					);
				});
		});
		return coordinates as google.maps.LatLng[];
	};

	const handleMouseEvent = (idx: number, state: boolean) => {
		setInfoModal({
			showTooltip: state,
			infoModalIndx: idx,
		});
	};

	const fitBounds = (map: google.maps.Map) => {
		const bounds = new window.google.maps.LatLngBounds();
		filteredData.forEach((report) => {
			bounds.extend(new window.google.maps.LatLng(+report.latitude, +report.longitude));
		});
		map.fitBounds(bounds);
	};

	return (
		<BaseModal show onCancel={onCancel} showExitBtn className='view-job-location'>
			<div className='row job-location-large p-3'>
				<div className='col-12'>
					<form className='filters-form-group filters-form-group--dashboard mb-4 p-3'>
						<div className='d-flex mb-3 flex-row align-items-center flex-fill justify-content-between'>
							<h5 className='font-weight-bold mb-0'>Filter Map View List</h5>
						</div>
						<div className='d-flex flex-row flex-wrap flex-lg-nowrap align-items-end'>
							<div className='mr-lg-4'>
								<div className='form-group mb-0 d-flex align-items-center'>
									<label>From: </label>
								</div>
								<CustomTimePicker
									value={
										timeFilters.timeFrom
											? UTCtimeToLocal(timeFilters.timeFrom)
											: undefined
									}
									onChange={(timeFrom) => {
										setTimeFilters((prev) => ({
											...prev,
											timeFrom,
										}));
									}}
									index={1}
								/>
							</div>
							<div className=''>
								<div className='form-group mb-0 d-flex align-items-center'>
									<label>To: </label>
								</div>

								<CustomTimePicker
									value={
										timeFilters.timeTo
											? UTCtimeToLocal(timeFilters.timeTo)
											: undefined
									}
									onChange={(timeTo) => {
										setTimeFilters((prev) => ({
											...prev,
											timeTo,
										}));
									}}
									index={2}
								/>
							</div>
							<button
								type='button'
								className='btn btn-aqua-blue ml-2 mr-1'
								onClick={() => {
									onFilterMultiTable(
										timeFilters.timeFrom,
										timeFilters.timeTo,
										'guardLocations'
									);
								}}>
								<SVGSearch />
							</button>
							{timeFilters.timeFrom || timeFilters.timeTo ? (
								<button
									type='button'
									className='btn btn-aqua-blue ml-2 mr-1'
									onClick={() => {
										setTimeFilters({
											timeFrom: timeWithoutSecondsFormat(
												filteredData[0]?.timeFrom || ''
											),
											timeTo: timeWithoutSecondsFormat(
												filteredData[0]?.timeTo || ''
											),
										});
										onClear();
									}}>
									<SVGEraser />
								</button>
							) : null}

							<Button
								variant='outline-aqua-blue'
								type='button'
								className='footer-modal-button ml-auto'
								onClick={onCancel}>
								Close
							</Button>
						</div>
					</form>
				</div>
				<div className='col-12 position-relative'>
					<LoadScript
						googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}
						libraries={['visualization']}
						onLoad={() => setShowHetaLayer(true)}>
						{readyData && (
							<GoogleMap
								zoom={12}
								center={readyData.defaultMapCenter}
								options={{
									streetViewControl: false,
								}}
								id='job-location-map'
								ref={mapRef}
								onLoad={(map) => fitBounds(map)}>
								{showHeatLayer && (
									<HeatmapLayer
										data={readyData.coordinates}
										options={{
											opacity: 1,
											radius: 12.5,
										}}
									/>
								)}
								{filteredData &&
									filteredData.length !== 0 &&
									filteredData.map((report, index) => (
										<>
											<Marker
												position={{
													lat: +report.latitude,
													lng: +report.longitude,
												}}
												icon={{ url: Pin }}
												onMouseOver={() => handleMouseEvent(index, true)}>
												{infoModal.showTooltip &&
													index === infoModal.infoModalIndx && (
														<InfoWindow
															onCloseClick={() =>
																handleMouseEvent(0, false)
															}>
															<div className={`map-info-window`}>
																<p className='text-dark-lighter mb-0'>
																	Job:
																	<b>
																		<Link
																			to={`/jobs/${report.id}/details`}>
																			{report.jobName}
																		</Link>{' '}
																	</b>
																</p>
																<p className='text-dark-lighter mb-0'>
																	Job ID: <b>{report.jobId}</b>
																</p>
															</div>
														</InfoWindow>
													)}
											</Marker>

											<Circle
												radius={report.geoFence || 100}
												center={{
													lat: +report.latitude,
													lng: +report.longitude,
												}}
												options={circleOptions}
											/>
										</>
									))}
							</GoogleMap>
						)}
					</LoadScript>
				</div>
			</div>
		</BaseModal>
	);
};

export default HeatmapReportModal;
