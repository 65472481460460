import { IClientDropdown } from 'common/interfaces/dropdowns/IClientDropDown';
import moment from 'moment';
import { useRef, useState } from 'react';
import { ISchoolYearDropdown } from '../common/interfaces/dropdowns/ISchoolYearDropdown';
import ResourceDataService from '../common/services/ResourceDataService';
import { momentDateFormat } from '../utils/DateFormatting';
interface IDropdownOpts {
    id: number | string;
    name: string;
}
export const useSchoolYear = () => {
    const [schoolYears, setSchoolYears] = useState<IDropdownOpts[]>([]);
    const [schoolYearsData, setSchoolYearsData] = useState<ISchoolYearDropdown[]>([]);
    const defaultYear =  useRef<string | number | null | undefined>('')
    const [selectedSchoolYear, setSelectedSchoolYear] = useState<ISchoolYearDropdown>({} as ISchoolYearDropdown)

    const getSchoolYears = async (callback?: (value: string | number, filterName: string) => void,
        getYearId?: (yearId: string | number | undefined, years?: ISchoolYearDropdown[]) => void, fromCurrentOnly?: boolean, quantityOfPastItems?: number
    ) => {
        const schoolSvc = new ResourceDataService<ISchoolYearDropdown[]>({ url: `schoolyear/dropdown?IncludePast=${!fromCurrentOnly}` });
        try {
            let { data: years } = await schoolSvc.getAll();
            const currentYearId = getCurrentYears(years)?.id;
            // Filter the years based on the quantityOfPastItems
            if (quantityOfPastItems !== undefined) {
                const currentYearIndex = years.findIndex(v => v.id === currentYearId);
                years = years.slice(Math.max(0, currentYearIndex - quantityOfPastItems));
            }

            setSchoolYears(
                years.map((year) => ({
                    id: year.id,
                    name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}`,
                }))
            );
            console.log(getCurrentYears(years))
            setSchoolYearsData(years)
            defaultYear.current = currentYearId;
            callback && callback(currentYearId || '', currentYearId ? 'schoolYearId' : '')
            getYearId && getYearId(currentYearId, years || []);
        } catch (error: any) {
            console.log(error);
        }
    };

    // const getCurrentYears = (years: ISchoolYearDropdown[]) => {
    //     for (const year of years) {
    //         const startDate = new Date(year.startDate).getTime();
    //         const endDate = new Date(year.endDate).getTime();
    //         const currentTime = new Date().getTime();
    //         if (startDate <= currentTime && endDate >= currentTime) {
    //             return { id: year.id, name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}` } as IDropdownOpts;
    //         }
    //     }
    //     const futureYear = years[0];
    //     if (futureYear) {
    //         return { id: futureYear.id, name: `${momentDateFormat(futureYear.startDate)} - ${momentDateFormat(futureYear.endDate)}` } as IDropdownOpts;
    //     }
    //     return null;
    // };

    const getCurrentYears = (years: ISchoolYearDropdown[]) => {
        const currentTime = new Date().getTime();
        let nearestFutureYear: ISchoolYearDropdown | undefined;
      
        for (const year of years) {
          const startDate = new Date(year.startDate).getTime();
          const endDate = new Date(year.endDate).getTime();
      
          if (startDate <= currentTime && endDate >= currentTime) {
                return { id: year.id, name: `${momentDateFormat(year.startDate)} - ${momentDateFormat(year.endDate)}` } as IDropdownOpts;
          }
      
          if (startDate > currentTime && (!nearestFutureYear || startDate < new Date(nearestFutureYear.startDate).getTime())) {
            nearestFutureYear = year;
          }
        }
      
        if (nearestFutureYear) {
          return { id: nearestFutureYear.id, name: `${momentDateFormat(nearestFutureYear.startDate)} - ${momentDateFormat(nearestFutureYear.endDate)}` } as IDropdownOpts;
        }
      
        return null;
      };
    
    const getCurrentYear = (years: ISchoolYearDropdown[]) => {
        const currentYear = years.find(year => moment().isBetween(
            moment(year.startDate),
            moment(year.endDate),
            null, '[]'
            ));
            return currentYear;
    }


    const getYearById = (years: ISchoolYearDropdown[], id: number) => years.find(y => y.id === id) || null

    const getSchoolYearByClientIdAndYear = (clients: IClientDropdown[], clientId: string, schoolYear: string) => {
        const client = clients
                    .find(client => client.id === +clientId)?.schoolYears
                    .find(year => year.id === +schoolYear);
        if (client) {
            return `${momentDateFormat(client.startDate)} - ${momentDateFormat(client.endDate)}`
        }
    }

    return { schoolYears, getSchoolYears, defaultYear, getCurrentYear, getSchoolYearByClientIdAndYear, getYearById, schoolYearsData, setSelectedSchoolYear, selectedSchoolYear };
};
