import React, { FC, ReactNode } from 'react'
import { IBaseModalProps } from '../../../common/interfaces/IModal'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import { useReason } from '../../../customHooks/useReason'
// import { checkIfIsOtherReason } from '../../../utils/CheckOtherReason'
import CustomSelectControl from '../../custom-select/CustomSelectControl'
import BaseModal from '../BaseModal'

interface Props extends IBaseModalProps {
    reasons?: IVocabulary[]
    reasonsHook?: any
    subtitle?: string
    htmlBody?: ReactNode
    vocabulary?: IVocabulary[]
    bodyClassName?: string
    htmlFooter?: ReactNode
}

const DeactivateModal: FC<Props> = ({ onCancel, onSubmit, title, subtitle, vocabulary, htmlBody, bodyClassName, htmlFooter }) => {

    const { reason, reasonIsEmpty, reasons, onReasonSelect, textareaRef, setReason, isOtherReason } = useReason(vocabulary)

    const onSubmitClick = () => {
        if (reasons.length === 0){
            onSubmit && onSubmit()
        } else if (!reasonIsEmpty('reasonForDeactivation', 'reasonForDeactivation--other')) {
            onSubmit && onSubmit(reason)
        }
    }
    
    return <BaseModal
        show={true}
        onCancel={() => onCancel()}
        onSubmit={() => onSubmitClick()}
        cancelBtnText={'No'}
        submitBtnText={'Yes'}
        bodyClassName={bodyClassName}
        classNameButton="d-flex flex-row-reverse justify-content-around"
    >
        { title && <h4 className="mb-4" dangerouslySetInnerHTML={{ __html: title }} /> }
        { subtitle && <div className="mb-4 d-flex flex-column" dangerouslySetInnerHTML={{ __html: subtitle }} /> }
        { htmlBody ? htmlBody : null }
        { reasons.length !== 0 && <form>
            <div className="form-group">
                <CustomSelectControl
                    // value={checkIfIsOtherReason(reasonsHook.reason) ? 0 : reasonsHook.reason.id}
                    value={isOtherReason ? 0 : (reason as IVocabulary).id}
                    placeholder={"Select a reason"}
                    options={reasons ? reasons : []}
                    onChange={(e) => onReasonSelect(e)}
                    className={`custom-react-select--reasonForDeactivation`}
                />
            </div>
            {
                isOtherReason && 
                <div className="form-group">
                    {/* (checkIfIsOtherReason(reasonsHook.reason) || reasonsHook.reason.id === 0) && */}
                    <div className="form-group mt-3">
                        <textarea
                        className="form-control"
                        rows={5}
                        name={'reasonForDeactivation--other'}
                        placeholder="Type other reason"
                        value={reason as string}
                        // value={reason.id === 0 ? reason : ""}
                        onChange={e => setReason(e.target.value)}
                        ref={textareaRef}
                        ></textarea>
                    </div>
                </div>
            }
            { htmlFooter ? htmlFooter : null }
        </form>
        }
    </BaseModal>
}

export default DeactivateModal
