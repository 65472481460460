import { IGuardAppLeaveRequestReport } from "common/interfaces/reports/xguard-reports/iGuardAppLeave";
import { ModalState } from "customHooks/modals/useActionModals";

export type LeaveRequestModalType = "approve" | "deny" | "updateDates" | "approveSuccess" | "updateSuccess";

type ModalAction =
  | { type: "open"; modalType: LeaveRequestModalType; data?: IGuardAppLeaveRequestReport, id: string | number }
  | { type: "close"; modalType: LeaveRequestModalType };

export type LeaveRequestModalState =  ModalState<LeaveRequestModalType, IGuardAppLeaveRequestReport>;

export const LeaveRequestModalInitialState: LeaveRequestModalState = {
  approve: {
    isOpen: false,
    id: ''
  },
  approveSuccess: {
    isOpen: false,
    id: ''
  },
  deny: {
    isOpen: false,
    id: ''
  },
  updateDates: {
    isOpen: false,
    id: ''
  },
  updateSuccess: {
    isOpen: false,
    id: ''
  },
};

export function leaveRequestModalReducer(state: LeaveRequestModalState, action: ModalAction): LeaveRequestModalState {
  switch (action.type) {
    case "open":
      return {
        ...state,
        [action.modalType]: {
          isOpen: true,
          data: action.data,
          id: action.id
        },
      };
    case "close":
      return {
        ...state,
        [action.modalType]: {
          isOpen: false,
          data: null,
          id: '',
        },
      };
    default:
      return state;
  }
}

