import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { SVGCalendar, SVGEraser, SVGPlus } from '../../assets/icons/SvgIcons'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import { VocabularyEnum } from '../../common/enums/VocabularyEnum'
import { IAvailableGuard } from '../../common/interfaces/guards/IGuard'
import {IJobShiftsInNeedOfGuards} from '../../common/interfaces/jobs/IJob'
import CustomSelectControl from '../../components/custom-select/CustomSelectControl'
import AvailableGuards from '../../components/guards/AvailableGuards'
import Layout from '../../components/layout/Layout'
import BaseModal from '../../components/modal/BaseModal'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import AppContext from '../../context/AppContext'
import { useListFilter } from '../../customHooks/useListFilter'
import { useTableList } from '../../customHooks/useTableList'
import { useMatchArrayOfReasons } from '../../customHooks/useMatchArrayOfReasons'
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary'
import {datePickerFormat, dateToUtcDate, filterDateFormat, momentDateFormat, momentHoursFormat } from '../../utils/DateFormatting'
import { matchOptionWithName } from '../../utils/MatchOptionWithName'
import DatePicker from "react-datepicker";
import { useDatapickerSvg } from '../../customHooks/useDatapickerSvg'
import moment from 'moment'
import { useShiftPeriod } from '../../customHooks/useShiftPeriod'
import ShiftAssignedSuccessModal from '../../components/modal/jobs/ShiftAssignedSuccessModal'
import useAssignGuard from '../../customHooks/useAssignGuard'
import MulTipleCheckbox from "../../components/miltiple-checkbox/miltiple-checkbox"
import {useMultipleCheckbox} from "../../customHooks/useMultipleCheckbox";
import { useBulk } from '../../customHooks/useBulk'
import ConfirmBulkShiftModal from 'components/modal/jobs/confirmBulkShiftModal/ConfirmBulkShiftModal'
import AutocompleteField from '../../components/autosuggestField/AutocompleteField'
import { useRole } from 'customHooks/useRole'
import {unassignedShifts} from '../../content/administration/AdministrationContent';
import {useVerifyRolesTHead} from "../../customHooks/useVerifyRolesTHead";
import { NotificationTypes } from 'common/interfaces/INotification'
import { useSchoolYear } from 'customHooks/useSchoolYear'
import { ISchoolYearDropdown } from 'common/interfaces/dropdowns/ISchoolYearDropdown'

const UnassignedJobShiftsPage = () => {
    const { currentPage, perPage, allRecords, onPagination, onPerPage, tableData, isLoading, setTableData, fetchData, onClearFilterQuery, onSortCall } = useTableList<IJobShiftsInNeedOfGuards>('shift/unassigned')
    const appContext = useContext(AppContext)
    const {
        generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, schoolBoards, getSchoolBoard,
        onFilterSearchClearClick, zones, getZones, filterIsClear, onFilterDefaultSchoolYear, ignoreSearchParams, setFilter
    } = useListFilter("shift/unassigned")
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const searchParamsToBeIgnored = ['TimeFrom', 'TimeTo', 'SchoolBoardId', 'ZoneId'];
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showBulkGuardsModal, setShowBulkGuardsModal] = useState<boolean>(false)
    const [showBulkSuccessGuardsModal, setShowBulkSuccessGuardsModal] = useState<boolean>(false)
    const [showBulkConfirmGuardsModal, setShowBulkConfirmGuardsModal] = useState<boolean>(false)
    const [dataForBulkSuccess, setDataForBulkSuccess] = useState<{ shifts: IJobShiftsInNeedOfGuards[], guard: IAvailableGuard }>({ shifts: [], guard: {} as IAvailableGuard })
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [selectedShift, setSelectedShift] = useState<IJobShiftsInNeedOfGuards>({} as IJobShiftsInNeedOfGuards);
    const [selectedGuard, setSelectedGuard] = useState<IAvailableGuard>({} as IAvailableGuard);

    const { vocabulary: shiftVocabulary } = useVocabulary(VocabularyEnum.shift, true)
    const { vocabulary: reasonVocabulary } = useVocabulary(VocabularyEnum.shiftReason, true)
    const { vocabulary: bookLeaveVocabulary } = useVocabulary(VocabularyEnum.bookLeave, true)
    const { vocabulary: guardDeactivation } = useVocabulary(VocabularyEnum.guardDeactivation, true)

    const { format, currentHoursTime } = useShiftPeriod()
    const { matchShiftsReasonIdWithName } = useMatchArrayOfReasons();
    const { onAssignGuardSubmit: saveAssignedGuard } = useAssignGuard()
    const {onChangeCheckbox, onRemoveCheckbox, allCheckbox, arrayInStringWith, setAllCheckbox} = useMultipleCheckbox()
    const { availableGuards, getGuardsForBulkAssigning, bulkAssignGuardToShift } = useBulk()
    const { isClientRole, isSupervisorRole } = useRole()
    const {theadTable, addVerifyRoles} = useVerifyRolesTHead()
    const [allReadUniqueJobs, setAllReadUniqueJobs] = useState<IJobShiftsInNeedOfGuards[]>([])

    const {getSchoolYears: setDefaultSchoolYears, schoolYears, getYearById, schoolYearsData, selectedSchoolYear, setSelectedSchoolYear, defaultYear } = useSchoolYear()
    const onAssignGuard = (shiftId: number) => {
        setAllCheckbox([])
        setShowModal(true)
        const shift = tableData.find(shift => shift.id === shiftId)
        setSelectedShift(shift ? shift : {} as IJobShiftsInNeedOfGuards)
    }

    const onAssignGuardSubmit = async (guard: IAvailableGuard, shiftId: number) => {
        saveAssignedGuard(guard, shiftId, 'assign', () => {
            setTableData(tableData.filter(shift => shift.id !== shiftId))
            setShowSuccessModal(true)
        })
    }

    const filterOnload = async (yearId: string | number | undefined | null, ignoredParams: string[], args: any[]) => {
        const queryUrl = generateFilterQuery(args[0], args[1], args[2], yearId ? `&SchoolYearId=${yearId}` : '')
        await fetchData(1, 24, ignoreSearchParams(queryUrl, ignoredParams) , false)
    }

    useEffect(() =>{
        addVerifyRoles(unassignedShifts.thead)
    }, [unassignedShifts])

    useEffect(() => {
        getSchoolBoard()
        getZones()
    }, [])
    useEffect(() => {
        if (!filter.hasValue) {
            setDefaultSchoolYears(onFilterDefaultSchoolYear, (yearId, years) => {
                    years && setSelectedSchoolYear((getYearById(years, +(yearId || 0)) || {}) as ISchoolYearDropdown)
                    filterOnload(yearId, searchParamsToBeIgnored, [false, false, undefined])
                },
                true
            )
        }
    }, [filter.hasValue])
    const onBulkAssignClick = async () => {
        await getGuardsForBulkAssigning(arrayInStringWith(), () => {
            setShowBulkGuardsModal(true)
        })
    }

    const onBulkAssignSubmitClick = async (guard: IAvailableGuard) => { ;
        setDataForBulkSuccess({
            ...dataForBulkSuccess,
            guard: guard
        })
        setShowBulkGuardsModal(false)
        setShowBulkConfirmGuardsModal(true)
    }

    const onBulkAssignConfirmClick = async () => {
        await bulkAssignGuardToShift({ guardId: dataForBulkSuccess.guard.id }, arrayInStringWith(), async () => {
            setShowBulkConfirmGuardsModal(false)
            setAllCheckbox([])
            setShowBulkSuccessGuardsModal(true)
            await fetchData(currentPage, perPage, generateFilterQuery())
        })
    }

    const filterUniqueById = useCallback((data: IJobShiftsInNeedOfGuards[]): IJobShiftsInNeedOfGuards[] => {
        const uniqueMap = new Map<string, IJobShiftsInNeedOfGuards>();
      
        for (const item of data) {
          uniqueMap.set(item.id + "", item);
        }
      
        return Array.from(uniqueMap.values());
    }, []);

    useEffect(() => {
        if (allCheckbox.length !== 0) {
            const copiedShifts = Array.from(tableData);
            setDataForBulkSuccess({
                ...dataForBulkSuccess,
                shifts: filterUniqueById([...dataForBulkSuccess.shifts,
                    ...copiedShifts.filter(shift => allCheckbox.some(item => +item === shift.id))]
                .filter(shift => allCheckbox.some(item => +item === shift.id)))
            })
        } else {
            setDataForBulkSuccess({
                ...dataForBulkSuccess,
                shifts: []
            })
        }
    }, [allCheckbox, filterUniqueById])

    

    useEffect(() => {
        setAllReadUniqueJobs(prev => filterUniqueById([...prev, ...tableData]))
    }, [filterUniqueById, tableData])
    

    const validateBulkAction = useCallback((currentId: string) => {
        const dataToCheck = ( allReadUniqueJobs.length > 0 ? allReadUniqueJobs : tableData).filter((shift) => allCheckbox.includes(shift.id + ""));
        const currentShift = tableData.find((shift) => shift.id + "" === currentId);
        if (!currentShift) return
        const isMatchingShift = dataToCheck.some((item) => {
          const startDate = moment(item.startDate);
          const aspStartDate = moment(item.aspStartDate);
          const timeTo = moment(item.timeTo);
          const currentStartDate = moment(currentShift.startDate);
          const currentAspStartDate = moment(currentShift.aspStartDate);
          const currentTimeTo = moment(currentShift.timeTo);
          const isOverlap =
            (currentStartDate.isSame(startDate, 'date')) &&
            (
                (currentAspStartDate.isSameOrAfter(aspStartDate) && currentAspStartDate.isSameOrBefore(timeTo)) ||
                (currentAspStartDate.isSameOrBefore(aspStartDate) && currentTimeTo.isSameOrAfter(timeTo)) ||
                (currentAspStartDate.isSameOrBefore(aspStartDate) && currentAspStartDate.isSameOrBefore(timeTo) && currentTimeTo.isSameOrAfter(aspStartDate)) ||
                (currentAspStartDate.isSameOrAfter(aspStartDate) && currentAspStartDate.isSameOrAfter(timeTo) && currentTimeTo.isSameOrBefore(aspStartDate))
              )
      
          return isOverlap;
        });
        return !isMatchingShift;
      }, [allCheckbox, allReadUniqueJobs, tableData]);

    return (
        <Layout
        breadcrumbs={{
            links: [{
                title: 'Jobs',
                link: 'jobs'
            }],
            currentPageTitle: 'Unassigned Job Shifts'
        }}
        >
            <div className="row mb-4">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-between justify-content-md-start">
                    <SectionTitle title={`Unassigned Job Shifts`}/>
                    <div className="d-flex flex-row ml-0 ml-sm-auto mt-2 mt-lg-0 w-100-md-0">
                        <div className="form-group mb-0 w-100-md-0">
                            <AutocompleteField
                                includes={'Job'}
                                isAsync={true}
                                onlyActive
                                placeholder='Enter job name'
                                onSearch={ async (value) => {
                                    setSearchIsActive(true);
                                    onFilterDropdownChange(value, 'keyWord')
                                    await fetchData(1, 24, generateFilterQuery(false, false, value), false, false)
                                }}
                                onClear={() => {
                                    setSearchIsActive(false);
                                    onFilterSearchClearClick(() => fetchData(1, 24, generateFilterQuery(true)))
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <form className="filters-form-group filters-form-group--dashboard mb-4" onKeyDown={(event) => {
                if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
            }}>
                <div className="row">
                    <div className="col-12">
                        <h5>Filter Shift List</h5>
                    </div>
                    <div className="d-flex w-100 flex-wrap ml-1  ">
                        {/* { TODO: ADD Zone } */}
                        <CustomSelectControl
                            isClearable={true}
                            options={zones || []}
                            value={filter.zone}
                            placeholder={"Select Zone"}
                            sort
                            onChange={e => onFilterDropdownChange(e, "zone")}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--small"
                        />

                        <CustomSelectControl
                            isClearable={true}
                            options={schoolBoards}
                            value={filter.schoolBoardId}
                            placeholder={"Select School Board"}
                            onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <CustomSelectControl
                            isClearable={true}
                            options={shiftVocabulary}
                            value={filter.shiftPeriod}
                            placeholder={"Select Shift Period"}
                            onChange={e => onFilterDropdownChange(e, "shiftPeriod")}
                            className="flex-grow-0 ml-2 mb-2"
                        />


                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2">
                            <DatePicker
                                selected={filter.startDate ? new Date(filter.startDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate')
                                    changeDatapicker(1)
                                }}
                                dateFormat="dd/MM/yyyy"
                                minDate={datePickerFormat(selectedSchoolYear?.startDate || '')}
                                maxDate={datePickerFormat(selectedSchoolYear?.endDate || '')}
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(1)}
                                open={showDatapicker[1]}
                                onClickOutside={() => changeDatapicker(1)}
                            />
                            <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                        </div>

                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 ts">
                            <DatePicker
                                selected={filter.endDate ? new Date(filter.endDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate')
                                    changeDatapicker(2)
                                }}
                                dateFormat="dd/MM/yyyy"
                                minDate={datePickerFormat(selectedSchoolYear?.startDate || '')}
                                maxDate={datePickerFormat(selectedSchoolYear?.endDate || '')}
                                className="flex-basis-0"
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(2)}
                                open={showDatapicker[2]}
                                onClickOutside={() => changeDatapicker(2)}
                            />
                            <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                        </div>

                        <CustomSelectControl
                            options={ schoolYears || []}
                            value={filter.schoolYearId}
                            placeholder={"Select School Year"}
                            onChange={e => {
                                onFilterDropdownChange(e, "schoolYearId");
                                setFilter(prev => ({...prev, startDate: '', endDate: ''}))
                                setSelectedSchoolYear((getYearById(schoolYearsData, +(e || 0)) || {}) as ISchoolYearDropdown)
                            }}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--schoolYear"
                        />  

                        <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => { fetchData(1, 24, generateFilterQuery())}
                                }>
                            Filter Shifts
                        </button>
                        {
                            filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        setSearchIsActive(false);
                                        onClearFilterQuery()
                                        onFilterClearClick()
                                    }}>
                                <SVGEraser/>
                            </button>
                        }
                    </div>
                </div>
            </form>

             <section>
                <div className="row">
                        { allCheckbox.length !== 0 &&
                            <div className="col-12 d-flex flex-row justify-content-center align-items-center mb-4">
                                <h5 className="mb-0">Assign Selected Unassigned Job Shifts</h5>
                                <button className="btn btn-aqua-blue mx-2"  onClick={() => onBulkAssignClick()}>Select Guard</button>
                            </div>
                        }
                        <div className="col-12">
                            {
                                React.useMemo(() => (
                                    <DataTable
                                        thead={theadTable}
                                        tbody={tableData
                                            .map(shift => {
                                                if (moment.utc(shift.startDate).isSame(moment(), "day")) {
                                                    if (moment(momentHoursFormat(shift.timeTo), format).isBefore(moment(currentHoursTime, format))) {
                                                        shift.isInPast = true
                                                    }
                                                }
                                                return shift
                                            })
                                            .filter(job => !job.isInPast)
                                            .map(shift => (
                                            {
                                                id: shift.id,
                                                jobName: shift.jobName,
                                                shiftDate: momentDateFormat(shift.startDate),
                                                aspStartDate: shift.aspStartDate ? momentHoursFormat(shift.aspStartDate, shift.timeFrom, true) : '-',
                                                timeForm: momentHoursFormat(shift.timeFrom),
                                                timeTo: momentHoursFormat(shift.timeTo, shift.timeFrom),
                                                shiftPeriod: matchOptionWithName(shift.shiftPeriod, shiftVocabulary),
                                                reason: matchShiftsReasonIdWithName(shift.reason, bookLeaveVocabulary, reasonVocabulary, guardDeactivation),
                                            }
                                        ))}
                                        isLoading={isLoading}
                                        ignoreCols={[0, isClientRole() || isSupervisorRole() ? 3 : -1]}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={page => onPagination(page)}
                                        onPerPage={value => onPerPage(value)}
                                        tableName={"Unassigned Job Shifts"}
                                        showTableLengthData
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                                        searchIsActive={searchIsActive}
                                        sortOnBackend
                                        filtersIsNotClear={!filterIsClear ? true : false}
                                        defaultSortedColumn={1}
                                        tableClass={'table-info--notFixed table-unassigned-job-shifts'}
                                    >
                                        {
                                            (shiftId: number) =>
                                            !isClientRole() && !isSupervisorRole() &&
                                            <>
                                                <td className="align-middle">
                                                    <div>
                                                        <button
                                                        className={`btn btn-aqua-blue`}
                                                        onClick={() => onAssignGuard(shiftId)}
                                                        ><SVGPlus /></button>
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="form-group mb-0">
                                                        <div className="custom-control custom-checkbox d-flex align-items-center">
                                                            <MulTipleCheckbox
                                                                id={shiftId.toString()}
                                                                isValid={validateBulkAction(shiftId.toString())}
                                                                onChange={(id) => {
                                                                    if (validateBulkAction(id)) {
                                                                        onChangeCheckbox(id);
                                                                    } else {
                                                                        appContext.showNotification(NotificationTypes.warning, 'The selected shift overlaps with 1 or more previously selected shifts.')
                                                                    }
                                                                }}
                                                                checkboxFlag
                                                                onRemove={onRemoveCheckbox}
                                                                allCheckboxes={allCheckbox}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }
                                    </DataTable>
                                ), [tableData, isLoading, reasonVocabulary, shiftVocabulary, guardDeactivation, allCheckbox, theadTable])
                            }
                        </div>
                </div>
            </section>
            { AuthService.checkIfUserHasPermission([Roles.ASP, Roles.Admin, Roles.SuperAdmin], appContext.currentUser.role) &&
            <>
            {
                showModal && <BaseModal
                    show={showModal}
                    onCancel={() => setShowModal(false)}
                    className="available-guards-modal"
                    cancelBtnText={'Cancel'}
                >
                    <AvailableGuards
                        onSubmit={guard => {
                            setSelectedGuard(guard)
                            onAssignGuardSubmit(guard, selectedShift.id)
                            setShowModal(false)
                        }}
                        title={`Assign Guard for Selected Job Shift for  <span class="font-weight-bold">${selectedShift.jobName}</span>`}
                        selectedItem={selectedShift}
                    />
                </BaseModal>
            }

            {
                showSuccessModal && <ShiftAssignedSuccessModal
                    show={showSuccessModal}
                    onClose={() => setShowSuccessModal(false)}
                    selectedGuard={selectedGuard}
                    selectedShift={selectedShift}
                    vocabulary={shiftVocabulary}
                />
            }

            {
                showBulkGuardsModal &&
                <BaseModal
                    show={showBulkGuardsModal}
                    onCancel={() => setShowBulkGuardsModal(false)}
                    className="available-guards-modal"
                    cancelBtnText={'Cancel'}
                >
                    <AvailableGuards
                        onSubmit={onBulkAssignSubmitClick}
                        title="Guard Search"
                        guardsFromParentCompoment={availableGuards}
                    />
                </BaseModal>
            }
            {
                showBulkConfirmGuardsModal && <ConfirmBulkShiftModal
                    title={`Are you sure you want to assign <span class="font-weight-bold">
                    ${dataForBulkSuccess.guard.firstName} ${dataForBulkSuccess.guard.middleName || ""} ${dataForBulkSuccess.guard.lastName}
                    </span> to the following shifts:`}
                    onClose={() => setShowBulkConfirmGuardsModal(false)}
                    onConfirm={onBulkAssignConfirmClick}
                    shifts={dataForBulkSuccess.shifts}
                    vocabulary={shiftVocabulary}
                />
            }

            {
                showBulkSuccessGuardsModal && <BaseModal
                    show={showBulkSuccessGuardsModal}
                    onCancel={() => setShowBulkSuccessGuardsModal(false)}
                    cancelBtnText={'Close'}
                >
                    <h4>Shifts has been assigned to
                        <span className="font-weight-bold"> {dataForBulkSuccess.guard.firstName} {dataForBulkSuccess.guard.middleName || ""} {dataForBulkSuccess.guard.lastName}</span>
                    </h4>
                    <p className="mb-0">A notification has been sent to the guard with the details of this new shift</p>
                </BaseModal>
            }
            </>
            }

        </Layout>
    )
}

export default UnassignedJobShiftsPage
