import { Link } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react'
import { SVGEraser, SVGMarker, SVGViewOnMap } from '../../assets/icons/SvgIcons'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import { IActiveJob } from '../../common/models/Job'
import AutocompleteField from '../../components/autosuggestField/AutocompleteField'
import CustomSelectControl from '../../components/custom-select/CustomSelectControl'
import GeofenceMap from '../../components/jobs/GeofenceMap'
import Layout from '../../components/layout/Layout'
import SectionLoader from '../../components/loading/SectionLoader'
import JobsMapComponent from '../../components/map/JobsMapComponent'
import BaseModal from '../../components/modal/BaseModal'
import DataTable from '../../components/tables/DataTable'
import SectionTitle from '../../components/titles/SectionTitle'
import { jobScheduleDropdownOptions } from '../../content/dashboard/Dropdowns'
import AppContext from '../../context/AppContext'
import { useListFilter } from '../../customHooks/useListFilter'
import { useSchoolYear } from '../../customHooks/useSchoolYear'
import { useSingleMarkerOnMap } from '../../customHooks/useSingleMarkerOnMap'
import { useTableList } from '../../customHooks/useTableList'
import { momentDateFormat } from '../../utils/DateFormatting'
import {activeJobsListTable} from "../../content/jobs/JobsContent";
import { handleScrollToMap } from 'utils/ScrollTo'

const ActiveJobsList = () => {
    const baseUrl = 'job';
    const searchParamsToBeIgnored = ['TimeFrom', 'TimeTo', 'JobScheduleStatus', 'ZoneId'];
    const filterParamsToBeIgnored = ['KeyWord', 'TimeFrom', 'TimeTo'];
    const jobsListFilter = useListFilter(baseUrl)
    const jobsOnMapListFilter = useListFilter(baseUrl)
    
    const jobsListTable = useTableList<IActiveJob>(`${baseUrl}`, 24, false);
    const jobsOnMapData = useTableList<IActiveJob>(`${baseUrl}`, 0, false);

    const {getSchoolYears: setDefaultSchoolYears, schoolYears, defaultYear} = useSchoolYear()
    const {viewLocationInMap, jobItemInFocus, onViewLocationInMapClick, setViewLocationInMap} = useSingleMarkerOnMap()
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const { currentUser } = useContext(AppContext)

    const refMap = useRef(null)

    useEffect(() => {
        jobsListFilter.getCities()
        jobsListFilter.getZones()
        jobsListFilter.getSchoolBoard()
       
    }, [])
    useEffect(() => {
        if (!jobsListFilter.filter.hasValue) {
            setDefaultSchoolYears(jobsListFilter.onFilterDefaultSchoolYear, yearId => 
                generateTableList(yearId, searchParamsToBeIgnored, [false, false, undefined]), true
            );
        }
    }, [jobsListFilter.filter.hasValue])

    useEffect(() => {
        if (!jobsOnMapListFilter.filter.hasValue) {
            setDefaultSchoolYears(jobsOnMapListFilter.onFilterDefaultSchoolYear, yearId => 
                generateJobsOnMapList(yearId, searchParamsToBeIgnored, [false, false, undefined]), true
            );
        }
        
    }, [jobsOnMapListFilter.filter.hasValue])
    const generateTableList = async (yearId: string | number | undefined | null, ignoredParams: string[], args: any[]) => {
        const queryUrl = jobsListFilter.generateFilterQuery(args[0], args[1], args[2], args[3])
        await jobsListTable.fetchData(1, 24, jobsListFilter.ignoreSearchParams(queryUrl, ignoredParams) , false)
    }

    const generateJobsOnMapList = async(yearId: string | number | undefined | null, ignoredParams: string[], args: any[]) => {
        const queryUrl = jobsOnMapListFilter.generateFilterQuery(args[0], args[1], args[2])
        await jobsOnMapData.fetchData(1, 24, jobsOnMapListFilter.ignoreSearchParams(queryUrl, ignoredParams) , false)
    }

    const onSearchBtn = async (value: string) => {
        setSearchIsActive(true);
        jobsListFilter.onFilterDropdownChange(value, 'keyWord')
        await generateTableList(jobsListFilter.filter.schoolYearId, searchParamsToBeIgnored, [false, false, value, `SchoolYearId=${jobsListFilter.filter.schoolYearId}`] )
    }

    const onSearchBtnClean = () => {
        setSearchIsActive(false);
        jobsListFilter.onFilterDefaultSchoolYear(defaultYear.current || '');
        jobsListFilter.onFilterSearchClearClick(() =>
            generateTableList(jobsListFilter.filter.schoolYearId, searchParamsToBeIgnored, [true, false, undefined])
        )
    }

    return (
        <Layout
            breadcrumbs={{ currentPageTitle: 'Jobs' }}
        >
            <div className="row mb-3">
                <div className="col-md-6 d-flex flex-row align-items-center flex-wrap justify-content-between justify-content-md-start">
                    <SectionTitle title={`Active Jobs`}/>
                    <button
                        type="button"
                        className="btn btn-outline-aqua-blue ml-md-3 d-flex align-items-center"
                        onClick={() => handleScrollToMap(refMap)}
                    >
                        <span className="d-flex align-items-center">
                            View Map
                        </span>
                        <SVGViewOnMap className="ml-3" />
                    </button>
                </div>
                {
                    !AuthService.checkIfUserHasPermission([Roles.Client], currentUser.role) &&
                    <div className="col-md-6 d-flex flex-row justify-content-end">
                        <Link className="btn btn-aqua-blue ml-auto" to="/jobs/add">Add a Job</Link>
                        <Link className="btn btn-outline-aqua-blue ml-2" to="/jobs/deactivated">View Inactive Jobs</Link>
                    </div>
                }
            </div>

            <form className="filters-form-group--dashboard row mb-4">
                <div className="col-12 d-flex flex-column flex-sm-row justify-content-end">
                    
                    <div className="form-group mb-0 ml-sm-2">
                        <AutocompleteField
                            includes={'Job,SchoolBoard,School,Guard'}
                            isAsync={true}
                            onlyActive
                            placeholder='Enter job name'
                            onSearch={(value) => onSearchBtn(value)}
                            onClear={() => onSearchBtnClean()}
                         />
                    </div>
                </div>
            </form>

            <form className="filters-form-group filters-form-group--dashboard mb-4"
                  onKeyDown={(event) => {
                      if (event.key === 'Enter') jobsListTable.fetchData(1, 24, jobsListFilter.generateFilterQuery())
                  }}>
                <div className="row">
                    <div className="col-12">
                        <h5>Filter Jobs List</h5>
                    </div>
                    <div className="d-flex w-100 flex-wrap ml-1 mr-1 ">
                        <CustomSelectControl
                            isClearable
                            options={jobsListFilter.zones || []}
                            value={jobsListFilter.filter.zone}
                            placeholder={"Select Zone"}
                            sort
                            onChange={e => jobsListFilter.onFilterDropdownChange(e, "zone")}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--zone"
                        />
                        <CustomSelectControl
                            isClearable
                            options={jobScheduleDropdownOptions}
                            value={jobsListFilter.filter.jobScheduleStatus}
                            placeholder={"Select Job Schedule Status"}
                            onChange={e => jobsListFilter.onFilterDropdownChange(e, "jobScheduleStatus")}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--jobSchedule"
                        />
                        <CustomSelectControl
                            isClearable
                            options={jobsListFilter.schoolBoards || []}
                            value={jobsListFilter.filter.schoolBoardId}
                            placeholder={"Select School Board"}
                            onChange={e => jobsListFilter.onFilterDropdownChange(e, "schoolBoardId")}
                            className="flex-grow-0 ml-2 mb-2 custom-react-multi-select"
                            isMulti
                        />
                        <CustomSelectControl
                            options={ schoolYears || []}
                            value={jobsListFilter.filter.schoolYearId}
                            placeholder={"Select School Year"}
                            onChange={e => jobsListFilter.onFilterDropdownChange(e, "schoolYearId")}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--schoolYear"
                        />
                       
                        <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => jobsListTable.fetchData(1, 24,
                                    jobsListFilter.ignoreSearchParams( 
                                        jobsListFilter.generateFilterQuery(), filterParamsToBeIgnored
                                    )
                                )}>
                            Filter Jobs
                        </button>
                        {
                            jobsListFilter.filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        setSearchIsActive(false);
                                        jobsListTable.onClearFilterQuery(`job?SchoolYearId=${defaultYear.current || ''}`)
                                        jobsListFilter.onFilterClearClick()
                                        jobsListFilter.onFilterDefaultSchoolYear(defaultYear.current || '');
                                    }}>
                                <SVGEraser/>
                            </button>
                        }
                    </div>
                </div>
            </form>

            <div className="row">
                {
                    React.useMemo(() => (
                        <div className="col-12">
                            <DataTable
                                thead={activeJobsListTable.thead}
                                tbody={jobsListTable.tableData ?
                                    jobsListTable.tableData.map(job => ({
                                        id: job.id,
                                        jobNameLink: `<a href="#/jobs/${job.id}/details">${job.jobName}</a>`,
                                        activeSchedules: job.activeSchedules,
                                        activeSchedulesUnasssigned: job.activeSchedulesUnasssigned,
                                        guardName: job.guardNames,
                                        schoolBoardNames: job.schoolBoardNames,
                                        startDate: job.startDate ? momentDateFormat(job.startDate as string) : '-',
                                        endDate: job.endsOn ? momentDateFormat(job.endsOn as string) : '-',
                                        assignedGuards: job.assignedGuards,
                                        zone: job.zone,
                                        latesOrAbsences: job.latesOrAbsences,
                                    }))
                                    : []}
                                ignoreCols={[0, 9, 10]}
                                isLoading={jobsListTable.isLoading}
                                // onColClick={(key, trIndx) => jobsListTable.handleColClick(key, trIndx, 'jobNameLink', 'jobs')}
                                tableClass={'table-info--notFixed'}
                                pagination={true}
                                currentPage={jobsListTable.currentPage}
                                itemsCount={jobsListTable.allRecords}
                                itemsPerPage={+jobsListTable.perPage}
                                onPager={page => jobsListTable.onPagination(page)}
                                onPerPage={value => jobsListTable.onPerPage(value)}
                                tableName={"Active Jobs"}
                                onSort={(orderBy, desc) => jobsListTable.onSortCall(orderBy, desc, jobsListFilter.filter.keyWord)}
                                showTableLengthData={true}
                                sortOnBackend={true}
                                searchIsActive={searchIsActive}
                                filtersIsNotClear={jobsListFilter.filterIsClear ? false : true}
                                defaultSortedColumn={5}
                            >
                                {
                                    (jobId, rowItem: IActiveJob) => (
                                        <>

                                            <td className="align-middle">
                                                <div>
                                                    <span
                                                        className={`d-flex align-items-center justify-content-center table-info__counter table-info__counter${rowItem.latesOrAbsences === 0 ? "--best" : "--good"}`}>
                                                        { rowItem.latesOrAbsences }
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="align-middle">
                                                <div>
                                                    { rowItem.zone }
                                                </div>
                                            </td>
                                            <td className="align-middle">
                                                <button className="btn btn-aqua-blue"
                                                        onClick={() => onViewLocationInMapClick(jobsListTable.tableData, jobId as number)}>
                                                    <SVGMarker/>
                                                </button>
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        </div>
                    ), [jobsListTable.tableData, jobsListTable.isLoading])
                }
            </div>

            <section className="mt-5 mb-4" ref={refMap}>
                <div className="row">
                    <div className="col-12 mt-4 mt-sm-0">
                    <form className="filters-form-group filters-form-group--dashboard mb-4"
                          onKeyDown={(event) => {
                              if (event.key === 'Enter') jobsOnMapData.fetchData(1, 24, jobsOnMapListFilter.generateFilterQuery())
                          }}>
                        <div className="row">
                            <div className="col-12 ">
                                <h5>Filter Jobs On Map</h5>
                            </div>
                            <div className="d-flex w-100 flex-wrap ml-1 mr-1 ">
                                <CustomSelectControl
                                    isClearable={true}
                                    options={jobsListFilter.zones || []}
                                    value={jobsOnMapListFilter.filter.zone}
                                    placeholder={"Select Zone"}
                                    sort
                                    onChange={e => jobsOnMapListFilter.onFilterDropdownChange(e, "zone")}
                                    className="flex-grow-0 ml-2 mb-2"
                                />
                                <CustomSelectControl
                                    isClearable={true}
                                    options={jobScheduleDropdownOptions}
                                    value={jobsOnMapListFilter.filter.jobScheduleStatus}
                                    placeholder={"Select Job Schedule Status"}
                                    onChange={e => jobsOnMapListFilter.onFilterDropdownChange(e, "jobScheduleStatus")}
                                    className="flex-grow-0 ml-2 mb-2 custom-react-select--jobSchedule"
                                />
                                <CustomSelectControl
                                    isClearable={true}
                                    options={jobsListFilter.schoolBoards || []}
                                    value={jobsOnMapListFilter.filter.schoolBoardId}
                                    placeholder={"Select School Board"}
                                    onChange={e => jobsOnMapListFilter.onFilterDropdownChange(e, "schoolBoardId")}
                                    className="flex-grow-0 ml-2 mb-2 custom-react-select-xl"
                                    isMulti
                                />
                                <CustomSelectControl
                                    options={schoolYears || []}
                                    value={jobsOnMapListFilter.filter.schoolYearId}
                                    placeholder={"Select School Year"}
                                    onChange={e => jobsOnMapListFilter.onFilterDropdownChange(e, "schoolYearId")}
                                    className="flex-grow-0 ml-2 mb-2 custom-react-select--schoolYear"
                                />

                                <button type="button" className="btn btn-aqua-blue ml-2"
                                        onClick={() => jobsOnMapData.fetchData(1, 24, jobsOnMapListFilter.generateFilterQuery())}>
                                    Filter Jobs
                                </button>
                                {
                                    jobsOnMapListFilter.filter.hasValue &&
                                    <button type="button" className="btn btn-aqua-blue ml-2"
                                            onClick={() => {
                                                setSearchIsActive(false);
                                                jobsOnMapData.onClearFilterQuery(`job?SchoolYearId=${defaultYear.current}`)
                                                jobsOnMapListFilter.onFilterClearClick()
                                                jobsOnMapListFilter.onFilterDefaultSchoolYear(defaultYear.current || '');
                                            }}>
                                        <SVGEraser/>
                                    </button>
                                }
                            </div>
                        </div>
                    </form>

                    </div>
                </div>
                {
                    React.useMemo(() => (
                    <div className="row job-location-large">
                        <div className="col-12">
                            <JobsMapComponent
                                jobMarkers={jobsOnMapData.tableData.map(job => ({
                                    jobName: job.jobName,
                                    jobId: job.jobId,
                                    id: job.id,
                                    geoFence: job.geoFence,
                                    location: {
                                        latitude: job.location.latitude,
                                        longititude: job.location.longitude
                                    }
                                }))}
                                hideGuardIcon={false}
                            >
                                { jobsOnMapData.isLoading && <SectionLoader /> }
                            </JobsMapComponent>
                        </div>
                    </div>
                    ), [jobsOnMapData.tableData, jobsOnMapData.isLoading])
                }

            </section>

            {
                viewLocationInMap && <BaseModal
                    show={viewLocationInMap}
                    onCancel={() => setViewLocationInMap(false)}
                    className={'view-job-location'}
                    cancelBtnText={"Close"}
                >
                    <GeofenceMap
                        lat={+jobItemInFocus.location.latitude}
                        lng={+jobItemInFocus.location.longitude}
                        radius={+jobItemInFocus.geoFence}
                        removeLoadScript={true}
                        markerIsALink={`/jobs/${jobItemInFocus.id}/details`}
                    />
                </BaseModal>
            }

        </Layout>
    )
}

export default ActiveJobsList
