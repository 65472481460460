import moment from 'moment'
import React, {FC, useRef} from 'react'
import {SVGHoliday, SVGWarning} from '../../assets/icons/SvgIcons'
import {IHoliday} from '../../common/interfaces/IHoliday'
import CustomTooltip from '../custom-tooltip/CustomTooltip'

type Props = {
    holidays: IHoliday[];
    onChange: (value: boolean, holidayId: number) => void
    parentId: number
    titleKeyWord?: string
}

const ScheduleHolidays: FC<Props> = ({holidays, onChange, parentId, titleKeyWord}) => {
    const holidayNameRef = useRef<HTMLElement>(null);

    return (
        <div className="holidays ">
            <div className="holidays-warning d-flex flex-row align-items-center mb-3">
                <div className="flex-grow-1 flex-md-grow-0">
                     <SVGWarning className="mr-3"/>
                </div>
                { titleKeyWord && <small className="d-inline-block" dangerouslySetInnerHTML={{__html: titleKeyWord}} /> }
            </div>
            {
                holidays
                    .filter(item  => !item.isDisabled)
                    .map((holiday, index) => (

                    <div key={holiday.id} className="holidays-item d-flex align-items-center mb-4 row">
                        <div className="col-sm-3 holidays-item__date mb-1">
                            {
                                (holiday.endDate === null || moment(holiday.startDate).format('MMM D Y') === moment(holiday.endDate).format('MMM D Y')) ?
                                    moment.utc(holiday.startDate).format('MMM D Y') :
                                    `${moment.utc(holiday.startDate).format('MMM D Y')} - ${moment.utc(holiday.endDate).format('MMM D Y')}`
                            }
                        </div>
                        <div className="col-sm-2 d-flex align-items-center mb-1">
                            <div className="custom-control custom-control-radio ">
                                <input type="radio" id={`ignoreHolidayNo${holiday.id}FromParent${parentId}`}
                                       className="custom-control-input"
                                       value="yes"
                                       defaultChecked={true}
                                       checked={!!holiday.remove}
                                       onChange={e => onChange(true, holiday.id)}
                                       name={`control-ignoreHolidayNo${holiday.id}FromParent${parentId}`}
                                />
                                <label className="custom-control-label custom-control-radio-label mb-0"
                                       htmlFor={`ignoreHolidayNo${holiday.id}FromParent${parentId}`}>Yes</label>
                            </div>
                            <div className="custom-control custom-control-radio">
                                <input type="radio" id={`allowHolidayNo${holiday.id}FromParent${parentId}`}
                                       className="custom-control-input"
                                       value="no"
                                       checked={!holiday.remove}
                                       onChange={() => onChange(false, holiday.id)}
                                       name={`control-allowHolidayNo${holiday.id}FromParent${parentId}`}
                                />
                                <label className="custom-control-label custom-control-radio-label mb-0"
                                       htmlFor={`allowHolidayNo${holiday.id}FromParent${parentId}`}>No</label>
                            </div>
                        </div>
                        <div className="col-sm-7 d-flex align-items-center holidays-item__col mb-1">
                            <div className="holidays-item__name d-flex align-items-stretch ml-md-3 ">
                                <div className="mr-2 d-flex align-items-center">
                                    <CustomTooltip
                                        bodyText={holiday.name}
                                        keyValue={holiday.id}
                                    >
                                        <small>
                                            <SVGHoliday/>
                                        </small>
                                    </CustomTooltip>
                                </div>
                                <span ref={holidayNameRef}>{holiday.name}</span>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default ScheduleHolidays

