export const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(process.env.REACT_APP_TYPE === 'MT' ? 'firebase-messaging-sw-mt.js' : 'firebase-messaging-sw.js')
        .then(function (registration) {
          // eslint-disable-next-line no-console
          console.log('[SW]: SCOPE: ', registration.scope);
          return registration.scope;
        })
        .catch(function (err) {
          return err;
        });
    }
};