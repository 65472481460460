import React, { useContext } from 'react'
import { SVGCancel } from '../../../assets/icons/SvgIcons'
import { manageDeactivatedSchoolsContent } from '../../../content/administration/AdministrationContent'
import { Link } from 'react-router-dom'

import { IInactiveSchool } from '../../../common/interfaces/administration/ISchool'
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum'
import { ActivationDeactivation } from '../../../common/enums/Actions'

import Layout from '../../../components/layout/Layout'
import DataTable from '../../../components/tables/DataTable'
import BaseModal from '../../../components/modal/BaseModal'
import RemoveBaseModal from '../../../components/modal/administration/RemoveBaseModal'
import SectionTitle from '../../../components/titles/SectionTitle'

import { useTableList } from '../../../customHooks/useTableList'
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate'
import { momentDateFormat } from '../../../utils/DateFormatting'
import { matchOptionWithName } from '../../../utils/MatchOptionWithName'
import { useVocabulary } from '../../../customHooks/vocabulary/useVocabulary'
import { AuthService } from '../../../common/auth/AuthService'
import { Roles } from '../../../common/enums/Roles'
import AppContext from '../../../context/AppContext'

const ManageInactiveSchools = () => {
    const { tableData, isLoading, setTableData } = useTableList<IInactiveSchool>('school/inactive', 0)
    const { vocabulary: provinces } = useVocabulary(VocabularyEnum.province, true)
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus } = useActivateDeactivate('school')
    
    const onSuccessReactivation = (schoolId?: number) => schoolId && setTableData(tableData.filter(row => row.id !== schoolId));
    const { currentUser } = useContext(AppContext)
    const itemsIsVisible = AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], currentUser.role)

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Administration',
                        link: 'administration'
                    },
                    {
                        title: 'Manage Schools and School Boards',
                        link: 'administration/schools-and-boards'
                    }
                ],
                currentPageTitle: 'Deactivated Schools'
            }}
            >

            <div className="row mb-4">
                <div className="col-12 d-flex flex-row align-items-center flex-wrap justify-content-between">
                    <div>
                        <SectionTitle title={`Deactivated Schools and School Boards`}/>
                    </div>
                    <Link className="btn btn-outline-aqua-blue" to="/administration/schools-and-boards">View active schools</Link>
                   
                </div>
            </div>

            <div className="row">

                <div className="col-12">

                {
                    React.useMemo(() => (
                        <DataTable
                            thead={manageDeactivatedSchoolsContent.thead}
                            tbody={tableData ?
                                tableData.map(item => ({
                                    id: item.id,
                                    schoolName: item.schoolName,
                                    address: [item.firstStreet, item.secondStreet, item.city, matchOptionWithName(item.province, provinces), item.postalCode ? item.postalCode.toUpperCase() : item.postalCode].filter(item => item).join(", "),
                                    schoolBoard: item.boardName,
                                    deactivationDate: momentDateFormat(item.deactivationDate) || '-',
                                    deactivatedBy: item.deactivatedBy || '-',
                                }))
                            : []}
                            ignoreCols={[0]}
                            isLoading={isLoading}
                            tableClass={'table-info--notFixed'}
                        >
                            {
                                (_id, rowItem: IInactiveSchool) => (
                                    <td className="aling-middle">
                                        <div>
                                            <button
                                                className={`btn ${itemsIsVisible ? 'btn-aqua-blue' : 'btn-disabled'}`}
                                                onClick={() => itemsIsVisible && onRequestHandler({
                                                    title: `Are you sure you want to reactivate <span class="font-weight-bold">${rowItem.schoolName}</span>?`,
                                                    itemInFocus: rowItem
                                                })}
                                            >
                                                <SVGCancel />
                                            </button>
                                        </div>
                                    </td>
                                )
                            }
                        </DataTable>
                    ), [tableData, isLoading])
                }
                </div>
            </div>

            {
                requestModals.showRequest && 
                <RemoveBaseModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(ActivationDeactivation.activate, { itemData: null, successCallback: id => onSuccessReactivation(id) })}
                    title={modalText.title}
                >
                    {/* <DeactivateSchoolModalBody /> */}
                </RemoveBaseModal>
            }

            {
                requestModals.showSuccess && 
                <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                    title={`<span class="font-weight-bold">${(itemInFocus as IInactiveSchool).schoolName}</span> has been reactivated`}
                >
                </BaseModal>
            }
            
        </Layout>
    )
}

export default ManageInactiveSchools
