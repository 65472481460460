import React, { FC, useRef } from 'react'
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
    value: EditorState
    onChange: (value: EditorState) => void
}

const WysiwygEditor: FC<Props> = ({ onChange, value }) => {
    const options = useRef({
        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link'],
        inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        list: {
            options: ['unordered', 'ordered'],
        },
    })

    return (
        <Editor
            editorState={value}
            toolbarClassName="content-editor__toolbar"
            wrapperClassName="content-editor__wrapper w-100"
            editorClassName="content-editor"
            onEditorStateChange={value => onChange(value)}
            toolbar={ options.current }
        />
    )
}

export default WysiwygEditor
