import React, { FC } from 'react'
import { Dropdown } from 'react-bootstrap'

import { SVGMoreDots } from '../../assets/icons/SvgIcons';
import { IJobsRightNow } from '../../common/interfaces/jobs/IJob'
import { useRole } from 'customHooks/useRole'
import { DashboardModals } from 'common/enums/Modals'

type TProps = {
    rowItem: IJobsRightNow
    jobItemId: any
    shiftStatus: (shiftId: number, showAsForClient?: boolean) => any
    onGuardTempCheckInClick: (shiftId: number) => any
    openModal: (e: React.MouseEvent<any> | null, type: DashboardModals, shiftId: number) => void
    setDefaultComment: (comment: string) => void
}

const ActionDropdown: FC<TProps> = ({ rowItem, jobItemId, shiftStatus, onGuardTempCheckInClick,
    openModal, setDefaultComment}) => {
    const { isClientRole, isSupervisorRole, isAdminRole, isSuperAdminRole } = useRole()

    return <Dropdown>
        {!isClientRole() && !isSupervisorRole() &&
            <Dropdown.Toggle
                id={`dropdown-action-${jobItemId}`}
                className={shiftStatus(jobItemId)?.assigned ? 'btn-aqua-blue' : 'btn-disabled'}
            >
                <SVGMoreDots width={16} height={16}/>
            </Dropdown.Toggle>
        }

        {shiftStatus(jobItemId)?.assigned &&
            <Dropdown.Menu rootCloseEvent="mousedown">
                {
                    !shiftStatus(jobItemId)?.checkIn && !shiftStatus(jobItemId)?.absent &&
                    <>
                        {!rowItem.tentantCheckIn && <Dropdown.Item href="#/action-1" onClick={e => { e.preventDefault(); onGuardTempCheckInClick(jobItemId) }}>Temp Check In</Dropdown.Item>}
                        <Dropdown.Item href="#/action-2" onClick={e => openModal(e, DashboardModals.in, jobItemId)}>
                            {rowItem.tentantCheckIn ? 'Confirm Check In' : 'Check In'}
                        </Dropdown.Item>

                    </>
                }
                {
                    shiftStatus(jobItemId)?.checkIn && !shiftStatus(jobItemId)?.checkOut &&
                    <Dropdown.Item href="#/action-3" onClick={e => openModal(e, DashboardModals.out, jobItemId)}>Check Out</Dropdown.Item>
                }
                {
                    !shiftStatus(jobItemId)?.absent &&
                    <Dropdown.Item href="#/action-3" onClick={e => openModal(e, DashboardModals.absent, jobItemId)}>Mark Absent</Dropdown.Item>
                }
                {
                    (shiftStatus(jobItemId)?.checkIn || shiftStatus(jobItemId)?.checkOut || shiftStatus(jobItemId)?.absent) && (isAdminRole() || isSuperAdminRole()) &&
                    <Dropdown.Item
                        href="#/action-4"
                        onClick={e => openModal(e, DashboardModals.update, jobItemId)}>
                        Update
                    </Dropdown.Item>
                }

                <Dropdown.Item
                    href="#/action-5"
                    onClick={e => {
                        openModal(e, DashboardModals.addComment, jobItemId)
                        setDefaultComment(rowItem.lastComment || '')
                    }}>
                    { rowItem.lastComment ? 'Edit' : 'Add' } Client Comment
                </Dropdown.Item>
                
                { rowItem.lastComment &&
                    <Dropdown.Item
                        href="#/action-5"
                        onClick={e => {
                            openModal(e, DashboardModals.removeComment, jobItemId)
                            setDefaultComment('')
                        }}>
                        Remove Client Comment
                    </Dropdown.Item>
                }

            </Dropdown.Menu>
        }
    </Dropdown>
}

export default ActionDropdown