import { FC, useEffect } from 'react'
// import TimePicker from 'rc-time-picker';
import "react-datepicker/dist/react-datepicker.css";
// import moment from 'moment';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';
import CustomSelectControl from '../custom-select/CustomSelectControl';
import { IJobReports, IJobsRightNow } from '../../common/interfaces/jobs/IJob';
import { momentDateFormat, timeWithoutSecondsFormat, todayDateFormat, todayTimeFormat, UTCtimeToLocal } from '../../utils/DateFormatting';
import CustomTimePicker from "../custom-time-picker/CustomTimePicker";
import { IShiftJobReportModel } from 'common/interfaces/IDataFilter';
// import { checkIfIsOtherReason } from '../../utils/CheckOtherReason';

type Props = {
    type: "checkin" | "checkout" | "absent"
    absenceReasons: IVocabulary[]
    selectedItem?: IJobsRightNow | IJobReports | IShiftJobReportModel
    onTimeChange?: (time: string) => void
    checkTime?: string
    reasonsHook?: any
    showReasonField?: boolean
    tempCheckInReason?: string,
    setTempCheckInReason?: (reason: string) => void,
    comment?: string,
    setComment?: (comment: string) => void,
    onCommentChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    showErrorMessage?: string,
    handleAddEditClientComment?: (jobId: number, submitCallback?: (() => void) | undefined) => Promise<void>,
}

const CheckInOutModal: FC<Props> = ({ 
    type,
    absenceReasons,
    selectedItem,
    checkTime,
    onTimeChange,
    reasonsHook,
    showReasonField,
    tempCheckInReason,
    setTempCheckInReason,
    comment,
    setComment,
    onCommentChange,
    showErrorMessage,
  }) => {

    useEffect(() => {
      if (selectedItem?.lastComment) {
        setComment && setComment(selectedItem.lastComment)
      }
    }, [selectedItem])

    return (
        <div>
            <div className="row mb-4">
                <div className="col-4 d-flex"><b className="d-block align-self-center">Job Location:</b></div>
                <div className="col">{ selectedItem?.jobName ? selectedItem?.jobName : `${selectedItem?.firstStreet} ${selectedItem?.secondStreet}`}</div>
            </div>
            {
                (type === "checkin" || type === "checkout" ) && <>
                    <div className="row mb-4">
                        <div className="col-4 d-flex">
                            <b className="d-block align-self-center">
                                { type === 'checkin' && 'Check In Time:' }
                                { type === 'checkout' && 'Check Out Time:' }
                            </b>
                        </div>
                        <div className="col">
                            <CustomTimePicker
                                onChange={time => onTimeChange && onTimeChange(timeWithoutSecondsFormat(time))}
                                value={checkTime ? UTCtimeToLocal(checkTime) : UTCtimeToLocal()}
                                index={1}/>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4 d-flex">
                            <b className="d-block align-self-center">
                                { type === 'checkin' && 'Check In Date:' }
                                { type === 'checkout' && 'Check Out Date:' }
                            </b>
                        </div>
                        <div className="col">{ momentDateFormat(todayDateFormat()) }</div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-4 d-flex"><b className="d-block align-self-center">Guard Name:</b></div>
                      <div className="col">{ selectedItem?.guardName }</div>
                    </div>
                    {showReasonField &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex"><b className="d-block align-self-start">Reason for Update: </b></div>
                            <div className="col">
                                <textarea
                                    name='comments'
                                    className='form-control'
                                    id='comments'
                                    placeholder='Enter reason'
                                    autoComplete='nope'
                                    rows={5}
                                    value={tempCheckInReason}
                                    onChange={(e) => setTempCheckInReason && setTempCheckInReason(e.target.value)}
                                />
                            </div>
                        </div>
                    }
                    <div className="row mb-4">
                        <div className="col-4 d-flex"><b className="d-block align-self-start">Client comment: </b></div>
                            <div className="col">
                                <textarea
                                    className='form-control'
                                    placeholder='Enter comment'
                                    rows={5}
                                    value={comment || ""}
                                    onChange={onCommentChange}
                                />
                                <small className='d-block text-right'>
                                  <span className={comment && comment.length === 200 ? 'text-danger font-weight-bold': ''}>
                                    {comment && comment.length}
                                  </span>
                                  / 200
                                </small>
                                <div className='form-group ml-auto'>
                                    <small className='text-danger pb-4'>
                                        {showErrorMessage || ""}
                                    </small>
                                </div>
                            </div>
                           
                    </div>
                </>
            }
             {
                type === "absent" &&
                <div className="row mb-4">
                    <div className="col-4 d-flex"><b className="d-block align-self-center">Date of Absence</b></div>
                    <div className="col">{ selectedItem?.startDate ? momentDateFormat(selectedItem?.startDate) : '-' }</div>
                </div>
            }
            {
                type === "absent" &&
                <div className="row mb-4">
                    <div className="col-4 d-flex"><b className="d-block align-self-center">Report Time:</b></div>
                    <div className="col">{ todayTimeFormat() }</div>
                </div>
            }
            {
                type === "absent" &&
                <div className="row">
                    <div className="col-4 d-flex"><b className="d-block align-self-start">Reason:</b></div>
                    <form className="col">
                        <div className="form-group mb-0">
                            <CustomSelectControl
                                value={reasonsHook.isOtherReason ? 0 : (reasonsHook.reason as IVocabulary).id}
                                placeholder={"Select a reason"}
                                options={absenceReasons ? absenceReasons : []}
                                onChange={(e) => reasonsHook.onReasonSelect(e)}
                                className={`custom-react-select--reasonForAbsence`}
                            />
                        </div>
                        {
                           reasonsHook.isOtherReason &&
                            <div className="form-group mt-3">
                                <textarea
                                className="form-control"
                                rows={5}
                                name={'reasonForAbsence--other'}
                                placeholder="Type other reason"
                                value={reasonsHook.reason as string}
                                onChange={e => reasonsHook.setReason(e.target.value)}
                                ref={reasonsHook.textareaRef}
                                ></textarea>
                            </div>
                        }
                    </form>
                </div>
            }
        </div>
    )
}

export default CheckInOutModal
