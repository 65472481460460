import {setCustomTimeInUTC, UTCTimeNow} from '../../utils/DateFormatting';
import { IJobsFilter } from './../interfaces/IDataFilter';

export class JobFilter implements IJobsFilter {
    clientId: number
    schoolYearId: number

    constructor(clientId = 0, schoolYearId = 0) {
        this.clientId =  clientId
        this.schoolYearId = schoolYearId
    }
    startDate = '';
    endDate = '';
    shiftPeriod = undefined;
    keyWord = "";
    hasValue = false;
    holidayTypeName = "";
    timeFrom = setCustomTimeInUTC(6).format();
    // timeFrom = ""
    // timeTo = ""
    timeTo = setCustomTimeInUTC(18).format();
    completedBy = '';
    role = '';
    guardName = '';
    jobId = ''
}
