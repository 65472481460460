import {SortDataTypesEnum} from "../../customHooks/useSortableData";
import {AuthService} from "../../common/auth/AuthService";
import {Roles} from "../../common/enums/Roles";

export const shiftStatusTooptipContent = `<strong>Guard Status</strong> <br />
Green: Assigned <br />
Red: Unassigned <br />
Blue: Covid Alert`;

export const deactivateJobsListContent = {
    thead: [
        {
            label: "Job Name",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        {
            label: "Assigned Guard(s)",
        },
        {
            label: "Related School Board",
        },
        {
            label: "Related School(s)",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Approve",
        },
    ],
    tbody: [
        {
            id: 1,
            jobName: "Street and Street",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            requestBy: "First Middle Last",
            assignedGuards: `First Middle Last, First M Last`,
            reason: "reasonoption",
        },
        {
            id: 2,
            jobName: "Location Name",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            requestBy: "First Middle Last",
            assignedGuards: `First Middle Last, First M Last`,
            reason: "reasonoption",
        },
        {
            id: 3,
            jobName: "Location Name 2",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            requestBy: "First Middle Last",
            assignedGuards: `First Middle Last, First M Last`,
            reason: "reasonoption",
        },
    ]
}
export const deactivatedJobsContent = {
    thead: [
        {
            label: "Job Name",
        },
        {
            label: "Deactivation Date",
        },
        {
            label: "Deactivation Approved By",
        },
        {
            label: "Request Date",
        },
        {
            label: "Requested By",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Reactivate Job",
        },
    ],
    tbody: [
        {
            id: 1,
            jobName: "Street and Street",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            reason: "reasonoption",
        },
        {
            id: 2,
            jobName: "Location Name",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            reason: "reasonoption",
        },
        {
            id: 3,
            jobName: "Location Name 2",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            reason: "reasonoption",
        },
    ]
}

export const pendingJobsContent = {
    thead: [
        {
            label: "Job Name", sortable: true, dataType: SortDataTypesEnum.string
        },
        {
            label: "School Board", sortable: true, dataType: SortDataTypesEnum.string
        },
        {
            label: "Zone", dataType: SortDataTypesEnum.string, sortable: true
        },
        {
            label: "School Year"
        },
        {
            label: "Dates", sortable: true, dataType: SortDataTypesEnum.date
        },
        {
            label: "Times", sortable: true, dataType: SortDataTypesEnum.time
        },
        {
            label: "Shift Period", sortable: true, dataType: SortDataTypesEnum.string
        },
        {
            label: "Repeat",
        },
        {
            label: "Bulk Action",
            actionLabel: "Select All"
        },
    ],
    tbody: [
        {
            id: 1,
            jobName: "Street and Street",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            reason: "reasonoption",
        },
        {
            id: 2,
            jobName: "Location Name",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            reason: "reasonoption",
        },
        {
            id: 3,
            jobName: "Location Name 2",
            startDate: "2021-02-20T07:52:10.112Z",
            endDate: "2021-02-20T07:52:10.112Z",
            timeFrom: "2021-02-20T07:52:10.112Z",
            timeTo: "2021-02-20T07:52:10.112Z",
            shiftPeriod: "Morning",
            repeatOn: "1;2;3;4",
            requestDate: "2021-02-20T07:52:10.112Z",
            reason: "reasonoption",
        },
    ]
}

export const jobsListTableHead = {
	thead: [
		{ label: 'Bulk<br/>Action', showSwitch: true},
		{ label: 'Job Location  <br /> (Name)', dataType: SortDataTypesEnum.string, sortable: true, fieldName: "jobLocationLink" },
		{ label: 'Shift Period', dataType: SortDataTypesEnum.string, sortable: true, fieldName: "shiftPeriod" },
		{ label: 'Guard Name', sortable: true, fieldName: "guard" },
		{
			label: 'ASP <br /> Start Time',
			invisible: true,
			sortable: true, fieldName: "aspStart",
			dataType: SortDataTypesEnum.time,
		},
		{ label: 'Shift Time' },
		{ label: 'Zone', dataType: SortDataTypesEnum.string, sortable: true, fieldName: "zone" },
		{ label: 'Shift Status' },
		{ label: 'Client <br/> Shift <br />Status' },
		{ label: 'Late/Absence-<br />Last 30 days' },
		{ label: 'Client <br />Late/Absence-<br />Last 30 days' },
		{
			label: 'In Geo-<br />fence',
			tooltip: true,
			tooltipText: `Is the Guard in the geofence at the time of Last Page Refresh.
            Past shifts show status at the Shift End Time, which could fall within 2 min before to 2 min after End Time`,
		},
		{
			label: 'Covid<br />Screen',
			tooltip: true,
			tooltipText: 'Has the Guard filled in their Covid Screening questions?',
		},
		{
			label: 'Coor-<br />dinates',
			tooltip: true,
			tooltipText: 'Did we receive location coordinates within the last 5 minutes?',
		},
		{ label: '' },
	],
};
export const availableGuardsTable = {
    thead: [
      {
        label: "Guard Name",
        sortable: true,
        dataType: SortDataTypesEnum.string,
      },
      {
        label: "Distance",
        sortable: true,
        dataType: SortDataTypesEnum.number,
      },
      {
        label: "Attendance",
        sortable: true,
        dataType: SortDataTypesEnum.number,
      },
      {
        label: "Phone Number",
      }
    ]
}

export const shiftsInNeedTabTable = {
    thead:  [ {label: "Shift Date"}, {label: "Reason"}, {label: "Shift Times"}, {label: "Shift Period"},
        {label: "Assign Guard", invisible: true}, {label: ""}  ]
}

export const assignedShiftsTable = {
    thead:   [
        {
            label: "Job Name",
            sortable: true,
            dataType: SortDataTypesEnum.string
        },
        {
            label: "Date",
            sortable: true,
            dataType: SortDataTypesEnum.date
        },
        {
            label: "ASP Start Time",
            sortable: true,
            dataType: SortDataTypesEnum.time,
            invisible: true,
        },
        {
            label: "Start Time",
            sortable: true,
            dataType: SortDataTypesEnum.time
        },
        {
            label: "End Time",
            sortable: true,
            dataType: SortDataTypesEnum.time
        },
        {
            label: "Unassign Guard <br>from Shift",
        },
        {
            label: "",
        }
    ]
}
export const assignedScheduleTable = {
    thead: [ {label: "Assigned Guard"}, {label: "Start and End Dates"}, {label: "ASP Start Time", invisible: true},
        {label: "Start and End Times"}, {label: "Shift Period"}, {label: "Repeat"}, {label: "Change Guard", invisible: true},
        {label: 'Unassign Guard', invisible: true} ]
}

export const assignedShiftsTabTable = {
    thead: [
        {label: "Assigned Guard", sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Shift Date", sortable: true, dataType: SortDataTypesEnum.date},
        {
            label: "ASP Start Time",
            sortable: true,
            invisible: true,
            dataType: SortDataTypesEnum.time
        },
        {label: "Start Time", sortable: true, dataType: SortDataTypesEnum.time},
        {label: "End Time", sortable: true, dataType: SortDataTypesEnum.time},
        {label: "Shift Period", sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Edit Shift", invisible: true},
        {label: "Unassign Guard <br /> from Shift", invisible: true},
        {label: ""}
    ]
}

export const holidaysTabTable = {
    thead: [
        { label: 'School Board', sortable: true, dataType: SortDataTypesEnum.string },
        { label: 'Holiday Type', sortable: true, dataType: SortDataTypesEnum.string },
        { label: 'Holiday Name', sortable: true, dataType: SortDataTypesEnum.string },
        { label: 'Start Date or Date', sortable: true, dataType: SortDataTypesEnum.date },
        { label: 'End Date', sortable: true, dataType: SortDataTypesEnum.date },
        { label: 'Applied holiday',  actionLabel: 'Select All' },
    ]
}

export const editJobSchedulesTable = {
    thead: [{label: "Job Name", sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Dates", sortable: true, dataType: SortDataTypesEnum.date},
        {label: "Times", sortable: true, dataType: SortDataTypesEnum.time},
        {label: "Shift Period", sortable: true, dataType: SortDataTypesEnum.string},
        {label: "Repeat"},
        {label: 'Edit Schedule', invisible: true},
        {label: 'Deactivate Schedule', invisible: true}]
}
export const assignedGuardsTable = {
    thead:  [
        {label: "Dates"},
        {label: "Times"},
        {label: "Shift Period"},
        {label: "Repeat"},
        {label: "Assign Schedule"},
    ]
}
export const activeJobsListTable = {
    thead:  [
        { label: 'Job Location (Name)', sortable: true},
        { label: '# of Active <br /> Schedules', sortable: true },
        { label: '# of Active <br /> Schedules <br /> Unassigned', sortable: true},
        { label: 'Guard Name' },
        { label: 'School Board' },
        { label: 'Job Start Date', sortable: true},
        { label: 'Job End Date', sortable: true},
        { label: '# of Guards <br /> Assigned'},
        { label: 'Late or Absent<br> Guards in <br>Last 30 Days', sortable: true },
        { label: 'Zone', sortable: true },
        { label: 'View Location <br /> on Map' },
    ]
}
export const jobsScheduleTable = {
    thead:  [
        {label: "Assigned Guard"},
        {label: "Start and End Dates"},
        {label: "ASP Start Time", invisible: true},
        {label: "Start and End Times"},
        {label: "Shift Period"},
        {label: "Repeat"},
        {label: "Actions"}
        // {label: 'Edit Schedule', invisible: true},
        // {label: 'Deactivate Schedule', invisible: true }
    ]
}

export const jobsScheduleThead = (userRole: any) => {
    return [
        {label: 'Job Name', sortable: true, dataType: SortDataTypesEnum.string},
        {label: 'Guard Name', sortable: true, dataType: SortDataTypesEnum.string},
        {label: 'Start Date', sortable: true, dataType: SortDataTypesEnum.date},
        {label: 'End Date', sortable: true, dataType: SortDataTypesEnum.date},
        {
            label: 'ASP Start Time',
            invisible: AuthService.checkIfUserHasPermission([Roles.Client], userRole),
            sortable: true,
            dataType: SortDataTypesEnum.time,
        },
        {
            label: 'Times',
            sortable: AuthService.checkIfUserHasPermission([Roles.Client, Roles.SuperAdmin], userRole) ? true : false,
        },
        {label: 'Zone', sortable: true, dataType: SortDataTypesEnum.string},
        {label: 'Schedule Status'},
        {label: 'Late or Absent<br> Guards in <br>Last 30 Days'},
        {label: 'View Location <br/ >in Map'},
        {label: 'Action'},
    ]
}

export const assignGuardToScheduleTable = {
    thead: [
        {label: "Guard Name",sortable: true, dataType: SortDataTypesEnum.string },
        {label: "Phone Number", sortable: true, dataType: SortDataTypesEnum.string},
        {label: 'Distance', sortable: true, dataType: SortDataTypesEnum.number},
        {label: "Attendance", sortable: true, dataType: SortDataTypesEnum.number},
    ]
}
