export enum AdminNotificationTypes {
    email = "email",
    sms = "sms",
}

export enum AdminCustomMessageTypes {
    client = "client",
    guard = "guard",
}

export enum HowSendMessageClientGuard {
    Email = "Email",
    Sms = "Sms",
    AsPreferred = 'AsPreferred'
}

export enum ToWhomeSendMessageClient {
    All = "All",
    WithAccess = "WithAccess",
    Selected = 'Selected'
}

export enum ToWhomeSendMessageGuard {
    All = "All",
    AssignedTojobs = "AssignedTojobs",
}