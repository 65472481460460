import { useState} from "react";
import {useRole} from "./useRole";
import {TheadData} from "../components/tables/DataTable";

export const useVerifyRolesTHead = () => {
    const [theadTable, setTheadTable] = useState<TheadData[]>([]);
    const { isClientRole, isSupervisorRole } = useRole()

    const addVerifyRoles = async (thead: TheadData[]) => {
        await thead.forEach((item: TheadData) => item.hasOwnProperty('invisible') && (item.invisible = isClientRole() || isSupervisorRole()));
        if (thead) setTheadTable(thead)
    }

    return { theadTable, addVerifyRoles };
}
