import { ISettings } from './../interfaces/administration/ISettings';
import { ISchool } from './../interfaces/administration/ISchool';
import { IHoliday } from "../interfaces/administration/IHoliday";
import { EditorState } from 'draft-js';
import {ISchoolYear} from "../interfaces/administration/ISchoolYear";
import { UTCTimeNow } from 'utils/DateFormatting';

export class Holiday implements IHoliday {
    id = '';
    boardName = '';
    boardId = '';
    holidayType = '';
    holidayName = '';
    startDate = UTCTimeNow(true, true) as string;
    endDate = UTCTimeNow(true, true) as string;
    schoolBoardId = '';
    applyHolidayToAllShifts = false;
}
export class SchoolYear implements ISchoolYear {
    id = '';
    clientId = 1;
    startDate = UTCTimeNow(true, true) as string;
    endDate = UTCTimeNow(true, true) as string;
}

export class School implements ISchool {
    id = '';
    schoolName = '';
    firstStreet = '';
    secondStreet = '';
    city = '';
    province = '';
    postalCode = '';
    schoolBoardId = '';
    schoolHasJobs = true;
    country = '';
}

export class Settings implements ISettings {
    bufferTime = 0;
    distance = 0;
    frequencyToSendHealtyAndSafetyCheck = "1";
}

export class ManageNotification {
    id = 1;
    name = ''
    message = EditorState.createEmpty()
    isActive = false;
    notificationSubject = ''
    notificationBody = EditorState.createEmpty()
    // role = Roles.Client
}
