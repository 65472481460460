import { FC, ReactNode } from 'react';
import Breadcrumbs, { BreadcrumbsData } from '../breadcrumbs/Breadcrumbs';
import Footer from '../footer/Footer';
import { Navbar } from 'react-bootstrap';
import Logo from '../../assets/images/asplogo.png';
type Props = {
    children: ReactNode;
    breadcrumbs?: BreadcrumbsData | null;
};
const SimpleLayout: FC<Props> = ({children, breadcrumbs}) => {
    return (
        <>
            <header>
                <Navbar className='primary-nav' expand='md'>
                    <div className='container'>
                        <Navbar.Brand href='#dashboard' className='nav-logo'>
                            <img src={Logo} alt='Brand Logo' />
                        </Navbar.Brand>
                    </div>
                </Navbar>
            </header>
            <main className='pb-5'>
                {breadcrumbs && <Breadcrumbs links={breadcrumbs.links} currentPageTitle={breadcrumbs.currentPageTitle} isHome={breadcrumbs.isHome} />}
                <div className={`container `}>
                    {children ? children : null}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default SimpleLayout;
