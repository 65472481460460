import { FC, useEffect, useState } from 'react'
import { FBNotificationEnum } from 'common/enums/FBNotifications'
import ContactFormSubmissionModal from 'components/modal/screen-notifications/ContactFormSubmissionModal'
import GuardHealthAndSafetyModal from 'components/modal/screen-notifications/GuardHealthAndSafetyModal'
import { IPushNotificaion } from 'common/interfaces/firebase/IPushNotificaion'
import GuardCovidTestFailed from 'components/modal/screen-notifications/GuardCovidTestFailed'

type TProps = {
    notifications: IPushNotificaion[]
    setFbNotifications: React.Dispatch<React.SetStateAction<IPushNotificaion[]>>
}

const FBNotificationsWrapper: FC<TProps> = ({ notifications, setFbNotifications }) => {
    const [modals, setModals] = useState<IPushNotificaion[]>([])
    // const [lastMessage, setLastMessage] = useState({} as IPushNotificaion)

    // useEffect(() => {
    //     setLastMessage(notifications[notifications.length - 1])
    // }, [notifications])

    useEffect(() => {
        let counter = 0;
        notifications && notifications.length !== 0 && 
        setModals([
            ...notifications.map((notification, index) => {
            counter = index === 0 ? 0 : counter + 7;
            notification.margin = counter;
            notification.show = true;
            return notification
        })])
    }, [notifications])

    const handleClose = (id: string) => {
        const filteredModals = [...modals.filter((notification: IPushNotificaion) => notification.fcmMessageId !== id)];
        setFbNotifications(filteredModals)
        setModals(filteredModals)
    }

    return (
        <>
            {  modals.length !== 0 &&
                modals.map((notification, index) =>
                <>
                    {notification.data.type === FBNotificationEnum.healthTest && modals[index]?.show &&
                        <GuardHealthAndSafetyModal
                            onCancel={() => handleClose(notification.fcmMessageId)}
                            message={notification}
                            key={index}
                            topMargin={notification.margin}
                        />
                    }
                    {(notification.data.type === FBNotificationEnum.adminCovidTestWeb) && modals[index]?.show &&
                        <GuardCovidTestFailed
                            onCancel={() => handleClose(notification.fcmMessageId)}
                            message={notification}
                            key={index}
                            topMargin={notification.margin}
                        />
                    }
                    {/* notification.data.messageId === lastMessage.data.messageId */}
                    {notification.data.type === FBNotificationEnum.formSubmission && modals[index]?.show &&
                    <ContactFormSubmissionModal
                        onCancel={() => handleClose(notification.fcmMessageId)}
                        message={notification}
                        // lastMessage={lastMessage}
                        key={index}
                        topMargin={notification.margin}
                    />
                    }
                </>
            )}
        </>
    )
}

export default FBNotificationsWrapper

