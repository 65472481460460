import { CheckOutTypeLabelEnum } from './../../common/enums/CheckInOutTypeEnum';
import { CheckInOutTypeEnum, CheckInTypeLabelEnum } from "common/enums/CheckInOutTypeEnum"
import {IVocabulary} from "common/interfaces/vocabulary/IVocabulary"

export const GuardStatusonShift: IVocabulary[] = [
    {id: 1, name: "Assigned", isDisabled: false},
    {id: 2, name: "Unassigned", isDisabled: false},
    {id: 3, name: "Guard Absent", isDisabled: false},
    {id: 4, name: "Guard Late", isDisabled: false}
]

export const GuardCheckInStatus: IVocabulary[] = [
    {id: 1, name: "On Time for ASP", isDisabled: false},
    {id: 2, name: "On Time for Shift Start", isDisabled: false},
    {id: 3, name: "Late for Shift Start", isDisabled: false}
]

export const GuardCheckOutStatus: IVocabulary[] = [
    {id: 1, name: "Checked out on time", isDisabled: false},
    {id: 2, name: "Checked out early", isDisabled: false},
]

export const GuardCheckInMethods: IVocabulary[] = [
    {id: +CheckInOutTypeEnum.Manual, name: CheckInTypeLabelEnum.Manual, isDisabled: false},
    {id: +CheckInOutTypeEnum.Auto, name: CheckInTypeLabelEnum.Auto, isDisabled: false},
    {id: +CheckInOutTypeEnum.Admin, name: CheckInTypeLabelEnum.Admin, isDisabled: false},
    {id: +CheckInOutTypeEnum.Temp, name: CheckInTypeLabelEnum.Temp, isDisabled: false},
]

export const GuardCheckOutMethods: IVocabulary[] = [
    {id: +CheckInOutTypeEnum.Manual, name: CheckOutTypeLabelEnum.Manual, isDisabled: false},
    {id: +CheckInOutTypeEnum.Auto, name: CheckOutTypeLabelEnum.Auto, isDisabled: false},
    {id: +CheckInOutTypeEnum.Admin, name: CheckOutTypeLabelEnum.Admin, isDisabled: false},
    {id: +CheckInOutTypeEnum.Offline, name: CheckOutTypeLabelEnum.Offline, isDisabled: false},
    // {id: +CheckInOutTypeEnum.Temp, name: "Admin Temp Check-Out", isDisabled: false},
]