import React, { FC, useContext } from 'react'
import DatePicker from "react-datepicker";
import { SVGCalendar } from '../../../../assets/icons/SvgIcons';
import LeaveReason from './LeaveReason';
import { BookLeaveModalContext } from '../../../../context/guards/BookLeaveContext';
import CustomSelectControl from '../../../custom-select/CustomSelectControl';
import { useEffect } from 'react';
import { datePickerFormat, setHoursAndMinutes } from '../../../../utils/DateFormatting';
import {useDatapickerSvg} from "../../../../customHooks/useDatapickerSvg";
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary';

type Props = {
    day?: boolean
}

const SingleDayAndShift: FC<Props> = ({ day }) => {
    const { shifts } = useContext(BookLeaveModalContext)
    const { bookLeave, onChange, createMode } = useContext(BookLeaveModalContext)
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()

    useEffect(() => {
        bookLeave?.startDate && onChange(bookLeave?.startDate ? new Date(bookLeave.startDate).toISOString() : new Date().toISOString(), "endDate")
    }, [bookLeave?.startDate])

    useEffect(() => {
        (day && createMode) && onChange(`${prepopulateShifts(shifts)}`, "shifts");
    }, [])

    const prepopulateShifts = (shifts: IVocabulary[]) => {
        let shiftValue = '';
        shifts.forEach(shift => {
            shiftValue += `${shift.id};`
        })
        return shiftValue.replace(/.$/,"")
    }

    return (
        <>
        <div className="row mt-3 mb-3">
            <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                <label className="mb-0">Date: </label>
            </div>
            <div className="col-md-9 form-group mb-0">
                <div className="react-datepicker-custom-wrapper d-inline-block">
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        minDate={new Date()}
                        selected={bookLeave?.startDate ? datePickerFormat(bookLeave.startDate) : null}
                        onChange={date => {
                            onChange(setHoursAndMinutes(date as Date), "startDate")
                            changeDatapicker(1)
                        }}
                        onInputClick={() => changeDatapicker(1)}
                        open={showDatapicker[1]}
                        onClickOutside={() => changeDatapicker(1)}
                    />
                    <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                </div>
            </div>
        </div>

        <div className="row mt-3 mb-3">
            <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                <label className="mb-0">Shifts: </label>
            </div>
            <div className="col-md-9 form-group mb-0">
                <CustomSelectControl
                    options={shifts}
                    onChange={val => onChange(val, "shifts")}
                    value={bookLeave?.shifts}
                    // value={"43;44;42"}
                    isMulti={true}
                    className={`custom-react-select--shifts`}
                    defaultValue={[shifts[0], shifts[1], shifts[2]]}
                />
            </div>
        </div>

        <LeaveReason />
        </>
    )
}

export default SingleDayAndShift
