import React, { useEffect, useMemo, useState } from 'react'
import Layout from '../../components/layout/Layout'
import DuplicateJobsChoose from "../../components/jobs/duplicate-job/DuplicateJobsChoose";
import queryString from 'query-string'
import { useHistory } from "react-router-dom";
import { IClientDropdown } from '../../common/interfaces/dropdowns/IClientDropDown'
import { NotificationTypes } from '../../common/interfaces/INotification'
import ResourceDataService from '../../common/services/ResourceDataService'
import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'

export type QueryParamsState = { client: string, schoolYear: string }

const DuplicateJobs = () => {
    const appContext = useContext(AppContext)
    const history = useHistory()
    const [activeScreen, setActiveScreen] = useState<string>("choose")
    const [queryParams, setQueryParams] = useState<QueryParamsState>({} as QueryParamsState)
    const [clients, setClients] = useState<IClientDropdown[]>([] as IClientDropdown[]);

    useEffect(() => {
        setActiveScreen("choose")
        getOptionsForDropdowns();
    }, [])

    useEffect(() => {
        parseQuery()
    }, [history.location.search])

    const parseQuery = () => {
        const parsed = queryString.parse(history.location.search) as QueryParamsState
        if (Object.keys(parsed).length !== 0) {
            setQueryParams({ client: parsed.client, schoolYear: parsed.schoolYear })
            setActiveScreen('editshifts')
        } else {
            setActiveScreen("choose")
        }
    }

    const getOptionsForDropdowns = useMemo(() =>
            async () => {
                const clientSvc = new ResourceDataService<IClientDropdown[]>({ url: "client/dropdown" })
                try {
                    const resClients = await clientSvc.getAll();
                    setClients(resClients.data)
                } catch (e: any) {
                    appContext.showNotification(NotificationTypes.danger, e.message)
                }
            }
        , [])

    const renderContent = (activeScreen: string) => {
        switch (activeScreen) {
            case 'choose' :
                return   <DuplicateJobsChoose clients={clients}  />
            default :
                return null;
        }
    }

    const renderBreadcrumbs = (activeScreen: string) => {
        const baseLinks = [{
            title: 'Jobs',
            link: 'jobs'
        }]
        switch (activeScreen) {
            case 'choose' :
                return  {
                    links: baseLinks,
                    currentPageTitle: 'Duplicate Job'
                }
            case 'editshifts' :
                if (AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], appContext.currentUser.role)) {
                    return  {
                        links: [...baseLinks, ...[{
                            title: 'Edit Job',
                            link: 'jobs/edit'
                        }]],
                        currentPageTitle: 'Edit Job Shifts'
                    }
                } else  return  {
                    links: baseLinks,
                    currentPageTitle: AuthService.checkIfUserHasPermission([Roles.Admin, Roles.ASP, Roles.SuperAdmin], appContext.currentUser.role) ? 'Edit Job Shifts' : 'Job Shifts'
                }

            default :
                return null;
        }
    }

    return (
        <Layout
            breadcrumbs={renderBreadcrumbs(activeScreen)}
        >
            { renderContent(activeScreen) }
        </Layout>
    )
}

export default DuplicateJobs
