import React, { FC } from 'react';
import BaseModal from '../BaseModal';
import { useTenant } from '../../../customHooks/useTenant';
import CustomSelectControl from '../../../components/custom-select/CustomSelectControl';

type Props = {
	show: boolean;
	submitCallback: () => void;
};
const ChooseTenantModal: FC<Props> = ({ show, submitCallback }) => {
	const { tableData, currentTenantId, setCurrentTenantId } = useTenant(true);

	const onSubmit = () => {
        (currentTenantId || !tableData.filter(v => v.isActive).length) && submitCallback();
    };
	return (
		<BaseModal
			show={show}
			title='Choose client'
			onCancel={() => {}}
			submitBtnText={'Select'}
            showCancelBtn={false}
            classNameButton={'justify-content-end'}
			onSubmit={onSubmit}>
			<form>
				<CustomSelectControl
					options={
						tableData.length
							? tableData.filter(v => v.isActive).map(({ id, name }) => ({
									id: id || '',
									name,
							  }))
							: []
					}
					value={currentTenantId}
					placeholder={'Select client'}
					onChange={(e) => setCurrentTenantId(e)}
					className='mb-2 custom-react-select--clientId d-block'
				/>
			</form>
		</BaseModal>
	);
};

export default ChooseTenantModal;
