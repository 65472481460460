import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { FC } from "react";
import { IAvailableGuard } from "../../common/interfaces/guards/IGuard";
import SimpleBar from "simplebar-react";
import { IVocabulary } from "../../common/interfaces/vocabulary/IVocabulary";
import {
    IActiveJobSchedules,
    IJob,
    IJobAssignedSchedules,
    IJobSchedule,
    IJobShiftsInNeedOfGuards,
    IJobsRightNow,
} from "../../common/interfaces/jobs/IJob";
import ResourceDataService, { IResourceData } from "../../common/services/ResourceDataService";
import { clearHighlight, highlightChoose } from "../../utils/HighlightChoose";
import { searchInArray } from "../../utils/Search";
import CustomSelectControl from "../custom-select/CustomSelectControl";
import DataTable from "../tables/DataTable";
import AppContext from "../../context/AppContext";
import { NotificationTypes } from "../../common/interfaces/INotification";
import AutocompleteField from "../autosuggestField/AutocompleteField";
import { convertToKm } from '../../utils/ConvertToKm';
import JobDetailsTabsContext from "../../context/jobs/JobDetailsTabsContext";
import { useBulk } from "../../customHooks/useBulk";
import { availableGuardsTable } from "../../content/jobs/JobsContent";

type Props = {
    onSubmit: (guard: IAvailableGuard) => void;
    selectedItem?: IJobShiftsInNeedOfGuards | IJobSchedule | IJobsRightNow | IJobAssignedSchedules;
    title: string;
    showReasons?: boolean;
    reasons?: IVocabulary[];
    reasonsHook?: any;
    itemHasEndDate?: boolean;
    guardsFromParentCompoment?: IAvailableGuard[];
    scheduleAssign?: boolean
    selectedJob?: IJob
    tableHeight?: number
};

const AvailableGuards: FC<Props> = ({
    onSubmit, selectedItem, title, showReasons,
    reasons, reasonsHook, itemHasEndDate, guardsFromParentCompoment,
    scheduleAssign, tableHeight
}) => {
    const [availableGuards, setAvailableGuards] = useState<{
        original: IAvailableGuard[];
        filtered: IAvailableGuard[];
        isLoading: boolean;
    }>({ original: [], filtered: [], isLoading: false });
    const [, setSearchValue] = useState<string>("");
    const [selectedGuard, setSelectedGuard] = useState<IAvailableGuard>({} as IAvailableGuard);
    const svc = new ResourceDataService<IAvailableGuard[]>({ url: "guard/bulkshifts" });
    const assignedSchedulesSvc = new ResourceDataService<IAvailableGuard[]>({ url: "guard/available" });
    const { showNotification } = useContext(AppContext);
    const contextJob = useContext(JobDetailsTabsContext);
    const { job } = contextJob;
    const { getGuardsForBulkScheduleAssigning } = useBulk()
    useEffect(() => {
        guardsFromParentCompoment ? getGuardsFromParent() : getAvailableGuards();
    }, []);

    useEffect(() => {
        showReasons && onSubmit(selectedGuard);
    }, [selectedGuard]);

    const getGuardsFromParent = () => {
        setAvailableGuards({ ...availableGuards, isLoading: true });
        setSelectedGuard({} as IAvailableGuard);
        setAvailableGuards({
            original: guardsFromParentCompoment as IAvailableGuard[],
            filtered: guardsFromParentCompoment as IAvailableGuard[],
            isLoading: false,
        });
    };

    function determineIfIsAssignedSchedules(toBeDetermined: IJobShiftsInNeedOfGuards | IJobSchedule | IJobsRightNow | IJobAssignedSchedules | IActiveJobSchedules): toBeDetermined is IJobAssignedSchedules {
        if ((toBeDetermined as IJobAssignedSchedules).guardId && !(toBeDetermined as IActiveJobSchedules).location) {
            return true
        }
        return false
    }
    function determineIfIsActiveJobSchedules(toBeDetermined: IJobShiftsInNeedOfGuards | IJobSchedule | IJobsRightNow | IJobAssignedSchedules | IActiveJobSchedules): toBeDetermined is IJobAssignedSchedules {

        if ((toBeDetermined as IJobsRightNow).shiftId) {
            return true
        }
        return false
    }
    const getAvailableGuards = async () => {
        setSelectedGuard({} as IAvailableGuard);
        setAvailableGuards({ ...availableGuards, isLoading: true });
        try {
            if (scheduleAssign && selectedItem) {
                getGuardsForBulkScheduleAssigning([selectedItem as IJobSchedule], {
                    latitude: job.latitude,
                    longititude: job.longititude,
                    ignoreleave: false
                },
                    (response) => {
                        setAvailableGuards({
                            original: response.data,
                            filtered: response.data,
                            isLoading: false,
                        });
                    })
                return
            }
            if (selectedItem) {
                let response: IResourceData<IAvailableGuard[]>;
                if (determineIfIsAssignedSchedules(selectedItem) && job) {
                    response = await assignedSchedulesSvc.queryString(
                        `StartDate=${selectedItem.startDate ? selectedItem.startDate : (selectedItem as IJobAssignedSchedules).aspStartDate
                        }&EndDate=${getEndDate()}&TimeFrom=${moment(selectedItem.timeFrom).toISOString()}&TimeTo=${moment(
                            selectedItem.timeTo
                        ).toISOString()}&RepeatOn=${!selectedItem.repeatOn ? moment.utc(selectedItem.startDate).weekday() : selectedItem.repeatOn
                        }&ShiftPeriod=${selectedItem.shiftPeriod}${'&Lat=' + job.latitude + '&Long=' + job.longititude}`
                    );
                } else {

                    let shiftId;
                    if (determineIfIsActiveJobSchedules(selectedItem)) {
                        shiftId = selectedItem?.shiftId;
                    } else {
                        shiftId = selectedItem.id;
                    }
                    response = await svc.queryString(`Shifts=${shiftId}`);
                }

                setAvailableGuards({
                    original: response.data,
                    filtered: response.data,
                    isLoading: false,
                });
            }
        } catch (e: any) {
            setAvailableGuards({ ...availableGuards, isLoading: false });
            showNotification(NotificationTypes.danger, e.message);
        }
    };

    const getEndDate = () => {
        if (itemHasEndDate) {
            return (selectedItem as IJobAssignedSchedules).endsOn;
        } else if (selectedItem) {
            return selectedItem.startDate ? selectedItem.startDate : (selectedItem as IJobsRightNow).aspStartDate;
        }
    };

    const handleSearch = (value: string) => {
        onFilterClear()
        const filteredData = searchInArray<IAvailableGuard>(
            availableGuards.original,
            ["firstName", "middleName", "lastName"],
            value.trim(),
        );
        filteredData && setAvailableGuards({ ...availableGuards, filtered: filteredData });
        // if (filteredData.length === 1) {
        //     setTimeout(() => {
        //         onGuardClick(filteredData[0].id)
        //     }, 0);
        // }
    };

    useEffect(() => {
           if (availableGuards.filtered.length === 1) {
            setTimeout(() => {
                onGuardClick(availableGuards.filtered[0].id)
            }, 0);
        }
    }, [availableGuards.filtered])

    const onFilterClear = () => {
        setSearchValue("");
        setAvailableGuards({ ...availableGuards, filtered: availableGuards.original });
        setSelectedGuard({} as IAvailableGuard);
        clearHighlight()
    };

    const onGuardClick = (guardId: number) => {
        const guard = availableGuards.original.find((guard) => guard.id === guardId);
        if (guard) {
            guardId === selectedGuard.id
                ? setSelectedGuard({} as IAvailableGuard)
                : setSelectedGuard(guard ? guard : ({} as IAvailableGuard));

            guardId === selectedGuard.id ? clearHighlight() : highlightChoose(guardId);
        }
    };

    const getSuggestionsSearched = (value: string) => {
        if (value.trim().length < 2) {
            return [];
        }
        return searchInArray<IAvailableGuard>(
            availableGuards.original,
            ["firstName", "middleName", "lastName"],
            value.trim(),
            2
        );
    };

    const getSuggestionsSearchedValue = ({ firstName, lastName, middleName }: IAvailableGuard) => {
        return `${firstName} ${middleName} ${lastName}`.replace(/  +/g, " ");
    };

    const renderSuggestions = ({ firstName, lastName, middleName }: IAvailableGuard) => (
        <span>{`${firstName} ${middleName} ${lastName}`.replace(/  +/g, " ")}</span>
    );

    return (
        <div className='row align-items-start'>
            <div className='col-12 assign-guard-guards'>
                <h4 className='mb-3' dangerouslySetInnerHTML={{ __html: title }} />

                <SimpleBar style={{ maxHeight: tableHeight ? tableHeight : 350 }} autoHide={false}>
                    <DataTable
                        thead={availableGuardsTable.thead}
                        tbody={
                            availableGuards.filtered
                                ? availableGuards.filtered
                                    .sort((a, b) => a.distance && b.distance !== undefined ? a.distance - b.distance : 0)
                                  .map((item) => ({
                                    id: item.id,
                                    fullName: `${item.firstName} ${item.middleName} ${item.lastName}`,
                                    distance: item.distance || 0,
                                    attendance: item.attendance || 0,
                                }))
                                : []
                        }
                        onColClick={(_, id) =>  id && onGuardClick(id)}
                        ignoreCols={[0,2,3]}
                        tableClass={"guard-for-schedule mb-0"}
                        isLoading={availableGuards.isLoading}
                    >
                      {
                        (_id, _rowItem, rowIndex) => (
                          <>
                            <td className="aling-middle" onClick={() => onGuardClick(_rowItem.id)}>
                              <div>{convertToKm(_rowItem.distance || 0)}</div>
                            </td>
                            <td className="aling-middle" onClick={() => onGuardClick(_rowItem.id)}>
                              <div>
                                {
                                  `${_rowItem.attendance
                                  && !isNaN(_rowItem.attendance)
                                  ? _rowItem.attendance.toFixed(1) : 0}%`
                                }
                              </div>
                            </td>
                            <td className="aling-middle" onClick={() => onGuardClick(_rowItem.id)}>
                              <div>
                                {availableGuards.filtered[rowIndex].aspMobile ?
                                  (
                                    <>
                                      <svg className="mr-2" width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="7" width="10" height="8">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 7C12.6035 7 11.6992 8.16972 11.4537 8.57358C11.2014 8.99061 11.099 9.21364 10.8293 8.91652C10.7134 8.78802 10.1379 8.4019 10.1379 8.4019C10.1379 8.4019 10.3381 10.4204 10.3297 10.7281C10.3211 11.0293 10.1769 11.164 9.99582 10.8358C9.86872 10.6074 9.14845 9.91518 9.14845 9.91518C9.14845 9.91518 9.00438 10.1204 8.80216 10.3805C8.65717 10.5662 8.7075 10.6222 8.4789 10.5455C8.23477 10.4645 7.32866 10.2577 7.32866 10.2577C7.32866 10.2577 7.59337 11.2682 7.66172 11.5602C7.67435 11.615 7.68435 11.6625 7.69093 11.704C7.71942 11.9024 7.6885 11.8531 7.38523 12.0459C7.10486 12.2234 7 12.3092 7 12.3092C7 12.3092 8.7246 13.4438 8.98254 13.672C9.24055 13.9008 9.16292 13.9815 9.02898 14.2812C8.88696 14.5988 8.71815 15 8.71815 15H14.3246C14.3246 15 14.2642 14.5988 14.2083 14.2812C14.1556 13.9815 14.0994 13.9008 14.4202 13.672C14.7397 13.4438 16.7712 12.3092 16.7712 12.3092C16.7712 12.3092 16.6897 12.2234 16.4583 12.0459C16.2067 11.8531 16.1622 11.9024 16.2456 11.704C16.263 11.6625 16.2849 11.615 16.3132 11.5602C16.4611 11.2682 17 10.2577 17 10.2577C17 10.2577 16.0369 10.4645 15.7717 10.5455C15.522 10.6222 15.5871 10.5662 15.4924 10.3805C15.3611 10.1204 15.2722 9.91518 15.2722 9.91518C15.2722 9.91518 14.3654 10.6074 14.1754 10.8358C13.9058 11.164 13.7973 11.0293 13.8706 10.7281C13.9467 10.4204 14.6939 8.4019 14.6939 8.4019C14.6939 8.4019 14.0133 8.78802 13.8622 8.91652C13.5121 9.21364 13.4704 8.99061 13.3314 8.57358C13.1953 8.16972 12.609 7 12.609 7H12.6035Z" fill="white"/>
                                      </mask>
                                      <g mask="url(#mask0)">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6035 7C12.6035 7 11.6992 8.16972 11.4537 8.57358C11.2014 8.99061 11.099 9.21364 10.8293 8.91652C10.7134 8.78802 10.1379 8.4019 10.1379 8.4019C10.1379 8.4019 10.3381 10.4204 10.3297 10.7281C10.3211 11.0293 10.1769 11.164 9.99582 10.8358C9.86872 10.6074 9.14845 9.91518 9.14845 9.91518C9.14845 9.91518 9.00438 10.1204 8.80216 10.3805C8.65717 10.5662 8.7075 10.6222 8.4789 10.5455C8.23477 10.4645 7.32866 10.2577 7.32866 10.2577C7.32866 10.2577 7.59337 11.2682 7.66172 11.5602C7.67435 11.615 7.68435 11.6625 7.69093 11.704C7.71942 11.9024 7.6885 11.8531 7.38523 12.0459C7.10486 12.2234 7 12.3092 7 12.3092C7 12.3092 8.7246 13.4438 8.98254 13.672C9.24055 13.9008 9.16292 13.9815 9.02898 14.2812C8.88696 14.5988 8.71815 15 8.71815 15H14.3246C14.3246 15 14.2642 14.5988 14.2083 14.2812C14.1556 13.9815 14.0994 13.9008 14.4202 13.672C14.7397 13.4438 16.7712 12.3092 16.7712 12.3092C16.7712 12.3092 16.6897 12.2234 16.4583 12.0459C16.2067 11.8531 16.1622 11.9024 16.2456 11.704C16.263 11.6625 16.2849 11.615 16.3132 11.5602C16.4611 11.2682 17 10.2577 17 10.2577C17 10.2577 16.0369 10.4645 15.7717 10.5455C15.522 10.6222 15.5871 10.5662 15.4924 10.3805C15.3611 10.1204 15.2722 9.91518 15.2722 9.91518C15.2722 9.91518 14.3654 10.6074 14.1754 10.8358C13.9058 11.164 13.7973 11.0293 13.8706 10.7281C13.9467 10.4204 14.6939 8.4019 14.6939 8.4019C14.6939 8.4019 14.0133 8.78802 13.8622 8.91652C13.5121 9.21364 13.4704 8.99061 13.3314 8.57358C13.1953 8.16972 12.609 7 12.609 7H12.6035Z" fill="url(#paint0_linear)"/>
                                      </g>
                                      <rect x="5.5" y="0.5" width="13" height="23" rx="0.5" stroke="#283044"/>
                                      <rect x="9" y="21" width="6" height="1" rx="0.5" fill="#283044"/>
                                      <defs>
                                      <linearGradient id="paint0_linear" x1="9.36814" y1="14.0562" x2="17.0086" y2="14.0562" gradientUnits="userSpaceOnUse">
                                      <stop stop-color="#D82332" stop-opacity="0.01"/>
                                      <stop offset="1" stop-color="#D82332"/>
                                      </linearGradient>
                                      </defs>
                                      </svg>
                                      <span>{availableGuards.filtered[rowIndex].aspMobile}</span>
                                    </>
                                  )
                                  :(availableGuards.filtered[rowIndex].personalMobile || '-')
                                }
                              </div>
                            </td>
                          </>
                        )
                      }

                    </DataTable>
                </SimpleBar>
            </div>
            {availableGuards.original.length !== 0 && (
                <form
                    className={`${showReasons ? "col-md-6" : "col-12"} mt-4 mb-1 d-flex align-items-end flex-wrap`}
                    onSubmit={(e) => e.preventDefault()}>
                    <div className='form-group mb-0 w-100-md-0'>
                        <label>Or Search by Name</label>
                        <AutocompleteField
                            getSuggestions={getSuggestionsSearched}
                            getSuggestionValue={getSuggestionsSearchedValue}
                            renderSuggestion={renderSuggestions}
                            onClear={onFilterClear}
                            onSearch={handleSearch}
                            placeholder={"Enter guard name"}
                        />
                    </div>

                    {Object.keys(selectedGuard).length !== 0 && !showReasons && (
                        <button
                            type='button'
                            className='btn btn-aqua-blue d-block ml-auto px-5 mt-2 mt-sm-0 mb-2 mb-sm-0'
                            onClick={(e) => {
                                e.preventDefault();
                                onSubmit(selectedGuard);
                            }}>
                            Select
                        </button>
                    )}
                </form>
            )}

            {showReasons && reasonsHook && availableGuards.original.length !== 0 && (
                <form className='col-md-5 offset-md-1 mt-4 mb-1 ml-auto'>
                    <div className='form-group mb-0'>
                        <label>Reason for replacement</label>
                        <CustomSelectControl
                            value={reasonsHook.isOtherReason ? 0 : (reasonsHook.reason as IVocabulary).id}
                            placeholder={"Select a reason"}
                            options={reasons ? reasons : []}
                            onChange={(e) => reasonsHook.onReasonSelect(e)}
                            className={`custom-react-select--reasonForReplacement`}
                        />
                    </div>
                    {reasonsHook.isOtherReason && (
                        <div className='form-group mt-3'>
                            <textarea
                                className='form-control'
                                rows={5}
                                name={"reasonForReplacement--other"}
                                placeholder='Type other reason'
                                value={reasonsHook.reason as string}
                                onChange={(e) => reasonsHook.setReason(e.target.value)}
                                ref={reasonsHook.textareaRef}></textarea>
                        </div>
                    )}
                </form>
            )}
        </div>
    );
};

export default AvailableGuards;
