export enum ActivationDeactivation {
    activate = 'reactivate',
    approve = 'approve',
    disapprove = 'disapprove',
    deactivate = 'deactivate',
    delete = 'delete',
    assign = 'assign',
    unassign = 'unassign'
}

export enum ModelStatus {
    active = 'Active',
    pending = 'Pending',
    inactive = 'Inactive',
    test = "Test"
}
