import React, {  useCallback, useEffect,  useState } from 'react'
import { SVGCalendar, SVGEraser, SVGPencil, SVGReplace, SVGTrash } from '../../../assets/icons/SvgIcons'
import { VocabularyEnum } from '../../../common/enums/VocabularyEnum'
import { IJobAssignedShifts } from '../../../common/interfaces/jobs/IJob'
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary'
import { DataService } from '../../../common/services/DataService'
import CustomSelectControl from '../../../components/custom-select/CustomSelectControl'
import EditShiftModal from '../../../components/modal/jobs/EditShiftModal'
import EditShiftSuccessModal from '../../../components/modal/jobs/EditShiftSuccessModal'
import RemoveShiftModalSuccess from '../../../components/modal/jobs/RemoveShiftModalSuccess'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import {useTableList} from '../../../customHooks/useTableList'
import {useVocabulary} from '../../../customHooks/vocabulary/useVocabulary'
import {matchOptionWithName} from '../../../utils/MatchOptionWithName'
import DatePicker from "react-datepicker";
import RemoveShiftModal from '../../../components/modal/jobs/RemoveShiftModal'
import {useListFilter} from '../../../customHooks/useListFilter'
import {datePickerFormat, filterDateFormat, momentDateFormat, momentHoursFormat} from '../../../utils/DateFormatting'
import AppContext from '../../../context/AppContext'
import {useContext} from 'react'
import AvailableGuards from '../../../components/guards/AvailableGuards'
import BaseModal from '../../../components/modal/BaseModal'
import {useReason} from '../../../customHooks/useReason'
import {useReplaceGuard} from '../../../customHooks/useReplaceGuard'
import { editJobsShiftsContent } from '../../../content/dashboard/TablesContent'
import {useDatapickerSvg} from "../../../customHooks/useDatapickerSvg";
import { NotificationTypes } from '../../../common/interfaces/INotification'
import useUnassignGuard from '../../../customHooks/useUnassignGuard'
import AutocompleteField from '../../../components/autosuggestField/AutocompleteField'
import { useRole } from 'customHooks/useRole'
import MulTipleCheckbox from 'components/miltiple-checkbox/miltiple-checkbox'
import { useMultipleCheckbox } from 'customHooks/useMultipleCheckbox'
import { useBulk } from 'customHooks/useBulk'
import { BulkActionsModal } from 'common/models/Modals'
import ConfirmBulkShiftBody from 'components/modal/jobs/confirmBulkShiftModal/ConfirmBulkShiftBody'
import { useSchoolYear } from 'customHooks/useSchoolYear'
import BulkUnassignModal from 'components/modal/jobs/BulkUnassignModal'
import { IJobShiftsModals } from 'common/interfaces/jobs/IJobModals'
import { ISchoolYearDropdown } from 'common/interfaces/dropdowns/ISchoolYearDropdown'
import Layout from 'components/layout/Layout'
import { getHolidayField } from 'utils/GetFieldFromTable'
import moment from 'moment'

const EditShifts = () => {
    const baseUrl = 'shift/assigned';
    const appContext = useContext(AppContext)

    const shiftUpdateSvc = new DataService<{ guardId: number | string }>({ url: "shift" })
    const { tableData, isLoading, setTableData, fetchData, perPage, onPagination, onPerPage, currentPage, onSortCall, onClearFilterQuery, totalCounts, allRecords } = useTableList<IJobAssignedShifts>(`${baseUrl}?ClientId=${appContext.currentClient?.id || 0}`, '24')
    const { vocabulary: reasonsVocabulary } = useVocabulary(VocabularyEnum.shiftReason, true)
    const shiftsVocabulary = useVocabulary(VocabularyEnum.shift, true)
    const { vocabulary: replacementVocabulary } = useVocabulary(VocabularyEnum.replacement, true)
    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const { onUnassignGuardSubmit, onBulkUnassingGuardSubmitted } = useUnassignGuard()
    const { isClientRole, isASPAndAdminRole } = useRole()
    const {onChangeCheckbox, onRemoveCheckbox, allCheckbox, arrayInStringWith, setAllCheckbox} = useMultipleCheckbox()
    const { availableGuards, getGuardsForBulkAssigning,
        bulkModalData, convertCheckboxesToShifts } = useBulk()
    const [allReadUniqueJobs, setAllReadUniqueJobs] = useState<IJobAssignedShifts[]>([])
    const [showWarningModal, setShowWarningModal] = useState(false)

    const [modals, setModals] = useState<IJobShiftsModals>(
        {
            remove: false,
            availableGuards: false,
            edit: false,
            removeSuccess: false,
            editSuccess: false,
            replaceGuard: false,
            successReplaceModal: false
        }
    )
    const [bulkActionsModal, setBulkActionsModal] = useState(new BulkActionsModal({}))
    const [selectedShift, setSelectedShift] = useState<{ id: number, body: IJobAssignedShifts }>({
        id: 0,
        body: {} as IJobAssignedShifts
    })

    const {
        generateFilterQuery, filter, onFilterDropdownChange, onFilterClearClick, schoolBoards, getSchoolBoard,
        onFilterSearchClearClick, zones, getZones, filterIsClear, onFilterDefaultSchoolYear, setFilter
    } = useListFilter(baseUrl, +(appContext.currentClient.id || 0))
    const reasonsHook = useReason(replacementVocabulary)
    const unAssignGuardReasonsHook = useReason(reasonsVocabulary)
    const { reasons: replaceGuardReasons  } = reasonsHook;
    const {
        selectedGuard, setSelectedGuard, modalContent, setReplaceModal, replaceModal,
        onReplaceGuardSaveClick, onReplaceGuardConfirmClick, onBulkReplaceGuardConfirmClick
    } = useReplaceGuard()
    const {  getSchoolYears: setDefaultSchoolYears,
         schoolYears, getYearById, schoolYearsData, selectedSchoolYear, setSelectedSchoolYear, defaultYear 
    } = useSchoolYear()

    const filterUniqueById = useCallback((data: IJobAssignedShifts[]): IJobAssignedShifts[] => {
        const uniqueMap = new Map<string, IJobAssignedShifts>();
      
        for (const item of data) {
          uniqueMap.set(item.id + "", item);
        }
      
        return Array.from(uniqueMap.values());
    }, []);

    useEffect(() => {
        getSchoolBoard()
        getZones()
    }, [])

    useEffect(() => {
        setAllReadUniqueJobs(prev => filterUniqueById([...prev, ...tableData]))
    }, [filterUniqueById, tableData])


    useEffect(() => {
       if (!filter.hasValue) {
            setDefaultSchoolYears(onFilterDefaultSchoolYear, (yearId, years) => {
                    const query = generateFilterQuery(false, false, undefined);
                    years && setSelectedSchoolYear((getYearById(years, +(yearId || 0)) || {}) as ISchoolYearDropdown)
                    fetchData(1, 24, generateFilterQuery(false, false, undefined, generateFilterQuery(false, false, undefined, yearId && !query.includes('SchoolYearId') ? `&SchoolYearId=${yearId}` : '')))
                },
                true
            );
       }
    }, [filter.hasValue])

    useEffect(() => {
        convertCheckboxesToShifts(allCheckbox, tableData)
    }, [allCheckbox])


    const onShiftClick = (shiftId: number, type: string, shiftPeriod?: string) => {
        let shift = tableData.find(shift => shift.id === shiftId)
        shift && setSelectedShift({
            id: shiftId,
            body: shiftPeriod ? { ...shift, shiftPeriod } : shift
        })

        setModals({
            ...modals,
            edit: type === 'edit' ? true : false,
            remove: type === "delete" ? true : false,
            replaceGuard: type === "replace" ? true : false,
            editSuccess: false,
        })
    }

    const onRemoveShiftConfirmedClick = async (reason: string | IVocabulary) => {
        onUnassignGuardSubmit(reason, selectedShift.id, () => {
            setTableData(tableData.filter(row => row.id !== selectedShift.id));
            setModals({...modals, remove: false, removeSuccess: true})
        })
    }

    const onEditShiftSaveClick = async (editedShift: IJobAssignedShifts) => {
        appContext.showLoader(true)
        try {
            const body = {
                guardId: editedShift.guardId,
                timeFrom: editedShift.timeFrom,
                timeTo: editedShift.timeTo,
            }
            await shiftUpdateSvc.update(body, editedShift.id)

            await fetchData(currentPage, perPage, generateFilterQuery())
            appContext.showLoader(false)

        } catch (error: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }

    const onSuccessGuardReplace = async () => {
        await onReplaceGuardConfirmClick(selectedShift.body, reasonsHook.reason)
        setTableData([...tableData.map(item => {
            if (item.id === selectedShift.id) {
                item.guardId = selectedGuard.id
                item.firstName = selectedGuard.firstName
                item.middleName = selectedGuard.middleName
                item.lastName = selectedGuard.lastName
                return item
            }
            return item
        })])
    }

    const onBulkReplaceConfirm = async () => {
        await onBulkReplaceGuardConfirmClick(arrayInStringWith(),reasonsHook.reason)
        setAllCheckbox([])
        setBulkActionsModal(new BulkActionsModal({}))
        reasonsHook.clearReasonStates()
        await fetchData(currentPage, perPage, generateFilterQuery())
    }

    const onBulkAssignClick = async () => {
        if (allCheckbox.some((id, _, arr) => !validateBulkAction(id.toString(), arr.map(String).filter(v => v !== id.toString())))){
            setShowWarningModal(true)
            return;
        }
        await getGuardsForBulkAssigning(arrayInStringWith(), () => {
            setBulkActionsModal(new BulkActionsModal({ showBulkGuardsModal: true }))
        })
    }

    const onUnassignClick = async () => {
        setBulkActionsModal(new BulkActionsModal({ showBulkUnassignModal: true }))
    }

    const onUnassignModalConfirmClicked = () => {
        onBulkUnassingGuardSubmitted(unAssignGuardReasonsHook, arrayInStringWith(), async () => {
            setBulkActionsModal(new BulkActionsModal({ showBulkUnassignSuccessModal: true }))
            setAllCheckbox([])
            unAssignGuardReasonsHook.clearReasonStates()
            await fetchData(currentPage, perPage, generateFilterQuery())
        })
    }
    const renderBreadcrumbs = () => {
		const baseLinks = [
			{
				title: 'Jobs',
				link: 'jobs',
			},
			{
				title: 'Shifts',
				link: '',
			},
		];
		return {
			links: baseLinks,
			currentPageTitle: 'Edit Shifts',
		};
	};

    const onSearch = async (value: string) => {
        setSearchIsActive(true);
        onFilterDropdownChange(value, 'keyWord');
        const queryParams = !selectedSchoolYear.id ? `&ClientId=${appContext.currentClient?.id || 0}`  :  `&ClientId=${appContext.currentClient?.id || 0}&SchoolYearId=${selectedSchoolYear.id}` 
        await fetchData(1, 24, generateFilterQuery(false, false, value, queryParams), false, false)
    }

    const validateBulkAction = (currentId: string, ids: string[]) => {
        console.log(ids)
        const dataToCheck = ( allReadUniqueJobs.length > 0 ? allReadUniqueJobs : tableData).filter((shift) => ids.includes(shift.id + ""));
        const currentShift = tableData.find((shift) => shift.id + "" === currentId);

        if (!currentShift || !dataToCheck.length) return true
        const isMatchingShift = dataToCheck.some((item) => {
          const startDate = moment(item.startDate);
          const aspStartDate = moment(item.aspStartDate);
          const timeTo = moment(item.timeTo);
          const currentStartDate = moment(currentShift.startDate);
          const currentAspStartDate = moment(currentShift.aspStartDate);
          const currentTimeTo = moment(currentShift.timeTo);
          const isOverlap =
            (currentStartDate.isSame(startDate, 'date')) &&
            (
                (currentAspStartDate.isSameOrAfter(aspStartDate) && currentAspStartDate.isSameOrBefore(timeTo)) ||
                (currentAspStartDate.isSameOrBefore(aspStartDate) && currentTimeTo.isSameOrAfter(timeTo)) ||
                (currentAspStartDate.isSameOrBefore(aspStartDate) && currentAspStartDate.isSameOrBefore(timeTo) && currentTimeTo.isSameOrAfter(aspStartDate)) ||
                (currentAspStartDate.isSameOrAfter(aspStartDate) && currentAspStartDate.isSameOrAfter(timeTo) && currentTimeTo.isSameOrBefore(aspStartDate))
              )
          return isOverlap;
        });
        return !isMatchingShift;
      };
    return (
        <Layout breadcrumbs={renderBreadcrumbs()}>
            <div className="row mb-4">
                <div className="col-12">
                    <SectionTitle
                        title={`${isASPAndAdminRole() ? 'Edit Job Shifts' : 'Job Shifts' }`}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex flex-row justify-content-start justify-content-md-end w-100-md-0">
                        <div className="form-group ml-0 mr-2 w-100-md-0">
                            <AutocompleteField
                                includes={'Job,Guard,SchoolBoard'}
                                isAsync={true}
                                placeholder='Enter job name'
                                onSearch={onSearch}
                                onClear={() => {
                                    setSearchIsActive(false);
                                    onFilterSearchClearClick(() => fetchData(1, 24, generateFilterQuery(true)))
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <form className="filters-form-group filters-form-group--dashboard mb-4" onKeyDown={(event) => {
                if (event.key === 'Enter') fetchData(1, 24, generateFilterQuery(true, false))
            }}>
                <div className="row">
                    <div className="col-12">
                        <h5>Filter Shift List</h5>
                    </div>
                    <div className="d-flex w-100 flex-wrap flex-xl-row ml-1  ">
                        <CustomSelectControl
                            isClearable={true}
                            options={zones || []}
                            value={filter.zone}
                            placeholder={"Select Zone"}
                            sort
                            onChange={e => onFilterDropdownChange(e, "zone")}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--small"
                        />

                        <CustomSelectControl
                            isClearable={true}
                            options={schoolBoards}
                            value={filter.schoolBoardId}
                            placeholder={"Select School Board"}
                            onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <CustomSelectControl
                            isClearable={true}
                            options={shiftsVocabulary.vocabulary}
                            value={filter.shiftPeriod}
                            placeholder={"Select Shift Period"}
                            onChange={e => onFilterDropdownChange(e, "shiftPeriod")}
                            className="flex-grow-0 ml-2 mb-2"
                        />

                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2">
                            <DatePicker
                                selected={filter.startDate ? new Date(filter.startDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'startDate')
                                    changeDatapicker(1)
                                }}
                                dateFormat="dd/MM/yyyy"
                                minDate={datePickerFormat(selectedSchoolYear?.startDate || '')}
                                maxDate={datePickerFormat(selectedSchoolYear?.endDate || '')}
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(1)}
                                open={showDatapicker[1]}
                                onClickOutside={() => changeDatapicker(1)}
                            />
                            <div onClick={() => changeDatapicker(1)}><SVGCalendar /></div>
                        </div>

                        <div className="react-datepicker-custom-wrapper datepicker-width d-inline-block ml-2 mb-2 ts">
                            <DatePicker
                                selected={filter.endDate ? new Date(filter.endDate) : null}
                                onChange={date => {
                                    date && onFilterDropdownChange(filterDateFormat(date as Date), 'endDate')
                                    changeDatapicker(2)
                                }}
                                dateFormat="dd/MM/yyyy"
                                minDate={datePickerFormat(selectedSchoolYear?.startDate || '')}
                                maxDate={datePickerFormat(selectedSchoolYear?.endDate || '')}
                                className="flex-basis-0"
                                placeholderText="dd/mm/yyyy"
                                onInputClick={() => changeDatapicker(2)}
                                open={showDatapicker[2]}
                                onClickOutside={() => changeDatapicker(2)}
                            />
                            <div onClick={() => changeDatapicker(2)}><SVGCalendar /></div>
                        </div>

                        <CustomSelectControl
                            options={ schoolYears || []}
                            value={filter.schoolYearId}
                            placeholder={"Select School Year"}
                            onChange={e => {
                                onFilterDropdownChange(e, "schoolYearId");
                                setFilter(prev => ({...prev, startDate: '', endDate: ''}))
                                setSelectedSchoolYear((getYearById(schoolYearsData, +(e || 0)) || {}) as ISchoolYearDropdown)
                            }}
                            className="flex-grow-0 ml-2 mb-2 custom-react-select--schoolYear custom-react-select-lg"
                        />  

                        <button type="button" className="btn btn-aqua-blue ml-2"
                                onClick={() => fetchData(1, 24, generateFilterQuery())}>
                            Filter Shifts
                        </button>
                        {
                            filter.hasValue &&
                            <button type="button" className="btn btn-aqua-blue ml-2"
                                    onClick={() => {
                                        setSearchIsActive(false);
                                        onClearFilterQuery()
                                        onFilterClearClick(() => fetchData(1, 0, generateFilterQuery(false, false, undefined, `&SchoolYearId=${defaultYear.current}`)))
                                        setDefaultSchoolYears(onFilterDefaultSchoolYear, undefined, true);
                                    }}>
                                <SVGEraser/>
                            </button>
                        }

                    </div>
                </div>
            </form>
            <section>
                <div className="row">
                    { allCheckbox.length !== 0 &&
                        <div className="col-12 d-flex flex-row justify-content-center align-items-center mb-4">
                            <h5 className="mb-0">​Apply to All Selected Shifts</h5>
                            <button className="btn btn-aqua-blue mx-2 d-flex align-items-center" onClick={() => onBulkAssignClick()}><SVGReplace className="pr-2"/>
                                <span className="line-height-normal">Replace Guard</span>
                            </button>
                            <button className="btn btn-aqua-blue mx-2 d-flex align-items-center" onClick={() => onUnassignClick()}><SVGTrash className="pr-2" />
                                <span className="line-height-normal">Unassign Guard</span>
                            </button>
                        </div>
                    }
                    <div className="col-12">
                        {
                            React.useMemo(
                                () => (
                                    <DataTable
                                        tableClass={'table-info--notFixed table-info--lastColHolidays mt-4 table-unassigned-job-shifts'}
                                        thead={editJobsShiftsContent.thead}
                                        tbody={
                                            tableData ? tableData
                                                .map(shift => (
                                                    {
                                                        id: shift.id,
                                                        jobName: shift.jobName,
                                                        guard: `${shift.firstName} ${shift.middleName} ${shift.lastName}`,
                                                        startdate: momentDateFormat(shift.startDate),
                                                        aspStartDate: shift.aspStartDate ? momentHoursFormat(shift.aspStartDate, shift.timeFrom, true) : '-',
                                                        timeFrom: momentHoursFormat(shift.timeFrom),
                                                        timeTo: momentHoursFormat(shift.timeTo, shift.timeFrom),
                                                        shiftPeriod: matchOptionWithName(shift.shiftPeriod, shiftsVocabulary.vocabulary),
                                                    }
                                                )) : []}
                                        ignoreCols={[0, ...(isClientRole() ? [4] : [])]}
                                        ignoreTheadCols={[...(isClientRole() ? [3,7,8, 9] : [])]}
                                        isLoading={isLoading}
                                        pagination={true}
                                        currentPage={currentPage}
                                        itemsCount={totalCounts}
                                        filteredItemsCount={allRecords}
                                        itemsPerPage={+perPage}
                                        onPager={page => onPagination(page)}
                                        onPerPage={value => onPerPage(value)}
                                        tableName={"Assigned Job Shifts"}
                                        onSort={(orderBy, desc) => onSortCall(orderBy, desc, filter.keyWord)}
                                        showTableLengthData={true}
                                        sortOnBackend={true}
                                        searchIsActive={searchIsActive}
                                        filtersIsNotClear={filterIsClear ? false : true}
                                        defaultSortedColumn={2}
                                    >
                                        {
                                            (shiftId, rowItem: IJobAssignedShifts) => (
                                                !isClientRole() &&
                                                <>
                                                    <td className="align-middle">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-aqua-blue`}
                                                            onClick={() => onShiftClick(shiftId, 'edit')}>
                                                            <SVGPencil/>
                                                        </button>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-aqua-blue`}
                                                            onClick={() => onShiftClick(shiftId, 'delete')}>
                                                            <SVGTrash/>
                                                        </button>
                                                    </td>
                                                    <td className="align-middle">
                                                        <button
                                                            type="button"
                                                            className={`btn btn-aqua-blue`}
                                                            onClick={() => onShiftClick(shiftId, 'replace', rowItem.shiftPeriod)}>
                                                            <SVGReplace/>
                                                        </button>
                                                    </td>
                                                    <td className="align-middle">
                                                        <div className="form-group mb-0">
                                                            <div className="custom-control custom-checkbox d-flex align-items-center">
                                                                <MulTipleCheckbox
                                                                    id={shiftId.toString()}
                                                                    onChange={onChangeCheckbox}
                                                                    onRemove={onRemoveCheckbox}
                                                                    allCheckboxes={allCheckbox}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        { getHolidayField(+shiftId, tableData) }
                                                    </td>
                                                </>
                                            )
                                        }
                                    </DataTable>
                                ), [tableData, isLoading, shiftsVocabulary.vocabulary]
                            )
                        }
                    </div>
                </div>
            </section>


            {modals.remove &&
            <RemoveShiftModal
                titleBody={`Are you sure you want to unassign <span class="font-weight-bold">${`${selectedShift.body.firstName} ${selectedShift.body.middleName} ${selectedShift.body.lastName}`}</span> from this shift?`}
                show={modals.remove}
                onClose={() => setModals({...modals, remove: false})}
                onSubmit={(reason) => onRemoveShiftConfirmedClick(reason as string | IVocabulary)}
                reasons={unAssignGuardReasonsHook.reasons}
                jobName={selectedShift.body.jobName as string}
                shift={selectedShift.body}
                shifts={shiftsVocabulary.vocabulary}
            />
            }

            {
                modals.removeSuccess &&
                <RemoveShiftModalSuccess
                    show={modals.removeSuccess}
                    shift={selectedShift.body}
                    jobName={selectedShift.body.jobName as string}
                    userName={`${selectedShift.body.firstName} ${selectedShift.body.lastName}`}
                    onClose={() => setModals({...modals, removeSuccess: false})}
                />
            }

            {
                modals.edit &&
                <EditShiftModal
                    show={modals.edit}
                    onClose={() => setModals({...modals, edit: false})}
                    shift={selectedShift.body}
                    onSubmit={shift => onEditShiftSaveClick(shift as IJobAssignedShifts)}
                    jobName={selectedShift.body.jobName as string}
                    modals={modals}
                    setJobShiftsModals={setModals}
                />
            }
            {
                modals.editSuccess &&
                <EditShiftSuccessModal
                    show={modals.editSuccess}
                    onClose={() => setModals({...modals, editSuccess: false, edit: false})}
                    shift={selectedShift.body}
                    jobName={selectedShift.body.jobName as string}
                />
            }

            {/* Replacement Modal */}
            {modals.replaceGuard &&
            <BaseModal
                show={modals.replaceGuard}
                onCancel={() => {
                    reasonsHook.setReason({} as IVocabulary)
                    setModals({...modals, replaceGuard: false})
                }}
                className="available-guards-modal"
                submitBtnText={'Save'}
                cancelBtnText={'Close'}
                onSubmit={() => onReplaceGuardSaveClick(
                    reasonsHook.reasonIsEmpty('reasonForReplacement', 'reasonForReplacement--other'),
                    selectedShift.body, reasonsHook.reason)
                    .then(() => setModals({...modals, replaceGuard: false}))
                }
            >
                <AvailableGuards
                    onSubmit={guard => setSelectedGuard(guard)}
                    selectedItem={selectedShift.body}
                    title={`Replacement <span class="font-weight-bold">${selectedShift.body.firstName} ${selectedShift.body.middleName} ${selectedShift.body.lastName}</span> for <span class="font-weight-bold">${selectedShift.body.jobName}</span>
                        <br /> <h5 class="font-weight-bold">${momentDateFormat(selectedShift.body.startDate)} - Shift ${momentHoursFormat(selectedShift.body.timeFrom, selectedShift.body.aspStartDate || '')} - ${momentHoursFormat(selectedShift.body.timeTo, selectedShift.body.aspStartDate || '')}</h5>`}
                    showReasons={true}
                    reasons={replaceGuardReasons}
                    reasonsHook={reasonsHook}
                />
            </BaseModal>
            }

            {
                (replaceModal.confirm) && !bulkActionsModal.showBulkConfirmGuardsModal &&
                <BaseModal
                    show={replaceModal.confirm}
                    onCancel={() => {
                        reasonsHook.setReason({} as IVocabulary)
                        setReplaceModal({confirm: false, success: false})
                    }}
                    cancelBtnText={'Cancel'}
                    submitBtnText={'Confirm'}
                    onSubmit={() => onSuccessGuardReplace()}
                >
                    <div dangerouslySetInnerHTML={{__html: modalContent}}/>
                </BaseModal>
            }

            {
                replaceModal.success &&
                <BaseModal
                    show={replaceModal.success}
                    onCancel={() => {
                        reasonsHook.setReason({} as IVocabulary)
                        setReplaceModal({confirm: false, success: false})
                    }}
                    cancelBtnText={'Close'}
                >
                    <div dangerouslySetInnerHTML={{__html: modalContent}}/>
                    <small className="text-danger">
                      Notifications have been sent to the the replaced guards that their shift has been unassigned,
                      and to the newly assigned guard with the details of these new shifts.
                    </small>
                </BaseModal>
            }

            {
                bulkActionsModal.showBulkUnassignModal &&
                <BulkUnassignModal
                    onCancel={() => setBulkActionsModal(new BulkActionsModal({}))}
                    onSubmit={() => onUnassignModalConfirmClicked()}
                    shifts={bulkModalData.shifts || []}
                    reasonsHook={unAssignGuardReasonsHook}
                    reasons={unAssignGuardReasonsHook.reasons}
                    shiftPeriods={shiftsVocabulary.vocabulary || []}
                />
            }

            {
                bulkActionsModal.showBulkUnassignSuccessModal && 
                <RemoveShiftModalSuccess
                    show
                    title="Assigned Guard(s) have been removed from selected shift(s)"
                    onClose={() => setBulkActionsModal(new BulkActionsModal({}))}
                />
            }

            {
                bulkActionsModal.showBulkGuardsModal &&
                <BaseModal
                    show={true}
                    onCancel={() => setBulkActionsModal(new BulkActionsModal({}))}
                    className="available-guards-modal"
                    cancelBtnText={'Cancel'}
                    submitBtnText={'Save'}
                    onSubmit={() => onReplaceGuardSaveClick(
                        reasonsHook.reasonIsEmpty('reasonForReplacement', 'reasonForReplacement--other'),
                        selectedShift.body, reasonsHook.reason)
                        .then(() => setBulkActionsModal(new BulkActionsModal({ showBulkConfirmGuardsModal: true })))
                    }
                >   
                    <ConfirmBulkShiftBody
                        title="Replace Assigned Guards for These Selected Shifts:"
                        vocabulary={shiftsVocabulary.vocabulary}
                        shifts={bulkModalData.shifts || []}
                        className="mb-4"
                    />

                    <AvailableGuards
                        onSubmit={guard => setSelectedGuard(guard)}
                        title="Select a Guard to Assign to All of the Above Listed Shifts:"
                        guardsFromParentCompoment={availableGuards}
                        reasons={replaceGuardReasons}
                        reasonsHook={reasonsHook}
                        showReasons
                        tableHeight={200}
                    />
                </BaseModal>
            }

            {
                bulkActionsModal.showBulkConfirmGuardsModal && 
                <BaseModal
                    show={true}
                    onCancel={() => {
                        setReplaceModal({confirm: false, success: false})
                        reasonsHook.setReason({} as IVocabulary)
                        setBulkActionsModal(new BulkActionsModal({}))
                    }}
                    cancelBtnText={'Cancel'}
                    submitBtnText={'Confirm'}
                    onSubmit={onBulkReplaceConfirm}
                >
                     <ConfirmBulkShiftBody
                        title={`Are you sure you want to replace the assigned Guards for
                        the selected shifts with <span class="font-weight-bold">${selectedGuard.firstName} ${selectedGuard.lastName}</span>?`}
                        vocabulary={shiftsVocabulary.vocabulary}
                        shifts={bulkModalData.shifts || []}
                    />
                </BaseModal>
            }
            {showWarningModal && (
                <BaseModal show onCancel={() => setShowWarningModal(false)} cancelBtnText={'Close'}>
                   <h6>There is a conflict between 2 or more selected shifts, and therefore a single guard cannot be assigned to them. Please review selected shifts.</h6>
                </BaseModal>
            )}
        </Layout>
    )
}

export default EditShifts
