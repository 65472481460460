import { IManagePassword, IAdministrationUser } from './../interfaces/manage-users/IManageUser';

export class AdministrationUser implements IAdministrationUser {
    firstName = '';
    lastName = '';
    middleName = '';
    position = '';
    email = '';
    mobileNumber = '';
}

export class ManagePassword implements IManagePassword {
    currentPassword = '';
    newPassword = '';
    confirmNewPassword = '';
}