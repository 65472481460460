import { shiftStatusTooptipContent } from 'content/jobs/JobsContent'
import { FC } from 'react'
import { useHistory } from 'react-router'
import { SVGGuardStatus, SVGInDoor, SVGOutDoor } from '../../assets/icons/SvgIcons'
import CustomTooltip from '../custom-tooltip/CustomTooltip'

type Props = {
    guardIconStatus: string | undefined,
    checkInStatus: string | undefined,
    checkOutStatus: string | undefined
    isVisibleForASP: boolean
    keyValue: any
}

const ShiftStatusIcons: FC<Props> = ({ guardIconStatus, checkInStatus, checkOutStatus, isVisibleForASP, keyValue }) => {
    const history = useHistory();
    const onGuardIconClick = () => {
        guardIconStatus === 'guard-test-failed' && history.push('/reports/guard-tests');
    };

    return (
        <div>
            <div className={guardIconStatus || ""} onClick={() => onGuardIconClick()}>
                <CustomTooltip
                    bodyText={shiftStatusTooptipContent}
                    keyValue={keyValue}
                >
                    <span><SVGGuardStatus width={24} height={24}/></span>
                </CustomTooltip>
            </div>

            <div className="d-flex flex-column">
                <div className={`d-flex flex-column ${checkInStatus}`}>
                    <CustomTooltip
                        bodyText={`<strong>Check-In Status</strong> <br />
                        ${isVisibleForASP ? "Purple: Tentatively<br />" : ""}
                        Green: On Time<br />
                        ${isVisibleForASP ? "Yellow: Within ASP Buffer<br />" : ""}
                        Red: Late`}
                        keyValue={keyValue}
                    >
                        <span> <SVGInDoor width={18} height={18}/></span>
                    </CustomTooltip>
                </div>
                <div className={`d-flex flex-column ${checkOutStatus}`}>
                    <CustomTooltip
                        bodyText={`<strong>Check-Out Status</strong> <br />
                        Green: On Time<br />
                        Red: Early`}
                        keyValue={keyValue}
                    >
                        <span><SVGOutDoor width={18} height={18}/></span>
                    </CustomTooltip>
                </div>
            </div>
        </div>
    )
}

export default ShiftStatusIcons
