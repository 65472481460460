import React, { useContext, useEffect } from 'react'
import { BookLeaveModalContext } from '../../../../context/guards/BookLeaveContext'
import CustomSelectControl from '../../../custom-select/CustomSelectControl'

const LeaveReason = () => {
    const context = useContext(BookLeaveModalContext)
    const { bookLeave, onChange, reasons, otherReason, setOtherReason} = context

    const setReasonValue = () => {
        if ((bookLeave?.reason !== undefined && bookLeave?.reason !== null)) {
            if (isNaN(+bookLeave.reason)) {
                return -1
            } else { return bookLeave.reason }
        } else { return undefined }
    }

    useEffect(() => {
        setOtherReasonValue()
        return () => {
            setOtherReason("")
        }
    }, [bookLeave?.reason])

    const setOtherReasonValue = () => {
        if (bookLeave?.reason) {
            return (bookLeave?.reason === -1 || isNaN(+bookLeave?.reason)) ? setOtherReason(bookLeave.reason === -1 ? "" : bookLeave.reason as string)  : otherReason
        }
    }

    return (
        <>
            {
                bookLeave && 
                    <>
                        <div className="row">
                            <div className="col-md-3 form-group mb-0 d-flex align-items-center">
                                <label className="mb-0">Reason: </label>
                            </div>
                            <div className="col-md-9 form-group mb-0">
                                <CustomSelectControl 
                                    options={reasons}
                                    placeholder="Select reason"
                                    value={ setReasonValue() }
                                    onChange={value => onChange(value, "reason")}
                                    className={`custom-react-select--reason`}
                                    />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 offset-md-3">
                                { bookLeave?.reason &&
                                    <>
                                        {
                                            (bookLeave.reason === -1 || isNaN(+bookLeave?.reason as number)) && 
                                            <div className="form-group mt-3">
                                                <textarea
                                                    className="form-control"
                                                    rows={5}
                                                    placeholder="Type other reason"
                                                    value={otherReason}
                                                    name={'bookLeaveReason--other'}
                                                    onChange={e => setOtherReason(e.target.value)}
                                                    ref={context.textareaRef}
                                                ></textarea>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default LeaveReason
