import React, { useEffect, useState } from 'react';
import './AutocompleteField.scss';
import Autosuggest from 'react-autosuggest';
import { SVGEraser, SVGSearch } from '../../assets/icons/SvgIcons';
import ResourceDataService from '../../common/services/ResourceDataService';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';

interface IProps<T> {
    getSuggestions?: (value: string) => T[];
    getSuggestionValue?: (suggestion: T) => string;
    renderSuggestion?: (suggestion: T) => JSX.Element;
    onSearch?: (value: string, id?: number) => void;
    onChange?: (value: string) => void;
    onClear?: () => void;
    placeholder?: string;
    includes?: string;
    isAsync?: boolean;
    showButton?: boolean;
    defaultValue?: boolean
    className?: string
    showClearButton?: boolean
    id?: string
    inputValue?: string | undefined;
    buttonColorClass?: string;
    onlyActive?: boolean;
}

const AutocompleteField = <T extends object>({
        getSuggestions,
        getSuggestionValue,
        renderSuggestion,
        placeholder,
        onSearch,
        onClear,
        includes,
        isAsync,
        showButton = true,
        defaultValue = false,
        className,
        showClearButton,
        inputValue,
        onChange,
        id,
        buttonColorClass,
        onlyActive
    }: IProps<T>) => {
    // TODO: Refactor in order to avoid any
    const [value, setValue] = useState<string>(inputValue ? inputValue : '');
    const [requstValue, setRequstValue] = useState<string>(value);
    const [suggestions, setSuggestions] = useState<T[]>([]);
    const [asyncSuggestions, setAsyncSuggestions] = useState<IVocabulary[]>([]);
    const [isKeyClick, setIsKeyClick] = useState<boolean>(false);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const svc = new ResourceDataService<string[]>({ url: 'vocabulary/predict' });

    useEffect(() => {
        if (!isKeyClick) {
            const timeoutId = setTimeout(() => {
                setRequstValue(value);
            }, 400);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [value, isKeyClick]);

    useEffect(() => {
        defaultValue && setValue('');
        defaultValue && setRequstValue('');
        setIsSelected(false)
    }, [defaultValue])

    useEffect(() => {
        if (isAsync && !isSelected) {
            const fetchSuggestions = async () => {
                const response: any = await svc.queryString(`searchText=${requstValue}&includes=${includes}&OnlyActive=${onlyActive || false}`);
                setAsyncSuggestions(response.data.result);
            };
            if (requstValue.length >= 2 && !isKeyClick) {
                fetchSuggestions();
            }
        }
    }, [requstValue, isKeyClick, isSelected, onlyActive]);

    const backSpaceClear = (event: React.KeyboardEvent<any>) => {
        if ((value.length === 1 || window.getSelection()?.toString()) && event.key === 'Backspace') {
            setValue('');
            // setRequstValue('');
            onClear && onClear();
            handleClearButton()
            setAsyncSuggestions([]);
        }
    };
    const onKeyPressHandler = (event: React.KeyboardEvent<any>) => {
        if(event.key === 'Enter') {
            event.preventDefault();
            if (onSearch) onSearch(value);
        }
    };

    const handlerChange = (_event: React.FormEvent<HTMLElement>, { newValue, method }: any) => {
        const matchedOption = asyncSuggestions.find(opt => opt.id === newValue.id ? newValue.id : Number(newValue));
        const value = matchedOption ? matchedOption?.name : newValue
        setValue(value);
        switch (method) {
            case 'down':
                setIsKeyClick(true);
                break;
            case 'up':
                setIsKeyClick(true);
                break;
            case 'click':
                setIsSelected(true);
                break;
            case 'type':
                setIsSelected(false);
                !showButton && onSearch 
                && onSearch(value, matchedOption && matchedOption.id);
                onChange && onChange(newValue);
                break;
            default:
                setIsKeyClick(false);
                setIsSelected(false);
                break;
        }
    }

    const handleClearButton = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event && event.preventDefault();
        onClear && onClear();
        setValue('');
        setRequstValue('');
    }

    const getAsyncSuggestionValue = (option: IVocabulary) => option as any;;

    const renderAsyncSuggestions = (suggest: IVocabulary) => <span>{suggest.name.trim()}</span>;

    const baseRender = (_obj: T) => <span></span>;

    const baseValue = (_obj: T) => '';

    return (
        <div className={`d-flex ${className ? className : ''}`}>
            {isAsync ? (
                <Autosuggest
                    suggestions={asyncSuggestions as any}
                    onSuggestionsFetchRequested={({ value }) => setValue(value)}
                    onSuggestionsClearRequested={() => setAsyncSuggestions([])}
                    onSuggestionSelected={(_: any, { suggestionValue }: any) => 
                        onSearch && onSearch(suggestionValue.name,suggestionValue.id)
                    }
                    getSuggestionValue={getAsyncSuggestionValue}
                    renderSuggestion={renderAsyncSuggestions}
                    inputProps={{
                        placeholder: placeholder || '',
                        value,
                        onChange: handlerChange,
                        onKeyDown: backSpaceClear,
                        onKeyPress: onKeyPressHandler
                    }}
                    highlightFirstSuggestion
                    id={id}
                />
            ) : (
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={({ value }) => {
                        setValue(value);
                        setSuggestions(getSuggestions && value.length > 1 ? getSuggestions(value) : []);
                    }}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    onSuggestionSelected={(_: any, { suggestionValue }: any) => onSearch && onSearch(suggestionValue)}
                    getSuggestionValue={getSuggestionValue || baseValue}
                    renderSuggestion={renderSuggestion || baseRender}
                    inputProps={{
                        placeholder: placeholder || '',
                        value,
                        onChange: handlerChange,
                        onKeyDown: backSpaceClear
                    }}
                    highlightFirstSuggestion
                    id={id}
                />
            )}

            {onSearch && showButton && (
                <button
                    className={`btn btn-${buttonColorClass || 'aqua-blue'} ml-2`}
                    type='button'
                    onClick={(e) => {
                        e.preventDefault();
                        onSearch(value);
                    }}>
                    <SVGSearch color={buttonColorClass === 'blue' ? '#fff' : undefined} />
                </button>
            )}

            {((value !== '' && onClear && showButton) || showClearButton) && (
                <button
                    className={`btn btn-outline-${buttonColorClass || 'aqua-blue'} ml-2`}
                    type='button'
                    onClick={handleClearButton}>
                    <SVGEraser color={buttonColorClass === 'blue' ? '#004e98' : undefined} />
                </button>
            )}
        </div>
    );
};

export default AutocompleteField;

