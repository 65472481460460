import { useRole } from 'customHooks/useRole'
import React, { FC, useContext, useEffect, useState } from 'react'
import { SVGPencil } from '../../../../assets/icons/SvgIcons'
import { VocabularyEnum } from '../../../../common/enums/VocabularyEnum'
import { IGuardDevice } from '../../../../common/interfaces/guards/IGuard'
import { IValidationBody, ValidationRules } from '../../../../common/interfaces/IValidation'
import { GuardDevice } from '../../../../common/models/Guard'
import AssignedDeviceContext from '../../../../context/guards/AssignedDeviceContext'
import GuardDetailsModalsContext from '../../../../context/guards/GuardDetailsModalsContext'
import { useVocabulary } from '../../../../customHooks/vocabulary/useVocabulary'
import { inputDataChecker } from '../../../../utils/InputDataChecker'
import { matchOptionWithName } from '../../../../utils/MatchOptionWithName'
import { highlightError, validateFiled } from '../../../../utils/Validation'
import BaseModal from '../../../modal/BaseModal'
import ASPDeviceModal from '../../../modal/guards/ASPDeviceModal'

const AssignedDeviceTab: FC = () => {
    const contextModal = useContext(GuardDetailsModalsContext)
    const contextDevice = useContext(AssignedDeviceContext)

    const { modals, setModals, userName } = contextModal
    const { device, onSubmit } = contextDevice;

    const [createMode, setCreateMode] = useState<boolean>(true)
    const [deviceInfo, setDeviceInfo] = useState<IGuardDevice>(new GuardDevice())
    const [guardhasDevice, setGuardhasDevice] = useState<boolean>(false);
    const { vocabulary, getVocabulary } = useVocabulary(VocabularyEnum.deviceBrand)
    const { isClientRole, isSupervisorRole } = useRole()

    useEffect(() => {
        (device !== undefined && device !== null) && setDeviceInfo(device)
        checkDevice();
    }, [device])

    useEffect(() => {
        getVocabulary();
    }, [])

    const checkDevice = () => {
        if (!device?.brand) {
            setGuardhasDevice(false)
            return
        } else {
            let errors: IValidationBody[] = [];
            for (let [, deviceValue] of Object.entries(device as IGuardDevice)) {
                let emptyDeviceFields: IValidationBody[] = [];
                const errorsResult = (value: any, fieldName: string) => [...errors, ...validateFiled(value as string, fieldName, ValidationRules.required)];
                if (deviceValue) {
                    for (let [subField, subValue] of Object.entries(deviceValue)) {
                        emptyDeviceFields = errorsResult(subValue, subField);
                    }
                }

                if (emptyDeviceFields.length < 4 ) {
                    errors = [...errors,...emptyDeviceFields]
                }
            }
            errors.length === 0 ? setGuardhasDevice(true) : setGuardhasDevice(false)
        }
    }

    const onInputChange = (value: React.ChangeEvent<HTMLInputElement> | string | Date | boolean, fieldName?: string) => {
        const res = inputDataChecker(value, fieldName)

        setDeviceInfo({ ...deviceInfo, [res.field]: res.value })
    }

    const onEditDevice = () => {
        setModals(true, 'device')
        setCreateMode(false)
    }

    const onAssignDevice = () => {
        setDeviceInfo(new GuardDevice())
        setModals(true, 'device')
        setCreateMode(true)
    }

    const onDeviceSave = () => {
        let errors: IValidationBody[] = [];

        errors = [...errors, ...validateFiled(deviceInfo.brand as string, "brand", ValidationRules.required)]
        errors = [...errors, ...validateFiled(deviceInfo.model as string, "model", ValidationRules.required)]
        errors = [...errors, ...validateFiled(deviceInfo.aspMobile as string, "aspMobile", ValidationRules.isValidPhone)]
        errors.length === 0 ? onSubmit(deviceInfo, createMode) : highlightError(errors)
    }

    return (
        <>
            <div className="d-flex align-items-center flex-wrap justify-content-md-between">
                <div className="d-flex align-items-center flex-wrap">
                    <h5 className="mb-0">Assigned ASP Device</h5>
                    {
                        guardhasDevice && !isClientRole() && !isSupervisorRole() &&
                        <button className="btn btn-aqua-blue ml-2" onClick={() => onEditDevice()}>
                            <SVGPencil />
                        </button>
                    }
                </div>
                {
                    !guardhasDevice && !isClientRole() && !isSupervisorRole() && <button className="btn btn-aqua-blue mt-2 mt-md-0 ml-0 ml-md-2" onClick={() => onAssignDevice()}>Assign Device</button>
                }
            </div>
                <div className="pt-5 d-flex details-information row">
                    <div className="col-lg-5 assigned-device">
                        <div className="mb-3 fs-14">This information has been manually entered into XGuard:</div>
                        <div className="row mb-3 d-flex flex-nowrap align-items-baseline">
                                <div className="col-4 col-sm-4 details-information__title">
                                    <h6>Brand:</h6>
                                </div>
                                <div className="col-8 col-sm-6 details-information__value">
                                    <p className="mb-0">{ device?.brand ? matchOptionWithName(device?.brand, vocabulary) : '--'}</p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex flex-nowrap align-items-baseline">
                                <div className="col-4 col-sm-4 details-information__title">
                                    <h6>Model Name:</h6>
                                </div>
                                <div className="col-8 col-sm-6 details-information__value">
                                    <p className="mb-0">{  device?.model || '--' }</p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex flex-nowrap align-items-baseline">
                                <div className="col-4 col-sm-4 details-information__title">
                                    <h6>Serial number:</h6>
                                </div>
                                <div className="col-8 col-sm-6 details-information__value">
                                    <p className="mb-0">{ device?.serialNumber || '--' }</p>
                                </div>
                            </div>
                            <div className="row mb-3 d-flex flex-nowrap align-items-baseline">
                                <div className="col-4 col-sm-4 details-information__title">
                                    <h6>Number:</h6>
                                </div>
                                <div className="col-8 col-sm-6 details-information__value">
                                    <p className="mb-0">{ device?.aspMobile || '--' }</p>
                                </div>
                            </div>
                        </div>
                    <div className="col-lg-7 active-device">
                        <div className="mb-3 fs-14">These details are detected from this Guard's device upon last login,
                            whether it is a an ASP-assigned device or personal:
                        </div>
                        <div className="row mb-3 d-flex flex-nowrap align-items-baseline">
                                <div className="col-2 col-sm-4 details-information__title">
                                        <h6>App Version:</h6>
                                </div>
                                <div className="col-8 col-sm-6 details-information__value">
                                    <p className="mb-0">{ device?.version || '--' }{device?.build ? `.${device?.build}` : ''}</p>
                                </div>
                        </div>
                        <div className="row mb-3 d-flex flex-nowrap align-items-baseline">
                                <div className="col-4 col-sm-4 details-information__title">
                                        <h6>Device OS Version:</h6>
                                </div>
                                <div className="col-8 col-sm-6 details-information__value">
                                    <p className="mb-0">{ device?.operationSystemVersion || device?.operationSystem ? `${device?.operationSystem || ''} ${device?.operationSystemVersion || ''}` : '--'}</p>
                                </div>
                        </div>
                    </div>
                </div>
            {
                modals.device && !isClientRole() && !isSupervisorRole() &&
                <BaseModal
                    show={modals.device}
                    onCancel={() => { setModals(false, 'device'); createMode ? setDeviceInfo({} as IGuardDevice) : setDeviceInfo(device as IGuardDevice)}}
                    onSubmit={() => onDeviceSave() }
                    submitBtnText={"Save"}
                    title={createMode ? `Assign a Device to <span class="font-weight-bold">${userName}</span>` : `Edit Assigned Device to <span class="font-weight-bold">${userName}</span`}
                >
                    { deviceInfo &&
                    <ASPDeviceModal
                        device={deviceInfo}
                        vocabulary={vocabulary}
                        onChange={(e,name) => onInputChange(e, name)}/>
                    }
                </BaseModal>
            }
            {
                modals.deviceSuccess &&
                <BaseModal
                    show={modals.deviceSuccess}
                    onCancel={() => {
                        checkDevice()
                        setModals(false, 'deviceSuccess')
                    }}
                    cancelBtnText={"Close"}
                    title={''}
                >
                    <h4 className="text-center mb-4">
                    {
                        createMode ? <>A device has been assigned to <span className="font-weight-bold">{userName}</span></> : `Device information has been updated`
                    }
                    </h4>
                </BaseModal>
            }
        </>
    )
}

export default AssignedDeviceTab
