import React from 'react'
import { IGuardBookLeave } from '../../common/interfaces/guards/IGuard';
import { IGuardShift } from '../../common/interfaces/guards/IShift';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';
import { LeaveTypeState } from '../../components/modal/guards/BookLeaveModal';
import { BookLeaveBody } from 'customHooks/reports/useLeaveReportActions';

interface IBookLeaveContext {
    bookLeaves: IGuardBookLeave[]
    assignedShifts: IGuardShift[]
    onChange: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean, fieldName?: string) => void;
    onSubmit: (obj?: IGuardBookLeave, create?: boolean) => void
    onDelete: (id: number) => void
}

interface IBookLeaveModalContext {
    bookLeave?: IGuardBookLeave | BookLeaveBody
    reasons: IVocabulary[]
    shifts: IVocabulary[]
    guardAssignedDuringLeave: boolean
    onChange: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string | Date | boolean, fieldName?: string) => void
    createMode: boolean
    otherReason: string
    setOtherReason: (value: string) => void
    onBookLeaveTypeChanged?: (value: LeaveTypeState) => void
    bookLeaveType: LeaveTypeState
    textareaRef?: React.RefObject<HTMLTextAreaElement>
}

const BookLeaveContext = React.createContext<IBookLeaveContext>({} as IBookLeaveContext);

export const BookLeaveModalContext = React.createContext<IBookLeaveModalContext>({} as IBookLeaveModalContext);
export default BookLeaveContext;
