import React, { useContext, useEffect, useState } from 'react';
import { SVGCancel, SVGPencil } from '../../assets/icons/SvgIcons';
import {
	manageOptionsTableContent,
	manageDropdownsTabContent,
	manageOptionsTableContentSQ,
} from '../../content/administration/DropdownOptionsContent';

import { AuthService } from '../../common/auth/AuthService';
import { Roles } from '../../common/enums/Roles';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';
import { VocabularyEnum } from '../../common/enums/VocabularyEnum';
import { IManageDropdownTab } from '../../common/interfaces/administration/IManageDropdown';
import { AddNewItemEnum } from '../../common/enums/AddNewItemEnum';
import { DataService } from '../../common/services/DataService';
import { NotificationTypes } from '../../common/interfaces/INotification';

import Layout from '../../components/layout/Layout';
import DataTable from '../../components/tables/DataTable';
import SectionTitle from '../../components/titles/SectionTitle';
import AddNewItemModal from '../../components/modal/AddNewItemModal';
import BaseModal from '../../components/modal/BaseModal';

import AppContext from '../../context/AppContext';
import { useModelDetails } from '../../customHooks/useModelDetails';
import { useAddNewItem } from '../../customHooks/useAddNewItem';

const ManageDropdownOptions = () => {
	const { showLoader, showNotification, currentUser } = useContext(AppContext);
	const [tabInFocus, setTabInFocus] = useState<IManageDropdownTab>(manageDropdownsTabContent.tabs[0]);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [modals, setModals] = useState<{
		note?: string;
		showSuccess: boolean;
		successTitle: string;
		confirm: boolean;
		itemFocus: IVocabulary;
	}>({ showSuccess: false, successTitle: '', confirm: false, itemFocus: {} as IVocabulary });
	const [optionInFocus, setOptionInFocus] = useState<IVocabulary>({} as IVocabulary);
	const {
		showAddNewItemModal,
		onAddNewItemClick,
		onAddNewItemSubmit,
		onAddNewItemModalClose,
		setShowAddNewItemModal,
	} = useAddNewItem();

	const {
		isLoading,
		detailsModel: tableData,
		fetchData,
		setDetailsModel,
	} = useModelDetails<{ [index: string]: IVocabulary[] }>('vocabulary', null, {
		include: [
			VocabularyEnum.guardActivation,
			VocabularyEnum.guardDeactivation,
			VocabularyEnum.absence,
			VocabularyEnum.replacement,
			VocabularyEnum.bookLeave,
			VocabularyEnum.guardRemoveJobScheduleReason,
			VocabularyEnum.shiftReason,
			VocabularyEnum.clientDeactivationReason,
			VocabularyEnum.aspUserDeactivationReason,
			VocabularyEnum.aspAdminDeactivationReason,
			VocabularyEnum.jobActivation,
			VocabularyEnum.jobDeactivation,
			VocabularyEnum.deviceBrand,
			VocabularyEnum.shiftReason,
			VocabularyEnum.scheduleDeactivation,
			VocabularyEnum.clientContactDeactivationReason,
			VocabularyEnum.incidentTypesOnGuardMobileApp,
			VocabularyEnum.contactFormTopicsOnGuardMobileApp,
			VocabularyEnum.arrivalTypesOnGuardMobileApp,
			VocabularyEnum.securityQuestionsForGuards,
		],
	});
	const svc = new DataService({ url: 'vocabulary' });

	useEffect(() => {
		fetchData();
	}, []);

	const onTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabId: number) => {
		event.preventDefault();

		const tab = manageDropdownsTabContent.tabs.find((tab) => tab.id === tabId);

		tab && setTabInFocus(tab);
	};

	const onActionColClick = async (rowItem: IVocabulary, type: 'edit' | 'delete') => {
		setOptionInFocus(rowItem);
		if (type === 'edit') {
			//edit logic
			setIsEditMode(true);
			setShowAddNewItemModal(true);
		} else {
			showLoader(true);
			try {
				setModals({
					...modals,
					confirm: true,
					showSuccess: false,
					itemFocus: rowItem,
					successTitle: `Are you sure you want to remove the option <span class="font-weight-bold">"${rowItem.name}"</span> from <span class="font-weight-bold">${tabInFocus.tabContentTitle}</span>?`,
					note: 'Removing this option will make the option unavailable for future selection. Any previous selections will continue to show the removed option text.',
				});
				showLoader(false);
			} catch (error: any) {
				showNotification(NotificationTypes.danger, error.message);
				showLoader(false);
			}
		}
	};
	const onSubmitRemoveClick = async (rowItem: IVocabulary) => {
		showLoader(true);
		try {
			await svc.update({}, rowItem.id, 'deactivate');
			setDetailsModel({
				...tableData,
				[tabInFocus.objKey]: tableData[tabInFocus.objKey].filter(
					(opt) => opt.id.toString() !== rowItem.id.toString()
				),
			});
			setModals({
				...modals,
				showSuccess: true,
				confirm: false,
				successTitle: `The option text has been removed from the <span class="font-weight-bold">${tabInFocus.tabContentTitle}</span>.`,
			});
			setOptionInFocus({} as IVocabulary);
			showLoader(false);
		} catch (error: any) {
			showNotification(NotificationTypes.danger, error.message);
			showLoader(false);
		}
	};

	const onEditItemSubmit = async (updatedName: string) => {
		showLoader(true);
		try {
			console.log(tabInFocus);
			await svc.update(
				{
					name: updatedName,
					...(tabInFocus.objKey === 'SecurityQuestions' ? { type: tabInFocus.objKey } : {}),
				},
				optionInFocus.id
			);
			setDetailsModel({
				...tableData,
				[tabInFocus.objKey]: tableData[tabInFocus.objKey].map((opt) => {
					if (opt.id === optionInFocus.id) {
						opt.name = updatedName;
					}
					return opt;
				}),
			});
			setModals({
				...modals,
				showSuccess: true,
				successTitle: `This option text has been updated in <span class="font-weight-bold">${tabInFocus.tabContentTitle}</span>`,
			});
			setIsEditMode(false);
			setShowAddNewItemModal(false);
			setOptionInFocus({} as IVocabulary);
			showLoader(false);
		} catch (error: any) {
			showNotification(NotificationTypes.danger, error.message);
			showLoader(false);
		}
	};

	const onNewOptionAdded = (option: IVocabulary, type: AddNewItemEnum) => {
		setModals({
			...modals,
			showSuccess: true,
			successTitle: `This option text has been added to <span class="font-weight-bold">${tabInFocus.tabContentTitle}</span> .`,
		});
		setDetailsModel({
			...tableData,
			[type]: [...tableData[type], ...[option]],
		});
	};

	return (
		<Layout
			breadcrumbs={{
				links: [
					{
						title: 'Administration',
						link: 'administration',
					},
				],
				currentPageTitle: 'Manage Dropdown Options',
			}}
		>
			<div className="row details-tabs details-tabs--manage-dropdown">
				<div className="col-12 mb-2 mb-md-4 d-flex flex-row align-items-center justify-content-between">
					<SectionTitle title="Manage Dropdown Options" />
				</div>
				<div className="col-12 mb-3">
					<h6>
						To update the options that appear in various screens through this platform, select the related
						tab and update as needed.
					</h6>
				</div>

				{/* Start Tab Buttons */}
				<div className="col-md-4">
					<div
						className="nav flex-column nav-pills"
						id="v-pills-tab"
						role="tablist"
						aria-orientation="vertical"
					>
						{manageDropdownsTabContent.tabs.map((tab) => (
							<a
								className={`nav-link text-center ${tabInFocus.id === tab.id ? 'active' : ''}`}
								href="!#"
								role="tab"
								onClick={(e) => onTabClick(e, tab.id)}
							>
								{tab.tabTitle}
							</a>
						))}
					</div>
				</div>

				{/* Start Tab Content */}
				<div className="col-md-8">
					<div className="details-tabs-content">
						<div className="d-flex flex-row flex-wrap align-items-center justify-content-between mb-4">
							<h5 className="mb-0 text-left mb-3 mb-md-0">{tabInFocus.tabContentTitle}</h5>
							{AuthService.checkIfUserHasPermission(
								[Roles.Admin, Roles.ASP, Roles.SuperAdmin],
								currentUser.role
							) && (
								<button
									className="btn btn-aqua-blue pt-0 pb-0 ml-auto pt-0 pb-0 ml-auto"
									onClick={() => onAddNewItemClick(tabInFocus.objKey)}
								>
									Add an option
								</button>
							)}
						</div>
						{tabInFocus?.tabContentDescription || null}
						{React.useMemo(
							() => (
								<DataTable
									thead={tabInFocus.objKey === 'SecurityQuestions' ? manageOptionsTableContentSQ.thead : manageOptionsTableContent.thead}
									tbody={
										tableData && tableData[tabInFocus.objKey]
											? tableData[tabInFocus.objKey]
													.map((option) => ({
														id: option.id,
														name: option.name,
													}))
													.sort((a, b) => a.name.localeCompare(b.name))
											: []
									}
									ignoreCols={[0]}
									isLoading={isLoading}
									tableClass={'table-info--notFixed'}
								>
									{(_id, rowItem: IVocabulary) => (
										<>
											<td className="aling-middle">
												<div>
													<button
														className={`btn ${
															AuthService.checkIfUserHasPermission(
																[Roles.Admin, Roles.ASP, Roles.SuperAdmin],
																currentUser.role
															)
																? 'btn-aqua-blue'
																: 'btn-disabled'
														}`}
														onClick={() => onActionColClick(rowItem, 'edit')}
													>
														<SVGPencil />
													</button>
												</div>
											</td>

											<td className="aling-middle">
												<div>
													<button
														className={`btn ${
															AuthService.checkIfUserHasPermission(
																[Roles.Admin, Roles.ASP, Roles.SuperAdmin],
																currentUser.role
															)
																? 'btn-aqua-blue'
																: 'btn-disabled'
														}`}
														onClick={() => onActionColClick(rowItem, 'delete')}
													>
														<SVGCancel />
													</button>
												</div>
											</td>
										</>
									)}
								</DataTable>
							),
							[isLoading, tableData, tabInFocus]
						)}
					</div>
				</div>

				{showAddNewItemModal && (
					<AddNewItemModal
						showModal={showAddNewItemModal}
						titleBody={
							isEditMode
								? `Update this option for <span class="font-weight-bold">${tabInFocus.tabContentTitle}</span>`
								: tabInFocus.modalTitle
						}
						placeholderText={tabInFocus.modalPlaceholder}
						onCancel={() => {
							setIsEditMode(false);
							onAddNewItemModalClose();
						}}
						onSubmit={(newItem) =>
							isEditMode
								? onEditItemSubmit(newItem)
								: onAddNewItemSubmit({ itemName: newItem }, onNewOptionAdded)
						}
						optionToEdit={isEditMode ? optionInFocus : null}
						note={
							isEditMode
								? tabInFocus.objKey === AddNewItemEnum.SecurityQuestionsForGuards
									? 'If questions are updated, Guards that have used this question will see the new text immediately. Please make sure that if you update this question, any answers already given by Guards will still make sense to them.'
									: 'Updating this option will appear in all future selections. Any previous selections will show the previous option text.'
								: undefined
						}
					/>
				)}

				{modals.confirm && (
					<BaseModal
						show={modals.confirm}
						cancelBtnText={'Close'}
						onCancel={() => setModals({ ...modals, confirm: false })}
						onSubmit={() => {
							onSubmitRemoveClick(modals.itemFocus);
						}}
						submitBtnText={'Confirm'}
					>
						<h4 className="mb-4" dangerouslySetInnerHTML={{ __html: modals.successTitle }} />
						{modals.note ? <p className="mb-0">{modals.note}</p> : null}
					</BaseModal>
				)}

				{modals.showSuccess && (
					<BaseModal
						show={modals.showSuccess}
						cancelBtnText={'Close'}
						onCancel={() => {
							setModals({ ...modals, showSuccess: false });
						}}
					>
						<h4 className="mb-4" dangerouslySetInnerHTML={{ __html: modals.successTitle }} />
					</BaseModal>
				)}
			</div>
		</Layout>
	);
};

export default ManageDropdownOptions;
