import React from 'react'
import {Link} from 'react-router-dom'
import RequestsToDeactivateJobs from '../../components/jobs/job-requests/RequestsToDeactivateJobs'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'

const DeactiveJobs = () => {
    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Jobs',
                        link: 'jobs'
                    },
                ],
                currentPageTitle: 'Deactivate Jobs'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row align-items-center justify-content-between flex-wrap">
                    <SectionTitle title="Deactivation Requests for Jobs"/>
                    <Link to="/jobs/deactivated" className="btn btn-outline-aqua-blue ml-0 ml-sm-3 mt-2 mt-sm-0">View
                        inactive jobs</Link>
                </div>
            </div>


            <div className="row">

                <div className="col-12">
                    <RequestsToDeactivateJobs/>
                </div>
            </div>

        </Layout>
    )
}

export default DeactiveJobs
