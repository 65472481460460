import { NotificationTypes } from 'common/interfaces/INotification';
import { IQueryParams } from 'common/interfaces/IUrlBuilder';
import ResourceDataService, { IResourceData } from 'common/services/ResourceDataService';
import AppContext from 'context/AppContext';
import { useContext, useEffect, useState } from 'react';

type Model = {
	[index: string]: any;
};

const useMultiTableList = <T extends Model>(url: string, loadData: boolean = true) => {
	const [isLoading, setLoading] = useState<boolean>(true);
	const context = useContext(AppContext);
	const [tablesData, setTablesData] = useState<T>({} as T);
	const fetchData = async (
		returnData = false,
		handleLoader = true
	) => {
		handleLoader && setLoading(prev => !prev ? true : false);
		const tableSvc = new ResourceDataService<T>({ url });
		try {
			let result: IResourceData<T>;

			result = await tableSvc.getAll();

			setTablesData(result.data);
			handleLoader && setLoading(false);
			if (returnData) {
				return result.data;
			} else {
				return;
			}
		} catch (e: any) {
			handleLoader && setLoading(false);
			setTablesData({} as T);
			context.showNotification(NotificationTypes.danger, e.message);
			return;
		}
	};
	useEffect(() => {
        loadData && fetchData()
	}, [url, loadData]);

	return { tablesData, setTablesData, fetchData, isLoading };
};

export default useMultiTableList;
