type SearchModel = {
    [index: string]: any
}

export const searchInArrayBy = <T extends SearchModel>(arr: T[], fieldNames: string[], searchValue: string, min: number = 1) => {
    if (searchValue.length >= min) {
        const result = arr.filter(obj => {
            for (let fieldName of fieldNames) {
                if (obj[fieldName].toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase())) {
                    return obj;
                }
            }
        })
        return result;
    } else {
        return arr
    }
}

export const searchInArray = <T extends SearchModel>(arr: T[], fieldNames: string[], searchValue: string, min: number = 1) => {
    if (searchValue.length >= min) {
        const result = arr.filter(obj => {
            let strArr: string[] = [];
            for (let fieldName of fieldNames) {
                if (obj[fieldName]) {
                    strArr.push(obj[fieldName].trim());
                }
            }
            if (strArr.join(' ').toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))  {
                return obj;
            }
        })
        return result;
    } else {
        return arr
    }
}
