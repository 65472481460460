export const deactivatedUsersContent = {
    thead: [
        {
            label: "Full Name",
        },
        {
            label: "User Type",
        },
        {
            label: "Deactivation Date",
        },
        {
            label: "Reason for Deactivation",
        },
        {
            label: "Requested By",
        },
        {
            label: "Request Date",
        },
        {
            label: "Deactivation Approved By",
        },
        {
            label: "Reactivate User",
        }
    ],
    tbody: [
        {
            id: "id",
            fullNameLink: "John Doe User",
            role: 'Client',
            deactivationDate: "2021-01-18T10:48:39.078Z",
            reason: 'Other reason',
            deactivationApprovedBy: "Peter Crouch",
            requestedDate: "2021-01-18T10:48:39.078Z" ,
            requestedBy: "Peter Crouch",
        },
        {
            id: "id2",
            fullNameLink: "John Doe User2",
            role: 'Client',
            deactivationDate: "2021-01-18T10:48:39.078Z",
            reason: 'Other reason',
            deactivationApprovedBy: "Peter Crouch",
            requestedDate: "2021-01-18T10:48:39.078Z" ,
            requestedBy: "Peter Crouch",
        },
        {
            id: "id3",
            fullNameLink: "John Doe User3",
            role: 'ASP User',
            deactivationDate: "2021-01-18T10:48:39.078Z",
            reason: 'Other reason',
            deactivationApprovedBy: "Peter Crouch",
            requestedDate: "2021-01-18T10:48:39.078Z" ,
            requestedBy: "Peter Crouch",
        },
    ]
}