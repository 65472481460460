import { setHoursAndMinutes } from "../../utils/DateFormatting";
import { IJob } from "../interfaces/jobs/IJob";
import { GuardAvailability } from './Guard';

export class JobSchedules extends GuardAvailability {
    shiftPeriod = '42'
    assigned = false
    guardId = null
    assignedGuard = ""
    jobName = ""
    setHolidayDefault = true
    scheduleHasBeenChanged = false
}

export class JobSchools {
    uid = 1;
    schoolId = null;
    schoolBoardId = null;
}

export class Job implements IJob {
    firstStreet = "";
    secondStreet = "";
    // schoolYearId = 1;
    schoolYearId = null;
    schoolYearDate = setHoursAndMinutes(new Date());
    cityId = 0;
    zoneId = null;
    jobId = "";
    geoFence = 100;
    latitude = null;
    longititude = null;
    comments = "";
    phone = "";
    email = "";
    jobSchedules = [new JobSchedules()];
    schools = [new JobSchools()];
    guardReports = []
    assignedSchedues = []
    assignedShits = []
    shiftsInNeedOfGuards = []
    jobHolidays = []
    guardsAssigned = 0
    jobName?: string;
}

export class JobDetailsModel extends Job {
    clientName = ""
    contactName = ""
    city = ""
    zone = ""
    guardsCount = null
    jobStartDate = ""
    jobEndDate = ""
    assignedSchedues = []
    assignedShits = []
    shiftsInNeedOfGuards = []
}

export interface IActiveJob {
    [index: string]: any
    jobNameLink?: string
    jobId: string,
    id: number,
    activeSchedules: number
    activeSchedulesUnasssigned: number
    assignedGuards: number
    endsOn: string | null
    jobName: string
    latesOrAbsences: number
    geoFence: number
    location: { latitude: string, longitude: string, longititude: string }
    startDate: string | null
    zone: string
}
