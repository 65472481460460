import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {SVGCancel, SVGEraser} from '../../../assets/icons/SvgIcons'
import {manageDevicesContent} from '../../../content/administration/AdministrationContent'

import {IGuardDevice} from '../../../common/interfaces/guards/IGuard'
import {VocabularyEnum} from "../../../common/enums/VocabularyEnum"

import Layout from '../../../components/layout/Layout'
import BaseModal from '../../../components/modal/BaseModal'
import DataTable from '../../../components/tables/DataTable'
import SectionTitle from '../../../components/titles/SectionTitle'
import RemoveBaseModal from "../../../components/modal/administration/RemoveBaseModal"

import {useListFilter} from '../../../customHooks/useListFilter'
import {useTableList} from '../../../customHooks/useTableList'
import {momentDateFormat} from '../../../utils/DateFormatting'
import useActivateDeactivate from "../../../customHooks/useActivateDeactivate"
import {ActivationDeactivation} from "../../../common/enums/Actions"
import {matchOptionWithName} from "../../../utils/MatchOptionWithName"
import {useVocabulary} from "../../../customHooks/vocabulary/useVocabulary"
import ReportActions from 'components/reports/ReportActions'

const ManageDevices = () => {
    const {tableData, isLoading, fetchData, onClearFilterQuery, setTableData, onSortCall} = useTableList<IGuardDevice>('device/assigned')
    const {generateFilterQuery, filter, onFilterSearchClearClick, onSearchChange} = useListFilter("device/assigned")
    const {vocabulary, getVocabulary} = useVocabulary(VocabularyEnum.deviceBrand, true)
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false)
    const {onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus, onSuccessResponse} = useActivateDeactivate('device');

    const handleSubmitSearch = () => {
        setSearchIsActive(true)
        fetchData(1, 0, generateFilterQuery(false, true, filter.keyWord), false, true)
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => event.key === 'Enter' && handleSubmitSearch()
    
    return (
        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Administration',
                    link: 'administration'
                },
                    {
                        title: 'Users',
                        link: 'administration/manage-users'
                    }
                ],
                currentPageTitle: 'Manage Devices'
            }}
        >
            <div className="row">
                <div className="col-12 mb-4 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Assigned Devices"/>
                    <Link to="/administration/devices/unassigned"
                          className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View unassigned devices</Link>
                </div>
            </div>

            <div className="row">

                <div className="col-12">

                    <form className="filters-form-group mb-4">
                        <div className="row">
                            <div className="col-12">
                                <h5>Search for a Guard or Device</h5>
                            </div>
                            <div className="col-12">
                                <div className="d-flex flex-row justify-content-start">
                                    <div className="form-group ml-0 mr-2 mb-0">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={filter.keyWord}
                                            placeholder={'Enter keyword(s)'}
                                            onChange={e => onSearchChange(e.target.value, "keyWord")}
                                            onKeyPress={(e) => handleKeyPress(e)}
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-aqua-blue"
                                        onClick={handleSubmitSearch}>
                                        Search
                                    </button>
                                    {
                                        filter.keyWord !== "" &&
                                        <button
                                            type="button"
                                            className="btn btn-aqua-blue ml-2"
                                            onClick={() => {
                                                setSearchIsActive(false);
                                                onFilterSearchClearClick()
                                                onClearFilterQuery()
                                            }}>
                                            <SVGEraser/>
                                        </button>
                                    }
                                </div>
                            </div>

                        </div>
                    </form>
                    <div className='mb-4 d-flex justify-content-end'>
                        <ReportActions
                            className="mr-3 mr-md-0 mt-2  mt-lg-0"
                            page="assigneddevices"
                            hidePrint
                            hidePdf
                            showForShiftReport={false}
                        />
                    </div>

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={manageDevicesContent.thead}
                                tbody={tableData ?
                                    tableData.map(item => ({
                                        id: item.id,
                                        fullName: `${item.firstName} ${item.lastName}`,
                                        employeeId: item.employeeId ? `<a href="#/guards/${item.guardId}/details">${item.employeeId}</a>` : '--',
                                        fullNameLink: `<a href="#/guards/${item.guardId}/details">${item.firstName} ${item.middleName} ${item.lastName}</a>`,
                                        brand: vocabulary && matchOptionWithName(item.brand, vocabulary) + ` ${item.model}`,
                                        serialNumber: item.serialNumber || "-",
                                        aspMobile: item.aspMobile || "-",
                                        assignDate: momentDateFormat(item.assignDate as string),
                                    }))
                                    : []}
                                ignoreCols={[0, 1]}
                                defaultSortedColumn={1}
                                isLoading={isLoading}
                                tableClass={'table-info--notFixed'}
                                searchIsActive={searchIsActive}
                                onSort={(orderBy, desc) => onSortCall(orderBy, desc)}
                                sortOnBackend
                            >
                                {
                                    (_id, rowItem: IGuardDevice) => (
                                        <>
                                            <td className="aling-middle">
                                                <div>
                                                    <button
                                                        className={`btn btn-aqua-blue`}
                                                        onClick={() => {
                                                            !rowItem.model && onRequestHandler({
                                                                title: `Are you sure you want to unassign <span class="font-weight-bold">${rowItem.brand}</span> from 
                                                            <span class="font-weight-bold">${rowItem.fullName}</span>
                                                            ?`,
                                                                itemInFocus: rowItem
                                                            })
                                                        }}
                                                    >
                                                        <SVGCancel/>
                                                    </button>
                                                </div>
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        ), [tableData, isLoading, getVocabulary, vocabulary])
                    }
                </div>
            </div>


            {
                requestModals.showRequest &&
                <RemoveBaseModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={() => onRequestSubmitHandler(ActivationDeactivation.unassign, {
                        itemData: null,
                        successCallback: id => setTableData(onSuccessResponse<IGuardDevice>(id, tableData))
                    })}
                    title={modalText.title}
                >
                </RemoveBaseModal>
            }

            {
                requestModals.showSuccess &&
                <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                    title={`<span class="font-weight-bold">${(itemInFocus as IGuardDevice).brand}</span> has been unassigned from
                        <span class="font-weight-bold">${(itemInFocus as IGuardDevice).fullName}</span>`}
                >
                </BaseModal>
            }

        </Layout>
    )
}

export default ManageDevices
