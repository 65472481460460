import {FC, useEffect, useRef} from 'react'
import {useContext} from 'react'
import JobContext from '../../../context/jobs/JobContext'
import {useHistory, useParams} from 'react-router-dom'
import WizardFormContext from '../../../context/jobs/WizardFormContext'
// import GeofenceMap from '../GeofenceMap'
// import CustomTooltip from '../../custom-tooltip/CustomTooltip'
// import {SVGInfo} from '../../../assets/icons/SvgIcons'
// import AppContext from '../../../context/AppContext'
// import {NotificationTypes} from '../../../common/interfaces/INotification'
import ScheduleForm from "../../schedule/ScheduleForm";
import { setHoursAndMinutes } from 'utils/DateFormatting'
import moment from 'moment'
import { useSchoolYear } from 'customHooks/useSchoolYear'

type Props = {
    onHolidayChange: (holidayId: number, yesNoValue: boolean, scheduleId: number) => void
    onRemoveItemFromCalendar: (scheduleId: number) => void
    activeScroll: boolean
}

const AssignSchedulesTab: FC<Props> = ({onHolidayChange, onRemoveItemFromCalendar, activeScroll}) => {
    const context = useContext(JobContext)
    // const appContext = useContext(AppContext)
    const {job, onChange, onScheduleChanged, onScheduleSubmit} = context
    const {stepHandler} = useContext(WizardFormContext)
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const tabsRef = useRef(null)
    const {getYearById, getSchoolYears, schoolYearsData} = useSchoolYear()
    useEffect(() => {
        if (params.id !== undefined) {
            job.schools.length !== 0 && job.schools.forEach((school, indx) => {
                school.uid = indx + 1
            })
        }
    }, [])

    useEffect(() => {
        onChange("", "repeatOn")
        onChange(setHoursAndMinutes(moment().format()), 'endsOn')
        getSchoolYears(undefined, undefined, true)
    }, [])

    useEffect(() => {
        if (activeScroll) {
            checkDefaultTabFromQuery()
        }
    }, [activeScroll])


    const checkDefaultTabFromQuery = () => {
        const element = tabsRef.current
        const topPos = (element && (element as HTMLElement).getBoundingClientRect().top + window.pageYOffset) || 0;
        window.scrollTo({top: topPos})
    }
    // const onGeofenceBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    //     if (+ev.target.value < 25) {
    //         appContext.showNotification(NotificationTypes.warning, "Geofence can't be less than 25m")
    //         context.onChange("25", "geoFence")
    //     } else if (+ev.target.value > 150) {
    //         appContext.showNotification(NotificationTypes.warning, "Geofence can't be greater than 150m")
    //         context.onChange("150", "geoFence")
    //     } else {
    //         context.onChange(ev.target.value)
    //     }
    // }

    return (
        <>

            {/* <div className="row">

                <div className="col-md-10 mx-auto">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group d-flex flex-column align-items-start">
                                <label className="required" htmlFor="geoFence">Geo Fence</label>
                                <div className="d-flex align-self-stretch">
                                    <input type="number" min={5} name="geoFence" className="form-control" id="geoFence"
                                           placeholder="Enter radius in meters" autoComplete="nope"
                                           value={job.geoFence as number}
                                           onChange={e => context.onChange(e)}
                                           onBlur={ev => onGeofenceBlur(ev)}
                                    />
                                    <CustomTooltip
                                        bodyText={"This is the area around the location which will be used to detect the Guard's presence. Min 25m to Max 150m."}>
                                        <button
                                            type="button"
                                            className="btn btn-transparent px-0 ml-3"
                                        >
                                            <SVGInfo/>
                                        </button>
                                    </CustomTooltip>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="geoFence">Comments (optional)</label>
                                <textarea name="comments" className="form-control" id="comments"
                                          placeholder="Enter primary street name" autoComplete="nope" rows={5}
                                          value={job.comments}
                                          onChange={e => context.onChange(e)}
                                />
                            </div>
                        </div>

                        <div className="col-md-7">
                            <GeofenceMap
                                lat={job.latitude !== null ? +job.latitude : 0}
                                lng={job.longititude !== null ? +job.longititude : 0}
                                radius={job.geoFence ? +job.geoFence : 0 as number}
                            />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row">
                <div className="col-md-10 mx-auto py-2 py-md-4" ref={tabsRef}>
                    <h5 className="mb-4">Set Job Schedule</h5>
                    {
                        job.jobSchedules.length !== 0 && job.jobSchedules.map((item, index) =>
                            <ScheduleForm
                                key={item.uid ? item.uid : item.id}
                                onChange={(event, fieldName) => onScheduleChanged(event, fieldName, item.uid ? item.uid : item.id)}
                                data={item}
                                showShiftPeriod={true}
                                subtitle={`${index + 1}. Schedule`}
                                hideEndDate={true}
                                trackHolidays={(holidayId: number, yesNoValue: boolean) =>
                                    onHolidayChange(holidayId, yesNoValue, item.uid ? item.uid : item.id as number)
                                }
                                onRemoveItemFromCalendar={scheduleId => onRemoveItemFromCalendar(scheduleId)}
                                holidaysTitle={`There is/are holiday(s) that fall within this job schedule. Do you want to <span class="font-weight-bold">remove holiday(s)</span> from job schedule?`}
                                schoolYear={job.schoolYearDate ? job.schoolYearDate : (job.schoolYear ? job.schoolYear.split('-').pop().trim() : undefined)}
                                tooltipDates={"Select dates during which the scheduled times should be part of this job."}
                                tooltipHours={"Select the actual shift times that should be repeated for this schedule. Start time should not include the ASP buffer time before the shift."}
                                tooltipRepeatOn={"Select the days of the week during which this schedule should be repeated each week within selected dates."}
                                schoolYearData={getYearById(schoolYearsData, job.schoolYearId || 0) || undefined}
                            />
                        )
                    }
                    <button
                        type="button"
                        className="btn btn-outline-aqua-blue text-dark-lighter"
                        onClick={() => onScheduleSubmit()}>
                        Add another schedule for job
                    </button>
                </div>

                <div className="col-md-10 mx-auto d-flex justify-content-lg-between mt-5 bottom-nav-card">
                    <div className="d-flex flex-row">
                        <button type="button" className="btn btn-outline-aqua-blue px-4 mr-2"
                                onClick={() => stepHandler()}>
                            Back
                        </button>
                        <button type="button" className="btn btn-outline-aqua-blue px-4"
                                onClick={() => history.push('/jobs')}>
                            Cancel
                        </button>
                    </div>
                    <button type="button" className="btn btn-aqua-blue px-4 ml-auto" onClick={() =>stepHandler(true)}>
                        Next
                    </button>
                </div>
            </div>

        </>
    )
}

export default AssignSchedulesTab