import React, { FC, useContext, useState } from 'react';
import InputMask from 'react-input-mask';

import Layout from '../../components/layout/Layout';
import SectionTitle from '../../components/titles/SectionTitle';

import { IClient, IClientContact } from '../../common/interfaces/clients/IClient';
import { Client } from '../../common/models/Client';

import { useOnIputChange } from '../../customHooks/useOnInputChange';
import {
	highlightError,
	removeHighlightError,
	validateEmail,
	validateFiled,
	validatePhoneNumber,
} from '../../utils/Validation';
import CustomSelectControl from '../../components/custom-select/CustomSelectControl';
import { Link, RouteComponentProps } from 'react-router-dom';
import AddEditUserModal from '../../components/modal/users/AddEditUserModal';
import { useEffect } from 'react';
import { IValidationBody, ValidationRules } from '../../common/interfaces/IValidation';
import { NotificationTypes } from '../../common/interfaces/INotification';
import AppContext from '../../context/AppContext';
import { DataService } from '../../common/services/DataService';
import useSaveClientContact from '../../customHooks/useSaveClientContact';
import { useTenant } from 'customHooks/useTenant';
import ImageUpload from 'components/upload/ImageUpload';
import { OptionTypeBase } from 'react-select';
import PlacesAutocomplete from 'components/places-autocomplete/PlacesAutocomplete';
import ResourceDataService from 'common/services/ResourceDataService';
import { ICityDropdown } from 'common/interfaces/dropdowns/ICityDropdown';
import { AddNewItemEnum } from 'common/enums/AddNewItemEnum';
import AddNewItemModal from 'components/modal/AddNewItemModal';
import { useAddNewItem } from 'customHooks/useAddNewItem';
import { matchOptionWithName } from 'utils/MatchOptionWithName';
import useCountryField from 'customHooks/useCountryField';
import { LoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';

const libraries = ["places"]

const AddClient: FC<RouteComponentProps> = (props) => {
	const [modals, setModals] = useState<{ add: boolean }>({ add: false });
	const [clientId, setClientId] = useState<number | null>(null);
	const { hookState, onChange, setHookState } = useOnIputChange<IClient>(new Client());
	
	const [cities, setCities] = useState<ICityDropdown[]>([]);
	const { saveClientContact } = useSaveClientContact();
	const appContext = useContext(AppContext);
	const {
		countries,
		populateAddressFields,
		setCountryName,
		findCountyNameById,
		countryName,
		currentStates,
		postalCodeMask,
	} = useCountryField();
	const formData = hookState;
	const clientSvc = new DataService<IClient>({ url: 'client' });
	const citySvc = new ResourceDataService<ICityDropdown[]>({ url: 'city/dropdown' });
	const {
		showAddNewItemModal,
		newItemModalTypo,
		onAddNewItemSubmit,
		onAddNewItemModalClose,
		currentItemType,
	} = useAddNewItem();
	const getCityList = async () => {
		try {
			const resCities = await citySvc.getAll();
			setCities(resCities.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getCityList();
	}, []);

	const addNewItemToDropdown = (option: any, type: AddNewItemEnum) => {
		switch (type) {
			case AddNewItemEnum.city:
				setCities((prev) =>
					[
						...prev,
						{
							id: option.id,
							name: option.cityName,
							zoneViewModels: [],
						},
					].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
				);
				onChange([{ value: option.id, name: 'city' }]);
				break;
			default:
				break;
		}
	};

	const validateClientFields = (callback: () => Promise<void>) => {
		let errors: IValidationBody[] = [];
		console.log(formData);
		for (let [field, value] of Object.entries(formData)) {
			const skipFields = [
				'secondAddress',
				'id',
				'primaryContactName',
				'tenantId',
				'aspStartDate',
				'contactPhoneNumber',
				'contactMiddleName',
				'contactMobileNumber',
				'organizationLogo',
				'email',
				'phoneNumber',
				'timeZone',
			];
			if (!skipFields.includes(field)) {
				console.log(errors)
				errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)];
			}
		}

		errors = [
			...errors,
			...validateFiled(formData.contactPhoneNumber as string, 'contactPhoneNumber', ValidationRules.isValidPhone),
			...validateFiled(
				formData.contactMobileNumber as string,
				'contactMobileNumber',
				ValidationRules.isValidPhone
			),
		];
		errors = [
			...errors,
			...validateFiled(
				formData.contactEmailAddress as string,
				'contactEmailAddress',
				ValidationRules.isValidEmail
			),
		];
		errors = [
			...errors,
			...validateFiled(formData.postalCode as string, 'postalCode', ValidationRules.isValidPostalCode),
		];
		console.log(errors)
		if (errors.length !== 0) {
			appContext.showNotification(NotificationTypes.warning, 'Please fill in all fields correctly');
			highlightError(errors);
		} else {
			removeHighlightError();
			callback();
		}
	};

	const saveClient = async (redirectOnSuccess = true, callback?: (clientId?: number) => void) => {
		appContext.showLoader(true);
		try {
			const response = await clientSvc.create(formData);

			setClientId(response.data);

			appContext.showNotification(NotificationTypes.success, `${formData.organizationName} has been created`);
			redirectOnSuccess && props.history.push(`/clients/add/${response.data}/confirmation`);
			!redirectOnSuccess && callback && callback(response.data);

			appContext.showLoader(false);
		} catch (error: any) {
			appContext.showNotification(NotificationTypes.danger, error.message);
			appContext.showLoader(false);
		}
	};

	const saveContact = async (redirectOnSuccess = true, clientContact: IClientContact, client: number | null) => {
		saveClientContact(clientContact, client, () => {
			redirectOnSuccess && props.history.push('/clients');
			redirectOnSuccess && document.location.reload();
		});
	};

	const populateFields = async (response: OptionTypeBase) => {
		populateAddressFields(response, (data) =>
			setHookState((prev) => ({
				...prev,
				...data,
			}))
		);
	};

	useEffect(() => {
		formData.country  && countries.length && setCountryName(matchOptionWithName(formData.country, countries)?.toString() || 'Canada');
		setHookState((prev) => ({
			...prev,
			clientCountry: formData.country || '',
			firstAddress: '',
			postalCode: '',
			province: '',
			city: '',
		}));
	}, [formData.country, countries]);

	return (
		<Layout
			breadcrumbs={{
				links: [
					{
						title: 'Clients',
						link: 'clients',
					},
				],
				currentPageTitle: 'Add a Client',
			}}
		>
			<div className="row mb-3">
				<div className="col-12">
					<SectionTitle title={'Add a Client'} />
				</div>
			</div>

			<LoadScript libraries={libraries as Libraries} googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}>

			
			<section className="row">
				<div className="col-lg-11 col-xl-9 mx-auto">
					<form autoComplete="new-password">
						<div className="row">
							<div className="form-group col-md-4">
								<ImageUpload
									image={formData.organizationLogo || ''}
									onChange={(value) => onChange(value, 'organizationLogo')}
								/>
							</div>
							<div className="form-group col-md-3">
								<label className="required" htmlFor="organizationName">
									Client Organization Name
								</label>
								<input
									type="text"
									name="organizationName"
									className="form-control"
									id="organizationName"
									placeholder="Enter client organization name"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.organizationName}
									onChange={(e) => onChange(e)}
								/>
							</div>
							<div className="form-group col-md-3">
								<label className="required" htmlFor="country">
									Country
								</label>
								<CustomSelectControl
									options={countries}
									onChange={(value) => onChange(value, 'country')}
									value={formData.country}
									placeholder={'Select Country'}
									className={`custom-react-select--province`}
								/>
							</div>
							<div className="form-group col-md-2">
								<label className="required" htmlFor="country">
									Institution Code
								</label>
								<InputMask
									mask={'***'}
									type="text"
									name="institutionCode"
									className="form-control"
									id="institutionCode"
									placeholder="Enter 3 character code"
									autoComplete="new-password"
									value={formData.institutionCode}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
									onBlur={(e) => validatePhoneNumber(e)}
								/>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="form-group col-md-4 align-self-end">
										<label className="required" htmlFor="firstAddress">
											Address 1
										</label>
										<PlacesAutocomplete
											placeholder={'Enter address 1'}
											onChange={async (option, response) => {
												response && (await populateFields(response));
												onChange(option, 'firstAddress');
											}}
											types={'address'}
											restrictions={
												formData.country
													? {
															country:
																matchOptionWithName(formData.country, countries) ===
																'Canada'
																	? 'CA'
																	: 'US',
													  }
													: undefined
											}
											selectedValue={formData.firstAddress}
										/>
									</div>
									<div className="form-group col-md-4">
										<label htmlFor="secondAddress">Address 2</label>
										<input
											type="text"
											name="secondAddress"
											className="form-control"
											id="secondAddress"
											placeholder="Enter address 2"
											autoComplete="new-password"
											spellCheck="false"
											autoCapitalize="none"
											autoCorrect="off"
											value={formData.secondAddress}
											onChange={(e) => onChange(e)}
										/>
									</div>
									<div className="form-group col-md-4">
										<div className="add-item-group d-flex flex-row align-items-center justify-content-between">
											<label className="required" htmlFor="cityName">
												City
											</label>
										</div>
										<input
											type="text"
											name="city"
											className="form-control"
											id="cityName"
											placeholder="Enter city"
											autoComplete="new-password"
											spellCheck="false"
											autoCapitalize="none"
											autoCorrect="off"
											value={formData.city}
											onChange={(e) => onChange(e)}
										/>
									</div>
								</div>
							</div>

							<div className="form-group col-md-4">
								<label className="required" htmlFor="province">
									Province/State
								</label>

								<CustomSelectControl
									options={currentStates || []}
									onChange={(value) => onChange(value, 'province')}
									value={formData.province}
									placeholder={'Select a Province/State'}
									className={`custom-react-select--province`}
								/>
							</div>

							<div className="form-group col-md-4">
								<label className="required" htmlFor="postalCode">
									Postal/Zip Code
								</label>
								<InputMask
									mask={postalCodeMask}
									type="text"
									name="postalCode"
									className="form-control"
									id="postalCode"
									placeholder="Enter postal/zip code"
									autoComplete="new-password"
									value={formData.postalCode}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
									onBlur={(e) => validatePhoneNumber(e)}
								/>
							</div>

							<div className="col-12">
								<div className="row">
									<div className="form-group col-md-4">
										<label htmlFor="phoneNumber">Company Phone Number</label>
										<InputMask
											mask={'999-999-9999'}
											type="text"
											name="phoneNumber"
											className="form-control"
											id="phoneNumber"
											placeholder="Enter company phone number"
											autoComplete="new-password"
											spellCheck="false"
											autoCapitalize="none"
											autoCorrect="off"
											value={formData.phoneNumber}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
											onBlur={(e) => validatePhoneNumber(e)}
										/>
									</div>

									<div className="form-group col-md-4">
										<label htmlFor="email">Company Email address</label>
										<input
											type="email"
											name="email"
											className="form-control"
											id="email"
											placeholder="Enter company email address"
											autoComplete="new-password"
											spellCheck="false"
											autoCapitalize="none"
											autoCorrect="off"
											value={formData.email ? formData.email : ''}
											onChange={(e) => onChange(e)}
											onBlur={(e) => validateEmail(e)}
										/>
									</div>
								</div>
							</div>
							<div className="col-12 mt-3 mb-2">
								<h6>Add Primary Contact</h6>
							</div>

							<div className="form-group col-md-4">
								<label className="required" htmlFor="contactFirstName">
									First Name
								</label>
								<input
									type="text"
									name="contactFirstName"
									className="form-control"
									id="contactFirstName"
									placeholder="Enter first name"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactFirstName}
									onChange={(e) => onChange(e)}
								/>
							</div>

							<div className="form-group col-md-4">
								<label htmlFor="contactMiddleName">Middle Name</label>
								<input
									type="text"
									name="contactMiddleName"
									className="form-control"
									id="contactMiddleName"
									placeholder="Enter middle name"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactMiddleName}
									onChange={(e) => onChange(e)}
								/>
							</div>

							<div className="form-group col-md-4">
								<label className="required" htmlFor="contactLastName">
									Last Name
								</label>
								<input
									type="text"
									name="contactLastName"
									className="form-control"
									id="contactLastName"
									placeholder="Enter last name"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactLastName}
									onChange={(e) => onChange(e)}
								/>
							</div>

							<div className="form-group col-md-4">
								<label className="required" htmlFor="contactPosition">
									Position/Title
								</label>
								<input
									type="text"
									name="contactPosition"
									className="form-control"
									id="contactPosition"
									placeholder="Enter position/title"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactPosition}
									onChange={(e) => onChange(e)}
								/>
							</div>

							<div className="form-group col-md-4">
								<label htmlFor="contactPhoneNumber">Phone Number</label>
								<InputMask
									mask={'999-999-9999'}
									type="text"
									name="contactPhoneNumber"
									className="form-control"
									id="contactPhoneNumber"
									placeholder="Enter phone number (000-000-0000)"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactPhoneNumber}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
									onBlur={(e) => validatePhoneNumber(e)}
								/>
							</div>

							<div className="form-group col-md-4">
								<label htmlFor="contactMobileNumber">Mobile Number</label>
								<InputMask
									mask={'999-999-9999'}
									type="text"
									name="contactMobileNumber"
									className="form-control"
									id="contactMobileNumber"
									placeholder="Enter mobile number (000-000-0000)"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactMobileNumber}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
									onBlur={(e) => validatePhoneNumber(e)}
								/>
							</div>

							<div className="form-group col-md-4">
								<label className="required" htmlFor="contactEmailAddress">
									Email address
								</label>
								<input
									type="email"
									name="contactEmailAddress"
									className="form-control"
									id="contactEmailAddress"
									placeholder="Enter email address"
									autoComplete="new-password"
									spellCheck="false"
									autoCapitalize="none"
									autoCorrect="off"
									value={formData.contactEmailAddress ? formData.contactEmailAddress : ''}
									onChange={(e) => onChange(e)}
									onBlur={(e) => validateEmail(e)}
								/>
							</div>
						</div>
					</form>
				</div>
				<div className="col-lg-11 col-xl-9 mx-auto d-flex flex-wrap py-2 py-md-5 justify-content-between">
					<Link className="btn btn-outline-aqua-blue mt-2" to="/clients">
						Cancel
					</Link>
					<div>
						<button
							type="submit"
							className="btn btn-aqua-blue mt-2"
							onClick={() => validateClientFields(() => saveClient())}
						>
							Save Client
						</button>
					</div>
				</div>
			</section>
			</LoadScript>
			{modals.add && (
				<AddEditUserModal
					onCancel={() => props.history.push('/clients')}
					onSubmit={(data) => saveContact(true, data as IClientContact, clientId)}
					title={`Add a Client Contact User for <span class="font-weight-bold">${formData.organizationName}</span>`}
					isClient={true}
					clientAlreadySet
					submitBtnText={'Save and Send Invitation to Set Password'}
				/>
			)}
			{showAddNewItemModal && (
				<AddNewItemModal
					titleBody={newItemModalTypo.title}
					placeholderText={newItemModalTypo.placeholder}
					showModal={showAddNewItemModal}
					onCancel={() => onAddNewItemModalClose()}
					onSubmit={(newItem) => onAddNewItemSubmit({ itemName: newItem }, addNewItemToDropdown)}
					allowClearForm={currentItemType === AddNewItemEnum.city ? true : false}
					allowPrediction={currentItemType === AddNewItemEnum.city ? true : false}
					citiesWithoutState
					country={countryName}
				/>
			)}
		</Layout>
	);
};

export default AddClient;
