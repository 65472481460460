import { IBaseModalProps } from 'common/interfaces/IModal'
import { IGuardTest, IGuardTestReportTest } from 'common/interfaces/reports/xguard-reports/IGuardTest'
import CustomSelectControl from 'components/custom-select/CustomSelectControl'
import { useOnIputChange } from 'customHooks/useOnInputChange'
import {FC, memo, useContext} from 'react'
import { momentDateFormat } from 'utils/DateFormatting'
import BaseModal from '../BaseModal'
import {NotificationTypes} from '../../../common/interfaces/INotification';
import {validateFiled, highlightError, removeHighlightError} from '../../../utils/Validation';
import {IValidationBody, ValidationRules} from '../../../common/interfaces/IValidation';
import AppContext from '../../../context/AppContext';

interface IProps extends IBaseModalProps{
    modalData: IGuardTestReportTest | undefined
    test: IGuardTest | undefined
}

const UpdateGuardTestResponsesModal: FC<IProps> = ({ onCancel, onSubmit, modalData, test }) => {
    const {hookState: formData, onChange,
        setHookState} = useOnIputChange<IGuardTestReportTest>(modalData ? modalData : {} as IGuardTestReportTest)
    const ctx = useContext(AppContext)

    const validateUpdateReason = (): boolean => {
        let errors: IValidationBody[] = []

        if(!formData.response){
            errors = [...errors, ...validateFiled(formData.response, 'response', ValidationRules.required)]
        }

        if(errors.length > 0){
            ctx.showNotification(NotificationTypes.warning, "Please fill in Reason for Update")
            highlightError(errors)
            return false
        }
        else {
            removeHighlightError()
            return true
        }

    }

    return (
        <BaseModal
            show
            onCancel={onCancel}
            onSubmit={() => validateUpdateReason() && onSubmit && onSubmit(formData)}
            submitBtnText={"Update"}
            title="Update Covid Screening Responses"
        >
            <form>
                <div className="row mb-3">
                    <div className="col-sm-5 details-information__title mb-0">
                        <h6 className="mb-0">Screening Date:</h6>
                    </div>
                    <div className="col-sm-7 details-information__value">
                        <p className="mb-0">
                            { momentDateFormat(modalData?.created || '') }
                        </p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-sm-5 details-information__title mb-0">
                        <h6 className="mb-0">Guard Name:</h6>
                    </div>
                    <div className="col-sm-7 details-information__value">
                        <p className="mb-0">
                            { modalData?.guardName || "" }
                        </p>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-sm-5 details-information__title mb-0">
                        <h6 className="mb-0">Employee ID:</h6>
                    </div>
                    <div className="col-sm-7 details-information__value">
                        <p className="mb-0">
                            { modalData?.employeeId || "" }
                        </p>
                    </div>
                </div>

                {
                    test && test.questions.map(question => (
                        <div className="row mb-3 align-items-center" key={question.id}>
                            <div className="col-sm-5 details-information__title mb-0">
                                <h6 className="mb-0">{ question.title }:</h6>
                            </div>
                            <div className="col-sm-7 details-information__value">
                                <CustomSelectControl
                                    options={question.questionOptions.map(opt => ({
                                        name: opt.option,
                                        id: opt.id
                                    }))}
                                    value={formData.questions[question.title].answer}
                                    valueIsLabel
                                    onChange={(_value, label) => label &&
                                        setHookState({
                                            ...formData,
                                            questions: {
                                                ...formData.questions,
                                                [question.title]: {
                                                    ...formData.questions[question.title],
                                                    answer: label,
                                                    optionValue: Number(question.questionOptions.find(item => item.id === +_value)?.optionValue)
                                                }
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    ))
                }
                <div className="row mb-3 align-items-center">
                    <div className="col-sm-5 details-information__title mb-0">
                        <h6 className="mb-0">Reason for Update: <span className='text-danger'>*</span></h6>
                    </div>
                    <div className="col-sm-7 details-information__value">
                        <div className="form-group mb-0">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter reason for update"
                                value={formData.response || ""}
                                name="response"
                                onChange={ev => onChange(ev)}
                            />
                        </div>
                    </div>
                </div>
            </form>
            
        </BaseModal>
    )
}

export default memo(UpdateGuardTestResponsesModal)
