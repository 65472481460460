import { UTCTimeNow } from "utils/DateFormatting";
import { IClient } from "../interfaces/clients/IClient";

export class Client implements IClient {
    id = 0;
    tenantId = '';
    organizationName = '';
    firstAddress = '';
    secondAddress? = '';
    phoneNumber = '';
    email = '';
    city = '';
    primaryContactName = '';
    postalCode = '';
    province = '';
    aspStartDate = UTCTimeNow(true, true) as string;
    clientContacts = []
    country = 'Canada';
    organizationLogo = '';

    contactFirstName = '';
    contactMiddleName = '';
    contactLastName = '';
    contactPosition = '';
    contactPhoneNumber = '';
    contactMobileNumber = '';
    contactEmailAddress = '';
    firstSchoolYear = {startDate: '', endDate: ''}
    lastSchoolYear = {startDate: '', endDate: ''}
    institutionCode = ''
}