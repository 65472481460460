import { IVocabulary } from './../../common/interfaces/vocabulary/IVocabulary';
import { IJob } from './../../common/interfaces/jobs/IJob';
import React from 'react'

interface IJobContext {
    job: IJob
    onChange: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any> | string | Date | boolean | Array<any>, fieldName?: string) => void;
    onScheduleChanged: (event: React.ChangeEvent<HTMLInputElement> | string | Date | boolean, fieldName?: string | null, id?: number) => void;
    onScheduleSubmit: () => void
    onGetJobLocation: () => void
    vocabulary: IVocabulary[]
}

const JobContext = React.createContext<IJobContext>({} as IJobContext);

export default JobContext;
