import moment from "moment";

export const checkDate = (date: string) => {
    return date === 'Invalid date' || date === 'Jan 01, 0001' || !date ? '-' : date
}

export const checkTime = (time: string) => {
    return time?.includes('.') ? time.split('.')[0] : time;
}

export const isSameDates = (dateOne: Date | string, dateTwo: Date | string): boolean => {
    return moment.utc(dateOne).isSame(moment.utc(dateTwo))
}