import React, { FC } from 'react'

import WysiwygEditor from '../../../wysiwyg/WysiwygEditor'
import { EditorState } from 'draft-js';

type Props = {
    onChangeHandler: (value: any, key: string) => void;
    fields: { subject: string, message: EditorState }
}

const CustomBaseNotificationEmail: FC<Props> = ({ onChangeHandler, fields }) => {
    return (
       <>
        <h6 className="mt-4 mb-3 font-weight-bold text-dark">
            <span className="list-counter mr-2 d-inline-flex align-items-center justify-content-center">4</span>
            Create your message
        </h6>
        <div className="form-group">
            <label className="required" htmlFor="subject">Subject header:</label>
            <input 
                type="text"
                className="form-control"
                placeholder="Enter notification subject header"
                value={fields.subject}
                name="subject"
                onChange={ev => onChangeHandler(ev.target.value, 'subject')}
            />
        </div>
        <div className="form-group d-flex flex-column align-items-start">
            <label className="required mb-0" htmlFor="subject">Notification body:</label>
            <small className="mb-2">Add links in the format www.domain.com, without "https"</small>
            <WysiwygEditor
                value={fields.message}
                onChange={value => onChangeHandler(value, 'editor')}
            />
        </div>
       </>
    )
}

export default CustomBaseNotificationEmail
