import { useContext, useState } from "react"

import { ActivationDeactivation } from "../common/enums/Actions"
import { NotificationTypes } from "../common/interfaces/INotification";
import { IVocabulary } from "../common/interfaces/vocabulary/IVocabulary";
import { DataService } from "../common/services/DataService";

import AppContext from '../context/AppContext'
// import { checkIfIsOtherReason } from "../utils/CheckOtherReason";

type FunctionArguments = { itemData: any, successCallback?: (id: number) => void, errorCallback?: () => void, showSuccessModaL?: boolean };

interface IBase {
    [index: string]: any
}

function useActivateDeactivate<T extends IBase>(baseUrl: string) {
    const { showLoader, showNotification } = useContext(AppContext)
    const [approveModals, setApproveModals] = useState<{
        showApprove: boolean, showDecline: boolean, showSuccess: boolean, showReactivate: boolean,
        showDeactivate: boolean
    }>({
        showApprove: false, showDecline: false, showSuccess: false, showReactivate: false, showDeactivate: false
    })
    const [requestModals, setRequestModals] = useState<{ showRequest: boolean, showDissaprove: boolean, showSuccess: boolean }>({ showRequest: false, showDissaprove: false, showSuccess: false });
    const [modalText, setModalText] = useState<{ title: string }>({ title: '' })
    const [itemInFocus, setItemInFocus] = useState<T>({} as T)

    const svc = new DataService<any>({ url: baseUrl });

    const onRequestHandler = ({ title = '', itemInFocus = {}, type = ActivationDeactivation.deactivate }) => {
        setModalText({
            ...modalText,
            title,
        })
        setItemInFocus(itemInFocus as T)
        setRequestModals({
            showSuccess: false,
            showRequest: type !== ActivationDeactivation.disapprove ? true : false,
            showDissaprove: type === ActivationDeactivation.disapprove ? true : false,
        })
    }

    const onRequestSubmitHandler = async (actionType: ActivationDeactivation, arg: FunctionArguments, modalTitle = '', reason?: IVocabulary | string) => {
        showLoader(true)
        try {
            let response;
            if (actionType === ActivationDeactivation.delete) {
                response = await deleteRequest(itemInFocus.boardId ? `${itemInFocus.boardId}/${itemInFocus.id}` : itemInFocus.id)
            } else {
                response = await svc.update(reason
                    ? {
                        deactivationReason: (reason as IVocabulary).id
                            ? (reason as IVocabulary).id : reason as string
                    }
                    : {}, (itemInFocus.scheduleId
                        ? itemInFocus.scheduleId
                        : itemInFocus.id)
                    , actionType)
            }
            setModalText({ title: modalTitle })
            setRequestModals({ showRequest: false, showSuccess: arg.showSuccessModaL !== undefined ? arg.showSuccessModaL : true, showDissaprove: false })

            arg.successCallback && arg.successCallback(response.data)

            showLoader(false)
        } catch (error: any) {
            arg.errorCallback && arg.errorCallback()
            showLoader(false)
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const approveRequest = async () => setApproveModals({ ...approveModals, showApprove: true })

    const deleteRequest = async (id: number | number) => await svc.delete(id)

    const onSuccessResponse = <T extends { [index: string]: any }>(itemKey: number | string, data: T[]) => data.filter(row => row.id !== itemKey)

    const onCloseRequestModals = () => {
        setRequestModals({
            showRequest: false, showDissaprove: false, showSuccess: false
        })
    }

    const getModalTitle = {
        approveDeactivation: (boldText: string, itemName: string) => (
            `Are you sure you want to deactivate ${itemName} for <span class="font-weight-bold">${boldText}</span>?`
        ),
        declineDeactivation: (boldText: string) => (
            `Are you sure you want to decline the deactivation request for <span class="font-weight-bold">${boldText}</span>?`
        )
    }

    return {
        approveRequest, approveModals, setApproveModals,
        onRequestHandler, onRequestSubmitHandler, modalText,
        requestModals, onCloseRequestModals, itemInFocus, setModalText,
        onSuccessResponse, setRequestModals, getModalTitle, setItemInFocus
    }
}

export default useActivateDeactivate