import moment from "moment";
import { useContext } from "react";
import { useState } from "react";
import { IGuardLocation } from "../common/interfaces/guards/IGuard";
import { NotificationTypes } from "../common/interfaces/INotification";
import ResourceDataService from "../common/services/ResourceDataService";
import AppContext from "../context/AppContext";

const useGuardLocation = () => {
    const [guardLocation, setGuardLocation] = useState<IGuardLocation | null>({} as IGuardLocation)
    const appContext = useContext(AppContext);

    const getGuardLocation = async (id: number) => {
        const svc = new ResourceDataService<IGuardLocation>({ url: 'guard/location' })
        try {
            const response = await svc.queryString(`shiftId=${id}`)
            // const response = await svc.queryString(`shiftId=3746`)
            if (response.data !== null) {
                setGuardLocation(checkIfPositionIsActual(response.data.time) ? response.data : null)
            } else setGuardLocation(null);

        } catch (error: any) {
            appContext.showNotification(NotificationTypes.danger, error.message)
        }
    }
    // time: "2021-01-13T15:08:46Z" 
    const checkIfPositionIsActual = (time: string) => {
        const isInPast = moment(time).isBefore(moment(), 'day')
        const result = moment.duration(moment.utc().diff(moment.utc(time), 'hours')).asHours()
        if (result > 1 || isInPast) return false;
        return true;
    }

    return {
        getGuardLocation, guardLocation, setGuardLocation
    }
}

export default useGuardLocation;