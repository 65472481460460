import { IJobGuardLeaves } from 'common/interfaces/jobs/IJobGuardLeaves'
import GuardsLeaveForJobModal from 'components/modal/jobs/GuardsLeaveForJobModal'
import React, {FC, useEffect, useMemo, useState} from 'react'
import {useContext} from 'react'
import {Link, RouteComponentProps} from 'react-router-dom'
import {AddNewItemEnum} from '../../common/enums/AddNewItemEnum'
import {JobIncludesEnum} from '../../common/enums/JobEnums'
import {VocabularyEnum} from '../../common/enums/VocabularyEnum'
import {ICityDropdown} from '../../common/interfaces/dropdowns/ICityDropdown'
import {IClientDropdown} from '../../common/interfaces/dropdowns/IClientDropDown'
import {ISchoolBoardsDropdown, ISchoolDropdown} from '../../common/interfaces/dropdowns/ISchoolDropdown'
import {IHoliday} from '../../common/interfaces/IHoliday'
import {NotificationTypes} from '../../common/interfaces/INotification'
import {IStepValidation, IValidationBody, ValidationRules} from '../../common/interfaces/IValidation'
import {IJob, IJobSchedule, IJobSchools, IJobShiftsInNeedOfGuards} from '../../common/interfaces/jobs/IJob'
import {Job, JobSchedules, JobSchools} from '../../common/models/Job'
import {DataService} from '../../common/services/DataService'
import ResourceDataService from '../../common/services/ResourceDataService'
import JobFormNavigation from '../../components/jobs/form-navigation/JobFormNavigation'
import AddDetailsTab from '../../components/jobs/tabs/AssignSchedulesTab'
import AssignGuards from '../../components/jobs/tabs/AssignGuards'
import Confirmation from '../../components/jobs/tabs/Confirmation'
import CreateJobTab from '../../components/jobs/tabs/CreateJobTab'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import AppContext from '../../context/AppContext'
import JobContext from '../../context/jobs/JobContext'
import WizardFormContext from '../../context/jobs/WizardFormContext'
import {useCalendarForm} from '../../customHooks/useCalendarForm'
import {useHolidays} from '../../customHooks/useHolidays'
import {useModelDetails} from '../../customHooks/useModelDetails'
import {useOnIputChange} from '../../customHooks/useOnInputChange'
import {useVocabulary} from '../../customHooks/vocabulary/useVocabulary'
import {highlightError, removeHighlightError, validateFiled} from '../../utils/Validation'
import BaseModal from 'components/modal/BaseModal'
import { useTableList } from 'customHooks/useTableList'
import { IZoneDropdown } from 'common/interfaces/dropdowns/IZoneDropdown'

type RouteParams = { id: string }

export type DropdownsState = {
    cities: ICityDropdown[]
    clients: IClientDropdown[]
    schools: ISchoolBoardsDropdown[]
    zones: IZoneDropdown[]
}

export type SchoolOptionsState = {
    schoolNum: number;
    options: ISchoolDropdown[];
}

export type SchoolDropdownsState = {
    schoolOpts: SchoolOptionsState[]
    schoolNumEditing: number
}

const AddEditJob: FC<RouteComponentProps<RouteParams>> = (props) => {
    const [jobId, setJobId] = useState(props.match.params.id || undefined)
    const {allRecords: countOfAllUnassignedRecords} = useTableList<IJobShiftsInNeedOfGuards>('shift/unassigned')
    const [dropdownOpts, setDropdownOpts] = useState<DropdownsState>({cities: [], clients: [], schools: [], zones: []})
    const [jobTitle, setJobTitle] = useState<string>('')
    const [activeStep, setActiveStep] = useState<number>(1)
    const [schoolDropdown, setSchoolDropdown] = useState({} as SchoolDropdownsState)
    const {hookState, onChange, setHookState} = useOnIputChange<IJob>(new Job())
    const job = hookState;
    const appContext = useContext(AppContext)
    const {onCalendarSubmit, calendarFormData, setCalendarFormData, onCalendarChange, onHolidayChange, setHolidays, onRemoveItemFromCalendar, trackHolidays, holidays} = useCalendarForm<IJob>(new Job(), "jobSchedules")
    const schoolFormHook = useCalendarForm<IJob>(new Job(), "schools")
    const {vocabulary, getVocabulary} = useVocabulary(VocabularyEnum.shift)
    const {allHolidays, prepopulateHolidays, getHolidaysSchoolBoardById} = useHolidays();
    const {detailsModel, isLoading} = useModelDetails<IJob>("job", new Job(), {include: [JobIncludesEnum.schedules, JobIncludesEnum.assignedSchedules], customQuery: '&editMode=true'})
    const [activeScroll, setActiveScroll] = useState(false)
    const [bookLeaveModal, setBookLeaveModal] = useState<boolean>(false)
    const [bookedLeaves, setBookedLeaves] = useState<IJobGuardLeaves[]>([] as IJobGuardLeaves[])
    const [saveJobModal, setSaveJobModal] = useState<boolean>(false)

    useEffect(() => {
        getOptionsForDropdowns();
        getVocabulary();
    }, [])

    useEffect(() => {
        setJobTitle(hookState.jobName ? hookState.jobName : `${hookState.firstStreet}${hookState.secondStreet ? (', ' + hookState.secondStreet) : ''}`)
    }, [hookState.firstStreet, hookState.jobName, hookState.secondStreet])

    useEffect(() => {
        if (props.match.path.includes('/add')) {
            setActiveStep(1)
            setHookState(new Job())
            setCalendarFormData(new Job())
        }
        removeHighlightError()
    }, [props.match.path])

    useEffect(() => {
        allHolidays.length !== 0 && setHolidays({
            original: allHolidays.map(holiday => {
                holiday.remove = true;
                return holiday
            }), filtered: allHolidays.map(holiday => {
                holiday.remove = true;
                return holiday
            })
        })
    }, [allHolidays])

    useEffect(() => {
        setHookState(calendarFormData)
    }, [calendarFormData])

    useEffect(() => {
        setHookState(schoolFormHook.calendarFormData)
    }, [schoolFormHook.calendarFormData])

    useEffect(() => {
        setJobId(props.match.params.id || undefined)
    }, [props])

    useEffect(() => {
        if (isLoading === false && props.match.params.id !== undefined) {
            setCalendarFormData(checkEmptySchoolsAndSchedules(detailsModel))
            // TODO: Find Better solution
            setTimeout(() => checkDefaultTabFromQuery(), 250)
        }
    }, [isLoading])

    useEffect(() => {
        if (props.match.params.id === undefined) {
            if (JSON.stringify(job) !== JSON.stringify(detailsModel)) return;
            setCalendarFormData(new Job())
        }
    }, [props.match.params])

    useEffect(() => {
        if (props.match.params.id !== undefined && isLoading === false && dropdownOpts.schools.length !== 0) {
            prepopulateRelatedSchools(checkEmptySchoolsAndSchedules(detailsModel).schools)
            setHookState({
                ...checkEmptySchoolsAndSchedules(detailsModel),
                schools: checkEmptySchoolsAndSchedules(detailsModel).schools.map((item, index) => {
                    return {...item, uid: index + 1}
                })
            })
        }
    }, [isLoading, dropdownOpts])

    const checkEmptySchoolsAndSchedules = (dataToCheck: IJob) => {
        const copiedData = {...dataToCheck}
        copiedData.jobSchedules.length === 0 && (copiedData.jobSchedules = [new JobSchedules()])
        copiedData.schools.length === 0 && (copiedData.schools = [new JobSchools()])
        return copiedData
    }

    const prepopulateRelatedSchools = (schools: IJobSchools[]) => {
        if (schools.length !== 0 && dropdownOpts.schools.length !== 0) {
            let schoolOptions = schools.map((school, indx) => ({
                schoolNum: indx + 1,
                options: dropdownOpts.schools.find(opt => opt.id === school.schoolBoardId)?.schools || []
            }))

            setSchoolDropdown({
                schoolNumEditing: schools.length,
                schoolOpts: schoolOptions
            })
        }
    }

    const getOptionsForDropdowns = useMemo(() =>
            async () => {
                const citySvc = new ResourceDataService<ICityDropdown[]>({url: "city/dropdown"})
                const clientSvc = new ResourceDataService<IClientDropdown[]>({url: "client/dropdown"})
                const schoolSvc = new ResourceDataService<ISchoolBoardsDropdown[]>({url: "school/dropdown"})
                const zoneSvc = new ResourceDataService<IZoneDropdown[]>({url: "zone/dropdown"})
                try {
                    const resClients = await clientSvc.getAll();
                    const resCities = await citySvc.getAll();
                    const resSchools = await schoolSvc.getAll();
                    const resZones = await zoneSvc.getAll();
                    setDropdownOpts({
                        clients: resClients.data,
                        cities: resCities.data,
                        schools: resSchools.data,
                        zones: resZones.data,
                    })
                } catch (e: any) {
                    appContext.showNotification(NotificationTypes.danger, e.message)
                }
            }
        , [])

    const updateSchools = async () => {
        appContext.showLoader(true)
        const schoolSvc = new ResourceDataService<ISchoolBoardsDropdown[]>({url: "school/dropdown"})
        try {
            const res = await schoolSvc.getAll();
            setDropdownOpts({
                ...dropdownOpts,
                schools: res.data
            })

        } catch (e: any) {
            appContext.showNotification(NotificationTypes.danger, e.message)
            appContext.showLoader(false)
        }
    }

    useEffect(() => {
        if (props.match.params.id !== undefined && allHolidays.length !== 0 && holidays.original.length !== 0) {
            const data = prepopulateHolidays(job, [], 'jobSchedules',
                (schedule, scheduleId) => trackHolidays(schedule, '', scheduleId)) as IJob;
            setCalendarFormData(data)
        }
    }, [allHolidays, holidays.original])

    const stepHandler = async (next: boolean = false) => {
        let errors: IValidationBody[] = [];
        if (next) {
            if (activeStep === 1) {
                setHolidays({original: [], filtered: []})
                await getHolidaysSchoolBoardById(job.schools)
            }

            for (let [jobField, jobValue] of Object.entries(job)) {
                const mainForm = stepsValidation(activeStep).mainForm;
                const subForm = stepsValidation(activeStep).subForm;


                if (mainForm.includes(jobField)) {
                    errors = [...errors, ...validateFiled(jobValue as string, jobField, ValidationRules.required)]
                }

                if (subForm && jobField === subForm.key) {
                    jobValue.forEach((item: any) => {

                        for (let [subFormField, subFormValue] of Object.entries(item)) {
                            if (subForm.fields.includes(subFormField)) {
                                errors = [...errors, ...validateFiled(subFormValue as string, subFormField, ValidationRules.required)]
                            }
                        }
                    })
                    // for (let [subFormField, subFormValue] of Object.entries(jobValue[0])) {
                    //     if (subForm.fields.includes(subFormField)) {
                    //         errors = [...errors, ...validateFiled(subFormValue as string, subFormField, ValidationRules.required)]
                    //     }
                    // }

                }

                if (jobField === "jobSchedules" && activeStep === 2) {
                    let scheduleErrors: IValidationBody[] = []
                    jobValue.forEach((schedule: IJobSchedule) => {
                        scheduleErrors = [...scheduleErrors, ...validateFiled(schedule.timeFrom as string, 'timeFrom', ValidationRules.notZero)]
                        scheduleErrors = [...scheduleErrors, ...validateFiled(schedule.timeTo as string, 'timeTo', ValidationRules.notZero)]
                        scheduleErrors = [...scheduleErrors, ...validateFiled(schedule.repeatOn as string, `repeatOn-${schedule.uid ? schedule.uid : schedule.id}`, ValidationRules.required)]
                    })
                    errors = [...errors, ...scheduleErrors]
                }
            }
        }
        console.log(errors);
        if (errors.length !== 0) {
            highlightError(errors)
            appContext.showNotification(NotificationTypes.warning, "Please fill in all required fields")
        } else {
            next ? setActiveStep(activeStep + 1) : setActiveStep(activeStep - 1)
        }
    }

    const saveJob = async () => {
        const guardsSvc = new DataService<IJob | {guardLeaves: IJobGuardLeaves[], id: string | number} | null>({url: 'job'})
        appContext.showLoader(true)
        try {
            const jobCopy: IJob = JSON.parse(JSON.stringify(job))
            removeHighlightError()
            const body = {
                ...jobCopy, jobSchedules: jobCopy.jobSchedules.map(schedule => {
                    if (schedule.holidays.length !== 0) {
                        schedule.holidays = schedule.holidays
                            .filter((holiday: IHoliday) => holiday.remove)
                    }
                    if (schedule.guardId === 0) {
                        schedule.guardId = null;
                    }
                    return schedule
                })
            }
            let response: IJobGuardLeaves[] = [];
            let id = ''
            if (jobId !== undefined) {
                // TODO: remove any
                const res = await guardsSvc.update(body, jobId) as any
                response = res.data
            } else { 
                const res = await guardsSvc.create(body)
                console.log(res)
                response = res.data.guardLeaves as IJobGuardLeaves[]
                id = res.data.id;
            }
            if (response && response?.length !== 0) {
                setBookedLeaves(response)
                setBookLeaveModal(true)
            } else {
                appContext.showNotification(NotificationTypes.success,
                    jobId !== undefined ? `Job - ${job.firstStreet} ${job.secondStreet} has been updated` : `Job - ${job.firstStreet} ${job?.secondStreet} has been created`
                )
                // setSaveJobModal(true)
            }
            appContext.showLoader(false)
            props.history.push(`/jobs/${jobId || id}/details`)
        } catch (e: any) {
            appContext.showLoader(false)
            appContext.showNotification(NotificationTypes.danger, e.message)
        }
    }

    const onGetJobLocation = () => {
        navigator.geolocation.getCurrentPosition(position => {
            setHookState({
                ...job,
                latitude: position.coords.latitude,
                longititude: position.coords.longitude
            })
        }, undefined, {enableHighAccuracy: true});
    }

    const onScheduleChanged = (event: React.ChangeEvent<HTMLInputElement> | string | Date | boolean, fieldName?: string | null, id?: number) => onCalendarChange(job, event, fieldName, id)

    const onScheduleSubmit = () => onCalendarSubmit(job)

    const onSchoolChanged = (event: React.ChangeEvent<HTMLInputElement> | string | null, fieldName?: string | null, id?: number) => {
        schoolFormHook.onCalendarChange(job, event, fieldName, id)
    }

    const onSchoolSubmit = () => schoolFormHook.onCalendarSubmit(job);

    const stepsValidation = (step: number): IStepValidation => {
        const firstStep: IStepValidation = {
            mainForm: ["firstStreet", "latitude", "longititude", "jobId", "schoolYearId", "cityId", "zoneId"],
            subForm: {
                key: "schools",
                fields: ["schoolId", "schoolBoardId"]
            }
        }
        const secondStep: IStepValidation = {
            mainForm: ["geoFence"],
        }

        switch (step) {
            case 1:
                return firstStep;
            case 2:
                return secondStep;
            default:
                return firstStep;
        }
    }

    const addNewItemToDropdown = (option: any, type: AddNewItemEnum) => {
        switch (type) {
            case AddNewItemEnum.city:
                setDropdownOpts({
                    ...dropdownOpts,
                    cities: [...dropdownOpts.cities, ...[{
                        id: option.id,
                        name: option.cityName,
                        zoneViewModels: []
                    }]].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
                })
                onChange([
                    {value: option.id, name: "cityId"},
                ])
                break;
            case AddNewItemEnum.zone:
                setDropdownOpts({
                    ...dropdownOpts,
                    zones: [...dropdownOpts.zones, {id: option.id, name: option.zoneName} as IZoneDropdown].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                })
                onChange(option.id, "zoneId")
                break;
            default:
                break;
        }
    }

    const showActiveStepFrom = (step: number) => {
        switch (step) {
            case 1 :
                return <CreateJobTab
                    cities={dropdownOpts.cities}
                    addNewItemToDropdown={(option, type) => addNewItemToDropdown(option, type)}
                    schoolBoards={dropdownOpts.schools}
                    zones={dropdownOpts.zones}
                    schoolDropdown={schoolDropdown}
                    setSchoolDropdown={setSchoolDropdown}
                    onChangeSchool={onSchoolChanged}
                    onSubmitSchool={onSchoolSubmit}
                    onRemoveItemFromCalendar={scheduleId => onRemoveItemFromCalendar(scheduleId, 'schools', job)}
                    updateSchools={updateSchools}
                    getOptionsForDropdowns={getOptionsForDropdowns}
                    setDropdownOpts={setDropdownOpts}
                />;
            case 2 :
                return <AddDetailsTab
                    onHolidayChange={(holidayId, yesNoValue, scheduleId) => onHolidayChange(holidayId, yesNoValue, scheduleId, 'jobSchedules', job)}
                    onRemoveItemFromCalendar={scheduleId => onRemoveItemFromCalendar(scheduleId, 'jobSchedules', job)}
                    activeScroll={activeScroll}
                />;
            case 3 :
                return <AssignGuards updateSchedules={schedules =>
                    setHookState({...job, jobSchedules: schedules})}
                />;
            case 4 :
                return <Confirmation job={job} dropdownOpts={dropdownOpts}/>;
            default :
                return null;
        }
    }

    const breadcrumbsLinks = () => {
        const baseLinks = [{
            title: 'Jobs',
            link: 'jobs'
        }]
        if (jobId) {
            return [...baseLinks, ...[{title: job.jobName ? job.jobName : '', link: `jobs/${jobId}/details`}]]
        } else {
            return baseLinks
        }
    }

    const checkDefaultTabFromQuery = () => {
        const { state } = props.location
        if (state) {
            setActiveStep(2)
            setActiveScroll(true)
        }
    }

    return (
        <Layout
            breadcrumbs={{
                links: breadcrumbsLinks(),
                currentPageTitle: `${jobId ? 'Edit Job' : 'Add a Job'}`
            }}
        >
            <div className="row">
                <div className="col-12">
                    <SectionTitle className="pb-4"
                                  title={`${jobId ? `Edit Job${jobTitle.trim() !== "" ? (': ' + jobTitle) : ''}` : `Add a Job${jobTitle.trim() !== "" ? (': ' + jobTitle) : ''}`}`}/>
                </div>
            </div>
            <JobFormNavigation activeStep={activeStep}/>
            <form className="pt-4 job-details" onSubmit={e => {
                e.preventDefault()
            }}>
                <JobContext.Provider
                    value={{job, onChange, onScheduleChanged, onScheduleSubmit, onGetJobLocation, vocabulary}}
                >
                    <WizardFormContext.Provider value={{stepHandler, saveJob}}>
                        {showActiveStepFrom(activeStep)}
                    </WizardFormContext.Provider>
                </JobContext.Provider>
            </form>
            
            { bookLeaveModal && 
                <GuardsLeaveForJobModal
                    onCancel={() => props.history.push('/jobs')}
                    leaves={bookedLeaves}
                />
            }

            { saveJobModal && 
                <BaseModal
                  show={saveJobModal}
                  title={`<span class="font-weight-bold">${job.firstStreet} ${job.secondStreet}</span> has been saved.`}
                  headerClassName="m-auto"
                  onCancel={() => {
                    props.history.push(`/jobs/${job.id}/details`)
                    setSaveJobModal(false)
                  }}
                  cancelBtnText={"Go To Job Details"}
                  onSubmit={() => {
                    props.history.push('/jobs/add')
                    setActiveStep(1)
                    setHookState(new Job())
                    setSaveJobModal(false)
                  }}
                  submitBtnText={"Add Another Job"}
                >
                  {job.jobSchedules.filter((schedule) => schedule.assigned === false).length > 0 && (
                    <>
                      <div className="text-center">This job was saved with unassigned schedules.</div>
                      <div className="text-center">
                        Resultant open shifts can be assigned in {" "}
                        <Link to="/jobs/unassigned" className="text-decoration-underline text-aqua-blue">
                          Unassigned Job Shifts.
                        </Link>
                      </div>
                    </>
                  )}
                </BaseModal>
            }

        </Layout>
    )
}

export default AddEditJob
