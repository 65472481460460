export const guardFieldsValidationToBeIgnored = [
	'secondStreet',
	'middleName',
	'device',
	'personalMobile',
	'homeMobile',
	'email',
	'profileImage',
	'latesOrAbsences',
	'guardReport',
	'latesOrAbsences',
	'guardReport',
	'repeatTimes',
	'endsDate',
	'jobSchedules',
	'jobScheduleShifts',
	'guardBookedLeaves',
	'guardDeviceToken',
	'firstAddress',
	'institutionCode',
];
