import { useContext, useEffect, useState } from "react";
import { NotificationTypes } from "../common/interfaces/INotification";
import { IQueryParams } from "../common/interfaces/IUrlBuilder";
import ResourceDataService, { IResourceData } from "../common/services/ResourceDataService";
import AppContext from "../context/AppContext";
type TSorting = { orderBy: string, desc: string, keyword: string, isSorted: boolean }
export function useTableList<T>(requestUrl: string, itemsOnPage: number | string = 24, requestOnLoad = true, defaultSorting: TSorting | undefined = undefined) {
    const [tableData, setTableData] = useState<T[]>([] as T[])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [perPage, setPerPage] = useState<number | string>(itemsOnPage)
    const [allRecords, setAllRecords] = useState<number>(0)
    const [totalCounts, setTotalCounts] = useState<number>(0)
    const [isLoading, setLoading] = useState<boolean>(true)
    const [sortStatus, setSortStatus] = useState<{ orderBy: string, desc: string, keyword: string, isSorted: boolean }>(defaultSorting ? defaultSorting : { orderBy: '', desc: '', keyword: "", isSorted: false })
    const [queryUrlState, setQueryUrl] = useState<string>("")

    const context = useContext(AppContext)

    useEffect(() => {
        requestOnLoad && fetchData(currentPage, perPage)
    }, [])

    const fetchData = async (page: number, take: number | string, queryUrl?: string, returnData = false,
        getWithoutPager = false, handleLoader = true) => {
        handleLoader && !isLoading && setLoading(true)
        const tableSvc = new ResourceDataService<T[]>({ url: queryUrl ? queryUrl : requestUrl })
        setQueryUrl(queryUrl ? queryUrl : requestUrl)
        try {
            let result: IResourceData<T[]>;

            const queryParams: IQueryParams = {
                page: page,
                take: +take
            };

            if (sortStatus.orderBy) {
                const isMultiWords = sortStatus.orderBy.split(" ");
                queryParams.orderBy = isMultiWords.length > 1 ? isMultiWords.join("").toLowerCase() :
                sortStatus.orderBy.replace("Link", "").toLowerCase();
            }

            sortStatus.desc && (queryParams.desc = sortStatus.desc === "up" ? "true" : "false")
            sortStatus.keyword && (queryParams.filter = [{ key: "KeyWord", value: sortStatus.keyword }])

            result = (itemsOnPage === 0 || getWithoutPager)
            ? await tableSvc.getAll()
            : await tableSvc.query(queryParams, (requestUrl.includes('?')
            || (queryUrl && queryUrl.includes('?'))) ? false : true)

            recordsCount(result.recordsCount)
            setPerPage(result.pageSize === result.recordsCount ? 0 : result.pageSize)
            setCurrentPage(result.pageNumber)
            setTotalCounts(result.totalCount || 0)
            setTableData(result.data)
            handleLoader && setLoading(false)
            if (returnData) { return result.data } else { return }
        } catch (e: any) {
            handleLoader && setLoading(false)
            recordsCount(0)
            setTableData([])
            context.showNotification(NotificationTypes.danger, e.message)
            return
        }
    }

    const onPagination = (pageNum: number) => {
        setCurrentPage(pageNum)
        fetchData(pageNum, perPage, queryUrlState)
    };

    const onPerPage = (recordsOnPage: string | number) => {
        setCurrentPage(+recordsOnPage === 0 ? 0 : 1)
        setPerPage(+recordsOnPage === 0 ? 0 : recordsOnPage)
        fetchData(+recordsOnPage === 0 ? 0 : 1, recordsOnPage, queryUrlState)
    }

    useEffect(() => {
        sortStatus.isSorted && fetchData(currentPage, perPage, queryUrlState)
    }, [sortStatus])

    const onSortCall = (orderBy = "", desc = "", keyword = "") => {
        setSortStatus({
            orderBy: orderBy.split('-')[0],
            desc: desc ,
            keyword: keyword,
            isSorted: true
        })
    }

    const onClearFilterQuery = (additionalQueryParam?: string) => {
        setCurrentPage(1)
        setPerPage(24)
        setQueryUrl(additionalQueryParam || "")
        setSortStatus({
            orderBy: "",
            desc: "" ,
            keyword: "",
            isSorted: true
        })
    }

    const recordsCount = (count: number) => setAllRecords(count)

    const handleColClick = (colKey: string, itemId: number | undefined, keyToListen: string, startUrl: string) => {
        if (itemId !== undefined && colKey === keyToListen) {
            const url = `#/${startUrl}/${itemId}/details`;
            window.open(url);
        }
        return
    }

    return {
        currentPage, perPage, allRecords, onPagination, onPerPage, recordsCount, setCurrentPage, setPerPage, tableData, isLoading, setTableData, fetchData,
        onSortCall, onClearFilterQuery, handleColClick, setLoading, totalCounts
    }
}
