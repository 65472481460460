import React from 'react'
import { IGuardShift } from '../../common/interfaces/guards/IShift';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';

interface IAssignedShiftsContext {
    assignedShifts: IGuardShift[]
    onSubmit: (obj: IVocabulary | string, id: number) => void
}

const AssignedShiftsContext = React.createContext<IAssignedShiftsContext>({} as IAssignedShiftsContext);

export const AssignedShiftsModalContext = React.createContext<IAssignedShiftsContext>({} as IAssignedShiftsContext);
export default AssignedShiftsContext;
