import React, { FC } from 'react'
import { IAutocompletePredictions } from '../../common/interfaces/maps/IAutocomplete'

type Props = {
    predictions: IAutocompletePredictions[]
    onPredictionClick: (item: string) => void
}

const CustomPredictionsControl: FC<Props> = ({ predictions, onPredictionClick }) => {
    return (
        <ul className="list-unstyled mb-0 custom-prediction">
            {
                predictions.map(item => (
                    <li
                        className="custom-prediction__item"
                        onClick={() => onPredictionClick(item.terms[0].value) }
                    >{ item.terms[0].value}, {item.terms[1].value }</li>
                ))
            }
        </ul>
    )
}

export default CustomPredictionsControl
