import React, { FC, useContext, useEffect, useState } from 'react'
import { NotificationTypes } from '../../../common/interfaces/INotification';
import { IVocabulary } from '../../../common/interfaces/vocabulary/IVocabulary';
import { IJobsRightNow } from '../../../common/interfaces/jobs/IJob';
import AppContext from '../../../context/AppContext';
import { useChangeMapView } from '../../../customHooks/useChangeMapView';
import { useJobMarkers } from '../../../customHooks/useJobMarkers';
import { useListFilter } from '../../../customHooks/useListFilter';
import { ShiftPeriod } from '../../../customHooks/useShiftPeriod';
import { useTableList } from '../../../customHooks/useTableList';
import LastRefreshBtn from '../../buttons/LastRefreshBtn';
import CustomSelectControl from '../../custom-select/CustomSelectControl';
import JobsMapComponent from '../../map/JobsMapComponent';
import SectionTitle from '../../titles/SectionTitle';
import BaseModal from '../BaseModal';
import { generateQueryShiftUrl } from '../../../helpers/GenerateQueryUrl'
import { ISchoolBoardsDropdown } from '../../../common/interfaces/dropdowns/ISchoolDropdown';
import { SVGEraser } from '../../../assets/icons/SvgIcons';
import { checkInTypeDropdownOptions, checkOutTypeDropdownOptions, showNextDropdownOptions } from '../../../content/dashboard/Dropdowns'
import SectionLoader from '../../loading/SectionLoader';
import AutocompleteField from '../../autosuggestField/AutocompleteField';
import { IZoneDropdown } from 'common/interfaces/dropdowns/IZoneDropdown';

interface IProps {
    onCancel: () => void
    shiftVocabulary: IVocabulary[]
    currentShiftPeriod: ShiftPeriod
    schoolBoards: ISchoolBoardsDropdown[]
    cities?: IVocabulary[]
    zones?: IZoneDropdown[]
}

const ActiveJobsOnMapModal: FC<IProps> = ({ onCancel, shiftVocabulary, currentShiftPeriod, cities, schoolBoards, zones }) => {
    const baseUrl = `job/shifts/${generateQueryShiftUrl.currentDay}`;
    const { showNotification } = useContext(AppContext)
    const { fetchData, tableData } = useTableList<IJobsRightNow>(baseUrl, 0, false);
    const [loader, setLoader] = useState<boolean>(true)
    const { filter, onFilterDropdownChange, generateFilterQuery, onSearchChange, onFilterSearchClearClick, setFilter} = useListFilter('')
    // const { mapViewOption, setMapViewOption, mapViewOptions, filterMarkersByMapView } = useChangeMapView();
    const { generateMarkersForJobs, jobsOnMap } = useJobMarkers()

    useEffect(() => {
        setFilter({
            ...filter,
            minutesFromNow: 30,
            checkInType: 0,
            checkOutType: 0
        })
    }, [])

    useEffect(() => {
        filter.minutesFromNow && getActiveJobItems()
    }, [filter.minutesFromNow, filter.shiftPeriod, filter.zone, filter.schoolBoardId, filter.checkInType, filter.checkOutType])

    useEffect(() => {
        (filter.keyWord === '' && filter.hasValue) && getActiveJobItems()
    }, [filter.keyWord])

    const getActiveJobItems = async (withoutKeyWord = false, keyWord?: string) => {
        setLoader(true)
        try {
            const jobItems = await fetchData(1, 0, `${baseUrl}${generateFilterQuery(withoutKeyWord, true, !withoutKeyWord ? keyWord: '').substring(1)}`, true)
            if (jobItems !== undefined) generateMarkersForJobs(jobItems)
            setLoader(false)
        } catch (error: any) {
            setLoader(false)   
            showNotification(NotificationTypes.danger, error.message)
        }
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') getActiveJobItems();
    }

    return (
        <BaseModal
            show
            showExitBtn
            cancelBtnText={'Close'}
            className="activeJobsOnModal"
            onCancel={() => console.log('')}
        >
            <section>
                <div className="row">
                    <div className="col-12 d-flex flex-column flex-lg-row justify-content-between mb-4">
                        <div className="d-flex flex-row align-items-center justify-content-between">
                            <SectionTitle title={`Active Job Shifts`} className="mb-0"/>
                            <button className="btn btn-aqua-blue d-lg-none" onClick={() => onCancel()}>Close</button>
                        </div>
                        <div className="d-flex flex-wrap mt-2 mt-lg-0">
                            <div className="d-flex flex-row ml-0 ml-sm-auto mb-2 mb-md-0 mr-2">
                                <div className="form-group mb-0 mx-0 w-100 mx-md-2">
                                    <AutocompleteField
                                        includes={'Job'}
                                        isAsync={true}
                                        placeholder={'Enter keyword'}
                                        onClear={() =>  onFilterSearchClearClick(() => getActiveJobItems(true))}
                                        onSearch={ (value) => {
                                            onFilterDropdownChange(value, 'keyWord')
                                            getActiveJobItems(false, value)
                                        }}    
                                    />        
                                </div>
                            </div>
                            <LastRefreshBtn onRefresh={() => getActiveJobItems()}/>
                            <button className="btn btn-aqua-blue ml-2 d-none d-lg-block" onClick={() => onCancel()}>Close</button>
                        </div>
                    </div>
                    <div className="col-12">
                        <form className="filters-form-group filters-form-group--dashboard mb-4">
                            <div className="d-flex mb-3 flex-row align-items-center flex-fill justify-content-between">
                                <h5 className="font-weight-bold mb-0">Filter Map View List</h5>
                                    {
                                        (filter.minutesFromNow !== 30 || filter.checkInType || filter.checkOutType || filter.zone || filter.shiftPeriod || filter.schoolBoardId) ? <button
                                            type="button"
                                            className="btn btn-aqua-blue ml-2 mr-1"
                                            onClick={() =>  setFilter({
                                                ...filter,
                                                minutesFromNow: 30,
                                                checkInType: 0,
                                                checkOutType: 0,
                                                zone: 0,
                                                shiftPeriod: 0,
                                                schoolBoardId: 0,
                                            })}>
                                            <SVGEraser/>
                                        </button>
                                        : null
                                    }
                            </div>
                            <div className="d-flex flex-row flex-wrap flex-lg-nowrap">
                                <CustomSelectControl
                                    options={showNextDropdownOptions}
                                    value={filter.minutesFromNow}
                                    placeholder={"Select View next"}
                                    onChange={e => onFilterDropdownChange(e, "minutesFromNow")}
                                    className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--showNext"
                                />
                                <CustomSelectControl
                                    isClearable={true}
                                    options={shiftVocabulary || []}
                                    value={filter.shiftPeriod}
                                    placeholder={"Select Shift"}
                                    onChange={e => onFilterDropdownChange(e, "shiftPeriod")}
                                    className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--small"
                                />
                                <CustomSelectControl
                                    isClearable={true}
                                    options={zones || []}
                                    value={filter.zone}
                                    sort
                                    placeholder={"Select Zone"}
                                    onChange={e => onFilterDropdownChange(e, "zone")}
                                    className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--small"
                                />
                                <CustomSelectControl
                                    isClearable={true}
                                    options={schoolBoards || []}
                                    value={filter.schoolBoardId}
                                    placeholder={"Select School Board"}
                                    onChange={e => onFilterDropdownChange(e, "schoolBoardId")}
                                    className="flex-grow-0 mx-1 mb-2 mb-xl-0"
                                />
                                <CustomSelectControl
                                    options={checkInTypeDropdownOptions}
                                    value={filter.checkInType}
                                    placeholder={"Select Check-In status"}
                                    onChange={e => onFilterDropdownChange(e, "checkInType")}
                                    className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--checkType"
                                />
                                <CustomSelectControl
                                    options={checkOutTypeDropdownOptions}
                                    value={filter.checkOutType}
                                    placeholder={"Select Check-Out status"}
                                    onChange={e => onFilterDropdownChange(e, "checkOutType")}
                                    className="flex-grow-0 mx-1 mb-2 mb-xl-0 custom-react-select--checkType"
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row job-location-large">
                    <div className="col-12 position-relative">
                        <JobsMapComponent
                            jobMarkers={jobsOnMap.markers}
                            showMarkerDetails
                            currShiftPeriodId={currentShiftPeriod?.id}
                            jobsNow={tableData}
                            showGuardPosition
                        >
                            { loader && <SectionLoader /> }
                        </JobsMapComponent>
                    </div>
                </div>
            </section>
        </BaseModal>
    )
}

export default React.memo(ActiveJobsOnMapModal)
