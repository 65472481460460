export function useSort<T extends {[index: string]: any}> () {

    const dateTimeSort = (dataToSort: T[], dateField: string, timeField: string) => 
        dataToSort
        .sort((dayPrev, dayNext) => {
            if (new Date(dayNext[dateField]) < new Date(dayPrev[dateField])) return 1
            if (new Date(dayNext[dateField]) > new Date(dayPrev[dateField])) return -1
            if (new Date(dayNext[timeField]) < new Date(dayPrev[timeField])) return 1
            if (new Date(dayNext[timeField]) > new Date(dayPrev[timeField])) return -1
            return 0;
        });

    return {
        dateTimeSort
    }
}