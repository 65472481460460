import React, { useEffect, useState } from 'react';
import CustomCheckBox from 'components/checkbox/CustomCheckBox';
import BaseModal from 'components/modal/BaseModal';
interface IDropdownOpts {
	id: number | string;
	name: string;
}
type Props<T> = {
	options: T[];
	title: string;
	value: string;
	onChange: (value: string) => void;
	btnText?: string;
};
const ModalMultiSelect = <T extends IDropdownOpts>({
	options,
	title,
	value,
	onChange,
	btnText = 'Update',
}: Props<T>) => {
	const [openModal, setOpenModal] = useState(false);
	const [selectedValues, setSelectedValues] = useState(value);

	const onCheckBoxChange = (id: string) => {
		return () => {
			let arrOfValues = !selectedValues ? [] : selectedValues.split(';');
			if (arrOfValues.some((v) => v === id)) {
				setSelectedValues(arrOfValues.filter((v) => v !== id).join(';'));
				return;
			}
			arrOfValues.push(id);
			setSelectedValues(arrOfValues.join(arrOfValues.length < 0 ? '' : ';'));
		};
	};

    const selectAll = () => {
        setSelectedValues(options.map(v => v.id).join(';'))
    }

    const clearAll = () => {
        setSelectedValues('')
    }
	useEffect(() => {
		setSelectedValues(value);
	}, [value]);

	return (
		<div className="form-group modal-multi-select">
			<label>{title}:</label>
			<div className="d-flex justify-content-between">
				<ul className="list-group mr-3">
					{options
						.filter((v) => value.split(';').some((id) => id === v.id.toString()))
						.map((item) => (
							<li key={item.id} className="list-group-item modal-multi-select__list-item">
								{item.name}
							</li>
						))}
					{value.length === 0 && (
						<li className="list-group-item modal-multi-select__list-item">Select the {title}</li>
					)}
				</ul>

				<button
					type="button"
					className="btn btn-outline-aqua-blue ml-0 ml-sm-3 "
					onClick={() => setOpenModal(true)}
				>
					{btnText}
				</button>
			</div>

			{openModal && (
				<BaseModal
					title={`Select ${title}`}
					submitBtnText="Confirm"
					onSubmit={() => {
						onChange(selectedValues);
						setOpenModal(false);
					}}
					show
					onCancel={() => {
						setOpenModal(false);
						setSelectedValues(value);
					}}
				>
					<div>
						<div className="d-flex justify-content-center mb-3">
							<button
								type="button"
								className="btn btn-aqua-blue btn-sm"
								onClick={selectAll}
							>
								Select All
							</button>
                            <button
								type="button"
								className="btn btn-outline-aqua-blue btn-sm ml-3"
								onClick={clearAll}
							>
								Clear All
							</button>
						</div>
						<ul className="list-group mr-3 modal-multi-select__modal-list">
							{options.map((opt) => (
								<li key={opt.id} className="list-group-item">
									<CustomCheckBox
										checked={selectedValues.split(';').some((v) => v === `${opt.id}`)}
										disabled={false}
										onChange={onCheckBoxChange(opt.id.toString())}
										label={opt.name}
										id={opt.id}
									/>
								</li>
							))}
						</ul>
					</div>
				</BaseModal>
			)}
		</div>
	);
};

export default ModalMultiSelect;
