import { IValidationBody, ValidationRules } from "../common/interfaces/IValidation";

export const validateFiled = (value: any, fieldName: string, rule: ValidationRules, values?: string[], errorLength?: string ,): IValidationBody[] => {
    let errors: IValidationBody[] = []

    switch (rule) {
        case ValidationRules.required:
            logError(errors, isEmpty(fieldName, value))
            break
        case ValidationRules.notZero:
            logError(errors, isZero(fieldName, value))
            break
        case ValidationRules.notNull:
            logError(errors, isNull(fieldName, value))
            break
        case ValidationRules.passwordMatch:
            logError(errors, passwordMatch(fieldName, (values as string[])));
            break
        case ValidationRules.lengthFields:
            logError(errors, lengthFields(fieldName, value ));
            break;
        case ValidationRules.isValidPhone:
            logError(errors, isValidPhone(fieldName, value ));
            break
        case ValidationRules.isValidPostalCode:
            logError(errors, isValidPostalCode(fieldName, value ));
            break
        case ValidationRules.isValidEmail:
            logError(errors, isEmail(fieldName, value ));
            break
        default:
            break;
    }
    if (errorLength) {
        logError(errors, lengthFields(fieldName, value ));
    }

    return errors
}

export const formatFieldName = (name: string) => name
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(name){ return name.toUpperCase(); });

export const logError = (existing: IValidationBody[], newError: IValidationBody | null): void => {
    if (newError) existing.push(newError);
}

export const isEmpty = (fieldName: string, value: any): IValidationBody | null => {
    if (typeof value !== 'boolean' && !value) {
        return {
            fieldName,
            errorMessage: `Please fill in ${formatFieldName(fieldName)} field`,
        }
    }
    return null
}

export const isValidPhone = (fieldName: string, value: any): IValidationBody | null => {
    if (value && value.includes("_")) {
        return {
            fieldName,
            errorMessage: `Please fill in correct ${formatFieldName(fieldName)}`,
        }
    }
    return null
}

export const isValidPostalCode = (fieldName: string, value: any): IValidationBody | null => {
    if (value && (value.includes("_") || (value.length !== 7 && value.length !== 5))) {
        return {
            fieldName,
            errorMessage: `Please fill in correct ${formatFieldName(fieldName)}`,
        }
    }
    return null
}

export const isEmail = (fieldName: string, value: any): IValidationBody | null => {
    if (!value) return null;
    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        return {
            fieldName,
            errorMessage: `Please fill in correct ${formatFieldName(fieldName)}`,
        }
    }
    return null
}

export const isZero = (fieldName: string, value: any): IValidationBody | null => {
    if (typeof value === 'number' && value === 0) {
        return {
            fieldName,
            errorMessage: `Field ${formatFieldName(fieldName)} can't be a zero`,
        }
    }
    return null
}

export const isNull = (fieldName: string, value: any): IValidationBody | null => {
    if (value === null) {
        return {
            fieldName,
            errorMessage: `Field ${formatFieldName(fieldName)} can't be a null`,
        }
    }
    return null
}

const passwordMatch = (fieldName: string, values: string[]): IValidationBody | null =>{
    if (values[0] !== values[1]) {
        return {
            fieldName,
            errorMessage: `Passwords do not match.`,
        };
    }
    return null;
}

const lengthFields = (fieldName: string, value: any): IValidationBody | null => {
    if (value.length < 5) {
        return {
            fieldName,
            errorMessage: `Password must be at least 6 characters.`,
        };
    }
    return null;
}

export const highlightError = (errors: IValidationBody[]) => {
    const invalidEl = document.querySelectorAll(`.form-group--invalid`);
    invalidEl.forEach(el => el.classList.remove('form-group--invalid'))
    // .form-group .react-autosuggest__input,
    errors.forEach(err => {
        const htmlElements = document.querySelectorAll(`form input[name=${err.fieldName}],
        form textarea[name=${err.fieldName}],
        form .dropdown-filter[data-name=${err.fieldName}],
        .form-group label.${err.fieldName},
        .form-group .react-autosuggest-wrapper--${err.fieldName},
        .form-group .react-datepicker-custom-wrapper.${err.fieldName},
        .form-group .react-autosuggest__input,
        form .custom-react-select--${err.fieldName}, form .content-editor__wrapper`,);
        htmlElements.forEach(htmlEl => {
            if (htmlEl !== null) {
                (htmlEl.parentElement as HTMLElement).classList.add('form-group--invalid');
            }
        })
    })
}

export const removeHighlightError = () => {
    const invalidEl = document.querySelectorAll(`form .form-group--invalid`);

    invalidEl.forEach(el => el.classList.remove('form-group--invalid'))
}

export const validateEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    const targ = event.target
    const value = targ.value;
    const parentClassList = targ.parentElement?.classList;
    if (value === "") {
        parentClassList?.remove('form-group--invalid')
        return;
    }
    isEmail(targ.name, value) ? parentClassList?.add('form-group--invalid') : parentClassList?.remove('form-group--invalid')
}

export const validatePhoneNumber = (event: React.FocusEvent<HTMLInputElement>) => {
    const toggleClass = (value: string, parentClassList: DOMTokenList | undefined) => value.includes("_") ? parentClassList?.add('form-group--invalid') : parentClassList?.remove('form-group--invalid')
    const targ = event.target
    const value = targ.value;
    const parentClassList = targ.parentElement?.classList;
    toggleClass(value, parentClassList)
}

export const objIsEmpty = (objectToCheck: any) => objectToCheck ? (Object.keys(objectToCheck).length === 0 ? true : false) : true