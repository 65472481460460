import React from 'react'
import { SVGTableLoader } from '../../assets/icons/SvgIcons'

const SectionLoader = () => {
    return (
        <div className="section-loader position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
            <SVGTableLoader className="section-loader__icon"/>
        </div>
    )
}

export default SectionLoader
