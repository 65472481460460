import { useState} from "react"

import { IGuardReportModel } from "common/interfaces/IDataFilter";
import { GuardReportModel } from "common/models/GuardReportModel";


export const useGuardReportFilter = (baseUrl: string, urlHasParams = false) => {
    const [filter, setFilter] = useState<IGuardReportModel>(new GuardReportModel())
    const [filterIsClear, setFilterIsClear] = useState<boolean>(true)

    const onFilterDropdownChange = (value: string | number | boolean, filterName: string) => {
        setFilter({ ...filter, [filterName]: value, hasValue: true })
    }
    const onFilterDropdownChangeAsync = (value: string | number | boolean, filterName: string) => {
        setFilter((prevState => ({
             ...prevState, [filterName]: value, hasValue: true
        })))
    }

    const onFilterDefaultSchoolYear = (schoolYear: string | number) => {
        setFilter(prev => ({...prev, schoolYears: schoolYear.toString()}))
    }

    const generateMultiFilterQuery = (filterValue: string, keyword: string) => {
        return filterValue.split(';').reduce((acc, id) => acc + `&${keyword}=${id}`,'')
    }


    const generateFilterQuery = (): string => {
        setFilterIsClear(false)
        let query = urlHasParams ? baseUrl : `${baseUrl}?`;
        if (filter.startDate) {
            query+= `&StartDate=${filter.startDate}`
        }
        if (filter.endDate) {
            query+= `&EndDate=${filter.endDate}`
        }
       
        if (filter.lateAbsenceRange) {
            query += generateMultiFilterQuery(filter.lateAbsenceRange, 'Attendance')
        }
        if (filter.numberOfDeparturesFromGeofence) {
            query += generateMultiFilterQuery(filter.numberOfDeparturesFromGeofence, 'NumberOfDeparturesFromGeofence')
        }

        if (filter.numberOfAssignedSchedule) {
            query += generateMultiFilterQuery(filter.numberOfAssignedSchedule, 'NumberOfAssignedSchedules')
        }

        if (filter.jobNameOrId) {
            query += `&Job=${filter.jobNameOrId}`
        }
       
        if (filter.guardNameOrEmployeeNumber) {
            query+= `&Guard=${filter.guardNameOrEmployeeNumber}`
        }

      
        if (filter.guardType) {
            query+= generateMultiFilterQuery(filter.guardType, 'GuardType')
        }
       
        if (filter.schoolYears) {
            query+= generateMultiFilterQuery(filter.schoolYears, 'SchoolYears')
        }

        return query.replace('?&', '?')
    }

    const onFilterClearClick = (callback?: () => Promise<any>) => {
        setFilter(new GuardReportModel())
        setFilterIsClear(true)
        callback && callback()
    }

    const onFilterSearchClearClick = (callback?: () => Promise<any>) => {
        setFilter({...filter})
        setFilterIsClear(true)
        callback && callback()
    }

    return {
        generateFilterQuery, onFilterDropdownChange, filter, filterIsClear, setFilter, onFilterClearClick, onFilterSearchClearClick, onFilterDropdownChangeAsync, onFilterDefaultSchoolYear
    }
}
