import DataTable from 'components/tables/DataTable'
import { FC, useState } from 'react'
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { matchWeekDays } from '../../../../utils/MatchWeekDays'
import { availablilityScheduleContent } from '../../../../content/dashboard/TablesContent'
import ActionDropdown from 'components/administration/ActionDropdown'
import { IGuard, IGuardAvailability } from 'common/interfaces/guards/IGuard'
import { DataService } from 'common/services/DataService'
import AddEditGuardAvilabilityModal from 'components/modal/guards/AddEditGuardAvilabilityModal'
import BaseModal from 'components/modal/BaseModal'
import ResourceDataService from 'common/services/ResourceDataService'
import { useRole } from 'customHooks/useRole'

type TProps = {
  guardDetails: IGuard,
  onSuccessAvailabilityRequest: () => Promise<void>,
}

const AvailabilityScheduleTab: FC<TProps> = ({ guardDetails, onSuccessAvailabilityRequest }) => {
  const { guardAvailabilities } = guardDetails
  const svc = new DataService<IGuardAvailability | IGuard>({ url: 'guardavailability' })
  const get = new ResourceDataService<any>({ url: `guardavailability` })
  const [showModal, setShowModal] = useState(false);
  const [availabilityInFocus, setAvailabilityInFocus] = useState<IGuardAvailability | null>({} as IGuardAvailability)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState<{ show: true | false, message?: string }>({ show: false, message: '' });
  const { isClientRole, isSupervisorRole } = useRole();

  const onEditHandler = (availability: IGuardAvailability) => {
    setAvailabilityInFocus(availability)
    setShowModal(true)
  }

  const onDeleteHandler = async (availability: IGuardAvailability) => {
    setShowDeleteModal(true)
    setAvailabilityInFocus(availability);
  }

  const onDeleteSubmit = async (availability: IGuardAvailability | null) => {
    try {
      const response = await get.getAll(`/${availability?.id}`)

      if (response.data['canBeDeleted'] === true) {
        try {
          svc.delete(availability?.id)
          onSuccessAvailabilityRequest()
        } catch (error: any) {
          console.log(error)
        }
        setShowDeleteModal(false);
        setValidationError(false);
        setAvailabilityInFocus(null);
        setShowSuccessModal({ show: true, message: 'Availability schedule was successfully deleted' });
      } else {
        setValidationError(true);
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
    setValidationError(false);
    setAvailabilityInFocus(null);
  }

  const onAvailabilityFormSubmitted = async (availability: IGuardAvailability, actionType: 'edit' | 'add') => {
    if (actionType === 'edit') {
      try {
        const { id, startDate, timeFrom, timeTo, repeatOn, endsOn } = availability;
        const response = await get.getAll(
          `/${id}?Edit=True&StartDate=${startDate}&TimeFrom=${timeFrom}&TimeTo=${timeTo}&RepeatOn=${repeatOn}&EndsOn=${endsOn}`
        )

        if (response.data['allShiftsCanBeRescheduled'] === true) {
          await svc.update(availability, availability.id)
          onSuccessAvailabilityRequest()
          setShowModal(false)
          setAvailabilityInFocus(null);
          setShowSuccessModal({ show: true, message: 'Availability schedule was successfully edited' })
        } else {
          setValidationError(true);
        }
      } catch (error: any) {
        console.log(error)
      }
    } else {
      try {
        await svc.create(availability)
        onSuccessAvailabilityRequest()
        setShowModal(false)
        setShowSuccessModal({ show: true, message: 'Availability schedule was successfully added' })
      } catch (error: any) {
        console.log(error)
      }
    }
  }

  const onCancel = () => {
    setShowModal(false);
    setValidationError(false);
    setAvailabilityInFocus(null);
  }

  return (
    <div>
      <div className="d-flex align-items-center flex-wrap">
        <h5 className="mb-0 mr-5">Availability Schedule</h5>
        {
           !isClientRole() && !isSupervisorRole() &&
          <button
            className="btn btn-aqua-blue ml-0 ml-md-2 mr-5 mr-md-0 mt-2 mt-md-0"
            onClick={() => setShowModal(true)}
          >
            Add an Availability Schedule
          </button>
        }
      </div>

      {/* {Delete modal} */}
      {
        <BaseModal
          show={showDeleteModal}
          onCancel={onDeleteCancel}
          cancelBtnText={validationError ? "Close" : "Cancel"}
          onSubmit={validationError ? undefined : () => onDeleteSubmit(availabilityInFocus)}
          submitBtnText={'Confirm'}
          className={'delete-schedule-modal'}
          title={validationError ? '' : 'Are you sure you want to remove this availablity schedule?'}
        >
          {validationError && <div>This change affects one or more shifts. Please update this guard's assigned schedules/shifts before
            removing this availability schedule.</div>}
        </BaseModal>
      }

      {/* {Success modal} */}
      {
        <BaseModal
          show={showSuccessModal.show}
          onCancel={() => setShowSuccessModal({ show: false })}
          cancelBtnText={'Close'}
          className={'success-modal'}
          title={showSuccessModal.message}
        >
        </BaseModal>
      }

      {showModal &&
        <AddEditGuardAvilabilityModal
          availabilitySchedule={availabilityInFocus || undefined}
          guardDetails={guardDetails}
          onCancel={onCancel}
          onAvailabilityFormSubmitted={onAvailabilityFormSubmitted}
          validationError={validationError}
        />
      }

      <DataTable
        tableClass="mt-4"
        thead={availablilityScheduleContent.thead}
        ignoreCols={[0]}
        ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [3] : [])]}
        tbody={guardAvailabilities ? guardAvailabilities.map(schedule => ({
          id: schedule.id,
          startEndDate: `${momentDateFormat(schedule.startDate)} - ${momentDateFormat(schedule.endsOn)}`,
          startEndTime: `${momentHoursFormat(schedule.timeFrom)} - ${momentHoursFormat(schedule.timeTo)}`,
          repeat: `Every week: ${matchWeekDays(schedule.repeatOn)}`,
        })) : []}
      >
        {
          (id, _row, rowIndex) => (
            <>
            { !isClientRole() && !isSupervisorRole() && 
              <td>
                <ActionDropdown
                  onDelete={() => onDeleteHandler(guardAvailabilities[rowIndex])}
                  onEdit={() => onEditHandler(guardAvailabilities[rowIndex])}
                  key={id}
                />
              </td>
            }
            </>
          )
        }
      </DataTable>
    </div>
  );
}

export default AvailabilityScheduleTab
