import { IZoneDropdown } from 'common/interfaces/dropdowns/IZoneDropdown';
import ResourceDataService from 'common/services/ResourceDataService';
import { useState, useEffect } from 'react';

const useZones = () => {
	const [zones, setZones] = useState<IZoneDropdown[]>([]);
	const zoneSvc = new ResourceDataService<IZoneDropdown[]>({ url: 'zone/dropdown' });
	useEffect(() => {
		const requestCities = async () => {
			const { data: zones } = await zoneSvc.getAll();
			if (zones.length === 0) return;
			const allZones = zones.sort((a, b) =>
				a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
			);
			setZones(allZones);
		};
		requestCities();
	}, []);
	return zones;
};

export default useZones;
