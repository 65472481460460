import moment from 'moment/moment.js'
import { IGuard, IGuardAvailability, IGuardDevice } from "../interfaces/guards/IGuard";
import { nextSchoolYear, setHoursAndMinutes, UTCTimeNow } from "../../utils/DateFormatting";

export class GuardAvailability implements IGuardAvailability {
    uid = 1;
    startDate = setHoursAndMinutes(moment().format());
    timeFrom = UTCTimeNow(true, false) as string;
    timeTo = UTCTimeNow(true, false) as string;
    repeatTimes = 0;
    repeatPeriod = "week";
    repeatOn = "";
    endsOnSchoolYear = false;
    endsDate = "";
    holidays = [];
    endsOn =  nextSchoolYear()
    endsOnSelected = null;
    scheduleHasBeenChanged = false
}

export class GuardDevice implements IGuardDevice {
    brand = "";
    model = "";
    serialNumber = "";
    aspMobile = "";
}

export class Guard implements IGuard {
    // id = null;
    firstName = "";
    middleName = "";
    lastName = "";
    homeMobile = "";
    personalMobile = "";
    email = null;
    // firstAddress = "";
    // secondAddress = "";
    firstStreet = "";
    secondStreet = "";
    city = "";
    cityId = 0;
    postalCode = "";
    province = "";
    employeeId = "";
    aspStartDate = setHoursAndMinutes(moment().format());
    profileImage = "";
    guardAvailabilities = [new GuardAvailability()];
    guardBookedLeaves = [];
    jobScheduleShifts = [];
    jobSchedules = [];
    device = new GuardDevice();
    guardReport = [];
    latesOrAbsences = 0;
    latitude = null;
    longitude = null;
    isSupervisor = false;
}