import { IVocabulary } from "../common/interfaces/vocabulary/IVocabulary"

const parseIds = (mapperIds: string | number) => mapperIds.toString().split(';')

const findInVocabulary = (mapper: IVocabulary[], splitedArr: string[]) => {
    return mapper.filter(item => splitedArr.includes(item.id !==null ? item.id.toString() : '')).map(item => item.name)
}

export const matchOptionWithName = (mapperIds: string | number, mapper: IVocabulary[] | undefined) => {
    if (mapper) {
        if (mapperIds === undefined) return "-";
        if (mapperIds === null) return mapperIds

        if (isNaN(+mapperIds) && !mapperIds.toString().includes(';')) return mapperIds;

        if (mapper.some(item => item.id === null || item.name === null)) return "";

        const names = findInVocabulary(mapper, parseIds(mapperIds))

        return names.length !== 0 ? names.join(', ') : mapperIds
    }
}

export const matchOptionWithoutOther = (mapperIds: string | number, mapper: IVocabulary[] | undefined) => {
    if (mapper) {
        if (mapperIds === null) return mapperIds
        return findInVocabulary(mapper, parseIds(mapperIds)).join(', ')
    }
}
