import { FC, memo } from 'react'
import BaseModal from 'components/modal/BaseModal'
import { IBaseModalProps } from 'common/interfaces/IModal'
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary'
import { matchOptionWithName } from 'utils/MatchOptionWithName'
import { momentDateFormat, momentHoursFormat } from 'utils/DateFormatting'
import { IReportAttachment } from 'common/interfaces/reports/xguard-reports/IGuardMessages'

interface IProps extends IBaseModalProps {
    report: any
    vocabulary?: Array<IVocabulary[] | undefined>
    typeLabel?: string
    handleIsResolvedAction?: (reportId: number, isResolved: boolean) => void
    isASPUser?: boolean
}

const ReportsDetailsModal: FC<IProps> = memo(({ onCancel, title,
    report, vocabulary, typeLabel, handleIsResolvedAction, isASPUser
    }) => {
    return (
        <BaseModal
            show
            onCancel={onCancel}
            cancelBtnText={"Close"}
            title={title}
            className={'report-details-modal'}
        >
            <div className="row">

                {/* Start Left Col */}
                <div className="col-md-6">
                    { report.guardName && 
                    <div className="row">
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">From:</h6>
                        </div>
                        <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                { report.guardName }
                            </p>
                        </div>
                    </div>
                    }

                    { report.employeeId && 
                        <div className="row">
                            <div className="col-sm-4 details-information__title mb-3 ">
                                <h6 className="mb-0">Employee ID:</h6>
                            </div>
                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                <p className="mb-0">
                                    { report.employeeId }
                                </p>
                            </div>
                        </div>
                    }
                    
                    {
                    report.location &&
                    <div className="row">
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Location:</h6>
                        </div>
                        <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                            <p className="mb-0">
                                { report.location }
                            </p>
                        </div>
                    </div>
                    }
                    
                    {  report.jobId && 
                        <div className="row">
                            <div className="col-sm-4 details-information__title mb-3 ">
                                <h6 className="mb-0">Job ID:</h6>
                            </div>
                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                <p className="mb-0">
                                    { report.jobId }
                                </p>
                            </div>
                        </div>
                    }
                </div>
                {/* End Left Col */}

                {/* Start Right Col */}
                <div className="col-md-6">
                    { report.shiftPeriod && 
                    <div className="row">
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Shift:</h6>
                        </div>

                        { vocabulary &&
                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                <p className="mb-0">
                                    { momentDateFormat(report.shiftStartDate) } | { matchOptionWithName(report.shiftPeriod, vocabulary[0]) }
                                </p>
                            </div>
                        }
                    </div>
                    }

                    <div className="row">
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Message Time:</h6>
                        </div>
                        { (report.date || report.created) &&
                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                <p className="mb-0">
                                    { report.created && `${momentDateFormat(report.created)} | ` }
                                    { momentHoursFormat(report.created ? report.created :  report.date) }
                                </p>
                            </div>
                        }
                    </div>
                    
                    { report.type &&
                        <div className="row">
                            <div className="col-sm-4 details-information__title mb-3 ">
                                <h6 className="mb-0">{ typeLabel ? typeLabel : "" }:</h6>
                            </div>
                            { vocabulary &&
                                <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                    <p className="mb-0">
                                        { matchOptionWithName(report.type, vocabulary[1]) }
                                    </p>
                                </div>
                            }
                        </div>
                    }

                    { report.directlyImpacted !== undefined &&
                        <div className="row">
                            <div className="col-sm-4 details-information__title mb-3 ">
                                <h6 className="mb-0">Guard Impacted by Incident:</h6>
                            </div>

                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                <p className="mb-0">
                                    { report.directlyImpacted ? 'Yes' : 'No' }
                                </p>
                            </div>
                        </div>
                    }

                    { report.isResolved !== undefined &&
                        <div className="row">
                            <div className="col-sm-4 details-information__title mb-3">
                                <h6 className="mb-0">Resolved:</h6>
                            </div>

                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 form-group">
                              {/* Start CheckBox */}
                              { isASPUser ? (report.isResolved ? <p className="mb-0">Yes</p> : <p className="mb-0">No</p>) :
                                <div
                                className="custom-control custom-checkbox d-flex flex-wrap align-items-center">
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={'isResolved'}
                                            name={'isResolved'}
                                            checked={report.isResolved}
                                            onChange={() => handleIsResolvedAction &&
                                                handleIsResolvedAction(report.id as number, !report.isResolved)}
                                        />
                                        <label className="custom-control-label mb-0" htmlFor={'isResolved'}></label>
                                    </div>
                                </div>
                                }
                              {/* End CheckBox */}
                            </div>
                        </div>
                    }

                    { report.topic &&
                    <div className="row">
                        <div className="col-sm-4 details-information__title mb-3 ">
                            <h6 className="mb-0">Topic:</h6>
                        </div>
                        { vocabulary &&
                            <div className="col-sm-8 details-information__value d-flex flex-row align-items-center mb-3 ">
                                <p className="mb-0">
                                    { matchOptionWithName(report.topic, vocabulary[1]) }
                                </p>
                            </div>
                        }
                    </div>
                    }

                </div>
                {/* End Right Col */}

            </div>
            
            { report.attachments && report.attachments.length !== 0 &&
                <div className="row">
                    <div className="col-12 details-information__title mb-3 ">
                        <h6 className="mb-0">Attachments:</h6>
                    </div>
                    <div className="col-12 details-information__value d-flex flex-row align-items-center mb-3 ">
                        <p className="mb-0">
                            {
                                report.attachments.map((item: IReportAttachment) => (
                                    item.downloadLink &&
                                    <a className="pr-2" href={item.downloadLink} target="_blank" rel="noreferrer">
                                        <u>{ item.fileName }</u>
                                    </a>
                                ))
                            }
                        </p>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12 details-information__title mb-3 ">
                    <h6 className="mb-0">Message:</h6>
                </div>
                <div className="col-12 details-information__value d-flex flex-row align-items-center mb-3 ">
                    <p className="mb-0">
                        { report.message  ? report.message : '-' }
                    </p>
                </div>
            </div>
        </BaseModal>
    )
})

export default ReportsDetailsModal
