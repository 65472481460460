export enum Reports {
    download = 'download',
    print = 'print',
    pdf = 'pdf',
    excel = 'excel',
    xlsx = 'xlsx',
    blob = 'blob',
    guard = 'guard',
    job = 'job',
    schools = 'schools',
    holidays = 'holidays'
}
