export const guardCoverageOpts = [
    {
        id: 1,
        name: "Coverage"
    },
    {
        id: 2,
        name: "No Coverage"
    }
]

export const healthAndChecksResponceOpts = [
    {
        id: 1,
        name: "All"
    },
    {
        id: 2,
        name: "Yes"
    },
    {
        id: 3,
        name: "No"
    },
    {
        id: 4,
        name: "No Response"
    }
]

export const typeOLeaveOpts = [
    {
        id: 1,
        name: "All"
    },
    {
        id: 2,
        name: "Multiple Days"
    },
    {
        id: 3,
        name: "Single Day"
    },
    {
        id: 4,
        name: "1-2 Shifts"
    }
]

export const LeaveStatusOpts = [
    {
        id: 2, 
        name: 'Pending'
    },
    {
        id: 1, 
        name: 'Approved'
    },
    {
        id: 3, 
        name: 'Denied'
    },
    {
        id: 4, 
        name: 'Expired'
    },
    {
        id: 5, 
        name: 'All'
    }
]