import { TheadData } from "components/tables/DataTable"

export const guardArrivalReportsThead = {
  thead: [
    {
      label: "Date",
      sortable: true,
    },
    {
      label: "Time Of Report",
      sortable: true,
    },
    {
      label: "Guard Name",
      sortable: true, 
    },
    {
      label: "Employee ID",
      sortable: true, 
    },
    {
      label: "Job ID",
      sortable: true, 
    },
    {
      label: "Shift Period",
      sortable: true, 
    },
    {
      label: "Arrival Report",
      sortable: true, 
    },
    {
      label: "Message",
      sortable: true, 
    },
  ]
}

export const guardMessagesThead = {
  thead: [
    {
      label: "Date",
      sortable: true,
    },
    {
      label: "Time",
      sortable: true,
    },
    {
      label: "Guard Name",
      sortable: true, 
    },
    {
      label: "Employee ID",
      sortable: true, 
    },
    {
      label: "Topic",
      sortable: true, 
    },
    {
      label: "Message",
    },
  ]
}

export const incidentReportsThead = {
  thead: [
    {
      label: "Date",
      sortable: true,
    },
    {
      label: "Guard Name",
      sortable: true, 
    },
    {
      label: "Employee ID",
      sortable: true, 
    },
    {
      label: "Job ID",
      sortable: true, 
    },
    {
      label: "Type of Incident",
      sortable: true, 
    },
    {
      label: "Guard Was <br /> Directly Impacted <br /> by Incident",
      sortable: true, 
    },
    {
      label: "Message",
    },
    {
      label: "Attachments",
    },
    {
      label: "Resolved",
    },
  ]
}

export const healthAndSafetyThead = {
  thead: [
    {
      label: "Date",
      sortable: true,
    },
    {
      label: "Guard Name",
      sortable: true, 
    },
    {
      label: "Employee ID",
      sortable: true, 
    },
    {
      label: "Response",
      sortable: true, 
    },
    {
      label: "Location When <br /> Responded",
    },
  ]
}

export const guardAppLeaveRequestsThead = {
  thead: [
    {
      label: "Leave Start Date",
      sortable: true,
    },
    {
      label: "Leave End Date",
      sortable: true,
    },
    {
      label: "Shift Period(s)",
      sortable: true, 
    },
    {
      label: "Guard Name",
      sortable: true,  

    },
    {
      label: "Employee <br> ID",
      sortable: true, 
    },
  
    {
      label: "Reason",
      sortable: true,
    },
    {
      label: "Date Request <br> Received",
      sortable: true,
    },
    {
      label: "Jobs Affected",
      sortable: true, 
    },
    {
      label: "Status",
      sortable: true, 
    },
    {
      label: "Date of Last <br> Status Update",
      sortable: true, 
    },
    {
      label: "Approve",
    },
  ] as TheadData[]
}

export const guardAppProfileRequestsThead = {
  thead: [
    {
      label: "Date Request Received",
      sortable: true,
    },
    {
      label: "Guard Name",
      sortable: true, 
    },
    {
      label: "Employee ID",
      sortable: true, 
    },
    {
      label: "Update Requested",
    },
    {
      label: "Approve",
    },
  ]
}

export const guardTestReportThead = {
  thead: [
    {
      label: "Date",
      sortable: true,
    },
    {
      label: "Guard Name",
      sortable: true, 
    },
    {
      label: "Employee ID",
      sortable: true, 
    },
  ]
}