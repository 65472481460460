import { useState } from "react"

interface IProps<T> {
    defaultState: T
    defaultSelectedItem?: any
}

export const useModals = <T extends object, P>(props: IProps<T>) => {
    const { defaultState, defaultSelectedItem } = props
    const [modals, setModals] = useState<T>(defaultState)
    const [itemInFocus, setItemInFocus] = useState<P>(defaultSelectedItem ? defaultSelectedItem : {} as P)

    return {
        modals, setModals, itemInFocus, setItemInFocus
    }
}
