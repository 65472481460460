import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import { NotificationTypes } from 'common/interfaces/INotification';
import { IClient, IClientContact } from 'common/interfaces/clients/IClient';
import { ICityDropdown } from 'common/interfaces/dropdowns/ICityDropdown';
import { Client } from 'common/models/Client';
import { DataService } from 'common/services/DataService';
import ResourceDataService from 'common/services/ResourceDataService';
import Layout from 'components/layout/Layout';
import SectionTitle from 'components/titles/SectionTitle';
import ImageUpload from 'components/upload/ImageUpload';
import AppContext from 'context/AppContext';
import { useModelDetails } from 'customHooks/useModelDetails';
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { matchOptionWithName } from 'utils/MatchOptionWithName';
import './Client.scss';
import { useTenant } from 'customHooks/useTenant';
import { makeFullName } from 'utils/FullName';

const ClientConfirmation = () => {
	const { detailsModel: clientData, userId } = useModelDetails<IClient>(
		'client/specific',
		new Client(),
		{ include: ['PrimaryContact'] },
		true
	);
	const {setTenantToLS} = useTenant(true);
	const appContext = useContext(AppContext);
	const [orgLogo, setOrgLogo] = useState(clientData.organizationLogo || '');
	const [primaryContact, setPrimaryContact] = useState<IClientContact>({} as IClientContact);
	const [cities, setCities] = useState<ICityDropdown[]>([]);
	const { vocabulary: provinces } = useVocabulary(VocabularyEnum.province, true);
	const { vocabulary: states } = useVocabulary(VocabularyEnum.state, true);
	const { vocabulary: countries, getVocabulary: getCountries } = useVocabulary(VocabularyEnum.country);
	useEffect(() => {
		const getCities = async () => {
			const citySvc = new ResourceDataService<ICityDropdown[]>({ url: 'city/dropdown' });
			try {
				const resCities = await citySvc.getAll();
				setCities(resCities.data);
			} catch (e: any) {
				appContext.showNotification(NotificationTypes.danger, e.message);
			}
		};
		getCities();
		getCountries();
	}, []);

	const onReplaceLogo = async (logo: string) => {
		const clientDs = new DataService<{ organizationLogo: string }>({ url: 'client', tenantId: userId });
		try {
			await clientDs.update({ organizationLogo: logo });
			setOrgLogo(logo);
		} catch (e: any) {
			appContext.showNotification(NotificationTypes.danger, e.message);
		}
	};

	useEffect(() => {
		clientData.organizationLogo && setOrgLogo(clientData.organizationLogo);
	}, [clientData.organizationLogo]);

	useEffect(() => {
		clientData.primaryContact && setPrimaryContact(clientData.primaryContact);
	}, [clientData.primaryContact]);
	
	const onChooseCurrentTenant = () => {
		setTenantToLS(userId);
		const {organizationName, province, country, city, postalCode, firstAddress, id} = clientData;
		appContext.setCurrentClient({organizationName, province, country, city, postalCode, firstAddress, id, clientContacts: []})
		// setTimeout(() => {window.location.reload()}, 500)
	}

	return (
		<Layout
			breadcrumbs={{
				links: [
					{
						title: 'Clients',
						link: 'clients',
					},
					{
						title: 'Add a Client',
						link: 'clients/add',
					},
				],
				currentPageTitle: 'Confirmation',
			}}
		>
			<div className="row mb-3">
				<div className="col-12">
					<SectionTitle title={`Client ${clientData.organizationName} ${clientData.institutionCode ? `(${clientData.institutionCode}) ` : '' }has been added`} />
				</div>
			</div>

			<section className="mt-4 client-confirmation">
				<div className="row">
					<div className="col-12 col-md-3 col-lg-3">
						<div className="form-group">
							<ImageUpload
								onClear={() => onReplaceLogo('')}
								image={orgLogo}
								onChange={onReplaceLogo}
								label="Replace Logo"
							/>
						</div>
					</div>

					<div className="col-12 col-md-7 col-lg-4 col-xl-4 mr-0 mr-lg-5 client-confirmation__content-card fs-14">
						<p className="mb-1">{clientData.firstAddress}</p>
						{clientData.secondAddress ? <p className="mb-1">{clientData.secondAddress}</p> : null}
						<p className="mb-3">
							{matchOptionWithName(clientData.city, cities)},{' '}
							{matchOptionWithName(clientData.province, [...provinces, ...states])} <br /> {clientData.postalCode?.toUpperCase()}{' '}
							{matchOptionWithName(clientData.country || '', countries)}
						</p>
						{clientData.phoneNumber ? (
							<p className="mb-1 d-flex justify-content-between">
								<span>Company Phone Number:</span> <span className='client-confirmation__text-value'>{clientData.phoneNumber}</span>
							</p>
						) : null}
						{clientData.email ? (
							<p className="mb-1 d-flex justify-content-between">
								<span>Company Email Address:</span> <span className='client-confirmation__text-value'>{clientData.email}</span>
							</p>
						) : null}
					</div>
					<div className="col-12 col-md-7 col-lg-4 col-xl-4 mt-4 mt-md-0 client-confirmation__content-card fs-14">
						<h5>Primary Contact</h5>
						<p className="mb-1">
							{makeFullName(primaryContact)}
						</p>
						{primaryContact?.position ? <p className="mb-3">{primaryContact?.position || ''}</p> : null}
						{primaryContact?.phoneNumber ? (
							<p className="mb-1 d-flex justify-content-between">
								<span>Phone Number:</span> <span className='client-confirmation__text-value'>{primaryContact?.phoneNumber || ''}</span>
							</p>
						) : null}
						{primaryContact?.mobileNumber ? (
							<p className="mb-1 d-flex justify-content-between">
								<span>Mobile Number:</span> <span className='client-confirmation__text-value'>{primaryContact?.mobileNumber}</span>
							</p>
						) : null}
						{primaryContact?.email ? (
							<p className="mb-1 d-flex justify-content-between">
								<span>Email Address:</span> <span className='client-confirmation__text-value'>{primaryContact?.email}</span>
							</p>
						) : null}
					</div>
				</div>

				<div className="row justify-content-center mt-4">
					<div className="col-12 col-md-3 col-xl-2 mt-2">
						<Link
							className="btn btn-aqua-blue btn-block d-flex justify-content-center"
							to="/administration/manage-users"
							onClick={onChooseCurrentTenant}
						>
							Add Client Contact
						</Link>
					</div>
					<div className="col-12 col-md-3 col-xl-2 mt-2">
						<Link
							className="btn btn-aqua-blue btn-block d-flex justify-content-center"
							to="/administration/schools-years"
							onClick={onChooseCurrentTenant}
						>
							Add School Year
						</Link>
					</div>
					<div className="col-12 col-md-3 col-xl-2 mt-2">
						<Link
							className="btn btn-aqua-blue btn-block d-flex justify-content-center"
							to={{
								pathname: '/administration/manage-users',
								state: {activeTab: 'asp-admin'}
							}}
							onClick={onChooseCurrentTenant}
						>
							Add Admin Users
						</Link>
					</div>
					<div className="col-12 col-md-3 col-xl-2 mt-2">
						<Link
							className="btn btn-aqua-blue btn-block d-flex justify-content-center"
							to="/administration/settings"
							onClick={onChooseCurrentTenant}
						>
							Add Settings
						</Link>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ClientConfirmation;
