import React, { FC } from 'react'
import { NotificationTypes } from '../../common/interfaces/INotification'

type Props = {
    type: NotificationTypes,
    msgs: Array<string> | string
    onClick: () => void
}

const Alert: FC<Props> = ({ type, msgs, onClick }) =>
    <div className={`position-fixed d-flex align-items-center align-items-md-start justify-content-center justify-content-md-between fixed-top ml-3 ml-sm-auto mr-3 mr-sm-auto mr-md-3 mt-3 alert alert-${type}`} role="alert">
        {
            typeof msgs === "string"
            ? <b>{ msgs }</b>
            : msgs && msgs.map(msg => <b key={msg}>{ msg }</b>)
        }
        <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => onClick()}
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

export default Alert
