import ResourceDataService from "../common/services/ResourceDataService";
import {Reports} from "../common/enums/Reports";

export const useDowloandFile = () => {

    const createLink = (url: string, typeFile: Reports, tabName?: string, page?: string) => {
        const link = document.createElement('a');
        link.href = url
        document.body.appendChild(link);
        if (typeFile === Reports.pdf) {
            link.setAttribute('download', `${tabName ?  tabName + '-' : ''}${page + '-'}${new Date().toISOString()}.${Reports.pdf}`);

        } else {
            link.setAttribute('download', `${tabName ?  tabName + '-' : ''}${page + '-'}${new Date().toISOString()}.${Reports.xlsx}`);

        }
        setTimeout(() => {
            link.click()
            document.body.removeChild(link)
        }, 1)

    }
    const createIframe = (url: string) => {
        let iframe = document.createElement('iframe');
        iframe.id = 'pdfIframe'
        iframe.className = 'pdfIframe'
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = url;
        iframe.onload = function () {
            setTimeout(function () {
                iframe.focus();
                if (iframe.contentWindow) iframe.contentWindow.print();
            },);
        };
    }

    
    const dowloandFile = async (page: string, typeFile: Reports, actionType: string,
        id?: string | number, tabName?: string, query?: string, isDocument = false) => {

        const svc = new ResourceDataService<BlobPart>({url: "document"})

        try {
            const svcPromise = isDocument ? svc.getDocument(Reports.blob, page, typeFile, query) :
            svc.getFile(Reports.blob, page, typeFile, id, tabName, query)

            const blob = await svcPromise;
            const blobType = typeFile === Reports.pdf ? 'application/pdf' :
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const url = window.URL.createObjectURL(new Blob([blob as unknown as Blob], {type: blobType}));
  
            createLink(url, typeFile, tabName, page);

            if (actionType === Reports.print) createIframe(url)

        } catch (error: any) {
            return console.log(error)
        }

    }
    return {dowloandFile}
}
