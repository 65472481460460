import React, { FC } from 'react';
import BaseModal from './BaseModal';
type Props = {
    show: boolean;
    onCancel: () => void;
    onSubmit: () => void;
    title: string;
    description: string;
    submitBtnText?: string;
}
const SimpleModal: FC<Props> = ({show, onCancel, onSubmit, title, description, submitBtnText = 'Submit'}) => {
	return (
		<BaseModal
			show={show}
			onCancel={onCancel}
			submitBtnText={submitBtnText}
			cancelBtnText={'Cancel'}
			onSubmit={onSubmit}>
			<div>
				<h4>{title}</h4>
				<p>
					{description}
				</p>
			</div>
		</BaseModal>
	);
};

export default SimpleModal;
