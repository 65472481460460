import moment from "moment";
import { useState } from "react";
import { IGuardAvailability } from "../common/interfaces/guards/IGuard";
import { IHoliday } from "../common/interfaces/IHoliday";
import { IJobSchedule } from "../common/interfaces/jobs/IJob";
import { inputDataChecker } from "../utils/InputDataChecker";
import { useHolidays } from "./useHolidays";

type Model = {
    [index: string]: any
}

export const useCalendarForm = <T extends Model> (mainObject: T, keyName: string) => {
   
    const [calendarFormData, setCalendarFormData] = useState(mainObject)
    const [holidays, setHolidays] = useState<{ original: IHoliday[], filtered: IHoliday[] }>({ original: [], filtered: [] })
    const { getWeekDayNum, checkIfDayHasHoliday } = useHolidays()

    const onCalendarChange = (originalData: T, event: React.ChangeEvent<HTMLInputElement> | string | Date | boolean | null,
        fieldName?: string | null, id?: number, callBack?: (result: T) => void) => {
        const res = inputDataChecker(event, fieldName);
        if (id) {
            const formDataCopy = {...originalData};
            Array.from(formDataCopy[keyName] as IGuardAvailability[] | IJobSchedule[]).forEach((item: IGuardAvailability | IJobSchedule) => {
                if (item.uid === id || item.id === id) {
                    if (res.field === "repeatOn") {
                        let daysArr = item.repeatOn === "" ? [] : getWeekDayNum(item.repeatOn);
                        if (daysArr.length === 0 ) {
                            if(res.value !== "") {
                                daysArr.push(res.value.toString())
                            }
                        } else {
                            if(res.value !== "") {
                                const index = daysArr.indexOf(res.value.toString());
                                index > -1 ? daysArr.splice(index, 1) : daysArr.push(res.value.toString());
                            }
                        }
                        item.repeatOn = daysArr.join(";");

                    } else {
                        if (res.field === "schoolBoardId") {
                            item.schoolId = 0
                        }
                        item[res.field] = res.value
                    }
                    const allowedFieldsForTrackingHolidays = ['startDate', 'endsOn', 'repeatOn']
                    if (allowedFieldsForTrackingHolidays.includes(res.field)) {
                        item.holidays = trackHolidays(item, res.field, id, item.shiftPeriod !== undefined ? true : false)
                    }
                }
                return item
            })
            callBack && callBack(formDataCopy)
            setCalendarFormData(formDataCopy)
        }
    }

    const trackHolidays = (schedule: IGuardAvailability | IJobSchedule, fieldName: string, id: number, defaultSet = false) => {
        const holidaysCopy = [...holidays.original]
        return checkIfDayHasHoliday<IGuardAvailability | IJobSchedule>(holidaysCopy, schedule)
        .filter(holiday => getWeekDayNum(holiday.dayOfWeek).some(day => getWeekDayNum(schedule.repeatOn).includes(day)))
        .map(holiday => {
            holiday.parentId = id;
            if (defaultSet) {
                if (schedule.holidays.length === 0) {
                    holiday.remove = defaultSet
                } else {
                    const mathchedHoliday = schedule.holidays.find((item: any) => moment(item.startDate).isSame(holiday.startDate) && moment(item.endDate).isSame(holiday.endDate))
                    holiday.remove = mathchedHoliday ? mathchedHoliday.remove : defaultSet
                }
            } else {
                holiday.remove = false
            }
            return holiday
        })

    }

    const onHolidayChange = (holidayId: number, yesOrNo: boolean, parentId: number, keyName: string, originalData: T) => {
        const formDataCopy = {...originalData};
        const matchCondition = (scheduleId: number | undefined) => scheduleId === parentId
        const scheduleInFocusIndex = (formDataCopy[keyName] as IGuardAvailability[] | IJobSchedule[]).findIndex(schedule => matchCondition(schedule.id) || matchCondition(schedule.uid));
        if (scheduleInFocusIndex > -1) {
            formDataCopy[keyName][scheduleInFocusIndex].holidays = [...formDataCopy[keyName][scheduleInFocusIndex].holidays].map((holiday: IHoliday) => {
                let shallowHolidayCopy = {...holiday}
                if (shallowHolidayCopy.id === holidayId) {
                    shallowHolidayCopy.remove = yesOrNo ? true : false;
                }
                return shallowHolidayCopy
            });
            setCalendarFormData({...formDataCopy})
        }
    }

    const onRemoveItemFromCalendar = (scheduleInFocusId: number, keyName: string, originalData: T) => {
        const formDataCopy = {...originalData};

        (formDataCopy[keyName] as IGuardAvailability[] | IJobSchedule[])= formDataCopy[keyName]
        .filter((schedule: IGuardAvailability | IJobSchedule) => (
            schedule.uid !== scheduleInFocusId
        ))
        setCalendarFormData({...originalData, ...formDataCopy})
    }

    const onCalendarSubmit = (originalData: T, callBack?: (res: T) => void) => {
        debugger
        const formDataCopy = [...originalData[keyName]];
        const lastObject = {...formDataCopy[formDataCopy.length - 1]};
        // const lastId = lastObject.id ? lastObject.id : lastObject.uid

        delete lastObject.id;
        lastObject.guardId && delete lastObject.guardId;
        lastObject.guardName && delete lastObject.guardName;
        lastObject.assigned = false
        const maxId = Math.max(...formDataCopy.map(item => item.id? item.id : item.uid), 0)
        lastObject.uid = maxId + 1;
        lastObject.edited = true;
        formDataCopy.push(lastObject)

        const result = { ...originalData,
            [keyName]: formDataCopy
        }
        callBack && callBack(result);
        setCalendarFormData(result)
    }

    return {
        onCalendarSubmit, calendarFormData, setCalendarFormData, onCalendarChange, holidays, setHolidays,
        onHolidayChange, onRemoveItemFromCalendar, trackHolidays
    }
}
