import React, {FC, useContext} from 'react'
import { SVGCheck, SVGCancel, SVGCheckIcon } from '../../assets/icons/SvgIcons'

import { ModelStatus } from '../../common/enums/Actions'
import { IClient } from '../../common/interfaces/clients/IClient'
import { IGuard } from '../../common/interfaces/guards/IGuard'
import { IJobDetails } from '../../common/interfaces/jobs/IJob'
import "./ModelStatusesNew.scss"

type Props = {
    item: IJobDetails | IGuard | IClient
}

const ModelStatusesNew: FC<Props> = ({ item }) => {
    return (
        <>
            {
                item.isActive === ModelStatus.active && (
                  <div className="d-flex align-items-center justify-content-end">
                    <SVGCheckIcon {...{width: 24, height: 24, stroke: "#fff", className: 'mr-2'}} />
                    <div className="status-title">Active</div>
                  </div>
                )
            }
            {
                item.isActive === ModelStatus.inactive && <h6 className="ml-2 mb-0 d-flex align-items-center justify-content-end text-uppercase"><SVGCancel />{ ModelStatus.inactive }</h6>
            }
            {
                item.isActive === ModelStatus.test && <h6 className="ml-2 mb-0 d-flex align-items-center justify-content-end text-uppercase">{ ModelStatus.test }</h6>
            }
            {
                item.isActive === ModelStatus.pending && <h6 className="ml-2 mb-0 d-flex align-items-center justify-content-end text-uppercase">
                    {/*{ AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) ? "Deactivation Requested" : ModelStatus.inactive}*/}
                    Deactivation Requested
                </h6>
            }
        </>
    )
}

export default ModelStatusesNew;
