import React from 'react';
import { SVGHoliday } from '../assets/icons/SvgIcons';
import CustomTooltip from '../components/custom-tooltip/CustomTooltip';

export const getHolidayField = (uid: number, originalData: Array<any>) => {
	const singleItem = originalData.find((item) => item.id === uid);

	if (singleItem) {
		return singleItem.holiday ? (
			<div className="holidays-item__name d-flex align-items-stretch ml-auto mr-1 justify-content-start">
				<div className="mr-2 d-flex align-items-center">
					<CustomTooltip bodyText={singleItem.holiday} keyValue={uid}>
						<small>
							<SVGHoliday />
						</small>
					</CustomTooltip>
				</div>
				<CustomTooltip bodyText={singleItem.holiday} keyValue={uid + '1'}>
					<span className="d-inline-block">{singleItem.holiday}</span>
				</CustomTooltip>
			</div>
		) : (
			''
		);
	} else {
		return '';
	}
};
