import React, {useContext, useEffect, useState} from 'react'
import DatePicker from "react-datepicker";
import {datePickerFormat, setCustomTimeInUTC, setHoursAndMinutes, timeWithoutSecondsFormat, UTCtimeToLocal,} from "../../../utils/DateFormatting";
import {SVGCalendar, SVGEraser} from "../../../assets/icons/SvgIcons";
import {useDatapickerSvg} from "../../../customHooks/useDatapickerSvg";
import CustomTimePicker from "../../../components/custom-time-picker/CustomTimePicker";
import {useListFilterJobShiftReport} from "../../../customHooks/useListFilterJobShiftReport";
import SectionTitle from "../../../components/titles/SectionTitle";
import Layout from "../../../components/layout/Layout";
import AutocompleteField from "../../../components/autosuggestField/AutocompleteField";
import ListGuardLocationReport from "./ListGuardLocationReport";
import {IValidationBody, ValidationRules} from "../../../common/interfaces/IValidation";
import {highlightError, removeHighlightError, validateFiled} from "../../../utils/Validation";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import AppContext from "../../../context/AppContext";

const GuardLocationReport = () => {

    const {showDatapicker, changeDatapicker} = useDatapickerSvg()
    const [showTable, setShowTable] = useState(false)
    const [generateQuery, setGenerateQuery] = useState('')
    const {generateFilterQuery, onFilterDropdownChange, filter, onFilterClearClick, onFilterDropdownChangeAsync} = useListFilterJobShiftReport('reports/guardlocation', false, true)
    // const myRef = useRef<any>(null)
    // const executeScroll = () => myRef.current?.scrollIntoView()
    const {showNotification} = useContext(AppContext)

    useEffect(() => {
        onFilterClearClick()
    }, [])

    const createReport = () => {
        setShowTable(true)
        let modQuery = generateFilterQuery().replace('', '')
        setGenerateQuery(modQuery)
        // !showTable && setTimeout(() => {
        //     executeScroll()
        // }, 800)
    }
    const onClickCreateReport = (): void => {
        validateHolidayFields(() => createReport())
    }
    const validateHolidayFields = (callback: () => void) => {
        let errors: IValidationBody[] = [];
        for (let [field, value] of Object.entries(filter)) {
            const fieldsToCheck = ['startDate', 'endDate' , 'timeFrom', 'timeTo', 'guardNameOrEmployeeNumber']
            if (fieldsToCheck.includes(field)) {
                errors = [...errors, ...validateFiled(value as string, field, ValidationRules.required)]
            }
        }
        if (errors.length !== 0) {
            showNotification(NotificationTypes.warning, "Please fill in Guard Name")
            highlightError(errors)
        } else {
            removeHighlightError()
            callback()
        }
    }
    const clearTime = async () => {
        await onFilterDropdownChangeAsync(setCustomTimeInUTC(6).format(), 'timeFrom');
        setTimeout(() => {
            onFilterDropdownChangeAsync(setCustomTimeInUTC(18).format(), 'timeTo');
        }, 150)
    }

    return (

        <Layout
            breadcrumbs={{
                links: [{
                    title: 'Reports',
                    link: 'reports'
                }],
                currentPageTitle: 'Guard Location Report'
            }}
        >
            <div className="row mb-3">
                <div className="col-12">
                    <SectionTitle
                        title={'Guard Location Report'}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <h5 className="mb-0 mt-2 mt-sm-0">Report Preferences</h5>
                        <button type="button" className="btn btn-aqua-blue mt-2 mt-sm-0 mb-3 mb-sm-0"
                                onClick={() => {
                                    onFilterClearClick()
                                    setShowTable(false)
                                    clearTime()
                                }}>
                                <SVGEraser />
                        </button>
                    </div>
                </div>
            </div>
            <form onSubmit={e => {
                e.preventDefault()
            }}  onKeyDown={(event) => {
                if (event.key === 'Enter') onClickCreateReport()
            }}>
                {
                    React.useMemo(
                        () => (

                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-md-6 form-group shfit-autosegest">
                                            <label className="required" htmlFor="firstStreet">Guard Name</label>
                                            <AutocompleteField
                                                showButton={false}
                                                defaultValue={!filter.guardNameOrEmployeeNumber ? true : false}
                                                includes={'Guard'}
                                                isAsync={true}
                                                placeholder='Guard Name'
                                                onSearch={async (value) => {
                                                    onFilterDropdownChange(value, 'guardNameOrEmployeeNumber')
                                                }}
                                                onChange={(value) => onFilterDropdownChange(value, 'guardNameOrEmployeeNumber')}
                                                onClear={() => onFilterDropdownChange('', 'guardNameOrEmployeeNumber')}/>
                                        </div>
                                        <div
                                            className="col-md-6 form-group d-flex flex-row align-items-start flex-wrap">
                                            <div className="col-6 pl-0">
                                                <div className="form-group mb-0 d-flex align-items-center">
                                                    <label>From: </label>
                                                </div>
                                                <div
                                                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                    <DatePicker
                                                        selected={datePickerFormat(filter.startDate!)}
                                                        onChange={date => {
                                                            date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'startDate')
                                                            changeDatapicker(1);
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        onInputClick={() => changeDatapicker(1)}
                                                        open={showDatapicker[1]}
                                                        onClickOutside={() => changeDatapicker(1)}
                                                    />
                                                    <div onClick={() => changeDatapicker(1)}><SVGCalendar/></div>
                                                </div>
                                            </div>
                                            <div className="col-6 pr-0">
                                                <div className="form-group mb-0 d-flex align-items-center">
                                                    <label>To: </label>
                                                </div>
                                                <div
                                                    className="react-datepicker-custom-wrapper react-datepicker-custom-wrapper--full-width d-inline-block w-100">
                                                    <DatePicker
                                                        selected={datePickerFormat(filter.endDate!)}
                                                        onChange={date => {
                                                            date && onFilterDropdownChange(setHoursAndMinutes(date as Date), 'endDate')
                                                            changeDatapicker(2);
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        onInputClick={() => changeDatapicker(2)}
                                                        open={showDatapicker[2]}
                                                        onClickOutside={() => changeDatapicker(2)}
                                                    />
                                                    <div onClick={() => changeDatapicker(2)}><SVGCalendar/></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-6 col-lg-12 form-group d-flex flex-row align-items-end flex-wrap">
                                            <div className="mr-lg-4">
                                                <div className="form-group mb-0 d-flex align-items-center">
                                                    <label>From: </label>
                                                </div>
                                                <CustomTimePicker
                                                    value={filter.timeFrom ? UTCtimeToLocal(filter.timeFrom) : undefined}
                                                    onChange={(time) => {
                                                        onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeFrom');
                                                    }}
                                                    index={1}
                                                />

                                            </div>
                                            <div className="">
                                                <div className="form-group mb-0 d-flex align-items-center">
                                                    <label>To: </label>
                                                </div>

                                                <CustomTimePicker
                                                    value={filter.timeTo ? UTCtimeToLocal(filter.timeTo) : undefined}
                                                    onChange={(time) => {
                                                        onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeTo')
                                                    }}
                                                    index={2}/>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ), [filter, showDatapicker]
                    )
                }

                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-aqua-blue ml-0 ml-sm-3 mt-2 mt-lg-0"
                                    onClick={() => onClickCreateReport()}>
                                View Report
                            </button>
                        </div>
                    </div>
                </div>

                {React.useMemo(() => (
                    showTable &&
                    <div className="mt-5 mb-3"><ListGuardLocationReport queryFilter={generateQuery}/></div>
                ), [generateQuery, showTable])
                }


            </form>
        </Layout>
    )
}

export default GuardLocationReport;
