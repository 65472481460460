import { IBaseModalProps } from 'common/interfaces/IModal'
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary'
import CustomSelectControl from 'components/custom-select/CustomSelectControl'
import React, { FC } from 'react'
import BaseModal from '../BaseModal'
import ConfirmBulkShiftBody from './confirmBulkShiftModal/ConfirmBulkShiftBody'

interface IProps extends IBaseModalProps {
  reasons?: IVocabulary[];
  reasonsHook?: any;
  shifts: any[]
  shiftPeriods: IVocabulary[];
}

const BulkUnassignModal: FC<IProps> = ({ onCancel, onSubmit, shifts, reasons, reasonsHook, shiftPeriods }) => {
  return (
    <BaseModal
      show={true}
      onCancel={onCancel}
      className="available-guards-modal"
      cancelBtnText={'Cancel'}
      submitBtnText={'Save'}
      onSubmit={onSubmit}
    > 
      <ConfirmBulkShiftBody
        title="Are you sure you want to unassign assigned guard(s) from selected shift(s)?"
        vocabulary={shiftPeriods || []}
        shifts={shifts || []}
        className="mb-4"
      />
      <div className="row">
        <form className='col-md-5 ml-auto'>
          <div className='form-group mb-0'>
            <CustomSelectControl
              value={reasonsHook.isOtherReason ? 0 : (reasonsHook.reason as IVocabulary).id}
              placeholder={"Select a reason"}
              options={reasons ? reasons : []}
              onChange={(e) => reasonsHook.onReasonSelect(e)}
              className={`custom-react-select--reasonForReplacement`}
            />
          </div>
          {reasonsHook.isOtherReason && (
            <div className='form-group mt-3'>
              <textarea
                className='form-control'
                rows={5}
                name={"reasonForReplacement--other"}
                placeholder='Type other reason'
                value={reasonsHook.reason as string}
                onChange={(e) => reasonsHook.setReason(e.target.value)}
                ref={reasonsHook.textareaRef}></textarea>
            </div>
          )}
      </form>
    </div>
    </BaseModal>
  )
}

export default BulkUnassignModal