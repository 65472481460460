import moment from "moment"
import { useEffect } from "react"
import { useState } from "react"

export type ShiftPeriod =  {
    id: number
    timeFrom: string,
    timeTo: string,
    label: string
}

export const useShiftPeriod = () => {
    const shiftPeriods: ShiftPeriod[] = [
        {
            id: 1,
            timeFrom: '12:00 AM',
            timeTo: '10:00 AM',
            label: 'Morning Shift'
        },
        {
            id: 2,
            timeFrom: '10:01 AM',
            timeTo: '2:00 PM',
            label: 'Afternoon Shift'
        },
        {
            id: 3,
            timeFrom: '2:01 PM',
            timeTo: '6:00 PM',
            label: 'Dismissal'
        },
        {
            id: 4,
            timeFrom: '6:01 PM',
            timeTo: '11:59 PM',
            label: 'Upcoming Shifts'
        },
    ]

    const [currentShiftPeriod, setCurrentShiftPeriod] = useState<ShiftPeriod>({} as ShiftPeriod)
    const format = 'h:mm A'
    const currentHoursTime = moment().format(format);

    useEffect(() => {
        getCurrentShiftPeriod()
    }, [])

    const getCurrentShiftPeriod = () => {
        const shiftPeriod = shiftPeriods.find(shift => 
            moment(currentHoursTime, format).isBetween(moment(shift.timeFrom, format), moment(shift.timeTo, format)) ||
            moment(currentHoursTime, format).isSame(moment(shift.timeTo, format)) ||
            moment(currentHoursTime, format).isSame(moment(shift.timeFrom, format))
        )

        shiftPeriod && setCurrentShiftPeriod(shiftPeriod)
    }

    return {
        currentShiftPeriod, format, currentHoursTime, shiftPeriods
    }
}