import moment from "moment/moment";
import { setHoursAndMinutes } from '../../utils/DateFormatting';
import { deepClone } from 'helpers/Cloning'

const baseFilter = {
  startDate: setHoursAndMinutes(moment().format()),
  endDate: setHoursAndMinutes(moment().format())
}

export const incidentReportsFilter = {
  startDate: setHoursAndMinutes(moment().format()),
  endDate: setHoursAndMinutes(moment().format()),
  unresolvedOnly: true,
}


export const guardTestsFilter = baseFilter

export const guardMessagesFilter = {
  startDate: setHoursAndMinutes(moment().format())
}

export const healthAndCheckFilter = {...deepClone(baseFilter), ...{
  response: '3;4'
}}
export const appProfileUpdateFilter = {
  // response: 1
}
export const arrivalReportFilter = {...deepClone(baseFilter), ...{
  type: "74"
}}

export const locationTrackingFilter = {
  shiftDate: setHoursAndMinutes(moment().format())
}

