import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {
    SVGHolidays,
    SVGSchoolBoard,
    SVGUsers,
    SVGGeneralSettings,
    SVGDevices,
    SVGNotifications,
    SVGDropDownOptions,
    SVGDeactivatedJobs,
    SVGDeactivateGuards,
    SVGDeactivateSchedules, SVGSchool,
} from '../../assets/icons/SvgIcons'
import { AuthService } from '../../common/auth/AuthService'
import { Roles } from '../../common/enums/Roles'
import Layout from '../../components/layout/Layout'
import SectionTitle from '../../components/titles/SectionTitle'
import AppContext from '../../context/AppContext'
import activityreport from '../../assets/images/activityreport.png'

const Administration = () => {
    const appContext = useContext(AppContext)
    
    return (
        <Layout
        breadcrumbs={{ currentPageTitle: 'Administration' }}
        >
            <div className="row mb-5">
                <div className="col-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                    <SectionTitle title="Administration"/>
                    { AuthService.checkIfUserHasPermission([Roles.Admin, Roles.SuperAdmin], appContext.currentUser.role) &&
                    <Link to="/administration/manage-users/deactivated-users" className="btn btn-outline-aqua-blue ml-0 ml-md-3 mt-2 mt-md-0">View Inactive Users</Link>
                    }
                </div>
            </div>

            <div className="administration row">
                <div className="col-12 mx-auto d-flex flex-row flex-wrap justify-content-center ">

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/manage-users" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGUsers />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Users</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/settings" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGGeneralSettings />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">General Settings</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/schools-and-boards" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGSchoolBoard />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Schools and Boards</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/holidays" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGHolidays />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Holidays</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/notifications" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGNotifications />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Notifications</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/dropdown" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGDropDownOptions />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Dropdown Options</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/devices" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGDevices />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Devices</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/schools-years" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGSchool />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">School Years</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/jobs/deactivate" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGDeactivatedJobs />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Job Deactivation Requests</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/guards/deactivate" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGDeactivateGuards />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Guard Deactivation Requests</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/jobs/job-schedules/schedules/deactivate" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <SVGDeactivateSchedules />
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Schedule Deactivation Requests</h5>
                        </Link>
                    </div>

                    <div className="administration__item text-center mx-2 mb-4">
                        <Link to="/administration/activity-report" className="text-decoration-none">
                            <div className="administration__item__circle d-flex justify-content-center align-items-center">
                                <img src={activityreport} alt="Brand Logo"/>
                            </div>
                            <h5 className="font-weight-bold text-primary pt-3">Activity Report</h5>
                        </Link>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default Administration
