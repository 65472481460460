import React, {FC, useContext, useEffect, useState} from 'react'
import {VocabularyEnum} from '../../../../common/enums/VocabularyEnum'
import {IVocabulary} from '../../../../common/interfaces/vocabulary/IVocabulary'
import {IJobAssignedShifts} from '../../../../common/interfaces/jobs/IJob'
import JobDetailsTabsContext from '../../../../context/jobs/JobDetailsTabsContext'
import {useFromToFilter} from '../../../../customHooks/useFromToFilter'
import {useVocabulary} from '../../../../customHooks/vocabulary/useVocabulary'
import {matchOptionWithName} from '../../../../utils/MatchOptionWithName'
import FromToFilter from '../../../guards/details/FromToFilter'
import BaseModal from '../../../modal/BaseModal'
import RemoveShiftModal from '../../../modal/jobs/RemoveShiftModal'
import DataTable from '../../../tables/DataTable'
import AvailableGuards from '../../../guards/AvailableGuards';
import {IJobModals} from '../../../../common/interfaces/jobs/IJobModals';
import {SVGPencil, SVGTrash} from '../../../../assets/icons/SvgIcons';
import RemoveShiftModalSuccess from '../../../modal/jobs/RemoveShiftModalSuccess';
import EditShiftModal from '../../../modal/jobs/EditShiftModal';
import {getHolidayField} from '../../../../utils/GetFieldFromTable'
import {momentDateFormat, momentHoursFormat} from '../../../../utils/DateFormatting'
import ReportActions from "../../../reports/ReportActions";
import {JobIncludesEnum} from "../../../../common/enums/JobEnums";
import { useRole } from 'customHooks/useRole'
import {assignedShiftsTabTable} from "../../../../content/jobs/JobsContent";
import {useVerifyRolesTHead} from "../../../../customHooks/useVerifyRolesTHead";

type Props = {
    onDelete: (reason: IVocabulary | string, shiftId: number) => void
    onEditSubmit: (shift: IJobAssignedShifts) => void
    modalsControl: IJobModals
    toggleModal: (name: string, value: boolean) => void;
}

const AssignedShiftsTab: FC<Props> = ({onDelete, onEditSubmit, modalsControl, toggleModal}) => {
    const {isLoading, job, vocabulary} = useContext(JobDetailsTabsContext)
    const vocabularyContext = useVocabulary(VocabularyEnum.shiftReason, true)
    const {onFilter, formEmpty, onClear, filteredData, filterQuery} = useFromToFilter<IJobAssignedShifts>(job.assignedShits)
    const [selectedShift, setSelectedShift] = useState<{ id: number, body: IJobAssignedShifts }>({
        id: 0,
        body: {} as IJobAssignedShifts
    })
    const [shiftDetails, setShiftDetails] = useState<IJobAssignedShifts>({} as IJobAssignedShifts)
    const { isClientRole, isSupervisorRole } = useRole()

    const [modals, setModals] = useState<{ remove: boolean, availableGuards: boolean, edit: boolean, editSuccess: boolean }>(
        {remove: false, availableGuards: false, edit: false, editSuccess: false}
    )
    const {theadTable, addVerifyRoles} = useVerifyRolesTHead()

    useEffect(() =>{
        addVerifyRoles(assignedShiftsTabTable.thead)
    }, [assignedShiftsTabTable])

    const onRemoveShiftConfirmedClick = (reason: string | IVocabulary) => {
        onDelete(reason, selectedShift.id);
        setModals({...modals, remove: false})
        setSelectedShift({...selectedShift, id: 0})
    }

    const onShiftClick = (shiftId: number, type: string) => {
        let shift = filteredData.find(shift => shift.id === shiftId)
        shift && setSelectedShift({
            id: shiftId,
            body: shift
        })
        type === "edit" ? setModals({...modals, edit: true, remove: false}) : setModals({
            ...modals,
            remove: true,
            edit: false
        })
    }

    return (
        <div>
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                <h5>Assigned Shifts</h5>
                <ReportActions
                    title="Guard Shift Report:"
                    id={job.id}
                    tabName={JobIncludesEnum.assignedShits}
                    page="job"
                    query={filterQuery}
                    showPrint
                    showForShiftReport={false}
                />
            </div>
            <FromToFilter
                isEmpty={formEmpty}
                onSubmit={(from, to, value) => onFilter(from, to, 'assignedGuard', value)}
                onClear={() => onClear()}
                showKeyword={true}
                dataFiltered={filteredData}
            />

            {
                React.useMemo(() => (
                    <DataTable
                        tableClass="mt-4 table-info--lastColHolidays table-info--notFixed"
                        responsive="xl"
                        thead={theadTable}
                        tbody={
                            filteredData ? filteredData
                                .map(shift => (
                                    {
                                        id: shift.id,
                                        assignedGuardLink: `<a href="#/guards/${shift.guardId}/details">${shift.assignedGuard}</a>`,
                                        dates: momentDateFormat(shift.startDate),
                                        aspStartDate: shift.aspStartDate ? momentHoursFormat(shift.aspStartDate, shift.timeFrom, true) : "-",
                                        timesFrom: momentHoursFormat(shift.timeFrom),
                                        timesTo: momentHoursFormat(shift.timeTo, shift.timeFrom),
                                        shiftPeriod: matchOptionWithName(shift.shiftPeriod, vocabulary),
                                    }
                                )) : []}
                        ignoreCols={[0, isClientRole()|| isSupervisorRole() ? 3 : -1]}
                        defaultSortedColumn={1}
                        isLoading={isLoading}
                    >
                        {
                            shiftId => (
                                <>
                                    { !isClientRole() && !isSupervisorRole() &&  
                                        <>
                                            <td className="align-middle">
                                                <button
                                                    type="button"
                                                    className={`btn btn-aqua-blue`}
                                                    onClick={() => onShiftClick(shiftId, 'edit')}>
                                                    <SVGPencil/>
                                                </button>
                                            </td>
                                            <td className="align-middle">
                                                <button
                                                    type="button"
                                                    className={`btn btn-aqua-blue`}
                                                    onClick={() => onShiftClick(shiftId, 'remove')}>
                                                    <SVGTrash/>
                                                </button>
                                            </td>
                                        </>
                                    }
                                    <td className="align-middle">
                                        {getHolidayField(shiftId, filteredData)}
                                    </td>
                                </>
                            )
                        }
                    </DataTable>
                ), [filteredData, vocabulary, theadTable])
            }

            {
                modals.availableGuards && <BaseModal
                    show={modals.availableGuards}
                    onCancel={() => setModals({...modals, availableGuards: false})}
                    className="available-guards-modal"
                    cancelBtnText={'Cancel'}
                >
                    <AvailableGuards
                        onSubmit={guard => {
                            setShiftDetails({
                                ...shiftDetails,
                                guardId: guard.id,
                                assignedGuard: `${guard.firstName} ${guard.middleName} ${guard.lastName}`
                            })
                            setModals({...modals, availableGuards: false})
                        }}
                        title={`Assign Guard for Selected Job Shift for <span class="font-weight-bold">${job.jobName}</span>`}
                        selectedItem={shiftDetails}
                    />
                </BaseModal>
            }

            {
                modals.edit && shiftDetails &&
                <EditShiftModal
                    show={modals.edit}
                    onClose={() => setModals({...modals, edit: false})}
                    shift={selectedShift.body}
                    onSubmit={shift => {
                        setModals({...modals, edit: false});
                        onEditSubmit(shift as IJobAssignedShifts);
                    }}
                    jobName={job.jobName as string}
                />
            }

            {modals.remove &&
            <RemoveShiftModal
                titleBody={`Are you sure you want to remove <span class="font-weight-bold">${selectedShift.body.assignedGuard}</span> from this shift?`}
                show={modals.remove}
                onClose={() => setModals({...modals, remove: false})}
                onSubmit={(reason) => onRemoveShiftConfirmedClick(reason as string | IVocabulary)}
                reasons={vocabularyContext.vocabulary}
                jobName={job.jobName as string}
                shift={selectedShift.body}
                shifts={vocabulary}
            />
            }

            {
                modalsControl.removeSuccess &&
                <RemoveShiftModalSuccess
                    show={modalsControl.removeSuccess}
                    shift={selectedShift.body}
                    jobName={job.jobName as string}
                    userName={selectedShift.body.assignedGuard}
                    onClose={() => toggleModal('removeSuccess', false)}
                />
            }

            {
                modalsControl.editSuccess && <BaseModal
                    show={modalsControl.editSuccess}
                    onCancel={() => toggleModal('editSuccess', false)}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4">
                        You have successfully updated this job shift for <span
                        className="font-weight-bold">{job.jobName}</span>
                    </h4>
                </BaseModal>
            }
        </div>
    )
}

export default AssignedShiftsTab
