import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import DataTable from "../../tables/DataTable";
import SectionTitle from "../../titles/SectionTitle";
import {momentDateFormat, momentHoursFormat} from "../../../utils/DateFormatting";
import BaseModal from "../../modal/BaseModal";
import Layout from "../../layout/Layout";
import {matchWeekDays} from "../../../utils/MatchWeekDays";
import MulTipleCheckbox from "../../miltiple-checkbox/miltiple-checkbox";
import {useMultipleCheckbox} from "../../../customHooks/useMultipleCheckbox";
import useActivateDeactivate from '../../../customHooks/useActivateDeactivate';

import {useTableList} from "../../../customHooks/useTableList";
import {IJobScheduleRequested} from "../../../common/interfaces/jobs/IJob";
import {matchOptionWithName} from "../../../utils/MatchOptionWithName";
import {useVocabulary} from "../../../customHooks/vocabulary/useVocabulary";
import {VocabularyEnum} from "../../../common/enums/VocabularyEnum";
import {DataService} from "../../../common/services/DataService";
import {NotificationTypes} from "../../../common/interfaces/INotification";
import {removeHighlightError} from "../../../utils/Validation";
import AppContext from "../../../context/AppContext";
import {pendingJobsContent} from "../../../content/jobs/JobsContent";


const PendingJobSchedules = () => {
    const appContext = useContext(AppContext)
    const history = useHistory();
    const {onChangeCheckbox, onRemoveCheckbox, arrayInStringWith, clearAllCheckbox, allCheckbox, selectAllCheckboxes, checkboxFlag} = useMultipleCheckbox()
    const {vocabulary: shiftVocabulary} = useVocabulary(VocabularyEnum.shift, true)
    const {approveModals, approveModals: {showApprove, showDecline, showSuccess}, setApproveModals} = useActivateDeactivate('');
    const {tableData, isLoading, fetchData} = useTableList<IJobScheduleRequested>('schedule/pending', 0)
    const dataSvc = new DataService<{ schedulesId: string }>('schedule')
    
    const removeInputCheckbox = async () => {
        allCheckbox.map(item => {
            const p = document.getElementById(`${item}`) as HTMLInputElement;
            p.remove();
        })
    }

    const onSelectDelete = async () => {
        appContext.showLoader(true)
        try {
            const schedulesId = arrayInStringWith()
            if (schedulesId.length) {
                await dataSvc.delete(undefined, { schedulesId: schedulesId })
                appContext.showNotification(NotificationTypes.success,
                    'Your job schedules have successfully be deleted.'
                )
                await removeInputCheckbox()
                await fetchData(0, 0)

                clearAllCheckbox()
            }
            appContext.showLoader(false)
        } catch (e: any) {
            removeHighlightError()
            appContext.showNotification(NotificationTypes.danger, e.message)
            appContext.showLoader(false)
        }
    }

    const onSelectActivate = async () => {
        appContext.showLoader(true)
        try {
            const schedulesId = arrayInStringWith()

            if (schedulesId.length) {
                await dataSvc.update({ schedulesId: schedulesId }, undefined, 'activate')
                // await dataSvc.updateQuery('activate', selectId)
                appContext.showNotification(NotificationTypes.success,
                    'Your job schedules have successfully be activated.'
                )
                await removeInputCheckbox()

                await fetchData(0, 0)
                clearAllCheckbox()
            }
            appContext.showLoader(false)
        } catch (e: any) {
            removeHighlightError()
            appContext.showNotification(NotificationTypes.danger, e.message)
            appContext.showLoader(false)
        }
    }

    const handleColClick = (key: string, scheduleId: number | undefined, item?: any) => {
        if (scheduleId !== undefined && key === "jobNameLink") {
            history.push({
                pathname: `/jobs/${item.jobId}/edit`,
                search: ``,
                state: {step: 2, lastJobId: item.id}
            });
        }
        return
    }

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Jobs',
                        link: 'jobs'
                    },
                    {
                        title: 'Job Schedules',
                        link: 'jobs/schedules'
                    }
                ],
                currentPageTitle: 'Pending Job Schedules'
            }}
        >
            <div className="row mb-4">
                <div className="col-12">
                    <SectionTitle title="Pending Job Schedules" className="mb-3"/>
                    <p>Update jobs by clicking on the job name. When you are ready to activate jobs, click on the relevant checkboxes on the right side and then scroll down to the Activate button at the bottom of the page. Once you click on Activate, the job schedules will become active.</p>
                </div>
            </div>

            <div className="row">

                <div className="col-12">

                    {
                        React.useMemo(() => (
                            <DataTable
                                thead={pendingJobsContent.thead}
                                tbody={tableData ?
                                    tableData.map(item => ({
                                        id: item.id,
                                        jobNameLink: item.jobName,
                                        schoolBoards: item.schoolBoards ? item.schoolBoards[0].boardName : '-',
                                        zone: item.zone,
                                        schoolYear: `${momentDateFormat(item.schoolYear.startDate)} - ${momentDateFormat(item.schoolYear.endDate)}`,
                                        date: `${momentDateFormat(item.startDate)} to ${momentDateFormat(item.endsOn)}`,
                                        times: `${momentHoursFormat(item.timeFrom)} to ${momentHoursFormat(item.timeTo, item.timeFrom)}`,
                                        shiftPeriod: matchOptionWithName(item.shiftPeriod, shiftVocabulary),
                                        repeat: `Every week: <br /> ${matchWeekDays(item.repeatOn)}`,

                                    }))
                                    : []}
                                tableName={"Pending Job Schedules"}
                                ignoreCols={[0]}
                                tableClass={'table table-info table-info--notFixed table-unassigned-job-shifts'} 
                                isLoading={isLoading}
                                onColClick={(key, itemId, item, tdIndx, trIndx) => handleColClick(key, itemId, tableData[trIndx!])}
                                onSelectAllCheckbox={selectAllCheckboxes}
                                checkboxIdArray={tableData ? tableData.map(item => item.id as number) : []}
                            >
                                {
                                    id => (
                                        <>
                                            <td className="align-middle">
                                                <div>
                                                    <div
                                                        className="custom-control custom-checkbox d-flex align-items-center">
                                                        {id && <MulTipleCheckbox id={id} onChange={onChangeCheckbox}
                                                                                 onRemove={onRemoveCheckbox}
                                                                                 allCheckboxes={allCheckbox}
                                                                                 checkboxFlag={checkboxFlag}
                                                        />
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                        ), [tableData, isLoading, shiftVocabulary, allCheckbox])
                    }
                </div>
            </div>


            <div className="row">
                <div className="col-12 mx-auto d-flex flex-wrap justify-content-lg-between mt-3 mt-md-5">
                    <button type="button" className="btn btn-outline-aqua-blue px-4 mb-2"
                            onClick={clearAllCheckbox}>
                        Cancel
                    </button>
                    <div className="ml-auto">
                        <button type="button" className="btn btn-aqua-blue px-4 ml-auto mr-3 mb-2"
                                onClick={() => onSelectDelete()}>
                            Delete selected job schedules
                        </button>

                        <button type="button" className="btn btn-aqua-blue px-4 ml-auto mb-2"
                                onClick={() => onSelectActivate()}>
                            Activate selected job schedules
                        </button>
                    </div>

                </div>
            </div>

            {
                showApprove && <BaseModal
                    show={showApprove}
                    onCancel={() => setApproveModals({...approveModals, showApprove: false})}
                    // onSubmit={() => approveRequestSubmit({} as any)}
                    submitBtnText={"Confirm"}
                >
                    <h4 className="text-left mb-4">
                        Are you sure you want to deactivate schedule for [jobname]?
                    </h4>
                </BaseModal>
            }

            {
                showDecline && <BaseModal
                    show={showDecline}
                    onCancel={() => setApproveModals({...approveModals, showDecline: false})}
                >
                    <h4 className="text-left mb-4">The selected schedule for [jobname] has not been deactivated.</h4>
                </BaseModal>
            }

            {
                showSuccess && <BaseModal
                    show={showSuccess}
                    onCancel={() => setApproveModals({...approveModals, showSuccess: false})}
                    cancelBtnText={"Close"}
                >
                    <h4 className="text-left mb-4"> The selected schedule for [jobname] has been deactivated.</h4>
                    <small className="text-danger">Affected guards will be automatically notified of their shift
                        updates.</small>
                </BaseModal>
            }

        </Layout>
    )
}

export default PendingJobSchedules
