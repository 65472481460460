import React, {useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { SVGTrash } from '../../../../assets/icons/SvgIcons'
import { VocabularyEnum } from '../../../../common/enums/VocabularyEnum'
import { IGuardShift } from '../../../../common/interfaces/guards/IShift'
import { IVocabulary } from '../../../../common/interfaces/vocabulary/IVocabulary'
import AssignedShiftsContext from '../../../../context/guards/AssignedShiftsContext'
import GuardDetailsModalsContext from '../../../../context/guards/GuardDetailsModalsContext'
import { useFromToFilter } from '../../../../customHooks/useFromToFilter'
import { useVocabulary } from '../../../../customHooks/vocabulary/useVocabulary'
import { momentDateFormat, momentHoursFormat } from '../../../../utils/DateFormatting'
import { getHolidayField } from '../../../../utils/GetFieldFromTable'
import { highlightError } from '../../../../utils/Validation'
import RemoveShiftModal from '../../../modal/jobs/RemoveShiftModal'
import RemoveShiftModalSuccess from '../../../modal/jobs/RemoveShiftModalSuccess'
import DataTable from '../../../tables/DataTable'
import FromToFilter from '../FromToFilter'
import ReportActions from "../../../reports/ReportActions";
import {GuardIncludesEnum} from "../../../../common/enums/GuardEnums";
import { useRole } from 'customHooks/useRole'
import {assignedShiftsTable, } from "../../../../content/jobs/JobsContent";
import {useVerifyRolesTHead} from "../../../../customHooks/useVerifyRolesTHead";

const AssignedShiftsTab = () => {
    const { isLoading, userName, userId, modals, setModals } = useContext(GuardDetailsModalsContext)
    const { assignedShifts, onSubmit }  = useContext(AssignedShiftsContext)
    const { onFilter, formEmpty, onClear, filteredData, filterQuery } = useFromToFilter<IGuardShift>(assignedShifts)
    const [selectedShift, setSelectedShift] = useState<IGuardShift>({} as IGuardShift)
    const { vocabulary } = useVocabulary(VocabularyEnum.shiftReason, true)
    const { isClientRole, isASPAndAdminRole, isSupervisorRole } = useRole()
    const {theadTable, addVerifyRoles} = useVerifyRolesTHead()

    const onUnassignClick = (id: number) => {
        const matchedShift = filteredData.find(shift => +shift.id === id);
        if (matchedShift) {
            setSelectedShift(matchedShift)
            setModals(true, 'unassignGuard')
        }
    }

    const onUnassignShiftConfirmed = (reason: string | IVocabulary) => {
        if (reason === "" || Object.keys(reason).length === 0) {
            highlightError([{ fieldName: 'unAssignReason', errorMessage: '' }])
        } else {
            onSubmit(reason as string | IVocabulary, +selectedShift.id)
        }
    }
    useEffect(() =>{
        addVerifyRoles(assignedShiftsTable.thead)
    }, [assignedShiftsTable])

    return (
        <>
            <div className="d-flex align-items-center flex-wrap justify-content-between">
                <div className="d-flex align-items-center flex-wrap">
                    <h5 className="mb-0 mr-5">Guard's Shifts</h5>
                    { isASPAndAdminRole() &&
                    <Link to={`/guards/${userId}/assignments`} className="btn btn-aqua-blue ml-0 ml-md-2 mr-5 mr-md-0 mt-2 mt-md-0">Assign Job Shift</Link>
                    }
                </div>
                <ReportActions
                    className="mt-2"
                    title="Guard Shift Report:"
                    id={userId}
                    tabName={GuardIncludesEnum.shift}
                    page="guard"
                    query={filterQuery}
                    showPrint
                    showForShiftReport={false}
                />
            </div>

            <FromToFilter
                isEmpty={formEmpty}
                onSubmit= {(from, to) => onFilter(from, to)}
                onClear={() => onClear()}
            />
            {
                React.useMemo(
                    () => (
                    <div className="row">
                        <div className="col-md-12 mx-auto ">
                            { filteredData && <DataTable
                                tableClass={"mt-4 table-info--lastColHolidays table-info--notFixed"}
                                thead={theadTable}
                                tbody={
                                    filteredData
                                    .filter(item  => !item.invisible)
                                    .map(item => ({
                                        id: item.id,
                                        jobNameLink: `<a href="#/jobs/${item.jobId}/details">${item.jobName}</a>`,
                                        startDate: momentDateFormat(item.startDate),
                                        aspStartDate: item.aspStartDate ? momentHoursFormat(item.aspStartDate, item.timeFrom, true) : "-",
                                        timeFrom: momentHoursFormat(item.timeFrom),
                                        timeTo: momentHoursFormat(item.timeTo, item.timeFrom)
                                })) }
                                ignoreCols={[0, ...(isClientRole() || isSupervisorRole() ? [3] : [])]}
                                ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [4] : [])]}
                                isLoading={isLoading}
                                defaultSortedColumn={1}
                                responsive="xl"
                            >
                                {
                                    shiftId => (
                                        <>
                                        { !isClientRole() && !isSupervisorRole() &&
                                            <td className="align-middle">
                                                <div>
                                                    <button
                                                        className={`btn btn-aqua-blue`}
                                                        onClick={() => onUnassignClick(shiftId)}
                                                    >
                                                        <SVGTrash />
                                                    </button>
                                                </div>
                                            </td>
                                        }
                                            <td className="align-middle">
                                                { getHolidayField(shiftId, filteredData) }
                                            </td>
                                        </>
                                    )
                                }
                            </DataTable>
                            }
                        </div>
                    </div>
                ), [filteredData, theadTable])
            }

            {
                isASPAndAdminRole() &&
                <>
                {

                    modals.unassignGuard &&
                    <RemoveShiftModal
                        titleBody={`Are you sure you want to unassign selected shift from <span class="font-weight-bold">${userName}</span>?`}
                        show={modals.unassignGuard}
                        onClose={() => setModals(false, 'unassignGuard') }
                        onSubmit={(reason) => onUnassignShiftConfirmed(reason as string | IVocabulary)}
                        reasons={vocabulary}
                        jobName={selectedShift.jobName as string}
                        shift={selectedShift}
                    />
                }
                {
                    modals.unassignGuardSuccess &&
                    <RemoveShiftModalSuccess
                        show={modals.unassignGuardSuccess}
                        shift={selectedShift}
                        jobName={selectedShift.jobName as string}
                        onClose={() => setModals(false, 'unassignGuardSuccess')}
                        userName={userName}
                    />
                }
                </>
            }
        </>
    )
}

export default AssignedShiftsTab
