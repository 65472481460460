// export const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
export const weekDays = [
    { label: 'M', index: 1 },
    { label: 'T', index: 2 },
    { label: 'W', index: 3 },
    { label: 'T', index: 4 },
    { label: 'F', index: 5 },
    { label: 'S', index: 6 },
    { label: 'S', index: 0 },
]

export const weekDaysLong = [
    { label: 'Mon', index: 1 },
    { label: 'Tue', index: 2 },
    { label: 'Wed', index: 3 },
    { label: 'Thu', index: 4 },
    { label: 'Fri', index: 5 },
    { label: 'Sat', index: 6 },
    { label: 'Sun', index: 0 },
]

export const matchWeekDays = (daysIndx: string) => {
    const daysToArray = daysIndx.split(';');
    let days = '';
    weekDaysLong.forEach(day => {
        if (daysToArray.includes(day.index.toString()) ) {
            days += `${day.label}, `;
        }
    })

    return days.substring(0, days.length - 2)
}