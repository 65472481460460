import { ISettings } from 'common/interfaces/administration/ISettings';
import ResourceDataService from 'common/services/ResourceDataService';
import { useEffect, useState } from 'react';

const useBufferTime = () => {
	const [bufferTime, setBufferTime] = useState(30);
	const settingSvc = new ResourceDataService<ISettings>({ url: 'setting' });
	useEffect(() => {
		const getSettings = async () => {
			const { data: settings } = await settingSvc.getAll();
			settings.bufferTime && setBufferTime(settings.bufferTime);
		};
		getSettings();
	}, []);
	return bufferTime;
};

export default useBufferTime;
