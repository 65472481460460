import React, { FC } from 'react'
import { SVGCovidScreenNo, SVGCovidScreenYes, SVGGeoNo, SVGGeoYes, SVGLocationNo, SVGLocationYes, } from '../../assets/icons/SvgIcons'
import { IJobsRightNow } from '../../common/interfaces/jobs/IJob'

type TProps = {
    rowItem: IJobsRightNow
}

const GeoCovidLocationIcons: FC<TProps> = ({ rowItem }) => (
    <>
        <td className="align-middle py-2">
            <div>
                {rowItem.inGeofence ? <SVGGeoYes /> : <SVGGeoNo />}
            </div>
        </td>
        <td className="align-middle py-2">
            <div>
                {rowItem.covidScreen ? <SVGCovidScreenYes /> : <SVGCovidScreenNo />}
            </div>
        </td>
        <td className="align-middle py-2">
            <div>
                {rowItem.coordinates ? <SVGLocationYes /> : <SVGLocationNo />}
            </div>
        </td>
    </>
)

export default GeoCovidLocationIcons