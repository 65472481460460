import React, { FC } from 'react'

type Props = {
    text: string
}

const ConfirmationModal: FC<Props> = ({ text }) => {
    return (
        <h4 className="mb-4" dangerouslySetInnerHTML={{__html: text}} />
    )
}

export default ConfirmationModal
