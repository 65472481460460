export const inputDataChecker = (event: any, fieldName?: string | null) => {
    let field = '', value;
    if (typeof event !== "string" && typeof event !== "number" && !(event instanceof Date) && typeof event !== "boolean" && event !== null && event) {
        const trg = event.currentTarget;
        field = trg.name;
        // if (trg.value !== "" && trg.value.split('').some((char: string) => char !== '0')) {
            // value = isNaN(+trg.value) ? trg.value : +trg.value;
        // } else {
            value = trg.value
        // }
    } else if (fieldName) {
        field = fieldName as string;
        if (typeof event === "boolean") {
            value = event
        } else if(event == null || event === '') {
            value = event;
        } else {
            if (fieldName === "repeatOn" && event === "") {
                value = ""
            } else {
                value = isNaN(+event) ? event : +event;
            }

        }
    }
    return { field, value }
}
