import React, { FC, useEffect, useState } from 'react'
import BaseModal from './BaseModal'
import { highlightError, removeHighlightError } from "../../utils/Validation"
// import useGoogleApi from '../../customHooks/useGoogleApi'
import { IAutocompletePredictions } from '../../common/interfaces/maps/IAutocomplete'
import CustomPredictionsControl from '../../components/custom-select/CustomPredictionsControl'
import { SVGClose } from '../../assets/icons/SvgIcons'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { CustomControlOptions } from '../custom-select/CustomSelectControl'

type Props = {
    titleBody: string
    placeholderText: string
    showModal: boolean
    onCancel: () => void
    onSubmit: (fieldValue: string, isEditMode?: boolean) => void
    allowClearForm?: boolean
    allowPrediction?: boolean
    optionToEdit?: { [index: string]: any } | null
    country?: string;
    citiesWithoutState?: boolean;
    note?: string;
}

const AddNewItemModal: FC<Props> = ({ onCancel, onSubmit, showModal, titleBody, placeholderText, allowClearForm, allowPrediction, optionToEdit, country, citiesWithoutState, note }) => {
    const [fieldValue, setFieldValue] = useState<string | null>(optionToEdit ? optionToEdit.name as string : '')
    const [dropdownValue, setDropdowndValue] = useState<CustomControlOptions | null>(null)
    const [predictions, setPredictions] = useState<IAutocompletePredictions[]>([])
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const [showClear, setShowClear] = useState<boolean>(false)
    // const { cityAutosuggestion } = useGoogleApi();

    useEffect(() => {
        setShowDropdown(predictions.length === 0 ? false : true)
    }, [predictions])

    useEffect(() => {
        fieldValue && removeHighlightError()
        dropdownValue !== null && removeHighlightError()
    }, [fieldValue, dropdownValue])

    const onAddClicked = () => {
        if (!allowPrediction && (fieldValue === null || fieldValue.length === 0) ) {
            highlightError([{ fieldName: 'addNewItem', errorMessage: ''}])
        } else if (allowPrediction && dropdownValue === null) {
            highlightError([{ fieldName: 'addNewItem', errorMessage: ''}])
        } else {
            removeHighlightError()
            onSubmit(allowPrediction ? dropdownValue!.label as string : fieldValue as string);
            // setFieldValue('')
        }
    }

    const onAutocompleteSelectedHandler = (option: any, withoutState?: boolean) => {
        setDropdowndValue(option ? {
            label: `${option.value.terms[0].value}${withoutState ? '' : `, ${option.value.terms[1].value}`}`,
            value: option.value
        } : null)
    }

    const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        
        if (value.length !== 0) removeHighlightError();
        setFieldValue(value)
    }

    const onPredictionClickHandler = (item: string) => {
        setFieldValue(item)
        setPredictions([])
    }

    const onClearInputHandler = () => {
        setFieldValue('')
        setPredictions([])
        setShowClear(false)
    }

    return (
        <BaseModal
            show={showModal}
            onCancel={() => onCancel()}
            onSubmit={() => onAddClicked()}
            cancelBtnText={"Cancel"}
            submitBtnText={optionToEdit ? 'Update' : "Add"}
        >
            <h4 className="mb-4" dangerouslySetInnerHTML={{ __html: titleBody }}/>
            <form autoComplete="off" className="add-new-item-modal" onSubmit={e => e.preventDefault()}>
                <div className="form-group mb-0 position-relative">
                { allowPrediction ?
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_MAP_KEY}
                        // minLengthAutocomplete={3}
                        autocompletionRequest={{
                            componentRestrictions: { country: country || 'CA' },
                            types: ["(cities)"],
                        }}
                        selectProps={{
                            value: dropdownValue,
                            onChange: value => onAutocompleteSelectedHandler(value, citiesWithoutState),
                            isClearable: true,
                            className: "custom-react-select custom-react-select--addNewItem",
                            classNamePrefix: "custom-react-select",
                            placeholder: placeholderText,
                            onInputChange: value => setShowDropdown(value.length >= 1 ? true : false),
                            components: { DropdownIndicator:() => null, IndicatorSeparator:() => null },
                            menuIsOpen: showDropdown
                        }}
                    />
                    :
                    <>
                    <input
                        type="text"
                        className="form-control"
                        name="addNewItem"
                        onChange={e => onChangeHandler(e)}
                        value={fieldValue as string}
                        placeholder={placeholderText}
                        autoComplete="off"
                        spellCheck="false"
                        autoCapitalize="none"
                        autoCorrect="off"
                    />

                    { allowClearForm && showClear &&
                        <button className="btn btn-aqua-blue p-0 h-100 position-absolute clear-btn" onClick={() => onClearInputHandler()}>
                            <SVGClose width={12} height={12}/>
                        </button>
                    }
                    {
                        showDropdown  && allowPrediction && 
                        <CustomPredictionsControl
                            predictions={predictions}
                            onPredictionClick={item => onPredictionClickHandler(item)}
                        />
                    } 
                    </>
                }
                </div>
                {note ? <p className='mb-0 mt-3'>{note}</p> : null}
            </form>
        </BaseModal>
    )
}

export default AddNewItemModal
