import React, { FC, useEffect, useState } from 'react'
import moment from 'moment';
import { SVGPlus, SVGReplace } from '../../../assets/icons/SvgIcons'
import { momentDateFormat, timeWithoutSecondsFormat, UTCtimeToLocal } from '../../../utils/DateFormatting';
import { IJobReports, IJobsRightNow, IUpdateShiftBody } from '../../../common/interfaces/jobs/IJob';
import BaseModal from '../BaseModal'
import UpdateShiftStatusReplaceModal from './UpdateShiftStatusReplaceModal'
import { IBaseModalProps } from '../../../common/interfaces/IModal';
import CustomTimePicker from '../../custom-time-picker/CustomTimePicker';
import { IValidationBody } from 'common/interfaces/IValidation';
import { IShiftJobReportModel } from 'common/interfaces/IDataFilter';
import { useReplaceGuard } from 'customHooks/useReplaceGuard';
import { useCheckGuard } from 'customHooks/modals/useCheckGuard';
import CheckInOutModal from '../CheckInOutModal';
import { useReason } from 'customHooks/useReason';
import { useModals } from 'customHooks/modals/useModals';
import { UpdateShiftModals } from 'common/models/Modals';
import { VocabularyEnum } from 'common/enums/VocabularyEnum';
import { useVocabulary } from 'customHooks/vocabulary/useVocabulary';
import AvailableGuards from 'components/guards/AvailableGuards';
import useAssignGuard from 'customHooks/useAssignGuard';
import { IAvailableGuard } from 'common/interfaces/guards/IGuard';

interface IProps extends IBaseModalProps {
    shift: IJobsRightNow | IJobReports | IShiftJobReportModel
    showReasonField?: boolean
    onMarkAbsent?: () => void
    refreshTableData?: () => Promise<void>,
    comment?: string,
    setComment?: (comment: string) => void,
    onCommentChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void,
    showErrorMessage?: string,
    handleAddEditClientComment?: (jobId: number, submitCallback?: (() => void) | undefined) => Promise<void>,
    setShift?: React.Dispatch<React.SetStateAction<IJobReports>>;
}

const UpdateShiftStatusModal: FC<IProps> = ({
    onSubmit,
    onCancel,
    shift,
    showReasonField,
    onMarkAbsent,
    refreshTableData,
    comment,
    setComment,
    onCommentChange,
    showErrorMessage,
    handleAddEditClientComment,
    setShift,
  }) => {
    const timeIsAfterShift = moment(moment.utc()).isAfter(moment.utc(shift.timeTo));
    const [formErorrs, setFormErrors] = useState<IValidationBody[]>([] as IValidationBody[])
    const [fields, setFields] = useState<IUpdateShiftBody>(
        {
            checkInDateTime: shift.shiftStatus?.checkIn ? shift.checkInDateTime : (shift.shiftStatus?.absent ? shift.aspStartDate : undefined),
            checkOutDateTime: shift.shiftStatus?.checkOut ? shift.checkOutDateTime : ((shift.shiftStatus?.absent && timeIsAfterShift) ? shift.timeTo : undefined),
            guardId: shift.guardId, updateComments: shift.updateComments || '',
        }
    )
    const { updateShiftStatus, setSelectedGuard, selectedGuard } = useReplaceGuard()
    const { handleCheckActions } = useCheckGuard();
    const absentReasonHook = useReason()
    const { mergeReasonsWithOther, reasons } = absentReasonHook
    const { vocabulary } = useVocabulary(VocabularyEnum.absence, true)
    const { modals: { showAbsentModal, showReplaceModal, showUpdateModal, showAddGuardModal },
        setModals } = useModals<UpdateShiftModals, any>({
            defaultState: new UpdateShiftModals({ showUpdateModal: true })
        })
    const { onAssignGuardSubmit: saveAssignedGuard } = useAssignGuard();
    const [showCheckInOut, setShowCheckInOut] = useState(false);

    useEffect(() => {
        vocabulary.length !== 0 && mergeReasonsWithOther(vocabulary)
    }, [vocabulary])

    useEffect(() => {
      if (shift.lastComment) {
        setComment && setComment(shift.lastComment)
      }
    }, [shift])

    const onInputChange = React.useMemo(() => (
        (key: string, value: moment.Moment | string) => {
            let errors: IValidationBody[] = [];
            if (key === "updateComments" && (value as string).length > 500) {
                errors.push({
                    errorMessage: 'The reason must contain less then 500 characters.',
                    fieldName: 'updateComments',
                });
                setFormErrors(errors)
                return
            };
            setFormErrors([])
            setFields({ ...fields, [key]: key === "updateComments" ? value : timeWithoutSecondsFormat(value) })
        }
    ), [fields])

    const handleSubmit = async (data?: IUpdateShiftBody) => {
        let filteredFields = {} as IUpdateShiftBody;

        if (!shift.shiftStatus?.absent || showCheckInOut) {
          filteredFields = Object.fromEntries(
            Object.entries(fields).filter(([_key, value]) => value)) as IUpdateShiftBody
        } else {
          filteredFields = Object.fromEntries(
            Object.entries(fields).filter(([key, value]) => (
              (key !== "checkInDateTime" && key !== "checkOutDateTime") && value 
            ))) as IUpdateShiftBody
        }

        updateShiftStatus({ ...filteredFields, ...data }, shift?.shiftId ? shift?.shiftId : shift.id!, () => {
            onSubmit && onSubmit(filteredFields)
        })

        handleAddEditClientComment && handleAddEditClientComment(+shift.shiftId!);
    }

    const onMarkAsAbsent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        setModals(new UpdateShiftModals({ showAbsentModal: true }))
    }

    const onMarkAsPresent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      setShowCheckInOut(true);
    }

    const onMarkAsAbsentSubmit = () => {
        if (absentReasonHook.reasonIsEmpty('reasonForAbsence', 'reasonForAbsence--other')) return;

        handleCheckActions({
            shiftId: shift?.shiftId ? shift?.shiftId! : shift.id!, checkType: 'absent',
            reason: absentReasonHook.reason
        }, async () => {
            onMarkAbsent && onMarkAbsent()
        })
    }

    const onAssignGuardSubmit = (guard: IAvailableGuard, shiftId: number) => {
      saveAssignedGuard(guard, shiftId, 'assign', () => {
          setModals(new UpdateShiftModals({}))
          setSelectedGuard(guard)
          setShift && setShift((prev) => ({...prev, guardId: guard.id, guardName: `${guard.firstName} ${guard.lastName || ''}` }))
          if (refreshTableData) refreshTableData()
            .then(() => {
                setModals(new UpdateShiftModals({ showUpdateModal: true }))
            });
          
      })
    }

    return (
        <>
            {
                <BaseModal
                    show={showUpdateModal}
                    title={"Update Shift Details"}
                    onSubmit={handleSubmit}
                    onCancel={onCancel}
                    submitBtnText={"Update"}
                >
                    <div className="row mb-4">
                        <div className="col-4 d-flex"><b className="d-block align-self-center">Job Location:</b></div>
                        <div className="col">{shift.jobName ? shift.jobName : `${shift?.firstStreet} ${shift?.secondStreet}`}</div>
                    </div>

                    {(shift.shiftStatus?.absent) &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex"><b className="d-block align-self-center">Shift Date:</b></div>
                            <div className="col">{momentDateFormat(shift.startDate)}</div>
                        </div>
                    }

                    {
                        ((shift.shiftStatus?.checkIn || (shift.guardId && !shift.shiftStatus?.absent))|| showCheckInOut || shift.shiftStatus?.tentantCheckIn) &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex">
                                <b className="d-block align-self-center">
                                    Check In Time:
                                </b>
                            </div>
                            <div className="col">
                                <CustomTimePicker
                                    onChange={time => onInputChange('checkInDateTime', time)}
                                    value={fields.checkInDateTime ? UTCtimeToLocal(fields.checkInDateTime!) : UTCtimeToLocal()}
                                    index={1}
                                />
                            </div>
                        </div>
                    }

                    {(shift.shiftStatus?.checkIn || (!shift.shiftStatus?.absent)) &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex">
                                <b className="d-block align-self-center">
                                    Check In Date:
                                </b>
                            </div>
                            <div className="col">
                                {
                                    shift.shiftStatus?.checkIn ? momentDateFormat(shift.checkInDateTime!, true) : '-'
                                }
                            </div>
                        </div>
                    }

                    {
                        ((shift.shiftStatus?.checkOut || (shift.guardId && timeIsAfterShift && !shift.shiftStatus?.absent)) || showCheckInOut) &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex">
                                <b className="d-block align-self-center">
                                    Check Out Time:
                                </b>
                            </div>
                            <div className="col">
                                <CustomTimePicker
                                    onChange={time => onInputChange('checkOutDateTime', time)}
                                    value={fields.checkOutDateTime ? UTCtimeToLocal(fields.checkOutDateTime) : UTCtimeToLocal()}
                                    index={2}
                                />
                            </div>
                        </div>
                    }

                    {(shift.shiftStatus?.checkOut || (!shift.shiftStatus?.absent && timeIsAfterShift)) &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex">
                                <b className="d-block align-self-center">
                                    Check Out Date:
                                </b>
                            </div>
                            <div className="col">
                                {
                                    shift.shiftStatus?.checkOut ? momentDateFormat(shift.checkOutDateTime!, true) : '-'
                                }
                            </div>
                        </div>
                    }

                    <div className="row mb-4">
                        <div className="col-4 d-flex"><b className="d-block align-self-center">Guard Name:</b></div>
                        <div className="col">
                            <span className="pr-3">{shift.guardName || '-'}</span>
                            <button
                                type="button"
                                className="btn btn-aqua-blue"
                                onClick={() => {
                                  shift.guardId ? setModals(new UpdateShiftModals({ showReplaceModal: true }))
                                  : setModals(new UpdateShiftModals({ showAddGuardModal: true }))
                                }}
                            >
                                {shift.guardName ? <SVGReplace /> : <SVGPlus />}
                            </button>
                            {
                              (shift.guardName && !shift.shiftStatus?.absent)
                                ? (
                                  <a className="font-weight-bold text-primary pl-2" href="!#"
                                      onClick={e => onMarkAsAbsent(e)}
                                  >Mark Absent</a>
                                )
                                : (
                                  (shift.guardName && shift.shiftStatus?.absent)
                                  ? (
                                    <a className="font-weight-bold text-primary pl-2" href="!#"
                                      onClick={(e) => onMarkAsPresent(e)}
                                    >Mark Present</a>
                                  )
                                  : (
                                    ""
                                  )
                                )
                            }
                        </div>
                    </div>

                    {showReasonField &&
                        <div className="row mb-4">
                            <div className="col-4 d-flex"><b className="d-block align-self-start">Reason for Update: </b></div>
                            <div className="col">
                                <textarea
                                    name='comments'
                                    className='form-control'
                                    id='comments'
                                    placeholder='Enter reason'
                                    autoComplete='nope'
                                    rows={5}
                                    value={fields.updateComments}
                                    onChange={(e) => onInputChange('updateComments', e.target.value)}
                                />
                                {formErorrs[0] &&
                                    <small className='text-danger pt-3'>
                                        {formErorrs[0].errorMessage}
                                    </small>
                                }
                            </div>

                        </div>
                    }

                        { onCommentChange &&
                            <div className="row mb-4">
                                <div className="col-4 d-flex"><b className="d-block align-self-start">Client comment: </b></div>
                                <div className="col">
                                    <textarea
                                        className='form-control'
                                        placeholder='Enter comment'
                                        rows={5}
                                        value={comment || ""}
                                        onChange={onCommentChange}
                                    />
                                    <small className='d-block text-right'><span className={comment && comment.length === 200 ? 'text-danger font-weight-bold': ''}>{(comment && comment.length) || 0}</span> / 200</small>
                                    <small className='text-danger pb-4 mt-2'>
                                        {showErrorMessage || ""}
                                    </small>
                                </div>
                            </div>
                        }
                </BaseModal>
            }

            {
                showAddGuardModal && <BaseModal
                    show={showAddGuardModal}
                    onCancel={() => setModals(new UpdateShiftModals({ showUpdateModal: true }))}
                    className="available-guards-modal"
                >
                    <AvailableGuards
                        title={`Add Guard for <span class="font-weight-bold">${shift.firstStreet || shift.jobName}</span>`}
                        onSubmit={guard => onAssignGuardSubmit(guard, shift.shiftId ? shift.shiftId : (shift.id || 0))}
                        // selectedItem={{
                        //     ...selectedShift.body,
                        //     shiftPeriod: shiftVocabulary.find(item => item.name.includes(currentShiftPeriod.label.split(' ')[0]))?.id
                        // }}
                        selectedItem={{...shift} as IJobsRightNow}
                    />
                </BaseModal>
            }

            {
                showReplaceModal &&
                <UpdateShiftStatusReplaceModal
                    body={{ ...fields, guardId: 0 }}
                    shift={shift}
                    onCancel={() => setModals(new UpdateShiftModals({ showUpdateModal: true }))}
                    onSubmit={(data: IUpdateShiftBody) => handleSubmit(data)}
                />
            }

            {showAbsentModal &&
                <BaseModal
                    show
                    title={"Absent"}
                    onSubmit={() => onMarkAsAbsentSubmit()}
                    onCancel={() => setModals(new UpdateShiftModals({ showUpdateModal: true }))}
                    submitBtnText={"Absent"}
                >
                    <CheckInOutModal
                        type={"absent"}
                        absenceReasons={reasons}
                        reasonsHook={absentReasonHook}
                        selectedItem={shift}
                    />
                </BaseModal>
            }
        </>
    )
}

export default UpdateShiftStatusModal