import React, {FC, useContext, useEffect} from 'react'
import { SVGCheck, SVGCancel } from '../../assets/icons/SvgIcons'
import { AuthService } from '../../common/auth/AuthService'

import { ModelStatus } from '../../common/enums/Actions'
import { Roles } from '../../common/enums/Roles'
import { IClient } from '../../common/interfaces/clients/IClient'
import { IGuard } from '../../common/interfaces/guards/IGuard'
import { IJobDetails } from '../../common/interfaces/jobs/IJob'
import AppContext from '../../context/AppContext'

type Props = {
    item: IJobDetails | IGuard | IClient
}

const ModelStatuses: FC<Props> = ({ item }) => {
    const { currentUser } = useContext(AppContext)

    return (
        <>
            {
                item.isActive === ModelStatus.active && <h6 className="ml-2 mb-0 d-flex align-items-center text-uppercase"><SVGCheck />{ ModelStatus.active }</h6>
            }
            {
                item.isActive === ModelStatus.inactive && <h6 className="ml-2 mb-0 d-flex align-items-center text-uppercase"><SVGCancel />{ ModelStatus.inactive }</h6>
            }
            {
                item.isActive === ModelStatus.test && <h6 className="ml-2 mb-0 d-flex align-items-center text-uppercase">{ ModelStatus.test }</h6>
            }
            {
                item.isActive === ModelStatus.pending && <h6 className="ml-2 mb-0 d-flex align-items-center text-uppercase">
                    {/*{ AuthService.checkIfUserHasPermission([Roles.Admin], currentUser.role) ? "Deactivation Requested" : ModelStatus.inactive}*/}
                    Deactivation Requested
                </h6>
            }
        </>
    )
}

export default ModelStatuses
