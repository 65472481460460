import React, { FC, ReactNode } from 'react'
import { IBaseModalProps } from '../../../common/interfaces/IModal'
import BaseModal from '../BaseModal'

interface Props extends IBaseModalProps {
    children: ReactNode
}

const RemoveBaseModal: FC<Props> = ({ onCancel, onSubmit, title, children }) => {

    return (
        <BaseModal
            show={true}
            onCancel={() => onCancel()}
            onSubmit={() => onSubmit && onSubmit()}
            submitBtnText={'Confirm'}
            title={title ? title : ""}
        >
            { children }
        </BaseModal>
    )
}

export default RemoveBaseModal
