import React, { MutableRefObject, useMemo, useRef, useState } from 'react';
import { SVGEraser, SVGMarker, SVGViewOnMap } from '../../assets/icons/SvgIcons';
import CustomSelectControl from '../../components/custom-select/CustomSelectControl';
import SectionTitle from '../../components/titles/SectionTitle';
import { IActiveJobSchedules, IJobSchedule } from '../../common/interfaces/jobs/IJob';
import { useTableList } from '../../customHooks/useTableList';
import Layout from '../../components/layout/Layout';
import { useListFilter } from '../../customHooks/useListFilter';
import { useEffect } from 'react';
import DataTable from '../../components/tables/DataTable';
import BaseModal from '../../components/modal/BaseModal';
import GeofenceMap from '../../components/jobs/GeofenceMap';
import { useVocabulary } from '../../customHooks/vocabulary/useVocabulary';
import { VocabularyEnum } from '../../common/enums/VocabularyEnum';
import { Link } from 'react-router-dom';
import ShiftsRequireGuard from '../../context/shifts/ShiftsRequireGuard';
import {
    momentDateFormat,
    momentHoursFormat,
    timeWithoutSecondsFormat,
    UTCTimeNow,
    UTCtimeToLocal
} from '../../utils/DateFormatting';
import AppContext from '../../context/AppContext';
import { useContext } from 'react';
import JobsMapComponent from '../../components/map/JobsMapComponent';
import { useJobMarkers } from '../../customHooks/useJobMarkers';
import { useSchoolYear } from '../../customHooks/useSchoolYear';
import { useSingleMarkerOnMap } from '../../customHooks/useSingleMarkerOnMap';
import moment from 'moment';
import AutocompleteField from '../../components/autosuggestField/AutocompleteField';
import SectionLoader from '../../components/loading/SectionLoader';
import CustomTimePicker from "../../components/custom-time-picker/CustomTimePicker";
import { jobsScheduleThead } from "../../content/jobs/JobsContent";
import SchedulesActions from 'components/jobs/details/SchedulesActions';
import { useRole } from 'customHooks/useRole';
import { ActivationDeactivation, ModelStatus } from 'common/enums/Actions';
import useActivateDeactivate from 'customHooks/useActivateDeactivate';
import { IVocabulary } from 'common/interfaces/vocabulary/IVocabulary';
import DeactivateScheduleModalBody from 'components/modal/administration/DeactivateScheduleModalBody';
import DeactivateModal from 'components/modal/users/DeactivateModal';
import { ScheduleModals } from 'common/models/Modals';
import { useModals } from 'customHooks/modals/useModals';
import AddEditScheduleModal from 'components/modal/jobs/AddEditScheduleModal';
import { DataService } from 'common/services/DataService';
import { NotificationTypes } from 'common/interfaces/INotification';
import AddShiftToScheduleModal from 'components/modal/jobs/AddShiftToScheduleModal';
import ViewShiftsOfScheduleModal from 'components/modal/jobs/ViewShiftsOfScheduleModal';

const ActiveJobSchedules = () => {
    const baseUrl = 'job/schedules';

    const jobsListTable = useTableList<IActiveJobSchedules>(`${baseUrl}`, 24, false);
    const { fetchData: fetchTableList, tableData: tableListData } = jobsListTable
    const jobsOnMapData = useTableList<IActiveJobSchedules>(`${baseUrl}`, 0, false);
    const jobsListFilter = useListFilter(baseUrl);
    const jobsOnMapListFilter = useListFilter(baseUrl);
    const { schoolYears, getSchoolYears: setDefaultSchoolYear, selectedSchoolYear, defaultYear } = useSchoolYear();
    const shiftVocabulary = useVocabulary(VocabularyEnum.shift);
    const reasonVocabulary = useVocabulary(VocabularyEnum.shiftReason);
    const bookLeaveVocabulary = useVocabulary(VocabularyEnum.bookLeave);
    const { vocabulary: scheduleDeactivation } = useVocabulary(VocabularyEnum.scheduleDeactivation, true)
    const { vocabulary: guardDeactVocabulary } = useVocabulary(VocabularyEnum.guardDeactivation, true);
    const { viewLocationInMap, jobItemInFocus, onViewLocationInMapClick, setViewLocationInMap } = useSingleMarkerOnMap();
    const { generateMarkersForJobs, jobsOnMap } = useJobMarkers();
    const [showClear, setShowClear] = useState(false)
    const { showLoader, showNotification, currentUser } = useContext(AppContext);
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
    const refMap = useRef(null);
    const { isClientRole, isSupervisorRole, isAdminRole, isSuperAdminRole } = useRole()
    const { onRequestHandler, onRequestSubmitHandler, requestModals, modalText, onCloseRequestModals, itemInFocus } = useActivateDeactivate('schedule');

    const { itemInFocus: scheduleInFocus, setItemInFocus: setScheduleInFocus,
        setModals: setScheduleModals, modals: scheduleModals } = useModals<ScheduleModals, IActiveJobSchedules>({
            defaultState: new ScheduleModals({})
        })

    let jobsThead = useMemo(() => jobsScheduleThead(currentUser.role), [currentUser.role])

    useEffect(() => {
        jobsListFilter.getCities();
        jobsListFilter.getZones();
        jobsListFilter.getSchoolBoard();
        shiftVocabulary.getVocabulary();
        reasonVocabulary.getVocabulary();
        bookLeaveVocabulary.getVocabulary();
    }, []);

    useEffect(() => {
        if (!jobsListFilter.filter.hasValue) {
            setDefaultSchoolYear(jobsListFilter.onFilterDefaultSchoolYear, yearId => 
                fetchTableList(1, 24, jobsListFilter.generateFilterQuery(false, false, undefined)),
                true
            );
        }
    }, [jobsListFilter.filter.hasValue])


    useEffect(() => {
        if (!jobsOnMapListFilter.filter.hasValue && !showClear) {
            setDefaultSchoolYear(jobsOnMapListFilter.onFilterDefaultSchoolYear, async yearId => {
                const query = jobsOnMapListFilter.generateFilterQuery(false, false, undefined);
                const schedulesForMap =  await jobsOnMapData.fetchData(1, 0, query.includes('SchoolYearId') ? query : jobsOnMapListFilter.generateFilterQuery(false, false, undefined, yearId ? `&SchoolYearId=${yearId}` : ''), true);

                generateMarkersForJobs(filterActiveSchedulesByDateTime(schedulesForMap || []))
            }, true
            );
        }
        ;
    }, [jobsOnMapListFilter.filter.hasValue, showClear])


    useEffect(() => {
        console.log(jobsOnMap)
    }, [])
    // TODO: Avoid any
    const clearTimeToAndFromToDefault = (filterHook: any) => {
        const { setFilter, filter } = filterHook
        setFilter({ ...filter, timeFrom: UTCTimeNow(), timeTo: UTCTimeNow() })
    }

    const filterActiveSchedulesByDateTime = (data: IActiveJobSchedules[]) => {
        return data
            .map((job) => {
                if (moment.utc(job.endsOn).isSame(moment(), 'day')) {
                    if (moment().isAfter(moment.utc(job.timeTo).local())) {
                        job.isInPast = true;
                    }
                }
                return job;
            })
            .filter((job) => !job.isInPast);
    };

    const clearListFilters = () => {
        clearTimeToAndFromToDefault(jobsListFilter);
        setShowClear(false)
        setSearchIsActive(false);
        jobsListTable.onClearFilterQuery();
        jobsListFilter.onFilterClearClick();
    }

    const clearMapFilters = () => {
        clearTimeToAndFromToDefault(jobsOnMapListFilter)
        setSearchIsActive(false);
        jobsOnMapData.onClearFilterQuery();
        jobsOnMapListFilter.onFilterClearClick();
        setShowClear(false)
    }

    const handleScrollToMap = () => {
        const myLink = refMap as MutableRefObject<HTMLElement | null>;
        myLink !== null && myLink.current!.scrollIntoView();
    };

    const onAddEditScheduleClick = (actionType: 'add' | 'edit', jobSchedule?: IActiveJobSchedules) => {
        jobSchedule && setScheduleInFocus(jobSchedule)
        setScheduleModals(new ScheduleModals({
            showEdit: actionType === 'edit' ? true : false,
            showAdd: actionType === 'add' ? true : false
        }))
    }

    const onAddEditScheduleSubmit = async (updatedSchedule: IActiveJobSchedules, actionType?: "add" | "edit") => {
        const dataSvc = new DataService<IActiveJobSchedules>({ url: "schedule" })
        showLoader(true)
        try {
            actionType === "add" ?
                await dataSvc.create(updatedSchedule) :
                await dataSvc.update(updatedSchedule, updatedSchedule.id as number)
            // onSuccessScheduleAddEdit(actionType)
            setScheduleInFocus({} as IActiveJobSchedules)
            setScheduleModals(new ScheduleModals({}))
            showLoader(false)
        } catch (error: any) {
            showNotification(NotificationTypes.danger, error.message)
            showLoader(false)
        }
    }

    // Start Add SHift
    const onAddAShiftClick = (jobSchedule: IActiveJobSchedules) => {
        setScheduleModals(new ScheduleModals({ showAddShift: true }))
        setScheduleInFocus(jobSchedule)
    }

    // Start View Shifts
    const onViewEditShiftsClick = (jobSchedule: IActiveJobSchedules) => {
        setScheduleModals(new ScheduleModals({ showListModal: true }))
        setScheduleInFocus(jobSchedule)
    }

    return (
        <Layout
            breadcrumbs={{
                links: [
                    {
                        title: 'Jobs',
                        link: 'jobs',
                    },
                ],
                currentPageTitle: 'Job Schedules',
            }}>
            <div className='row mb-4'>
                <div
                    className='col-12 d-flex flex-row align-items-center flex-wrap justify-content-between justify-content-md-start'>
                    <SectionTitle title={`Active Job Schedules`} />
                    <button
                        type="button"
                        className="btn btn-outline-aqua-blue ml-md-3 d-flex align-items-center"
                        onClick={handleScrollToMap}
                    >
                        <span className="d-flex align-items-center">
                            View Map
                        </span>
                        <SVGViewOnMap className="ml-3" />
                    </button>
                    <div className='d-flex flex-row ml-0 ml-sm-auto mt-2 mt-lg-0 w-100-md-0'>
                        <div className='form-group mb-0 mx-2 w-100-md-0'>
                            <AutocompleteField
                                includes={'Job,Guard,SchoolBoard'}
                                isAsync={true}
                                onlyActive
                                placeholder='Enter job name'
                                onSearch={async (value) => {
                                    setSearchIsActive(true);
                                    jobsListFilter.onFilterDropdownChange(value, 'keyWord')
                                    await fetchTableList(1, 24, jobsListFilter.generateFilterQuery(false, false, value, defaultYear.current  ? `SchoolYearId=${defaultYear.current}` : ''), false, false);
                                }}
                                onClear={() => {
                                    setSearchIsActive(false);
                                    jobsListFilter.onFilterSearchClearClick(() =>
                                        fetchTableList(1, 24, jobsListFilter.generateFilterQuery(true))
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <form className='filters-form-group mb-4' onSubmit={e => {
                e.preventDefault()
            }} onKeyDown={(event) => {
                if (event.key === 'Enter') fetchTableList(1, 24, jobsListFilter.generateFilterQuery())
            }}>
                <div className='row'>
                    <div className='col-12'>
                        <h5>Filter Job Schedules List</h5>
                    </div>
                    <div className='d-flex w-100 flex-wrap ml-1 mr-1 align-items-center' >
                    { React.useMemo(() => (
                                <>
                                    <div className='d-flex w-100 flex-wrap justify-content-between'>
                                        <CustomSelectControl
                                            isClearable={true}
                                            options={jobsListFilter.zones || []}
                                            value={jobsListFilter.filter.zone}
                                            placeholder={'Select Zone'}
                                            sort
                                            onChange={(e) => jobsListFilter.onFilterDropdownChange(e, 'zone')}
                                            className='flex-grow-0 ml-2 mb-2 custom-react-select-lg--24'
                                        />
                                        <CustomSelectControl
                                            isClearable={true}
                                            options={jobsListFilter.schoolBoards || []}
                                            value={jobsListFilter.filter.schoolBoardId}
                                            placeholder={'Select School Board'}
                                            onChange={(e) => jobsListFilter.onFilterDropdownChange(e, 'schoolBoardId')}
                                            className='flex-grow-0 ml-2 mb-2 custom-react-select-lg--24'
                                        />

                                        <CustomSelectControl
                                            isClearable={true}
                                            options={shiftVocabulary.vocabulary}
                                            value={jobsListFilter.filter.shiftPeriod}
                                            placeholder={'Select Shift Period'}
                                            onChange={(e) => jobsListFilter.onFilterDropdownChange(e, 'shiftPeriod')}
                                            className='flex-grow-0 ml-2 mb-2 custom-react-select-lg--24'
                                        />
                                        <CustomSelectControl
                                            options={schoolYears}
                                            onChange={(value) => jobsListFilter.onFilterDropdownChange(value, 'schoolYearId')}
                                            value={jobsListFilter.filter.schoolYearId}
                                            placeholder={'Select a School Year'}
                                            className={`flex-grow-0 ml-2 mb-2 custom-react-select-lg--24`}
                                        />
                                    </div>

                                    <div className='d-flex flex-wrap ml-2 mb-2 mb-lg-0'>
                                        <div
                                            className='react-datepicker-custom-wrapper datepicker-width d-inline-block mr-sm-0 d-sm-flex mb-2 mb-md-0'>
                                            <div className="form-group mb-0 d-flex align-items-center">
                                                <label className="mb-0 mr-2">Time from: </label>
                                            </div>
                                            <CustomTimePicker
                                                value={jobsListFilter.filter.timeFrom ? UTCtimeToLocal(jobsListFilter.filter.timeFrom) : UTCtimeToLocal()}

                                                onChange={(time) => {
                                                    jobsListFilter.onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeFrom');
                                                }}
                                                index={1}
                                            />
                                        </div>

                                        <div
                                            className='react-datepicker-custom-wrapper datepicker-width d-inline-block mr-5 mr-sm-0 d-sm-flex mb-2 mb-md-0'>
                                            <div className="form-group mb-0 d-flex align-items-center">
                                                <label className="mb-0 mr-4 mr-md-2">Time to: </label>
                                            </div>
                                            <CustomTimePicker
                                                value={jobsListFilter.filter.timeTo ? UTCtimeToLocal(jobsListFilter.filter.timeTo) : UTCtimeToLocal()}
                                                onChange={(time) => {
                                                    jobsListFilter.onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeTo');
                                                }}
                                                index={2}
                                            />
                                        </div>
                                    </div>
                                    
                                </>

                            ),[jobsListFilter.cities, jobsListFilter.schoolBoards, jobsListFilter.filter, schoolYears, shiftVocabulary.vocabulary])
                        }
                        <div className='d-flex ml-auto'>
                            <button
                                type='button'
                                className='btn btn-aqua-blue ml-2'
                                onClick={async () => {
                                    await fetchTableList(1, 24, jobsListFilter.generateFilterQuery())
                                }}>
                                Filter Schedules
                            </button>
                            {jobsListFilter.filter.hasValue && (
                                <button
                                    type='button'
                                    className='btn btn-aqua-blue ml-2'
                                    onClick={clearListFilters}       
                                >
                                    <SVGEraser/>
                                </button>
                            )}
                        </div>
                        
                    </div>
                </div>
            </form>

            <div className='row' >
                {React.useMemo(
                    () => (
                        <div className='col-12'>
                            <DataTable
                                thead={jobsThead}
                                tbody={filterActiveSchedulesByDateTime(tableListData).map((job) => {
                                    return {
                                        id: job.id,
                                        jobNameLink: `<a href="#/jobs/${job.id}/details">${job.jobName}</a>`,
                                        guardName: job.guardName || '-',
                                        startDate: momentDateFormat(job.startDate),
                                        endDate: job.endsOn,
                                        aspstartdate: job.aspStartDate && momentHoursFormat(job.aspStartDate, job.timeFrom, true),
                                        'aspstartdate-times': `${momentHoursFormat(job.timeFrom)} - ${momentHoursFormat(job.timeTo, job.timeFrom)}`,
                                        zone: job.zone,
                                        scheduleStatus: job.scheduleStatus,
                                        latesOrAbsences: job.latesOrAbsences,
                                    };
                                })}
                                // ignoreCols={[0, AuthService.checkIfUserHasPermission([Roles.Client], currentUser.role) ? 5 : -1, 8, 9]}
                                ignoreCols={[...(isClientRole() || isSupervisorRole() ? [0, 5, 8, 9] : [0, 8, 9])]}
                                ignoreTheadCols={[...(isClientRole() || isSupervisorRole() ? [10] : [])]}
                                isLoading={jobsListTable.isLoading}
                                tableClass={'table-info--notFixed'}
                                pagination={true}
                                currentPage={jobsListTable.currentPage}
                                itemsCount={jobsListTable.allRecords}
                                itemsPerPage={+jobsListTable.perPage}
                                onPager={(page) => jobsListTable.onPagination(page)}
                                onPerPage={(value) => jobsListTable.onPerPage(value)}
                                tableName={'Active Schedules'}
                                onSort={(orderBy, desc) => jobsListTable.onSortCall(orderBy, desc, jobsListFilter.filter.keyWord)}
                                showTableLengthData={true}
                                sortOnBackend={true}
                                searchIsActive={searchIsActive}
                                filtersIsNotClear={jobsListFilter.filterIsClear ? false : true}
                                defaultSortedColumn={2}>
                                {(scheduleId, rowItem: IActiveJobSchedules, rowIndex: number) => (
                                    <>
                                        <td className='align-middle'>
                                            <div>
                                                <span
                                                    className={`d-flex align-items-center justify-content-center table-info__scheduleStatus table-info__scheduleStatus${rowItem.scheduleStatus === 0 ? '--empty' : '--nonEmpty'
                                                        }`}>
                                                    {rowItem.scheduleStatus !== 0 && (
                                                        <Link
                                                            to={`/jobs/${scheduleId}/details?defaultKey=shifts-need`}
                                                            className='px-1 text-dark'>
                                                            see open shifts
                                                        </Link>
                                                    )}
                                                </span>
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            <div>
                                                <span
                                                    className={`d-flex align-items-center justify-content-center table-info__counter table-info__counter${rowItem.latesOrAbsences === 0 ? "--best" : "--good"}`}>
                                                    {rowItem.latesOrAbsences}
                                                </span>
                                            </div>
                                        </td>
                                        <td className='align-middle'>
                                            <button
                                                className='btn btn-aqua-blue'
                                                onClick={() =>
                                                    onViewLocationInMapClick(tableListData, tableListData[rowIndex].id as number)
                                                }>
                                                <SVGMarker />
                                            </button>
                                        </td>
                                        {!isClientRole() &&
                                            <td className="aling-middle">
                                                {(tableListData[rowIndex]?.isActive === ModelStatus.pending || tableListData[rowIndex]?.isActive === ModelStatus.inactive)
                                                    ?
                                                    <div>
                                                        <span className="font-weight-bold text-uppercase">
                                                            {tableListData[rowIndex]?.isActive === ModelStatus.pending ? ModelStatus.pending : ModelStatus.inactive}
                                                        </span>
                                                    </div>
                                                    :
                                                    <SchedulesActions
                                                        // onEditSchedule={() => onAddEditScheduleClick('edit', tableListData[rowIndex])}
                                                        onDeactivateSchedule={() => onRequestHandler({
                                                            title: `Are you sure you want to deactivate selected schedule for <span class="font-weight-bold">${tableListData[rowIndex].jobName}</span>?`,
                                                            itemInFocus: tableListData[rowIndex],
                                                            type: ActivationDeactivation.deactivate
                                                        })}
                                                        onAddAShift={() => onAddAShiftClick(tableListData[rowIndex])}
                                                        onViewEditShifts={() => onViewEditShiftsClick(tableListData[rowIndex])}
                                                    />
                                                }
                                            </td>
                                        }
                                    </>
                                )}
                            </DataTable>
                        </div>
                    ),
                    [tableListData, jobsListTable.isLoading]
                )}
            </div>

            {viewLocationInMap && (
                <BaseModal
                    show={viewLocationInMap}
                    onCancel={() => setViewLocationInMap(false)}
                    className={'view-job-location'}
                    cancelBtnText={'Close'}>
                    <GeofenceMap
                        lat={+jobItemInFocus.location.latitude}
                        lng={+jobItemInFocus.location.longititude}
                        radius={+jobItemInFocus.geoFence}
                        removeLoadScript={true}
                        markerIsALink={`/jobs/${jobItemInFocus.id}/details`}
                    />
                </BaseModal>
            )}

            <section className='mt-5 mb-4' ref={refMap}>
                <div className='row'>
                    <div className='col-12'>
                        <form className='filters-form-group mb-4' onSubmit={e => {
                            e.preventDefault()
                        }} onKeyDown={(event) => {
                            if (event.key === 'Enter') jobsOnMapData.fetchData(0, 0, jobsOnMapListFilter.generateFilterQuery(true, false))
                        }}>
                            <div className='row'>
                                <div className='col-12'>
                                    <h5>Filter Jobs Schedules on Map</h5>
                                </div>

                                <div className='d-flex w-100 flex-wrap ml-1 align-items-center'>

                                    {React.useMemo(() => (
                                        <>
                                            <div className='d-flex w-100 flex-wrap justify-content-between'>
                                                <CustomSelectControl
                                                    isClearable={true}
                                                    options={jobsListFilter.zones || []}
                                                    value={jobsOnMapListFilter.filter.zone}
                                                    placeholder={'Select Zone'}
                                                    sort
                                                    onChange={(e) => jobsOnMapListFilter.onFilterDropdownChange(e, 'zone')}
                                                    className='flex-grow-0 ml-2 mb-2 custom-react-select-lg--24'
                                                />
                                                <CustomSelectControl
                                                    isClearable={true}
                                                    options={jobsListFilter.schoolBoards || []}
                                                    value={jobsOnMapListFilter.filter.schoolBoardId}
                                                    placeholder={'Select School Board'}
                                                    onChange={(e) => jobsOnMapListFilter.onFilterDropdownChange(e, 'schoolBoardId')}
                                                    className='flex-grow-0 ml-2 mb-2 custom-react-select-lg--24'
                                                />

                                                <CustomSelectControl
                                                    isClearable={true}
                                                    options={shiftVocabulary.vocabulary}
                                                    value={jobsOnMapListFilter.filter.shiftPeriod}
                                                    placeholder={'Select Shift Period'}
                                                    onChange={(e) => jobsOnMapListFilter.onFilterDropdownChange(e, 'shiftPeriod')}
                                                    className='flex-grow-0 ml-2 mb-2 custom-react-select-lg--24'
                                                />

                                                <CustomSelectControl
                                                    options={schoolYears}
                                                    onChange={(value) => jobsOnMapListFilter.onFilterDropdownChange(value, 'schoolYearId')}
                                                    value={jobsOnMapListFilter.filter.schoolYearId}
                                                    placeholder={'Select a School Year'}
                                                    className={`flex-grow-0 ml-2 mb-2 custom-react-select-lg--24`}
                                                />  
                                            </div>
                                            

                                            <div className='d-flex flex-wrap ml-2 mb-2 mb-lg-0'>
                                                <div
                                                    className='react-datepicker-custom-wrapper datepicker-width d-inline-block mr-5 mr-sm-0 d-sm-flex mb-2 mb-md-0'>
                                                    <div className="form-group mb-0 d-flex align-items-center">
                                                        <label className="mr-2 mb-0">Time from: </label>
                                                    </div>
                                                    <CustomTimePicker
                                                        value={jobsOnMapListFilter.filter.timeFrom ? UTCtimeToLocal(jobsOnMapListFilter.filter.timeFrom) : UTCtimeToLocal()}
                                                        onChange={(time) => {
                                                            jobsOnMapListFilter.onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeFrom');
                                                        }}
                                                        index={5}

                                                    />
                                                </div>

                                                <div
                                                    className='react-datepicker-custom-wrapper datepicker-width d-inline-block d-sm-flex mb-2 mb-md-0'>
                                                    <div className="form-group mb-0 d-flex align-items-center">
                                                        <label className="mb-0 mr-4 mr-md-2">Time to: </label>
                                                    </div>
                                                    <CustomTimePicker
                                                        value={jobsOnMapListFilter.filter.timeTo ? UTCtimeToLocal(jobsOnMapListFilter.filter.timeTo) : UTCtimeToLocal()}
                                                        onChange={(time) => {
                                                            jobsOnMapListFilter.onFilterDropdownChange(timeWithoutSecondsFormat(time), 'timeTo');
                                                        }}
                                                        index={6}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </>

                                    ), [jobsListFilter.cities, jobsListFilter.schoolBoards, jobsOnMapListFilter.filter, shiftVocabulary.vocabulary])}


                                    <div className='d-flex ml-auto'>
                                        <button
                                            type='button'
                                            className='btn btn-aqua-blue ml-2'
                                            onClick={async () => {
                                                setShowClear(true)
                                                const jobsForMap = await jobsOnMapData.fetchData(1, 0, jobsOnMapListFilter.generateFilterQuery(true, false), true)
                                                generateMarkersForJobs(filterActiveSchedulesByDateTime(jobsForMap || []));

                                            }}>
                                            Filter Schedules
                                        </button>
                                        {jobsOnMapListFilter.filter.hasValue && showClear && (
                                            <button
                                                type='button'
                                                className='btn btn-aqua-blue ml-2'
                                                onClick={clearMapFilters}
                                            >
                                                <SVGEraser />
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                {
                    React.useMemo(() => (
                        <div className='row job-location-large'>
                            <div className='col-12'>
                                <JobsMapComponent jobMarkers={jobsOnMap.markers}>
                                    {
                                        jobsOnMapData.isLoading &&
                                        <SectionLoader />
                                    }
                                </JobsMapComponent>
                            </div>
                        </div>
                    ), [jobsOnMap.markers, jobsOnMapData.isLoading])
                }
            </section>

            <section className='mt-3 mt-md-5 mb-2'>
                <div className='row'>
                    <div
                        className='col-12 pb-4 d-flex flex-row flex-wrap justify-content-end justify-content-md-between'>
                        <SectionTitle title='Shifts That Require A Guard to be Assigned Today' />
                        <Link className='font-weight-bold text-primary' to={'/jobs/unassigned'}>
                            See All
                        </Link>
                    </div>
                    <ShiftsRequireGuard
                        shiftVocabulary={shiftVocabulary.vocabulary}
                        reasonVocabulary={reasonVocabulary.vocabulary}
                        bookLeaveVocabulary={bookLeaveVocabulary.vocabulary}
                        guardDeactVocabulary={guardDeactVocabulary}
                    />
                </div>
            </section>

            {/* Start ScheduleDeactivation */}
            {

                requestModals.showRequest && <DeactivateModal
                    onCancel={() => onCloseRequestModals()}
                    onSubmit={(reason: string | IVocabulary) => onRequestSubmitHandler(
                        ActivationDeactivation.deactivate,
                        { itemData: null, successCallback: () => fetchTableList(1, 24, jobsListFilter.generateFilterQuery()) },
                        `${isAdminRole() || isSuperAdminRole()
                            ? `<span class="font-weight-bold">${itemInFocus.jobName}</span> schedule has been deactivated.`
                            : `Your request to deactivate <span class="font-weight-bold">${itemInFocus.jobName}</span> schedule has been sent to the Administrator.`} `,
                        reason
                    )}
                    title={modalText.title}
                    vocabulary={scheduleDeactivation}
                    htmlBody={<DeactivateScheduleModalBody selectedItem={itemInFocus as IJobSchedule} />}
                />
            }


            {
                requestModals.showSuccess &&
                <BaseModal
                    show={requestModals.showSuccess}
                    onCancel={() => onCloseRequestModals()}
                    cancelBtnText={"Close"}
                    title={modalText.title}
                >
                </BaseModal>
            }
            {/* End ScheduleDeactivation */}

            {/* Start Edit Schedule Modal */}
            {
                (scheduleModals.showEdit || scheduleModals.showAdd) && <AddEditScheduleModal
                    onCancel={() => setScheduleModals(new ScheduleModals({}))}
                    onSubmit={(schedule, action) => onAddEditScheduleSubmit(schedule as IActiveJobSchedules, action)}
                    schedule={scheduleModals.showEdit ? scheduleInFocus : undefined}
                    descriptionNote={scheduleModals.showEdit ? 'When a schedule is edited, the affected future shifts will be removed, and new ones will be created. Removed shifts will be stored in the archive records. The assigned guard will be unassigned.' :
                        'When a schedule is added, there is no check for overlap with an existing schedule or shifts for this job. You will be asked to assign a Guard.'}
                />
            }
            {/* End Edit Schedule Modal */}

            {/* Add Shift To Schedule Modal */}
            {
                scheduleModals.showAddShift &&
                <AddShiftToScheduleModal
                    jobName={scheduleInFocus.jobName || ""}
                    jobSchedule={scheduleInFocus}
                    shiftVocabulary={shiftVocabulary.vocabulary}
                    onCancel={() => setScheduleModals(new ScheduleModals({}))}
                    successCallback={async () => {
                        await fetchTableList(1, 24, jobsListFilter.generateFilterQuery());
                    }}
                />
            }

            {/* View Shifts Modal */}
            {scheduleModals.showListModal &&
                <ViewShiftsOfScheduleModal
                    jobName={scheduleInFocus.jobName}
                    jobSchedule={scheduleInFocus}
                    shiftVocabulary={shiftVocabulary.vocabulary}
                    onCancel={() => setScheduleModals(new ScheduleModals({}))}
                    successCallback={async () => {
                        await fetchTableList(1, 24, jobsListFilter.generateFilterQuery());
                    }}
                />
            }
        </Layout>
    );
};

export default ActiveJobSchedules;
