import moment from 'moment';
import React, { FC, memo, useEffect, useRef, useState } from 'react'
import { customFormatFromUTC } from '../../utils/DateFormatting';

type Props = {
    value: moment.Moment | undefined
    defaultValue?: moment.Moment
    format?: string
    className?: string
    onChange: (time: string) => void
    index?: any
    defaultSet?: boolean
}

enum DayPeriod {
    am = 'AM',
    pm = 'PM'
}

const CustomTimePicker: FC<Props> = memo(({className, value, onChange, index, defaultValue}) => {
    const [hours, setHours] = useState<string>('')
    const [minutes, setMinutes] = useState<string>('')
    const [dayPeriod, setDayPeriod] = useState<string>(DayPeriod.am)
    // const [prevValue, setPrevValue] = useState<string>('')
    const prevValue = useRef<string>('')
    const hrsInputRef = useRef<HTMLInputElement>({} as HTMLInputElement)
    const minsInputRef = useRef<HTMLInputElement>({} as HTMLInputElement)
    const firstLoadRef = useRef(false);

    useEffect(() => {
        if (value) {
            parseIncomeDate(value)
        } else {
            defaultValue && parseIncomeDate(defaultValue)
        }
    }, [value, defaultValue])

    const minuteRules = React.useMemo(() => (
        {
            singleZero: (value: string) => value.charAt(0) === "0" && value.length === 1,
            notMore: (value: string) => +value > 59,
            isEmpty: (value: string) => value.length === 0
        }
    ), [])

    const hoursRules = React.useMemo(() => (
        {
            singleZero: (value: string) => value.charAt(0) === "0" && value.length === 1,
            zeroAtStart: (value: string) => value.charAt(0) === "0" && value.length === 2 && value.charAt(1) !== "0",
            notMore: (value: string) => +value > 12,
            isEmpty: (value: string) => value.length === 0
        }
    ), [])

    useEffect(() => {
        const incomeValue = value ? value : defaultValue;
        if (hours === "" || minutes === "" || dayPeriod === "" || !incomeValue) return;
        if (minuteRules.notMore(minutes) || hoursRules.notMore(hours)) return;
        const incomeValueFormatted = incomeValue.format()
        
        const dateWithoutTime = incomeValue.format('YYYY-MM-DD')
        const hoursWithoutDate = moment(`${hours}:${minutes} ${dayPeriod}`, 'h:mm A').format('HH:mm:ss')
        
        try {
            const timeWithDate = moment(`${dateWithoutTime} ${hoursWithoutDate}`).seconds(0).format()
            prevValue.current = timeWithDate;
            if (incomeValueFormatted === timeWithDate) return;
            !firstLoadRef.current && onChange(timeWithDate)
        } catch (error: any) {
            console.log(error)
        }
    }, [hours, minutes, dayPeriod])
    
    const validation = (input: React.MutableRefObject<HTMLInputElement>, add: boolean = true) => {
        add ? input.current.classList.add('invalid') : input.current.classList.remove('invalid')
    }

   

    const parseIncomeDate = (value: moment.Moment) => {
        if (value.format() === prevValue.current) return;
        firstLoadRef.current = (hours === "" && minutes === "") ? true : false;
        
        setHours(customFormatFromUTC(value, "h"))
        setMinutes(customFormatFromUTC(value, "mm"))
        setDayPeriod(customFormatFromUTC(value, "A"))
    }

    const onHoursChanged = (target: EventTarget & HTMLInputElement) => {
        firstLoadRef.current = false;
        if (target.validity.valid) {
            setHours(target.value);
        }
    }

    const onMinutesChanged = (target: EventTarget & HTMLInputElement) => {
        firstLoadRef.current = false;
        if (target.validity.valid) {
            setMinutes(target.value)
        }
    }

    const onDayPeriodChanged = (target: HTMLInputElement) => {
        firstLoadRef.current = false;
        setDayPeriod(target.value)
    }

    const validateHrs = React.useMemo(() => (
        (target: EventTarget & HTMLInputElement) => {
            if (hoursRules.singleZero(target.value)) setHours("12");
            if (hoursRules.zeroAtStart(target.value)) setHours(target.value.charAt(1));
            if (hoursRules.notMore(target.value)) setHours('12');
            if (hoursRules.isEmpty(target.value)) setHours('12');
        }
    ), [hoursRules])

    const validateMins = React.useMemo(() => (
        (target: EventTarget & HTMLInputElement) => {
            if (minuteRules.singleZero(target.value)) setMinutes("00");
            if (minuteRules.notMore(target.value)) setMinutes('59');
            if (minuteRules.isEmpty(target.value)) setMinutes('00');
        }
    ), [minuteRules])

    useEffect(() => {
        const value = hrsInputRef?.current.value;
        if (hoursRules.notMore(value) || value === "") {
            validation(hrsInputRef)
        } else if (hoursRules.singleZero(value)) {
            validation(hrsInputRef)
        } else if (hoursRules.zeroAtStart(value)) {
            validation(hrsInputRef)
        } else {
            validation(hrsInputRef, false)
        }
    }, [hours, hoursRules])

    useEffect(() => {
        const value = minsInputRef?.current.value;
        if (minuteRules.notMore(value) || value === "") {
            validation(minsInputRef)
        } else if (minuteRules.singleZero(value)) {
            validation(minsInputRef)
        } else {
            validation(minsInputRef, false)
        }
    }, [minuteRules, minutes])

    return (
        <form className={`custom-time-picker d-flex fex-row align-items-center ${className || ""}`} autoComplete="off">
            <div className="form-group mb-0 custom-time-picker__time d-flex flex-row align-items-center">

                {
                    React.useMemo(() => (
                        <input
                            type="text"
                            pattern="[0-9]*"
                            placeholder={"--"}
                            maxLength={2}
                            className="form-control text-center custom-time-picker__time__input"
                            autoComplete="off"
                            name="hours"
                            value={hours}
                            onChange={e => onHoursChanged(e.target)}
                            onBlur={e => validateHrs(e.target)}
                            ref={hrsInputRef}
                        />
                    ), [hours, validateHrs])
                }
                <span className="custom-time-picker__time__divider">:</span>
                {
                    React.useMemo(() => 
                        <input
                            type="text"
                            pattern="[0-9]*"
                            placeholder={"--"}
                            maxLength={2}
                            className="form-control text-center custom-time-picker__time__input"
                            autoComplete="off"
                            name="minutes"
                            value={minutes}
                            onChange={e => onMinutesChanged(e.target)}
                            onBlur={e => validateMins(e.target)}
                            ref={minsInputRef}
                        />
                    ,[minutes, validateMins])
                }

            </div>
            { React.useMemo(() =>
                <div className="custom-time-picker__dayPeriod d-flex align-items-center">
                    <div className="custom-control custom-control-radio">
                        <input type="radio" id={`AMdayPeriod${index ? ('-' + index) : ''}`}
                            className="custom-control-input"
                            value={DayPeriod.am}
                            defaultChecked={true}
                            checked={dayPeriod === DayPeriod.am ? true : false}
                            onChange={e => onDayPeriodChanged(e.target)}
                            name={`AMdayPeriod`}
                        />
                        <label className="custom-control-label custom-control-radio-label mb-0"
                            htmlFor={`AMdayPeriod${index ? ('-' + index) : ''}`}>AM</label>
                    </div>
                    <div className="custom-control custom-control-radio">
                        <input type="radio" id={`PMdayPeriod${index ? ('-' + index) : ''}`}
                            className="custom-control-input"
                            value={DayPeriod.pm}
                            defaultChecked={true}
                            checked={dayPeriod === DayPeriod.pm ? true : false}
                            onChange={e => onDayPeriodChanged(e.target)}
                            name={`PMdayPeriod${index ? ('-' + index) : ''}`}
                        />
                        <label className="custom-control-label custom-control-radio-label mb-0"
                            htmlFor={`PMdayPeriod${index ? ('-' + index) : ''}`}>PM</label>
                    </div>
                </div>
            , [dayPeriod, index])
            }
        </form>
    )
})

export default CustomTimePicker
