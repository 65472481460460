import {IGuardReportModel} from './../interfaces/IDataFilter';
import moment from "moment/moment";
import { setHoursAndMinutes } from '../../utils/DateFormatting';

export class GuardReportModel implements IGuardReportModel {
    hasValue = false;
    jobNameOrId = ''
    startDate = setHoursAndMinutes(moment().format());
    endDate = setHoursAndMinutes(moment().format());
    guardNameOrEmployeeNumber = ''
    lateAbsenceRange = ''
    SchoolYears = ''
    guardType = ''
    numberOfDeparturesFromGeofence = ''
    numberOfAssignedSchedule = ''
}
