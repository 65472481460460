export const Guard_Attendance = {
    totalGuards: {
        description: 'Number of Guards assigned to Jobs during the selected Shift Period today.',
        title: 'Total Number of Guards'
    },
    guardAttendanceForASP: {
        description: 'Percentage of Guards that have checked in for shifts for which ASP Start Time has passed. The percentage will appear once all shifts have started for the selected Shift Period.',
        title: 'Guard Attendance for ASP'
    },
    guardAttendanceForClient: {
        description: 'Percentage of Guards that have checked in for shifts for which Start Time has passed. The percentage will appear once all shifts have started for the selected Shift Period.',
        title: 'Guard Attendance for Client'
    }
}