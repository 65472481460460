import React, {FC, memo, ReactNode, useEffect, useState} from 'react'
import {SVGArrowDown, SVGArrowUp, SVGInfo, SVGTableLoader} from '../../assets/icons/SvgIcons'
import {SortConfig, SortDataTypesEnum, useSortableData} from '../../customHooks/useSortableData'
import CustomSelectControl from '../custom-select/CustomSelectControl'
import moment from 'moment/moment.js'
import ReactPaginate from 'react-paginate';
import { momentHoursFormat } from 'utils/DateFormatting'
import CustomTooltip from "../custom-tooltip/CustomTooltip";
// import TableBody from './TableBody'
import CustomSwitch from '../checkbox/CustomSwitch';
export type TheadData = {
    [index: string]: string | boolean | undefined
    label: string
    fieldName?: string
    actionLabel?: string
    sortable?: boolean
    dataType?: SortDataTypesEnum
    direction?: 'none' | 'up' | 'down'
    insivible?: boolean
    tooltip?: boolean,
    tooltipText?: string
}

type Props = {
    tbody: Array<any>
    isLoading?: boolean
    thead?: Array<TheadData>
    children?: (rowItemId: any, rowItem: any, rowIndex: number) => ReactNode
    customMarkupAtStart?: (rowItemId: any, rowItem: any, rowIndex: number) => ReactNode
    tableClass?: string
    theadClass?: string
    tbodyClass?: string
    ignoreCols?: number[] // Start from 0
    ignoreTheadCols?: number[] // Start from 0
    onColClick?: (key: string, itemId: any, rowItem: any, tdIndx: any, trIndx?: number) => void
    pagination?: boolean
    onPager?: (currPage: number) => void
    onPerPage?: (value: string) => void
    onSort?: (field: string, direction: "up" | "down") => void
    currentPage?: number
    itemsCount?: number
    filteredItemsCount?: number // shows current filtered count of records
    itemsPerPage?: number
    showTableLengthData?: boolean
    tableName?: string
    additionalTextToItemsCount?: string,
    sortOnBackend?: boolean
    searchIsActive?: boolean
    filtersIsNotClear?: boolean
    defaultSortedColumn?: number
    responsive?: 'sm' | 'md' | 'lg' | 'xl' | 'none'
    onRowClick?: (rowItem: any) => void;
    onSelectAllCheckbox?: (checkboxIdArray?: number[]) => void
    checkboxIdArray?: number[]
    showTableLastRefresh?: boolean;
    hideSelectAll?: boolean;
    onSwitchChange?: () => void;
    defaultSortDirection?: "up" | "down"
    defaultSortConfig?: SortConfig
    syncPagination?: boolean
}

const perPageOptions = [
    {
        id: "24", name: "24"
    },
    {
        id: "50", name: "50"
    },
    {
        id: "100", name: "100"
    },
    {
        id: "0", name: "All"
    }
]

const DataTable: FC<Props> = ({
        thead, tbody, children, tableClass, ignoreCols, onColClick, pagination, onPager, currentPage,
        itemsCount, itemsPerPage, onPerPage, isLoading, showTableLengthData, tableName, additionalTextToItemsCount, onSort,  sortOnBackend, searchIsActive,
        filtersIsNotClear, defaultSortedColumn, ignoreTheadCols, responsive = '', onRowClick, onSelectAllCheckbox, checkboxIdArray = [],
        showTableLastRefresh, hideSelectAll = false, customMarkupAtStart, onSwitchChange, defaultSortDirection, defaultSortConfig, syncPagination
    }) => {
    const {items, requestSort, headItems} = useSortableData(tbody,  null, thead, sortOnBackend, defaultSortedColumn, defaultSortDirection, syncPagination && currentPage && itemsPerPage ? {currentPage, itemsPerPage} : undefined);
    const [pagesNum, setPagesNum] = useState<number>(0)
    const [lastRefresh, setLastRefresh] = useState<string>(momentHoursFormat(moment().toDate()))
    const [isSwitchChecked, setIsSwitchChecked] = useState(false);
    useEffect(() => {
        (searchIsActive || !filtersIsNotClear) && headItems.forEach(th => th.direction = 'none');
    }, [searchIsActive, filtersIsNotClear])

    useEffect(() => {
        !sortOnBackend && defaultSortConfig && requestSort((defaultSortConfig.direction as 'up' | 'down') || 'up', defaultSortConfig?.colName, defaultSortConfig?.dataType, defaultSortConfig?.key)
    }, [])

    useEffect(() => {
        const pages = itemsPerPage ? Math.ceil((itemsCount as number) / (itemsPerPage as number)) : 0;
        setPagesNum(pages)
    }, [itemsCount, itemsPerPage])

    useEffect(() => {
        showTableLastRefresh && setLastRefresh(momentHoursFormat(moment().toDate()));
    }, [isLoading, showTableLastRefresh])

    const checkDataType = (data: any, fieldName: string) => {
        if (data === null || data === undefined) {
            return ""
        }
        if (isNaN(+data) && !data.toString().includes(';') && !data.toString().includes(',') && !data.toString().includes(' ') && !data.toString().includes('%') && moment(data, true).isValid()) {
            if (fieldName === "timeTo" || fieldName === "timeFrom") {
                return moment.utc(data).local().format("h:mm A").toString()
            } else {
                return moment.utc(data).format("MMM DD, Y").toString()
            }
        }
        return data;
    }

    function tbodyFieldName(theadIndx: number) {
        if (thead && thead[theadIndx].fieldName !== undefined) {
            return thead[theadIndx].fieldName!;
      }

      return Object.keys(items[0])[items[0].id !== undefined ? (theadIndx + 1) : theadIndx];
    }

    const showingItemsAmount = () => items.length.toLocaleString();

    return (
        <div className="main-table-container">

            { !isLoading && showTableLengthData && tableName !== "" &&
                <div className="mb-3 d-flex flex-row align-items-center justify-content-between flex-wrap">
                    <h5 className="mb-0 font-weight-bold">
                      Showing { showingItemsAmount() } of { itemsCount?.toLocaleString() } { tableName } { additionalTextToItemsCount }
                    </h5>
                    {showTableLastRefresh && <h6 className="mb-0">Last Table Refresh: {lastRefresh}</h6>}
                    {
                        tbody.length !== 0 && onPerPage && (itemsPerPage !== undefined)  &&
                        <div className="table-info__pagination d-flex justify-content-end align-items-end ml-auto ml-md-0 mt-2 mt-sm-0">
                            <div className="table-info__perPage d-flex flex-row align-items-center justify-content-end">
                                <label className="mr-2 mb-0">Items per page</label>
                                <CustomSelectControl
                                    className="flex-shrink-0"
                                    value={itemsPerPage.toString()}
                                    options={perPageOptions}
                                    onChange={value => onPerPage(value)}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
            <div className={`${responsive === 'none' ? 'table-responsive' : `table-responsive-${responsive ? responsive : 'lg'}`} 
            table-wrapper`}>
                <table className={`table table-info ${tableClass ? tableClass : ""}`} cellSpacing={0} cellPadding={0}>
                    {
                        headItems &&
                        <thead>
                            <tr>
                                {headItems.map((th, indx) =>
                                    (th.invisible || (ignoreTheadCols && ignoreTheadCols.includes(indx))) ? null :
                                        <th
                                            className="text-center"
                                            style={th.sortable ? {cursor: "pointer"} : {}}
                                            key={th.label}
                                        >
                                            <div className="sort-arrows">
                                                {
                                                    (items.length !== 0 && !isLoading) ?
                                                    th.sortable && !isLoading &&
                                                    <div className="d-flex flex-column align-items-start mr-1">
                                                        {
                                                            (th.direction === "down") &&
                                                            <button
                                                                type="button"
                                                                className={`btn p-0 m-0 w-auto ${th.direction === "down" ? "active-arrow" : ""}`}
                                                                onClick={() => {
                                                                    th.sortable && th.direction &&
                                                                    requestSort('up', tbodyFieldName(indx), th.dataType, indx)
                                                                    sortOnBackend && onSort && onSort(tbodyFieldName(indx), 'down')
                                                                }}
                                                            >
                                                                <SVGArrowUp height={11}
                                                                    className="d-flex align-self-start pb-1"/>
                                                            </button>
                                                        }

                                                        {
                                                            (th.direction === "up" || th.direction === 'none') &&
                                                            <button
                                                                type="button"
                                                                className={`btn p-0 m-0 w-auto ${th.direction === "up" ? "active-arrow" : ""}`}
                                                                onClick={() => {
                                                                    th.sortable && th.direction &&
                                                                    requestSort('down', tbodyFieldName(indx), th.dataType, indx)
                                                                    sortOnBackend && onSort && onSort(tbodyFieldName(indx), 'up')
                                                                }}
                                                            >
                                                                <SVGArrowDown height={11}
                                                                    className="d-flex align-self-start pt-1"/>
                                                            </button>
                                                        }
                                                    </div> : null
                                                }
                                                <div>
                                                    <div>
                                                        <span className={th.sortable ? 'sortable' : ""}
                                                              dangerouslySetInnerHTML={{__html: th.label}}/>
                                                        { th.tooltip && (
                                                            <CustomTooltip
                                                                bodyText={th.tooltipText ? th.tooltipText : ''}>
                                                                    <span className="d-block mt-1"><SVGInfo width={18} height={18}/></span>
                                                            </CustomTooltip>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {th.actionLabel && !hideSelectAll &&
                                                        <u dangerouslySetInnerHTML={{__html: th.actionLabel}}
                                                           onClick={() => {onSelectAllCheckbox && checkboxIdArray && onSelectAllCheckbox(checkboxIdArray) }}
                                                           style={{color: '#1A5B71'}} role={'button'}/>}
                                                    </div>
                                                    {
                                                        th.showSwitch && onSwitchChange && <CustomSwitch onChange={() => {
                                                            setIsSwitchChecked(prev => !prev)
                                                            onSwitchChange();
                                                        }} checked={isSwitchChecked}/>
                                                    }
                                                </div>
                                            </div>
                                        </th>
                                )}
                            </tr>
                        </thead>
                    }
                    {/* <TableBody
                        dataIsLoading = {isLoading}
                        colSpan = {headItems?.length - (ignoreTheadCols?.length || 0)}
                        tableRows = {items}
                        onRowClick = {(rowIndex) => onRowClick && onRowClick(rowIndex)}
                        onRowColClick = {(key, itemId, rowItem, tdIndx, trIndx) => onColClick && onColClick(key, itemId, rowItem, tdIndx, trIndx)}
                        ignoreTableRowCols = {ignoreCols}
                    >
                        { (id, rowItem, rowIndex) => children && children(id, rowItem, rowIndex) }
                    </TableBody> */}
                    {
                        React.useMemo((() => (
                            <tbody>
                            {
                                isLoading ?
                                    <tr className="text-center">
                                        <td colSpan={headItems?.length - (ignoreTheadCols?.length || 0)}>
                                            <div className="py-4">
                                                <SVGTableLoader className="table-info-loader"/>
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    (items.length !== 0 && !isLoading) ? items.map((tr, trIndx) =>
                                            tr !== undefined &&
                                        <tr className={`text-center row-id-${items[trIndx]?.id}`} key={`tr-${trIndx}`}
                                            onClick={() => onRowClick && onRowClick(items[trIndx])}
                                        >
                                            { customMarkupAtStart && customMarkupAtStart(items[trIndx]?.id, items[trIndx], trIndx) }

                                            {Object.entries(tr).map(([key, value], tdIndx) =>
                                                ((ignoreCols && ignoreCols.includes(tdIndx)) || value === -1) ? null :
                                                    <td className={`align-middle ${(key.includes('Link') && value !== '-') ? 'isLink' : ''}`}
                                                        key={`td-${tdIndx}`}
                                                        onClick={() => onColClick && onColClick(key, items[trIndx]?.id, items[trIndx], tdIndx, trIndx)}>
                                                            {
                                                            (Array.isArray(value) && value.every((el: any) => el.hasOwnProperty('type')) ) ? value :
                                                            <div dangerouslySetInnerHTML={{__html: checkDataType(value as any, key)}}/>
                                                            }
                                                    </td>
                                            )}
                                            {children && children(items[trIndx]?.id, items[trIndx], trIndx)}
                                        </tr>) :
                                        <tr className="text-center">
                                            <td colSpan={headItems?.length - (ignoreTheadCols?.length || 0)}>
                                                <div className="py-4">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                            }

                            </tbody>
                        )), [isLoading, items, headItems])
                        // )) ,[isLoading, splitedData, headItems])
                    }
                </table>


            </div>
            {/* Start Per Page */}

            {/* Start Pagination */}
            {
                !searchIsActive && tbody.length !== 0 && onPerPage && (itemsPerPage !== undefined) &&
                <div className="table-info__pagination pt-3 pb-2 d-flex justify-content-end align-items-end">
                    <div className="table-info__perPage d-flex flex-row align-items-center justify-content-end">
                        <label className="mr-2 mb-0">Items per page</label>
                        <CustomSelectControl
                            className="flex-shrink-0"
                            value={itemsPerPage.toString()}
                            options={perPageOptions}
                            onChange={value => onPerPage(value)}
                        />
                    </div>
                </div>
            }
            {
                pagination && pagesNum > 1 &&
                <div
                    className="table-info__pagination pt-3 pb-2 d-flex flex-wrap justify-content-end align-items-center">
                    <ReactPaginate
                        containerClassName="d-flex flex-wrap ml-md-o mr-md-0"
                        pageCount={pagesNum}
                        // initialPage={1}
                        forcePage={ currentPage ? (+currentPage - 1) : undefined}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        previousClassName="previousClassName ml-0 mr-auto mt-3 mt-md-0"
                        nextClassName="nextClassName mt-3 mt-md-0  mr-0"
                        nextLabel={'Next'}
                        previousLabel={'Previous'}
                        onPageChange={item => onPager && onPager(item.selected + 1)}
                        previousLinkClassName={'btn btn-outline-aqua-blue px-2 mr-auto ml-auto ml-md-0'}
                        nextLinkClassName={'btn btn-aqua-blue px-2 mr-auto'}
                    />
                </div>
            }
        </div>
    )
}

export default memo(DataTable)
