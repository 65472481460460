import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { SVGEraser, SVGPlus } from '../../assets/icons/SvgIcons';
import { IGuardShift } from '../../common/interfaces/guards/IShift';
import { IVocabulary } from '../../common/interfaces/vocabulary/IVocabulary';
import { IJobSchedule } from '../../common/interfaces/jobs/IJob';
import BaseModal from '../../components/modal/BaseModal';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import ShiftAssignedSuccessModal from '../../components/modal/jobs/ShiftAssignedSuccessModal';
import DataTable from '../../components/tables/DataTable';
import SectionTitle from '../../components/titles/SectionTitle';
import { availableJobShiftsContet, availableSchedulesContet } from '../../content/dashboard/TablesContent';
import GuardDetailsModalsContext from '../../context/guards/GuardDetailsModalsContext';
import useAssignGuard from '../../customHooks/useAssignGuard';
import { useTableList } from '../../customHooks/useTableList';
import { momentDateFormat, momentHoursFormat } from '../../utils/DateFormatting';
import { getHolidayField } from '../../utils/GetFieldFromTable';
import { matchWeekDays } from '../../utils/MatchWeekDays';
import { matchOptionWithName } from '../../utils/MatchOptionWithName';
import { useListFilter } from '../../customHooks/useListFilter';
import AutocompleteField from '../../components/autosuggestField/AutocompleteField';
import { convertToKm } from '../../utils/ConvertToKm';
import CustomSelectControl from 'components/custom-select/CustomSelectControl';
import { useSchoolYear } from 'customHooks/useSchoolYear';

type Props = {
    onAssignGuardToJobShift: (jobId: number, jobShift: IGuardShift) => void;
    onAssignGuardToJobSchedule: (schedule: IJobSchedule) => void;
    vocabulary: IVocabulary[];
};

const UpdateAssignments: FC<Props> = ({ onAssignGuardToJobShift, onAssignGuardToJobSchedule, vocabulary }) => {
    const { userName, userId } = useContext(GuardDetailsModalsContext);
    const [modals, setModals] = useState<{ assignShift: boolean; assignShiftSuccess: boolean; assignSchedule: boolean; assignScheduleSuccess: boolean }>({
        assignShift: false,
        assignShiftSuccess: false,
        assignSchedule: false,
        assignScheduleSuccess: false,
    });
    const { tableData, isLoading, setTableData, fetchData: fetchJobShifts, onClearFilterQuery: onClearFilterQueryShifts } = useTableList<IGuardShift>(
        `shift/unassigned?GuardId=${userId}`,
        0,
        false
    );
    const {
        tableData: availableSchedules,
        isLoading: schedulesIsLoading,
        setTableData: setScheduleTable,
        fetchData: fetchJobSchedules,
        onClearFilterQuery: onClearFilterQuerySchedules,
    } = useTableList<IJobSchedule>(`schedule/unassigned?GuardId=${userId}`, 0, false);
    const {getSchoolYears: setDefaultSchoolYears, schoolYears, defaultYear } = useSchoolYear()

    const [shift, setJobShift] = useState<{ name: string; id: number; selectedJob: IGuardShift }>({
        name: '',
        id: 0,
        selectedJob: {} as IGuardShift,
    });
    const [schedule, setJobSchedule] = useState<{ name: string; id: number; selectedSchedule: any }>({
        name: '',
        id: 0,
        selectedSchedule: {} as any,
    });
    const { onAssignGuardSubmit: saveAssignedGuard } = useAssignGuard('schedule');
    const { onAssignGuardSubmit: saveGuardToShift } = useAssignGuard('shift');
    const {  filter, onFilterDropdownChange, onFilterClearClick,
        onFilterSearchClearClick, onFilterDefaultSchoolYear } = useListFilter('', 0, 0, true);

    useEffect(() => {
        setDefaultSchoolYears(onFilterDefaultSchoolYear, yearId => {
                fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&SchoolYearId=${yearId}`, false, true)
                fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&SchoolYearId=${yearId}`, false, true)
            },
            true
        );
    }, []);

    const onAssignShiftSubmit = async () => {
        saveGuardToShift(userId, shift.id, 'assign', () => {
            const jobShift = tableData.find((item) => +item.id === +shift.id);

            jobShift && onAssignGuardToJobShift(shift.id, jobShift);

            setTableData(tableData.filter((item) => +item.id !== +shift.id));
            setModals({
                ...modals,
                assignShift: false,
                assignScheduleSuccess: false,
                assignShiftSuccess: true,
            });
            fetchJobShifts(0, `shift/unassigned?GuardId=${userId}`);
            fetchJobSchedules(0, `schedule/unassigned?GuardId=${userId}`);
        });
    };

    const onAssignShiftClick = useMemo(
        () => (id: number) => {
            const job = tableData.find((item) => +item.id === +id);
            job && setJobShift({ id: id, name: job.jobName as string, selectedJob: job });
            setModals({
                ...modals,
                assignShift: true,
                assignShiftSuccess: false,
                assignScheduleSuccess: false,
            });
        },
        [tableData]
    );

    const onAssignScheduleClick = useMemo(
        () => (id: number) => {
            const schedule = availableSchedules.find((item) => item.id === id);
            schedule && setJobSchedule({ id: schedule.id as number, name: schedule.jobName as string, selectedSchedule: schedule });
            setModals({
                ...modals,
                assignSchedule: true,
                assignShiftSuccess: false,
                assignScheduleSuccess: false,
            });
        },
        [availableSchedules]
    );

    const onAssignScheduleSubmit = () => {
        saveAssignedGuard(userId, schedule.id, 'assign', () => {
            setScheduleTable(availableSchedules.filter((item) => item.id !== +schedule.id));
            onAssignGuardToJobSchedule(schedule.selectedSchedule);
            setModals({
                ...modals,
                assignShift: false,
                assignSchedule: false,
                assignScheduleSuccess: true,
            });
            fetchJobShifts(0, `shift/unassigned?GuardId=${userId}`);
            fetchJobSchedules(0, `schedule/unassigned?GuardId=${userId}`);
        });
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            filter.keyWord !== '' && fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&KeyWord=${filter.keyWord}`, false, true);
            filter.keyWord !== '' && fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&KeyWord=${filter.keyWord}`, false, true);
        }
    };

    return (
        <>
            <section>
                <div className='row'>
                    <div className='col-12 mb-4'>
                        <SectionTitle title={`Update Assignments for ${userName}`} />
                        <h6 className='pt-4'>These are job shifts that fit within the availability of this guard.</h6>
                    </div>
                    <div className='col-12 col-md-4 ml-auto w-100-md-0'>
                        <div className='d-flex flex-row justify-content-start justify-content-md-end w-100-md-0'>
                            <div className='form-group ml-0 mr-2 w-100-md-0'>
                                <AutocompleteField
                                    includes={'Job'}
                                    isAsync={true}
                                    placeholder={'Enter keyword'}
                                    onClear={() => {
                                        onClearFilterQueryShifts();
                                        onClearFilterQuerySchedules();
                                        onFilterSearchClearClick(() =>  Promise.all(
                                            [
                                                fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&SchoolYearId=${defaultYear.current}`, false, true),
                                                fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&SchoolYearId=${defaultYear.current}`, false, true)
                                            ]
                                        ));
                                        setDefaultSchoolYears(onFilterDefaultSchoolYear)
                                    }}
                                    onSearch={(value) => {
                                        fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&KeyWord=${value}&SchoolYearId=${filter.schoolYearId}`, false, true);
                                        fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&KeyWord=${value}&SchoolYearId=${filter.schoolYearId}`, false, true);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <form className="filters-form-group mb-0" onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&SchoolYearId=${filter.schoolYearId}`, false, true);
                                fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&SchoolYearId=${filter.schoolYearId}`, false, true);
                            }
                        }}>
                            <div className="">
                                <h5>Filter assignments</h5>
                            </div>
                            <div className=" d-flex flex-row flex-wrap">
                                
                                <CustomSelectControl
                                    options={schoolYears || []}
                                    value={filter.schoolYearId}
                                    placeholder={"Select School Year"}
                                    onChange={e => onFilterDropdownChange(e, "schoolYearId")}
                                    className="flex-grow-0 ml-0 ml-lg-2 mb-2 mb-lg-0 custom-react-select-lg"
                                />

                                <button
                                    type="button"
                                    className="btn btn-aqua-blue ml-auto ml-md-0 ml-lg-2 mb-2 mb-md-0 mt-2 mt-lg-0"
                                    onClick={() => {
                                        fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&SchoolYearId=${filter.schoolYearId}`, false, true);
                                        fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&SchoolYearId=${filter.schoolYearId}`, false, true);
                                    }}
                                >
                                    Filter Schedules and Shifts
                                </button>
                                {
                                    filter.hasValue &&
                                    <button
                                        type="button"
                                        className="btn btn-aqua-blue ml-2 mb-2 mb-md-0 mt-2 mt-lg-0"
                                        onClick={() => {
                                            onFilterClearClick(() => 
                                                Promise.all(
                                                    [
                                                        fetchJobSchedules(1, 0, `schedule/unassigned?GuardId=${userId}&SchoolYearId=${defaultYear.current}`, false, true),
                                                        fetchJobShifts(1, 0, `shift/unassigned?GuardId=${userId}&SchoolYearId=${defaultYear.current}`, false, true)
                                                    ]
                                                ), {schoolYearId: defaultYear.current}
                                            );
                                            setDefaultSchoolYears(onFilterDefaultSchoolYear)
                                          
                                        }}
                                    >
                                        <SVGEraser/>
                                    </button>
                                }

                            </div>
                        </form>
                    </div>
                    {/* Start Available Job Schedules */}
                    <div className='col-12 mt-4'>
                        <h5>Available Job Schedules</h5>
                    </div>

                    <div className='col-12 mt-2'>
                        {React.useMemo(
                            () => (
                                <DataTable
                                    tableClass={'mt-4 table-info--notFixed'}
                                    thead={availableSchedulesContet.thead}
                                    tbody={availableSchedules.map((item) => ({
                                        id: item.id,
                                        jobName: item.jobName,
                                        distance: convertToKm(item.distance || 0),
                                        dates: `${momentDateFormat(item.startDate)} - ${momentDateFormat(item.endsOn)}`,
                                        AspStartTime: `${item.aspStartDate ? momentHoursFormat(item.aspStartDate, item.timeFrom, true) : ''}`,
                                        times: `${momentHoursFormat(item.timeFrom)} - ${momentHoursFormat(item.timeTo, item.timeFrom)}`,
                                        shiftPeriod: matchOptionWithName(item.shiftPeriod, vocabulary),
                                        repeatOn: `Every week: ${matchWeekDays(item.repeatOn)}`,
                                    }))}
                                    ignoreCols={[0]}
                                    isLoading={schedulesIsLoading}>
                                    {(_id) => (
                                        <td className='align-middle'>
                                            <div>
                                                <button className='btn btn-aqua-blue' onClick={() => onAssignScheduleClick(_id)}>
                                                    <SVGPlus />
                                                </button>
                                            </div>
                                        </td>
                                    )}
                                </DataTable>
                            ),
                            [availableSchedules, vocabulary, onAssignScheduleClick, schedulesIsLoading]
                        )}
                    </div>

                    {/* Start Available Job Shifts */}
                    <div className='col-12 mt-4'>
                        <h5>Available Job Shifts</h5>
                    </div>
                    <div className='col-12 mt-2'>
                        {React.useMemo(
                            () => (
                                <DataTable
                                    tableClass={'mt-4 table-info--lastColHolidays table-info--notFixed'}
                                    responsive="xl"
                                    thead={availableJobShiftsContet.thead}
                                    tbody={tableData.map((item) => ({
                                        id: item.id,
                                        jobName: item.jobName,
                                        distance: convertToKm(item.distance ? +item.distance : 0),
                                        startDate: momentDateFormat(item.startDate),
                                        AspStartTime: `${item.aspStartDate ? momentHoursFormat(item.aspStartDate, item.timeFrom, true) : ''}`,
                                        times: `${momentHoursFormat(item.timeFrom)} - ${momentHoursFormat(item.timeTo, item.timeFrom)}`,
                                        shiftPeriod: matchOptionWithName(item.shiftPeriod, vocabulary),
                                    }))}
                                    ignoreCols={[0]}
                                    isLoading={isLoading}>
                                    {(id) => (
                                        <>
                                            <td className='align-middle'>
                                                <div>
                                                    <button className='btn btn-aqua-blue' onClick={() => onAssignShiftClick(id)}>
                                                        <SVGPlus />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className='align-middle'>{getHolidayField(id, tableData)}</td>
                                        </>
                                    )}
                                </DataTable>
                            ),
                            [tableData, onAssignShiftClick, isLoading]
                        )}
                    </div>
                </div>
            </section>
            {(modals.assignShift) && (
                <BaseModal
                    show={modals.assignShift}
                    onSubmit={() => (onAssignShiftSubmit())}
                    onCancel={() => setModals({ ...modals, assignShift: false, assignSchedule: false })}
                    submitBtnText={'Confirm'}>
                    <ConfirmationModal
                        text={`Are you sure you want to assign <span class="font-weight-bold">${userName} </span>
                        to this shift of <span class="font-weight-bold">${shift.name}</span>?`}
                    />
                </BaseModal>
            )}
            {(modals.assignSchedule) && (
                <BaseModal
                    show={modals.assignSchedule}
                    onSubmit={() => (onAssignScheduleSubmit())}
                    onCancel={() => setModals({ ...modals, assignShift: false, assignSchedule: false })}
                    submitBtnText={'Confirm'}>
                    <ConfirmationModal
                        text={`Are you sure you want to assign <span class="font-weight-bold">${userName} </span>
                        to this schedule of <span class="font-weight-bold">${schedule.name}</span>?`}
                    />
                </BaseModal>
            )}
            {(modals.assignShiftSuccess || modals.assignScheduleSuccess) && (
                <ShiftAssignedSuccessModal
                    show={modals.assignShiftSuccess || modals.assignScheduleSuccess}
                    onClose={() => setModals({ ...modals, assignShiftSuccess: false, assignScheduleSuccess: false })}
                    selectedGuard={userName}
                    selectedShift={modals.assignShiftSuccess ? shift.selectedJob : schedule.selectedSchedule}
                    vocabulary={vocabulary}
                    isSchedule={modals.assignScheduleSuccess ? true : false}
                />
            )}
        </>
    );
};

export default UpdateAssignments;
